import { Close } from '@mui/icons-material';
import { Box, FormControlLabel, Grid, IconButton, Paper, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { FormModalContainer } from '../../../../../../view/design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../view/design/form/FormActions';
import { FormTextArea } from '../../../../../../view/design/form/FormTextArea';
import { FormTextField } from '../../../../../../view/design/form/FormTextField';
import { RiskManagerRiskAdminModalDomain } from './RiskManagerRiskAdminModalDomain';

export interface IRiskManagerRiskAdminModalAdminModalProperties {
  domain: RiskManagerRiskAdminModalDomain;
}

export const RiskManagerRiskAdminModal = observer(({ domain }: IRiskManagerRiskAdminModalAdminModalProperties) => {
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <TagNameCustomFieldText domain={domain} />
      <TagDescriptionCustomFieldArea domain={domain} />
      <TagOrderCustomFieldText domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const TagNameCustomFieldText = observer(({ domain }: { domain: RiskManagerRiskAdminModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const TagDescriptionCustomFieldArea = observer(({ domain }: { domain: RiskManagerRiskAdminModalDomain }) => {
  return (
    <FormTextArea
      required={true}
      label={'Описание'}
      value={domain.ui.model.entity.description}
      onChange={domain.getUpdateFieldHandler('description')}
      errorMessage={domain.getValidationErrorFor('description')?.message}
    />
  );
});

const TagOrderCustomFieldText = observer(({ domain }: { domain: RiskManagerRiskAdminModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.sortIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('sortIndex')}
      errorMessage={domain.getValidationErrorFor('sortIndex')?.message}
    />
  );
});
