import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';

export const extendedGenerateName = ({
  domain,
  reportName,
}: {
  domain: ApplicationDetailDomain;
  reportName: string;
}): string => {
  const shortedName = domain.ui.application.entity.customInformation?.fields?.find(
    (field) => field.fieldName.toLocaleLowerCase() === 'b. Краткое название АС'.toLocaleLowerCase(),
  )?.value;

  return `${shortedName ? shortedName : domain.ui.application.entity.name}${reportName ? '.' + reportName : ''}`;
};
