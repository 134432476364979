import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { AnalyticsDomain } from "../store/AnalyticsDomain";
import { AnalyticsRequirements } from "./requirementsAnalytics/AnalyticsRequirements";

export const AnalyticsPageContent = observer(({ domain }: { domain: AnalyticsDomain }) => {

  return (
    <Grid container width={'100%'} >
      {domain.ui.analyticsPageTabs.value === 'requirementsAnalytics' ?
        <AnalyticsRequirements domain={domain} /> : <div>В разработке.</div>
      }
    </Grid>
  )
})
