import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { UserDataLevel } from '../../../../../service/userData/IUserDataModel';

export interface ILevelBarsProps {
  level: UserDataLevel;
}

export const LevelBars = observer(({ level }: ILevelBarsProps) => {
  return (
    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      {level === UserDataLevel.JUNIOR ? (
        <>
          <Box sx={{ display: 'flex', gap: '2px' }}>
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(244, 67, 54, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 255, 255, 0.12)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 255, 255, 0.12)' }} />
          </Box>
          <Typography>Новичок</Typography>
        </>
      ) : level === UserDataLevel.MIDDLE ? (
        <>
          {' '}
          <Box sx={{ display: 'flex', gap: '2px' }}>
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 167, 38, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 167, 38, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 255, 255, 0.12)' }} />
          </Box>
          <Typography>Миддл</Typography>
        </>
      ) : level === UserDataLevel.SENIOR ? (
        <>
          <Box sx={{ display: 'flex', gap: '2px' }}>
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(102, 187, 106, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(102, 187, 106, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(102, 187, 106, 1)' }} />
          </Box>
          <Typography>Чемпион</Typography>
        </>
      ) : (
        ''
      )}
    </Box>
  );
});
