import { ArrowDownward, ArrowUpward, DeleteForever, VisibilityOff } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { IMainLayoutDomainStore } from '../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { IFile } from '../../../../../../service/unit/interface/theoryBlocks/IFile';
import { FileUpload } from '../../../../fileUpload/FileUpload';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';
import { ImageUploadDomain } from './upload/ImageUploadDomain';
import { FileType } from '../../../../../../../../service/fileStore/IFileStoreService';

export interface ImageProperties {
  data: ITheoryBlock<IFile>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
  layoutDomain: IMainLayoutDomainStore;
}
export const ImageComponent = observer(
  ({ data: { data, pseudoId }, onDelete, onUp, onDown, layoutDomain }: ImageProperties) => {
    const [source, setSource] = useState<string>('link');
    const [uploadDomain] = useState<ImageUploadDomain>(new ImageUploadDomain(layoutDomain));
    data.type = 'image';
    return (
      <Box
        key={pseudoId}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          background: 'rgba(0,0,0,0.2)',
          padding: '16px 42px ',
          '&:hover .action': { visibility: 'visible' },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'flex-end' }}>
          <Typography sx={{ marginRight: 'auto' }}>Изображение</Typography>
          <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
            <DeleteForever
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onDelete();
              }}
            />
            <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {}} />
            <ArrowUpward
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onUp();
              }}
            />
            <ArrowDownward
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onDown();
              }}
            />
          </Box>
        </Box>
        <FormControl sx={{ marginBottom: '32px' }}>
          <RadioGroup
            defaultValue="link"
            onChange={(event) => {
              setSource(event.target.value);
            }}
          >
            <FormControlLabel value="link" control={<Radio />} label="Ссылка" />
            <FormControlLabel value="file" control={<Radio />} label="Файл" />
          </RadioGroup>
        </FormControl>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {source === 'link' && (
            <FormTextFieldStandard
              variant="outlined"
              label="Ссылка"
              value={data.data}
              onChange={(event) => {
                data.data = event;
              }}
            />
          )}
          {source === 'file' && (
            <FileUpload
              uploadDomain={uploadDomain}
              onChange={(event) => (data.data = event)}
              fileType={FileType.IMAGE}
            />
          )}
          <FormTextFieldStandard
            variant="outlined"
            label="Описание"
            multiline
            value={data.indexName}
            onChange={(event) => {
              data.indexName = event;
            }}
          />
        </Box>
      </Box>
    );
  },
);
