import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../../../common/store/interface/IPrimitiveStore';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IStatusEntityModel } from '../../../../../../service/status/entity/IStatusEntityModel';
import { IStatusEntitySearch } from '../../../../../../service/status/entity/IStatusEntitySearch';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { makeMultiAutoCompleteForTable } from '../../../../../design/table/edit/TableMultiAutocomplete';
import { makeMultiAutoCompleteViewForTable } from '../../../../../design/table/edit/TableMultiAutocompleteView';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdminTableDomain } from '../../../table/store/AdminTableDomain';
import { IAdminTableColumn } from '../../../table/store/IAdminTableUI';

export class StatusEntityAdminDomain extends AdminTableDomain<IStatusEntityModel, IStatusEntitySearch> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    protected statusTypes: IPrimitiveStore<any> = injectPrimitive<any>({}),
    protected projectRoles: IPrimitiveStore<any> = injectPrimitive<any>([]),
  ) {
    super(layoutDomain, rootPrivateServices.status.entity);
  }

  setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-update']]),
    );
  }

  protected async loadDependencies() {
    try {
      this.ui.isLoading.setValue(true);

      const statusTypes = await this.rootPrivateServices.status.entity.getValueTypes();
      const projectRoles = await this.rootPrivateServices.projectRole.entity.search({ limit: 10000 });
      this.ui.isLoading.setValue(false);
      this.statusTypes.setValue(this.translateTypes(statusTypes));
      this.projectRoles.setValue(projectRoles.data);
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  private translateTypes(statusTypes: any) {
    if (statusTypes?.enum) {
      statusTypes.enum = 'Выбор значения';
    }
    if (statusTypes?.text) {
      statusTypes.text = 'Текст';
    }

    return statusTypes;
  }

  async loadData() {
    await this.loadDependencies();
    const columns = await this.getColumns();
    this.ui.columns.setList(columns);
    await super.loadData();
  }

  private customSorting(a, b) {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();

    if (aLower < bLower) {
      return -1;
    } else if (aLower > bLower) {
      return 1;
    } else {
      return 0;
    }
  }

  private async getColumns(): Promise<IAdminTableColumn[]> {
    const projectRoles = this.projectRoles.value.map((column: any) => ({
      value: column.id,
      name: column.isActive ? column.name : `${column.name} (неактивен)`,
    }));
    return [
      {
        title: 'Название',
        field: 'name',
        isRequired: true,
        customSort: (a, b) => this.customSorting(a.name, b.name),
      },
      {
        title: 'Описание',
        field: 'description',
        custom: {
          multiline: true,
        },
        isRequired: true,
        customSort: (a, b) => this.customSorting(a.description, b.description),
      },
      {
        title: 'Роли',
        field: 'rolesAccessIds',
        editComponent: makeMultiAutoCompleteForTable('Роли', projectRoles),
        render: makeMultiAutoCompleteViewForTable((item) => item.rolesAccessIds, projectRoles),
      },
      {
        title: 'Порядок отображения',
        type: 'numeric',
        field: 'orderIndex',
        initialEditValue: 1,
        validate: (rowData) =>
          Number.isInteger(rowData.orderIndex)
            && !Number.isNaN(rowData.orderIndex)
              ? true
              : { isValid: false, helperText: 'Введите целое число' }
      },
      {
        title: 'Основной статус в Системах',
        field: 'isPrimaryApplicationStatus',
        type: 'boolean',
        initialEditValue: true,
      },
      {
        title: 'Активен',
        field: 'isActive',
        type: 'boolean',
        initialEditValue: true,
      },
    ];
  }
}
