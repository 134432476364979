import { inject } from '../../../../../../common/container/inject';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../service/LearningRootService';
import { ITheoryBlock } from '../../../../service/unit/interface/IUnitTheory';
import { generateUUID } from '../../../../utils/generateUUID';
import { IUnitDomain } from '../../../admin/page/unit/store/IUnitDomain';
import { ConstructorUI } from './ConstructorUI';
import { ElementFactory } from './elements/ElementFactory';
import { IConsturctorDomain } from './IConstructorDomain';
import { IConstructorUI } from './IConstructorUI';

export class ConstructorDomain implements IConsturctorDomain {
  public ui: IConstructorUI;
  public unitDomain: IUnitDomain;

  constructor(
    unitDomain: IUnitDomain,
    public layoutDomain: IMainLayoutDomainStore,
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {
    this.unitDomain = unitDomain;
    this.ui = new ConstructorUI();
  }

  loadData = async (unitId: string): Promise<void> => {
    this.ui.startLoading();
    const unit = await this.learningRootService.unit.getById(unitId);
    this.unitDomain.ui.unit.setEntity(unit);
    this.ui.endLoading();
  };

  onAddChapter = async () => {
    const chapterId = generateUUID();

    const chapter = {
      title: `Глава ${this.unitDomain.ui.unit.entity.theory.chapters.length + 1}`,
      theoryBlocks: [],
      pseudoId: chapterId,
      sortIndex: this.unitDomain.ui.unit.entity.theory.chapters.length + 1,
    };

    this.unitDomain.ui.unit.entity.theory.chapters.push(chapter);
  };

  onDeleteChapter = (index: number) => {
    if (this.unitDomain.ui.unit.entity.theory.chapters.length !== 1) {
      this.unitDomain.ui.unit.entity.theory.chapters.splice(index, 1);
      this.ui.activeChapter.setValue(this.unitDomain.ui.unit.entity.theory.chapters[0]);
    }
  };

  onAddComponent = (block: ITheoryBlock<any>) => {
    const activeChapter = this.ui.activeChapter.value.pseudoId;
    const newBlock: ITheoryBlock<any> & { Element?: any } = JSON.parse(JSON.stringify(block));
    newBlock.pseudoId = generateUUID();
    newBlock.Element = ElementFactory[newBlock.block];

    this.unitDomain.ui.unit.entity.theory.chapters.forEach((chapter) => {
      if (chapter.pseudoId === activeChapter) {
        chapter.theoryBlocks.push(newBlock);
      }
    });
  };

  onDelete = (index: number) => {
    const activeChapter = this.ui.activeChapter.value.pseudoId;

    this.unitDomain.ui.unit.entity.theory.chapters.forEach((chapter) => {
      if (chapter.pseudoId === activeChapter) {
        chapter.theoryBlocks.splice(index, 1);
      }
    });
  };

  onClearAll = () => {
    const activeChapter = this.ui.activeChapter.value.pseudoId;

    this.unitDomain.ui.unit.entity.theory.chapters.forEach((chapter) => {
      if (chapter.pseudoId === activeChapter) {
        chapter.theoryBlocks = [];
      }
    });
  };

  onHide = () => {};

  onUp = (index: number) => {
    const activeChapter = this.ui.activeChapter.value.pseudoId;

    this.unitDomain.ui.unit.entity.theory.chapters.forEach((chapter) => {
      if (chapter.pseudoId === activeChapter) {
        if (index === 0) {
          const block = chapter.theoryBlocks.shift();
          chapter.theoryBlocks.push(block!);
        } else {
          const block = chapter.theoryBlocks[index];
          chapter.theoryBlocks[index] = chapter.theoryBlocks[index - 1];
          chapter.theoryBlocks[index - 1] = block;
        }
      }
    });
  };

  onDown = (index: number) => {
    const activeChapter = this.ui.activeChapter.value.pseudoId;

    this.unitDomain.ui.unit.entity.theory.chapters.forEach((chapter) => {
      if (chapter.pseudoId === activeChapter) {
        if (index === chapter.theoryBlocks.length - 1) {
          const block = chapter.theoryBlocks.pop();
          chapter.theoryBlocks.unshift(block!);
        } else {
          const block = chapter.theoryBlocks[index];
          chapter.theoryBlocks[index] = chapter.theoryBlocks[index + 1];
          chapter.theoryBlocks[index + 1] = block;
        }
      }
    });
  };

  reindexChapter = () => {
    let index = 1;
    this.unitDomain.ui.unit.entity.theory.chapters.map((chapter) => {
      chapter.title = `Chapter ${index}`;
      index++;
      return chapter;
    });
    this.ui.activeChapter.setValue(this.unitDomain.ui.unit.entity.theory[0]);
  };
  drugReorder = (startIndex, endIndex) => {
    const result = Array.from(this.unitDomain.ui.unit.entity.theory.chapters);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    this.unitDomain.ui.unit.entity.theory.chapters = result;
  };
  onDrugEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    this.drugReorder(result.source.index, result.destination.index);
  };
}
