export const order = [
  "1. Полные наименования автоматизируемых процессов с указанием их кодов".toLocaleLowerCase(),
  "2. Наименования этапов процессов с указанием их кодов".toLocaleLowerCase(),
  "3. (A). Тип автоматизируемого банковского технологического процесса (ТП)".toLocaleLowerCase(),
  "ожидаемое количество транзакций в сутки".toLocaleLowerCase(),
  "4. a. Полное название автоматизированной системы (АС)".toLocaleLowerCase(),
  "b. Краткое название АС".toLocaleLowerCase(),
  "c. Номер АС в Реестре АС".toLocaleLowerCase(),
  "5. Назначение АС, перечень выполняемых ей функций".toLocaleLowerCase(),
  "Если какие-либо функции АС касаются работы с банковскими картами, то явно указать к какой области это относится".toLocaleLowerCase(),
  "6. Заказчик АС".toLocaleLowerCase(),
  "7. Владелец АС".toLocaleLowerCase(),
  "8. (B). Категории обрабатываемой в АС информации".toLocaleLowerCase(),
  "(B3) Информация категории «Банковская тайна» (БТ) (платежная информация), в том числе".toLocaleLowerCase(),
  "(PCD) БТ.1.7. Информация, нанесенная на банковские карты".toLocaleLowerCase(),
  "(B5) Персональные данные".toLocaleLowerCase(),
  "Категория ПДн".toLocaleLowerCase(),
  "Категория субъектов ПДн".toLocaleLowerCase(),
  "Количество субъектов ПДн".toLocaleLowerCase(),
  "Тип актуальных угроз".toLocaleLowerCase(),
  "Уровень защищенности ПДн".toLocaleLowerCase(),
  "В АС планируется взаимодействие со следующими АС".toLocaleLowerCase(),
  "Получение данных из автоматизированных систем".toLocaleLowerCase(),
  "Получатели информации из АС".toLocaleLowerCase(),
  "10. (E). Требуемые каналы доступа к АС".toLocaleLowerCase(),
  "a. (EA) Из внутренней сети Банка".toLocaleLowerCase(),
  "b. (EC) Через Мобильный офис/АС ПКМ".toLocaleLowerCase(),
  "c. (ED) Через общедоступные сети (Интернет)".toLocaleLowerCase(),
  "11. Предполагаемое количество пользователей АС".toLocaleLowerCase(),
  "Предполагаемое количество групп/ролей в АС".toLocaleLowerCase(),
  "12. (F). Автоматизация предоставления доступа к АС".toLocaleLowerCase(),
  "13. (H). Планируется ли применение сертифицированных ФСБ России средств криптографической защиты информации (СКЗИ)?".toLocaleLowerCase(),
  "14. (G) АС является клиентским мобильным приложением, интернет-банком / клиентским web-приложением".toLocaleLowerCase(),
  "15. (K) В АС планируется использовать контейнерную виртуализацию".toLocaleLowerCase(),
  "16. Архитектура АС (вид клиента)".toLocaleLowerCase(),
  "17. (M) В АС планируется использование одноразовых кодов (СМС, Push, …)".toLocaleLowerCase(),
  "18. Принадлежность АС к контуру PCI DSS".toLocaleLowerCase(),
  "19. (I). Способ создания продукта".toLocaleLowerCase(),
  "20. В АС планируется обработка ПДн граждан Евросоюза".toLocaleLowerCase(),
  "21. (J). Дополнительные условия/ограничения/требования Заказчика АС к проектируемой АС".toLocaleLowerCase(),
  "Руководитель ССП ИТ-блока, выполняющего работы по созданию (модернизации) АС".toLocaleLowerCase()
];

export const sortCallBack = (a, b) => {
  const aIndex = order.indexOf(a.name.toLocaleLowerCase());
  const bIndex = order.indexOf(b.name.toLocaleLowerCase());

  if (aIndex === -1 && bIndex === -1) {
    return 0;
  } else if (aIndex === -1) {
    return 1;
  } else if (bIndex === -1) {
    return -1;
  } else {
    return aIndex - bIndex;
  }
}
