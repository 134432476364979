import { Search } from '@mui/icons-material';
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useState } from 'react';

import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { EmptyPage } from '../../../design/emptyPage/EmptyPage';
import adminEmptyPage from './parts/adminEmptyPage.png';
import { UnitListCard } from './parts/UnitListCard';
import { IUnitListAdminPageDomain } from './store/IUnitListAdminPageDomain';
import { UnitListAdminPageDomain } from './store/UnitListAdminPageDomain';

enum FilterValues {
  All = 'all',
  Published = 'published',
  NotPublished = 'notPublished',
}

export interface IUnitListAdminPage {
  layoutDomain: IMainLayoutDomainStore;
}
export const UnitListAdminPage = observer(({ layoutDomain }: IUnitListAdminPage) => {
  const [domain] = useState<IUnitListAdminPageDomain>(new UnitListAdminPageDomain(layoutDomain));
  const [filter, setFilter] = useState<FilterValues>(FilterValues.All);

  useEffect(() => {
    domain.loadData();
  }, [domain]);

  return (
    <>
      {domain.ui.isLoading.value ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {domain.ui.unitList.list.length === 0 ? (
            <EmptyPage
              image={adminEmptyPage}
              title={'Создавайте здесь юниты с теорией, квизом и практикой'}
              buttonTitle={'Создать юнит'}
              buttonAction={domain.redirectToCreateUnit}
            />
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', marginTop: '48px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px', alignItems: 'flex-end' }}>
                <TextField
                  onChange={(event) => {
                    domain.handleSearchByGroupsAndName(event.target.value);
                  }}
                  variant="standard"
                  sx={{ flex: '1' }}
                  label="Название контекста или юнита"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <Search />
                      </IconButton>
                    ),
                  }}
                />
                <FormControl variant="standard" sx={{ flex: '1' }}>
                  <InputLabel>Показывать</InputLabel>
                  <Select
                    value={filter}
                    onChange={(event) => {
                      setFilter(event.target.value as FilterValues);
                    }}
                  >
                    <MenuItem value={FilterValues.All}>Все</MenuItem>
                    <MenuItem value={FilterValues.Published}>Опубликованные</MenuItem>
                    <MenuItem value={FilterValues.NotPublished}>Неопубликованные</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3,1fr)',
                  columnGap: '24px',
                  alignItems: 'baseline',
                  rowGap: '32px',
                  paddingBottom: '64px',
                }}
              >
                {domain.ui.filtredGroupedUnits.list.map((group) => {
                  const publishedUnits = group.unitsList.filter((item) => item.isPublished);
                  const notPublishedUnits = group.unitsList.filter((item) => !item.isPublished);

                  if (filter === FilterValues.All && group.unitsList.length) {
                    return <UnitListCard title={group.groupName} units={group.unitsList} domain={domain} />;
                  } else if (filter === FilterValues.Published && publishedUnits.length) {
                    return <UnitListCard title={group.groupName} units={publishedUnits} domain={domain} />;
                  } else if (filter === FilterValues.NotPublished && notPublishedUnits.length) {
                    return <UnitListCard title={group.groupName} units={notPublishedUnits} domain={domain} />;
                  }
                })}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
});
