import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { ICommonProperties } from '../../../../../common/properties/ICommonProperties';
import { FooterButtons } from '../footerButtons';
import { IPassingStepperDomain } from './store/IPassingStepperDomain';

export interface IPassingStepperProperties extends ICommonProperties {
  stepperDomain: IPassingStepperDomain;
}
export const PassingStepper = observer(({ stepperDomain }: IPassingStepperProperties) => {
  const stepContentList = stepperDomain.rootDomain.ui.stepContent.list;
  const activeStepIndex = stepperDomain.rootDomain.ui.activeStep.value;
  useEffect(() => {
    stepperDomain.rootDomain.boot();
    const timerId = setInterval(() => {
      stepperDomain?.rootDomain?.getIsEnableRePassing?.();
    }, 60000);
    return () => clearInterval(timerId);
  }, [stepperDomain, stepperDomain.rootDomain]);

  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.contentContainer}>{stepContentList?.[activeStepIndex]?.component}</div>

      <FooterButtons stepperDomain={stepperDomain} />
    </div>
  );
});

export const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    height: '100%',
    overflowY: 'auto',
    width: '100%',
  },
}));
