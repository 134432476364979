import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Grid, IconButton, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent, useContext } from 'react';

import { ContainerContext } from '../../../../../../common/container/ContainerContext';
import { serverToLocalDate, timeSince } from '../../../../../../service/date/since';
import { Colors } from '../../../../../design/color/Colors';
import { ListItemLink, ListItemLinkColor } from '../../../../../design/list/ListItemLink';
import { ConfirmModal } from '../../../../../design/modal/confirm/ConfirmModal';
import { UserProjectItemStatus, UserProjectItemView } from '../store/model/UserProjectItemView';
import { ProjectListDomain } from '../store/ProjectListDomain';
import { UserProjectTableExtensions } from './extension/UserProjectTableExtensions';

export interface UserProjectTableRowProperties {
  item: UserProjectItemView;
  domain: ProjectListDomain;
}

const getIconStyle = (color: string) => {
  return { color, fontSize: 35 };
};

const createProjectLink = (projectId: string): string => {
  return `/project/${projectId}/info`;
};

export const UserProjectTableRow: FunctionComponent<UserProjectTableRowProperties> = ({ item, domain }) => {
  const classes = useStyles();
  const { id, title, subtitle, projectApplicationsCount, projectRequirementCount, status } = item;
  const userProjectTableExtensions = useContext(ContainerContext).get(UserProjectTableExtensions);

  const deleteConfirmation = () => (
    <ConfirmModal
      title="Удалить проект?"
      text={`Вы уверены, что хотите удалить проект '${title}' ?`}
      onCancel={async () => {
        domain.layoutDomain.modalWindow.closeModalWindow();
      }}
      onOk={async () => {
        domain.onDeleteProject(id);
        domain.layoutDomain.modalWindow.closeModalWindow();
      }}
    />
  );

  const onTryToDelete = () => {
    domain.layoutDomain.modalWindow.showModalWindow(deleteConfirmation);
  };

  const trendIconStyle = () => {
    switch (status) {
      case UserProjectItemStatus.down:
        return <TrendingDownIcon style={getIconStyle(Colors.negativeActive)} />;
      case UserProjectItemStatus.grow:
        return <TrendingUpIcon style={getIconStyle(Colors.positiveActive)} />;
      case UserProjectItemStatus.stable:
        return item.lastActivity === null ? (
          <TrendingDownIcon style={getIconStyle(Colors.negativeActive)} />
        ) : (
          <TrendingFlatIcon style={getIconStyle(Colors.warningActive)} />
        );
    }
  };

  const isCanEdit = domain.getIsCanEdit(id);
  const isCanDelete = domain.getIsCanDelete(id);
  return (
    <TableRow key={id}>
      {domain.ui.tableSettings.value.showName && (
        <TableCell className={classes.titleCell} component="th" scope="row">
          <ListItemLink
            color={ListItemLinkColor.light}
            itemKey={`${id}${title}`}
            primaryText={title}
            secondaryText={subtitle}
            url={createProjectLink(id)}
            dataCy={`${title}-project-info`}
            primaryTextClassName={classes.primaryTitleText}
          />
        </TableCell>
      )}
      {domain.ui.tableSettings.value.showSystemsCount && (
        <TableCell className={classes.systemCell} align="right">
          {projectApplicationsCount}
        </TableCell>
      )}{' '}
      {domain.ui.tableSettings.value.showRequirementsCount && (
        <TableCell className={classes.requirementCell} align="right">
          {projectRequirementCount}
        </TableCell>
      )}
      {domain.ui.tableSettings.value.showLastActive && (
        <TableCell className={classes.stateCell} align="right">
          <Grid container justifyContent="flex-end" alignItems="flex-end" direction="row">
            <Grid className={classes.lastActivityText} item>
              {timeSince(serverToLocalDate(item.lastActivity || null))}
            </Grid>
            <Grid item>{trendIconStyle()}</Grid>
          </Grid>
        </TableCell>
      )}
      {userProjectTableExtensions.map((Extension) => (
        <Extension.RowComponent domain={domain} item={item} />
      ))}
      {(isCanDelete === true || isCanEdit === true) && (
        <TableCell className={classes.actionsCell} align="right">
          <Grid container justifyContent="flex-end" alignItems="flex-end" direction="row">
            {isCanEdit && (
              <Grid item>
                <IconButton
                  onClick={() => {
                    domain.onEditProject(id);
                  }}
                  color="secondary"
                  aria-label="Редактировать"
                  component="span"
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            )}
            {isCanDelete && (
              <Grid item>
                <IconButton
                  onClick={() => {
                    onTryToDelete();
                  }}
                  color="secondary"
                  aria-label="Удалить"
                  component="span"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </TableCell>
      )}
    </TableRow>
  );
};

export const useStyles = makeStyles(() => {
  const cellStyle = {
    borderBottom: 'none',
    verticalAlign: 'top',
  };

  return {
    lastActivityText: {
      paddingBottom: '10px',
      paddingRight: '10px',
    },
    primaryTitleText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '24px',
      // lineHeight: '36px',
    },
    secondaryTitleText: {},
    titleCell: {
      maxWidth: '720px;',
      ...cellStyle,
    },
    systemCell: {
      maxWidth: '130px',
      minWidth: '130px',
      paddingTop: '30px',

      ...cellStyle,
    },
    stateCell: {
      maxWidth: '50px',
      minWidth: '50px',
      paddingTop: '20px',
      ...cellStyle,
    },
    requirementCell: {
      maxWidth: '162px',
      minWidth: '162px',
      paddingTop: '30px',
      ...cellStyle,
    },
    actionsCell: {
      maxWidth: '152px',
      minWidth: '152px',
      paddingTop: '12px',
      ...cellStyle,
    },
  };
});
