import { inject } from '../../../../../../../common/container/inject';
import { IRequirementService } from '../../../../../../../service/requirement/entity/IRequirementService';
import { RequirementType } from '../../../../../../../service/requirement/entity/RequirementType';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { FormDomain } from '../../../../form/store/FormDomain';
import { RequirementFormModel } from './RequirementFormModel';
import { RequirementFormUI } from './RequirementFormUI';

export class RequirementFormDomain extends FormDomain<RequirementFormModel, IRequirementService> {
  private onSave: any = null;
  private onCancel: any = null;
  private onDelete: any = null;
  private isDelete: boolean = false;

  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    public ui = new RequirementFormUI(),
  ) {
    super(layoutDomain, rootService.requirement.entity);
  }

  loadData = async (id: string | null, applicationId?: any) => {
    await super.loadData(id);
    if (applicationId) {
      this.ui.applicationId.setValue(applicationId);
      this.ui.type.setValue(RequirementType.local);
    } else {
      this.ui.type.setValue(RequirementType.system);
    }

    await this.loadDependencies();
  };

  //@todo Promise.all
  loadDependencies = async () => {
    try {
      this.ui.isLoading.setValue(true);

      const defaultSearchQuery = { limit: 1000000 };
      const tagsSearch = await this.rootService.tag.entity.search(defaultSearchQuery);

      const tagsCategoriesSearch = await this.rootService.tag.category.search(defaultSearchQuery);
      const specificationsSearch = await this.rootService.specification.entity.search(defaultSearchQuery);
      const specificationsCategoriesSearch = await this.rootService.specification.category.search(defaultSearchQuery);
      const performerTypesSearch = await this.rootService.performer.type.search(defaultSearchQuery);
      const categories = await this.rootService.requirement.category.search(defaultSearchQuery);

      this.ui.tags.setList(tagsSearch.data);
      this.ui.tagsCategories.setList(tagsCategoriesSearch.data);
      this.ui.specifications.setList(specificationsSearch.data);
      this.ui.performersType.setList(performerTypesSearch.data);
      this.ui.categories.setList(categories.data);
      this.ui.specificationsCategories.setList(specificationsCategoriesSearch.data);

      this.ui.isLoading.setValue(false);
    } catch (error) {
      return this.errorsHandler(error);
    }
  };

  setShortNameGeneratorState(isCanGenerate: boolean) {
    this.ui.isCanGenerateShortName.setValue(isCanGenerate);
  }

  generateShortName = async (): Promise<string> => {
    const categoryId = this.ui.model.entity.categoryId || null;
    if (categoryId && this.ui.isCanGenerateShortName.value === true) {
      const categoryRequirements = await this.rootService.requirement.entity.search({
        filter: { categoryId: { equal: categoryId }, type: { equal: RequirementType.system } },
      });
      const namesNumbers = categoryRequirements.data.map((requirement) =>
        Math.round(Number(requirement.shortName?.replace(/^\D+/g, '') || 0)),
      );

      namesNumbers.push(0);
      const lastNameIndex = Math.max(...namesNumbers.filter((item) => `${item}` !== `NaN`));
      const newNameIndex = lastNameIndex + 1;
      const categoryName = this.ui.categories.list.find((category) => category.id === categoryId)?.shortName || '';
      const generatedShortName = `${categoryName}${newNameIndex}`;
      this.ui.model.entity.shortName = generatedShortName;
    }

    return '';
  };

  setEvents(onDelete: any, onSave: any, onCancel: any) {
    this.onDelete = onDelete || this.onDelete;
    this.onSave = onSave || this.onSave;
    this.onCancel = onCancel || this.onCancel;
  }

  setIsDelete(isDelete: any) {
    this.isDelete = isDelete === true;
  }

  save = async () => {
    this.ui.isLoading.setValue(true)
    const type = this.ui.type.value;
    if (type === RequirementType.local) {
      await this.saveLocal(this.ui.applicationId.value);
    }
    if (type === RequirementType.system) {
      await this.saveSystem();
    }
    this.ui.isLoading.setValue(false)
  };

  saveLocal = async (applicationId: string) => {
    await this.removeValidationErrors();
    const entity = {
      ...this.ui.model.entity,
      applicationId,
    };

    let serviceCallHandler: any = null;

    serviceCallHandler = entity.id
      ? this.rootService.requirement.entity.updateLocal(entity)
      : this.entityService.createLocal(entity);

    await this.callService(serviceCallHandler);
    if (this.ui.validationErrors.list.length === 0) {
      if (this.onSave) {
        this.onSave();
      } else {
        this.router.goTo(`/application/${applicationId}/info`);
      }
    }
    this.ui.isLoading.setValue(false)
  };

  saveSystem = async () => {
    await this.removeValidationErrors();
    const entity = this.ui.model.entity;
    let serviceCallHandler: any = entity.id
      ? this.rootService.requirement.entity.updateByModel(entity)
      : this.entityService.createByModel(entity);

    await this.callService(serviceCallHandler);
    if (this.ui.validationErrors.list.length === 0) {
      if (this.onSave) {
        this.onSave();
      } else {
        this.router.goTo('/settings/requirement/entity');
      }
    }
    this.ui.isLoading.setValue(false)
  };

  delete = async () => {
    if (this.isDelete) {
      const type = this.ui.type.value;
      if (type === RequirementType.local) {
        await this.rootService.requirement.entity.deleteLocal(this.ui.model.entity.id || '');
      }
      if (type === RequirementType.system) {
        await this.rootService.requirement.entity.deleteById(this.ui.model.entity.id || '');
      }
      if (this.onDelete) {
        this.onDelete();
      }
    }
  };

  cancelEdit = async () => {
    if (this.onCancel) {
      this.onCancel();
    } else {
      const type = this.ui.type.value;
      if (type === RequirementType.local) {
        this.router.goTo(`/application/${this.ui.applicationId.value}/info`);
      }
      if (type === RequirementType.system) {
        this.router.goTo('/settings/requirement/entity');
      }
    }
  };
}
