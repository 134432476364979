import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Theme, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContainerContext } from '../../../../../common/container/ContainerContext';
import { ConstructorType, injectValue } from '../../../../../common/container/inject';
import { ProjectButtonLink } from '../../../../design/link/ProjectButtonLink';
import { PageEmptyContent } from '../../../../design/page/PageEmptyContent';
import { PageLoader } from '../../../../design/page/PageLoader';
import { IUserPageProperties } from '../../IUserPageProperties';
import { ProjectInfoButtonInject } from './inject/ProjectInfoButtonInject';
import { ProjectApplicationsTable } from './parts/ProjectApplicationsTable';
import { ProjectInfoHeader } from './parts/ProjectInfoHeader';
import { ProjectTeamTable } from './parts/ProjectTeamTable';
import { IProjectView } from './store/model/IProjectView';
import { ProjectInfoDomain } from './store/ProjectInfoDomain';
import { TransferModalWindow } from './parts/TransferModalWindow';
import { AllApplicationsPageHeader } from '../info/parts/ApplicationPageHeader'
import { LoadingButton } from '@mui/lab';

export interface IProjectInfoProperties extends IUserPageProperties { }

export const ProjectInfo = observer(({ layoutDomain }: IProjectInfoProperties) => {
  const [pageDomain] = useState<ProjectInfoDomain>(
    new (injectValue<ConstructorType<ProjectInfoDomain>>(ProjectInfoDomain))(layoutDomain),
  );
  const { projectId = undefined } = useParams<any>();
  const classes = useStyles();
  const currentProjects =
    pageDomain.ui.filteredProjects.list.length || pageDomain.ui.searchTerm.value
      ? pageDomain.ui.filteredProjects.list : pageDomain.ui.projects.list
  const isCanLoadMoreProjects = !(pageDomain.ui.projectListRenderLimit.value >= currentProjects.length)
  useEffect(() => {
    const projectListLazyLoad = [
      {
        handler: async () => {
          pageDomain.ui.projectListRenderLimit.setValue(pageDomain.ui.projectListRenderLimit.value + 15)
        },
        idName: `load-more-application-button`,
        isOnce: true,
      },
    ];
    layoutDomain.ui.addDetections(projectListLazyLoad);

    return () => {
      layoutDomain.ui.removeDetections(projectListLazyLoad);
    };
  }, [pageDomain.ui.projectListRenderLimit.value]);


  useEffect(() => {
    pageDomain.loadData(projectId);
  }, [pageDomain, projectId]);

  useEffect(() => {
    if (projectId) {
      layoutDomain.setPageTitle(`Проект: ${pageDomain.ui.projects.list[0].name}`);
    }
  }, [layoutDomain, projectId, pageDomain.ui.projects.list]);

  const projects = pageDomain.ui.projects;
  const isLoading = pageDomain.ui.isLoading.value;
  const isEmptyContent = currentProjects.length === 0;
  const emptyContentMessage = projectId ? 'Проект пуст' : pageDomain.ui.searchTerm ? 'По вашим критериям поиска ничего не найдено' : 'Нет проектов';
  return (
    <>
      {!projectId && <AllApplicationsPageHeader
        projectInfoDomain={pageDomain}
      />}
      <Grid
        className={classes.mainContainer}
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <TransferModalWindow domain={pageDomain} />
        {isLoading ? (
          <PageLoader />
        ) : isEmptyContent ? (
          <PageEmptyContent text={emptyContentMessage} isFullPage={true} />
        ) : projects?.list[0]?.id ? (
          <>
            {currentProjects.map((project, index) => {
              if (index < pageDomain.ui.projectListRenderLimit.value) {
                return (
                  <Fragment key={project.id}>
                    <ProjectView domain={pageDomain} project={project} />
                    <Toolbar />
                  </Fragment>
                )
              }
            })}
            {!projectId && <LoadingButton
              id={`load-more-application-button`}
              onClick={() => pageDomain.ui.projectListRenderLimit.setValue(pageDomain.ui.projectListRenderLimit.value + 10)}
              variant="outlined"
              color="primary"
              style={{ marginTop: '10px', margin: '0 20px 0 16px' }}
              disabled={!isCanLoadMoreProjects}
            >
              {isCanLoadMoreProjects ? 'Загрузить еще' : 'Все проекты загружены'}
            </LoadingButton>}
            <Toolbar />
          </>
        ) : (
          <Grid item className={classes.mainContainer}>
            <Grid container direction="row">
              <PageEmptyContent text="Вы не являетесь участником проектов" isFullPage={false} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
});

const ProjectView = ({ project, domain }: { project: IProjectView; domain: ProjectInfoDomain }) => {
  const container = useContext(ContainerContext);
  const projectInfoButtonInject = container.get(ProjectInfoButtonInject);
  const isEmptyApplications = project.applications.length === 0;
  const isEmptyTeam = project.team.length === 0;
  const classes = useStyles();
  const editUrl = project.isUnitedWithApplication
    ? `/project/edit/${project.id}/application/${project.unitedApplicationId}`
    : `/project/${project.id}/edit`;
  return (
    <Grid item className={classes.mainContainer}>
      <Grid container direction="row">
        <Grid item={true} xs={12}>
          <ProjectInfoHeader project={project} />
        </Grid>
        <Grid item={true} xs={12}>
          {!isEmptyApplications && <Toolbar />}
          <Grid container direction="row" spacing={2}>
            {project.activeUserCanAddApplications && !project.isUnitedWithApplication && (
              <Grid item>
                <ProjectButtonLink
                  icon={<AddIcon />}
                  url={`/project/${project.id}/application/new`}
                  dataCy='add-system-button'
                >
                  Добавить Систему
                </ProjectButtonLink>
              </Grid>
            )}
            {project.activeUserCanEditProject && (
              <Grid item>
                <ProjectButtonLink icon={<EditIcon />} url={editUrl}
                  dataCy='edit-project-button'
                >
                  Редактировать проект
                </ProjectButtonLink>
              </Grid>
            )}
            {projectInfoButtonInject.map((ButtonComponent: any) => {
              return (
                <Grid item>
                  <ButtonComponent domain={domain} layoutDomain={domain.layoutDomain} projectId={project.id} />
                </Grid>
              );
            })}
          </Grid>
          {!isEmptyApplications && (
            <ProjectApplicationsTable domain={domain} project={project} applications={project.applications} />
          )}

          {isEmptyApplications && isEmptyTeam && (
            <PageEmptyContent text="В ваших проектах еще нет созданных АС" isFullPage={false} />
          )}
        </Grid>

        <Grid item={true} xs={12}>
          <Toolbar />
          <ProjectTeamTable
            isEmptyApplications={isEmptyApplications}
            domain={domain}
            projectId={project.id}
            team={project.team}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    width: '100%',
  },
  root: {
    padding: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));
