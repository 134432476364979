import { Button, Divider, FormControlLabel, Grid, Switch, Theme } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { ConfigManager } from '../../../../../../application/config/ConfigManager';
import { Colors } from '../../../../../../view/design/color/Colors';
import { FormModalContainer } from '../../../../../../view/design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../view/design/form/FormActions';
import { FormAutocomplete } from '../../../../../../view/design/form/FormAutocomplete';
import { FormBox } from '../../../../../../view/design/form/FormBox';
import { FormTextField } from '../../../../../../view/design/form/FormTextField';
import { FormTextLabel } from '../../../../../../view/design/form/FormTextLabel';
import { IRiskManagerQuestionAnswerModel } from '../../../../service/question/answer/IRiskManagerQuestionAnswerModel';
import { RiskManagerQuestionFormDomain } from './RiskManagerQuestionFormDomain';

export interface IRiskManagerQuestionFormProperties {
  domain: RiskManagerQuestionFormDomain;
}

export const RiskManagerQuestionForm = observer(({ domain }: IRiskManagerQuestionFormProperties) => {
  const answersEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    answersEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const [pagination, setPagination] = useState({
    page: 1,
    numberPerPage: 25,
    pageCount: 0,
    currentData: [],
  });

  useEffect(() => {
    scrollToBottom();
    setPagination((prevState: any) => ({
      ...prevState,
      pageCount: Math.trunc(domain.ui.model.entity.answersMap.answers.length / prevState.numberPerPage) + 1,
      currentData: domain.ui.model.entity.answersMap.answers.slice(
        (pagination.page - 1) * pagination.numberPerPage,
        (pagination.page - 1) * pagination.numberPerPage + pagination.numberPerPage,
      ),
    }));
  }, [domain.ui.model.entity?.answersMap.answers.length, pagination.numberPerPage, pagination.page]);

  const handlePageClick = (event, page) => {
    setPagination({ ...pagination, page });
  };
  const isSupportAssyst = ConfigManager.getConfig().extensions.getExtensionsConfigByName('ASSYST_INTEGRATION',false);

  const classes = useStyles();
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <div style={{ width: 600 }}>
        <NameField domain={domain} />
        <PresetField domain={domain} />
        <AnswerIsMultiAnswerField domain={domain} />
        {isSupportAssyst && <UpdateFromExternalServer domain={domain} />}
        <OrderField domain={domain} />
        <Grid
          className={classes.answerTitleContainer}
          justifyContent={'space-between'}
          container
          flexDirection="row"
          alignItems={'end'}
        >
          <Grid item>
            <FormTextLabel variant={'h5'} text={'Ответы: '} />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              // className={classes.actionButton}
              onClick={() => {
                domain.ui.addAnswer();
                setPagination((prevState) => ({
                  ...prevState,
                  page: pagination.pageCount,
                }));
                setTimeout(() => scrollToBottom(), 100);
              }}
              // disabled={disabled}
            >
              Добавить ответ
            </Button>
          </Grid>
        </Grid>
        <Grid
          className={classes.answerTitleContainer}
          justifyContent={'center'}
          container
          flexDirection="row"
          alignItems={'center'}
        >
          {pagination.pageCount !== 1 && (
            <Grid item>
              <Pagination
                count={pagination.pageCount}
                size="large"
                page={pagination.page}
                defaultPage={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageClick}
              />
            </Grid>
          )}
        </Grid>
        <Divider light={true} variant={'middle'} />
        <Grid className={classes.answerContainer} flexWrap={'nowrap'} container flexDirection="column">
          {pagination?.currentData &&
            pagination.currentData.map((answer: IRiskManagerQuestionAnswerModel, index) => (
              <Grid key={answer.pseudoId || index} container>
                <AnswerNameField domain={domain} answer={answer} index={index} />
                <AnswerSortIndexField domain={domain} answer={answer} index={index} />
                <AnswerDefaultValueField domain={domain} answer={answer} index={index} />
                <FormBox>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      domain.ui.removeAnswer(answer.pseudoId || '');
                    }}
                    style={{ color: Colors.negativePassive }}
                  >
                    Удалить ответ
                  </Button>
                </FormBox>

                <FormBox>
                  <Divider style={{ width: 470 }} light={true} variant={'fullWidth'} />
                </FormBox>
              </Grid>
            ))}
          {domain.ui.model.entity.answersMap.answers.length === 0 && (
            <Grid container justifyContent="center">
              Нет ответов
            </Grid>
          )}
          <div ref={answersEndRef} />
        </Grid>
        <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
      </div>
    </FormModalContainer>
  );
});

const NameField = observer(({ domain }: { domain: RiskManagerQuestionFormDomain }) => {
  return (
    <FormTextField
      required={true}
      multiline={true}
      rows={4}
      label={'Текст вопроса'}
      value={domain.ui.model.entity.text}
      onChange={domain.getUpdateFieldHandler('text')}
      errorMessage={domain.ui.getQuestionError('text')}
    />
  );
});

const OrderField = observer(({ domain }: { domain: RiskManagerQuestionFormDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.sortIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('sortIndex')}
      errorMessage={domain.getValidationErrorFor('sortIndex')?.message}
    />
  );
});

const PresetField = observer(({ domain }: { domain: RiskManagerQuestionFormDomain }) => {
  const answerPresetsValues = [
    { name: 'Без шаблона', value: null },
    ...domain.ui.answerPresets.list.map((preset) => ({
      value: preset.id,
      name: preset.name,
    })),
  ];
  return (
    <FormAutocomplete
      values={answerPresetsValues}
      onChangeSelect={(newValue: any) => {
        const preset = domain.ui.answerPresets.list.find((preset) => preset.id === newValue.value);

        domain.setPreset(preset || null);
      }}
      key="requirementsRelationType"
      errorMessage={undefined}
      selected={domain.ui.model.entity.presetId}
      label="Шаблон ответа"
    />
  );
});

const AnswerNameField = observer(
  ({
    domain,
    answer,
    index,
  }: {
    domain: RiskManagerQuestionFormDomain;
    answer: IRiskManagerQuestionAnswerModel;
    index: number;
  }) => {
    return (
      <FormTextField
        required={true}
        label={'Название'}
        value={answer.text}
        onChange={(newValue: any) => {
          answer.text = newValue;
          domain.ui.dropPreset();
        }}
        errorMessage={domain.ui.getAnswerError(index, 'text')}
      />
    );
  },
);

const AnswerSortIndexField = observer(
  ({
    domain,
    answer,
    index,
  }: {
    domain: RiskManagerQuestionFormDomain;
    answer: IRiskManagerQuestionAnswerModel;
    index: number;
  }) => {
    return (
      <FormTextField
        type={'number'}
        required={true}
        label={'Порядок сортировки'}
        value={answer.sortIndex?.toString()}
        onChange={(newValue: any) => {
          answer.sortIndex = newValue;
        }}
        errorMessage={domain.ui.getAnswerError(index, 'sortIndex')}
      />
    );
  },
);

const AnswerIsMultiAnswerField = observer(({ domain }: { domain: RiskManagerQuestionFormDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isMultiAnswer);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isMultiAnswer = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isMultiAnswer);
  }, [domain.ui.model.entity.isMultiAnswer]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} />}
      label="Несколько ответов"
    />
  );
});

const UpdateFromExternalServer = observer(({ domain }: { domain: RiskManagerQuestionFormDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isUpdatedByExternalService);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isUpdatedByExternalService = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isUpdatedByExternalService);
  }, [domain.ui.model.entity.isUpdatedByExternalService]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} />}
      label="Синхронизировать с ИТ-каталогом Сервисов"
    />
  );
});

const AnswerDefaultValueField = observer(
  ({
    domain,
    answer,
    index,
  }: {
    domain: RiskManagerQuestionFormDomain;
    answer: IRiskManagerQuestionAnswerModel;
    index: number;
  }) => {
    return (
      <FormTextField
        type={'number'}
        required={true}
        label={'Значение по умолчанию'}
        value={answer.defaultValue?.toString()}
        onChange={(newValue: any) => {
          answer.defaultValue = newValue;
        }}
        errorMessage={domain.ui.getAnswerError(index, 'defaultValue')}
      />
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  answerTitleContainer: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  answerContainer: {
    // padding: theme.spacing(2),
    height: '400px',
    width: '100%',
    // overflow: 'scroll',
    overflow: 'scroll',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}));
