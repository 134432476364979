import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';
import { Colors } from '../color/Colors';
import { LoadingButton } from '@mui/lab';

export interface IFormActionsProperties extends ICommonProperties {
  onSave: any;
  onCancel: any;
  onDelete?: any;
  disabled?: boolean;
  isLoading?: boolean;
}

export const FormActions = observer(
  ({ onCancel, onSave, disabled = false, onDelete = null, isLoading = false }: IFormActionsProperties) => {
    const classes = useStyles();
    return (
      <Grid container className={classes.container}>
        <Grid item>
          <LoadingButton
            variant="contained"
            color="secondary"
            className={classes.actionButton}
            onClick={onCancel}
            loading={isLoading}
            disabled={disabled}
          >
            Отменить
          </LoadingButton>
          {onDelete && (
            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={onDelete}
              disabled={disabled}
              loading={isLoading}
              style={{ color: Colors.negativePassive }}
            >
              Удалить
            </LoadingButton>
          )}
          <LoadingButton
            variant="contained"
            color="secondary"
            size="large"
            className={classes.actionButton}
            startIcon={<SaveIcon />}
            onClick={onSave}
            disabled={disabled}
            loading={isLoading}
          >
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    justifyContent: 'flex-end',
  },
  actionButton: {
    margin: theme.spacing(2),
  },
}));
