import AddIcon from '@mui/icons-material/Add';
import { Grid, Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';

import { ProjectButtonLink } from '../../../../../design/link/ProjectButtonLink';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { AdminTable } from '../../../table/AdminTable';
import { AdditionalColumnContentEntityAdminDomain } from './store/AdditionalColumnContentEntityAdminDomain';

export interface IAdditionalColumnContentEntityAdminTableProperties extends IAdminPageProperties {}

export const AdditionalColumnContentEntityAdminTable = observer(
  ({ layoutDomain }: IAdditionalColumnContentEntityAdminTableProperties) => {
    const [domain] = useState(new AdditionalColumnContentEntityAdminDomain(layoutDomain));
    const classes = useStyles();
    return (
      <Fragment>
        {domain.ui.isCanCreate.value && (
          <Paper className={classes.root} elevation={3}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                {' '}
                <ProjectButtonLink icon={<AddIcon />} url={`/settings/additional/column/content/new`}>
                  Добавить Контент
                </ProjectButtonLink>
              </Grid>
            </Grid>
          </Paper>
        )}
        <AdminTable tableDomain={domain} />
      </Fragment>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));
