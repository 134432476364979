import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IApplicationModel } from './IApplicationModel';
import { IApplicationSearch } from './IApplicationSearch';
import { IApplicationService } from './IApplicationService';

export class RestApplicationService
  extends RestCRUDService<IApplicationModel, IApplicationSearch>
  implements IApplicationService {
  constructor(private type: CRUDServiceType) {
    super();
  }
  async transferApplication(applicationId: string, newProjectId: string) {
    const requestData = {
      applicationId,
      newProjectId,
    }
    await this.request()
      .url(`${this.getEndpoint()}/transferApplication`)
      .post(requestData)
      .json()
      .catch(this.handleError);

    return true;
  }

  async copyApplication(copyData) {
    await this.request()
      .url(`${this.getEndpoint()}/copyApplication`)
      .post(copyData)
      .json()
      .catch(this.handleError);

    return true;
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/application`;
  }
}
