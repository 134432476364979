import Editor from '@monaco-editor/react';
import { FileCopy } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ICodeExample } from '../../../../../../service/passing/interface/IPassingQuiz';
import { IQuestion } from '../../../../../../service/unit/interface/IUnitQuiz';
import { IQuestionsQuizResult } from '../../../../../../service/unitResult/IUnitResultModel';
import { IPassingQuizDomain } from './store/IPassingQuizDomain';

export interface IPassingQuestionProperties {
  question: IQuestion;
  answer?: IQuestionsQuizResult;
  rootDomain?: IPassingQuizDomain;
  index: number;
}

export const PassingQuestion = observer(({ question, answer, rootDomain, index }: IPassingQuestionProperties) => {
  const [currentCode, setCurrentCode] = useState<ICodeExample | null>(
    question.codeExample && question.codeExample.length > 0 ? question.codeExample[0] : null,
  );

  const [editorHeight, setEditorHeight] = useState<number>(0);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [radioValue, setRadioValue] = useState<string>('');

  const maxHeight = 272;
  const editorRef = useRef(null as any);

  const handleEditorMount = (editor) => {
    setTimeout(() => {
      editorRef.current = editor;
      setEditorHeight(editor.getModel().getLineCount() * 18);
      editor.layout();
    }, 0);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    let newSelectedCheckboxes: string[];

    if (isChecked) {
      newSelectedCheckboxes = [...selectedCheckboxes, value];
    } else {
      newSelectedCheckboxes = selectedCheckboxes.filter((item) => item !== value);
    }

    setSelectedCheckboxes(newSelectedCheckboxes);
    rootDomain?.addAnswer(newSelectedCheckboxes, question.pseudoId);
  };
  const useStyles = makeStyles((theme: Theme) => ({
    editor: {
      maxHeight: '272px',
      height: editorHeight > maxHeight ? '100%' : editorHeight,
      '& .monaco-editor .overflow-guard': {
        height: editorHeight,
        borderRadius: '0px 0px 16px 16px',
      },
    },
  }));
  const defaultAnswer = {
    answers: [],
    result: 0,
  };

  const effectiveAnswer = answer || defaultAnswer;
  const classes = useStyles();
  const handleClick = useCallback((code) => {
    setCurrentCode(code);
    handleEditorMount(editorRef.current);
  }, []);
  useEffect(() => {
    if (rootDomain?.ui?.isEbaleToRePass?.value === false && effectiveAnswer.answers.length > 0) {
      setRadioValue(effectiveAnswer.answers[0]);
      setSelectedCheckboxes(effectiveAnswer.answers);
    }
  }, [rootDomain?.ui?.isEbaleToRePass?.value]);
  return (
    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
      <Typography variant="subtitle1" sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
        Вопрос {index + 1}
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: '16px', width: 'inherit', wordWrap: 'break-word' }}>
        <Link
          sx={{ textDecoration: 'none', pointerEvents: 'none', cursor: 'none' }}
          href={`#${question.pseudoId}`}
          id={question.pseudoId}
        >
          {question.name}
        </Link>
      </Typography>
      {question.isCodeExample && (
        <Box>
          <Box sx={{ display: 'flex', gap: '20px', marginBottom: '16px' }}>
            {question.codeExample.map((code: ICodeExample) => (
              <Chip
                key={code.language}
                onClick={() => {
                  handleClick(code);
                }}
                label={code.name}
                sx={{ background: currentCode?.language === code.language ? '#1976D2' : '', cursor: 'pointer' }}
              />
            ))}
          </Box>
          <Box sx={{ marginBottom: '32px' }}>
            <Box
              sx={{
                borderRadius: '16px 16px 0px 0px',
                background: '#1E1E1E',
                padding: '4px 16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignSelf: 'center',
              }}
            >
              <Typography variant="body1">{currentCode?.name}</Typography>
              <IconButton
                sx={{ cursor: 'pointer', fontSize: '20px' }}
                onClick={() => {
                  currentCode && navigator.clipboard.writeText(currentCode?.value);
                }}
              >
                <FileCopy />
              </IconButton>
            </Box>
            {currentCode !== null && (
              <Editor
                className={classes.editor}
                theme="vs-dark"
                height={editorHeight > maxHeight ? maxHeight : editorHeight}
                language={currentCode?.language}
                value={currentCode?.value}
                onMount={handleEditorMount}
                options={{
                  readOnly: true,
                  scrollbar: {
                    vertical: editorHeight > maxHeight ? 'auto' : 'hidden',
                    horizontal: 'hidden',
                    handleMouseWheel: editorHeight > 100 ? true : false,
                  },
                  minimap: { enabled: false },
                }}
              />
            )}
            <Box
              sx={{
                height: '14px',
                borderRadius: '0px 0px 16px 16px',
                background: '#1E1E1E',
              }}
            ></Box>
          </Box>
        </Box>
      )}
      {question.type === 'single' && (
        <FormControl>
          <RadioGroup
            sx={{ gap: '10px' }}
            onChange={(event) => {
              if (rootDomain?.ui?.isEbaleToCheck?.value) {
                setRadioValue(event.target.value);
                rootDomain.addAnswer([event.target.value], question.pseudoId);
              }
            }}
            value={radioValue}
          >
            {question.answers.map((answer) => (
              <FormControlLabel
                sx={{ display: 'flex', alignItems: 'flex-start' }}
                key={answer.answer}
                value={answer.answer}
                control={
                  <Radio
                    sx={{ marginTop: '-8.5px' }}
                    disabled={!rootDomain?.ui?.isEbaleToCheck?.value || !rootDomain?.ui?.isEbaleToRePass?.value}
                  />
                }
                label={answer.answer}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {question.type === 'multi' && (
        <FormControl>
          <FormGroup sx={{ gap: '10px' }}>
            {question.answers.map((answer) => (
              <FormControlLabel
                sx={{ display: 'flex', alignItems: 'flex-start' }}
                key={answer.answer}
                value={answer.answer}
                control={
                  <Checkbox
                    sx={{ marginTop: '-8.5px' }}
                    value={answer.answer}
                    onChange={handleCheckboxChange}
                    checked={selectedCheckboxes.includes(answer.answer)}
                    disabled={!rootDomain?.ui?.isEbaleToCheck?.value || !rootDomain?.ui?.isEbaleToRePass.value}
                  />
                }
                label={answer.answer}
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
      {(!rootDomain?.ui?.isEbaleToCheck?.value || !rootDomain?.ui?.isEbaleToRePass?.value) && (
        <Box sx={{ paddingTop: '16px' }}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: effectiveAnswer.result > 0.5 ? 'rgba(183, 229, 53, 0.1)' : 'rgba(229, 57, 53, 0.1)',
              borderRadius: '8px',
            }}
          >
            <Typography variant="h6">
              {effectiveAnswer.result > 0.5 ? 'Вы ответили правильно!' : 'Вы ответили неправильно!'}
            </Typography>
            <Typography variant="body1">{question.explanation}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
});
