import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { PerformerTypeAdminTable } from '../../performer/type/PerformerTypeAdminTable';
import { TagCategoryAdminTable } from '../../tag/category/TagCategoryAdminTable';
import { TagEntityAdminTable } from '../../tag/entity/TagEntityAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const PerformerSettingsItem: ISettingsItem = {
  id: 'performer',
  settingsItem: {
    key: 'performer',
    title: 'Исполнители требований',
    buttons: [
      {
        title: 'Исполнители',
        url: `/settings/performer/type`,
        permissions: [UserSystemRoleModelPermissionMap['settings-performers-read']],
      },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-performers-read']],
    infoText:
      'Данная характеристика выбирается при создании анкеты для АС и используется для скрытия части полей или требований от внешних разработчиков.',
    orderIndex: 600,
  },
  routers: [
    {
      key: 'performer-type-settings',
      path: '/settings/performer/type',
      pageTitle: 'Тип исполнителей',
      component: PerformerTypeAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-performers-read']],
    },
  ],
};
