import { IFileOption, ITaskLanguage } from '../../../../service/unit/interface/IUnitPractice';
import { generateUUID } from '../../../../utils/generateUUID';
import { IFile } from '../parts/fileTree/IFileTree';
import { CodeEditorUI } from './CodeEditorUI';
import { ICodeEditorDomain } from './ICodeEditorDomain';
import { ICodeEditorUI } from './ICodeEditorUI';
import { CodeMode } from './ICodeMode';

export class CodeEditorDomain implements ICodeEditorDomain {
  public ui: ICodeEditorUI;
  constructor() {
    this.ui = new CodeEditorUI();
  }
  onChangeMode = (mode: string) => {
    this.ui.mode.setValue(CodeMode[mode]);
  };
  openContextMenu = (file: IFile, event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.ui.currentFile.setValue(file);
    this.ui.isContextOpen.setValue(true);
    this.ui.event?.setValue({ clientX: event.clientX, clientY: event.clientY });
  };
  closeContextMenu = () => {
    this.ui.isContextOpen.setValue(false);
  };
  pickingFile = (file: IFile) => {
    if (file.children?.length === undefined) {
      if (this.ui.mode.value === CodeMode.review) {
        this.ui.currentFile.setValue(file);
        this.ui.currentOption.setValue(null as any);
      }
      if (this.ui.mode.value === CodeMode.writing) {
        this.ui.currentFile.setValue(file);
      }
    }
  };
  pickingOption = (option: IFileOption) => {
    this.ui.currentOption.setValue(option);
  };
  addOption = (lang: ITaskLanguage) => {
    lang.options?.push({ name: `Вариант ${lang.options.length + 1}`, isCorrect: false, value: '' });
    const detectQuestion = (file: IFile) => {
      file.children?.forEach((child) => {
        if (child.isQuestion) {
          child.options?.push({ name: `Вариант ${child.options.length + 1}`, isCorrect: false, value: '' });
        } else {
          detectQuestion(child);
        }
      });
    };
    this.ui.fileTree.list.map((file) => detectQuestion(file));
  };
  deleteOption = (option: IFileOption, lang: ITaskLanguage) => {
    lang.options = lang.options?.filter((opt) => opt.name !== option.name);
    const detectQuestion = (file: IFile) => {
      file.children?.forEach((child) => {
        if (child.isQuestion) {
          const filteredOption = child.options?.filter((opt) => opt.name !== option.name);
          child.options = filteredOption;
          this.ui.currentOption.setValue(null as any);
        } else {
          detectQuestion(child);
        }
      });
    };
    this.ui.fileTree.list.map((file) => detectQuestion(file));
  };
  toggleOption = (name: string) => {
    this.ui.lang.value.options?.forEach((opt) => {
      if (opt.name === name) {
        opt.isCorrect = !opt.isCorrect;
      }
    });
    const detectQuestion = (file: IFile) => {
      file.children?.forEach((child) => {
        if (child.isQuestion) {
          child.options?.forEach((opt) => {
            if (opt.name === name) {
              opt.isCorrect = !opt.isCorrect;
            }
          });
        } else {
          detectQuestion(child);
        }
      });
    };
    this.ui.fileTree.list.map((file) => detectQuestion(file));
  };

  newFolder = (currentFile: IFile) => {
    const findFolder = (file: IFile) => {
      file.children?.forEach((child) => {
        if (child.name === currentFile.name) {
          this.ui.currentFile.setValue(file);
        } else {
          findFolder(child);
        }
      });
    };
    if (currentFile.children) {
      this.ui.currentFile.setValue(currentFile);
      this.closeContextMenu();
      this.ui.isInputFolderOpen.setValue(true);
      setTimeout(() => document.getElementById('filetree__input')?.focus(), 0);
    } else {
      this.ui.fileTree.list.map((file) => findFolder(file));
    }
  };
  creatingNewFolder = (value: string) => {
    const newFolder: IFile = {
      id: generateUUID(),
      name: value,
      children: [],
    };
    this.ui.currentFile.value.children?.push(newFolder);
  };
  newFile = (currentFile: IFile) => {
    const findFolder = (file: IFile) => {
      file.children?.forEach((child) => {
        if (child.name === currentFile.name) {
          this.ui.currentFile.setValue(file);
        } else {
          findFolder(child);
        }
      });
    };
    if (currentFile.children) {
      this.ui.currentFile.setValue(currentFile);
      this.closeContextMenu();
      this.ui.isInputFileOpen.setValue(true);
      setTimeout(() => document.getElementById('filetree__input_file')?.focus(), 0);
    } else {
      this.ui.fileTree.list.map((file) => findFolder(file));
    }
  };
  creatingNewFile = (value: string) => {
    const newFile: IFile = {
      id: generateUUID(),
      name: value,
      value: '',
      options: [],
      isQuestion: false,
    };
    this.ui.currentFile.value.children?.push(newFile);
  };

  onDelete = (id: string) => {
    const findFolder = (file: IFile) => {
      file.children?.forEach((child, index) => {
        if (child.id === id) {
          file.children?.splice(index, 1);
        } else {
          findFolder(child);
        }
      });
    };
    this.ui.fileTree.list.map((file) => findFolder(file));

    this.ui.isContextOpen.setValue(false);
  };
  toggleQuestion = (currentFile: IFile) => {
    currentFile.isQuestion = !currentFile.isQuestion;
  };
  onRename = (currentFile: IFile) => {
    this.closeContextMenu();
    this.ui.isInputRenameOpen.setValue(true);
    setTimeout(() => document.getElementById('file_rename')?.focus(), 0);
  };
  rename = (name: string) => {
    this.ui.currentFile.value.name = name;
  };
  onDuplicate = () => {};
}
