import { DoDisturbOn, Done, StarBorder } from '@mui/icons-material';
import React from 'react';

import { UnitStatus } from '../models/unitStatus';
import { IUnitStatusNode } from '../models/unitStatusNode';

export const UNIT_LIST_ITEMS_STYLES: Record<UnitStatus, IUnitStatusNode> = {
  passed: {
    baseColor: 'rgba(46, 125, 50, 0.12)',
    hoverColor: 'rgba(46, 125, 50, 0.18)',
    icon: <Done sx={{ height: '20px', width: '20px', background: 'rgba(102, 187, 106, 1)', borderRadius: '50%' }} />,
  },
  failed: {
    baseColor: '#F443361F',
    hoverColor: '#F443362F',
    icon: <DoDisturbOn sx={{ height: '24px', width: '24px', color: '#F44336', borderRadius: '50%' }} />,
  },
  new: {
    baseColor: 'rgba(144, 202, 249, 0.12)',
    hoverColor: 'rgba(144, 202, 249, 0.18)',
    icon: (
      <StarBorder sx={{ height: '20px', width: '20px', background: 'rgba(25, 118, 210, 1)', borderRadius: '50%' }} />
    ),
  },
};

export const getUnitStatus = (isPassed) => {
  if (isPassed === true) {
    return UnitStatus.Passed;
  } else if (isPassed === false) {
    return UnitStatus.Failed;
  }

  return UnitStatus.New
};
