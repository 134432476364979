import { Paper } from '@mui/material';
import * as React from 'react';
import ReactJson from 'react-json-view';

import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { AdminTableDomain } from '../../../../../view/admin/page/table/store/AdminTableDomain';
import { IAdminTableColumn } from '../../../../../view/admin/page/table/store/IAdminTableUI';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { AdminLogRootService } from '../service/AdminLogRootService';
import { AdminLogModel } from './AdminLogModel';
import { AdminLogsUI } from './AdminLogsUI';

export class AdminLogsDomain extends AdminTableDomain<AdminLogModel, any> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    mvideoService = new AdminLogRootService(),
    public ui: AdminLogsUI = new AdminLogsUI(),
  ) {
    super(layoutDomain, mvideoService.log);
    this.ui.searchRequest.setEntity({
      limit: 1000,
    });
    this.ui.isInlineEdit.setValue(false);
    this.ui.isShowDelete.setValue(false);
    this.ui.tableOptions = {
      search: false,
    };
    this.setDetailPanel();
  }

  setPermissions() {
    super.setPermissions();
    const isCanLogger = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow'],
    ]);

    this.ui.isCanCreate.setValue(isCanLogger);
    this.ui.isCanDelete.setValue(isCanLogger);
    this.ui.isCanEdit.setValue(isCanLogger);
  }

  setDetailPanel() {
    this.ui.detailPanel = [
      {
        tooltip: 'Детали',
        render: (data: { rowData: AdminLogModel }) => {
          //@ts-ignore
          return (
            <Paper
              style={{ padding: '25px', paddingLeft: '27px' }}
              key={`additional_data_${data.rowData.id}`}
              elevation={2}
            >
              <ReactJson src={data.rowData.eventData} theme={'ocean'} />
            </Paper>
          );
        },
      },
    ];
  }

  updateBySearch() {
    this.ui.entities.setList(
      this.ui.allEntities.list.filter((item) => {
        let createDate = item.createDate;
        if (item.createDate) {
          createDate = new Date(item.createDate as number).getTime();
        }
        let isAllowed = true;
        if (this.ui.startDate.value && (createDate || 0) < this.ui.startDate.value) {
          isAllowed = false;
        }
        if (this.ui.endDate.value && (createDate || 0) > this.ui.endDate.value) {
          isAllowed = false;
        }
        if (this.ui.selectedEvents.value.length > 0 && !this.ui.selectedEvents.value.includes(item.event)) {
          isAllowed = false;
        }

        return isAllowed;
      }),
    );
  }

  async loadData() {
    const columns = await this.getColumns();
    this.ui.columns.setList(columns);
    await super.loadData();
    this.ui.allEntities.setList(this.ui.entities.list);
  }

  private async getColumns(): Promise<IAdminTableColumn[]> {
    return [
      {
        title: 'Пользователь',
        field: 'userLogin',
      },
      {
        title: 'Событие',
        field: 'event',
      },
      {
        title: 'Id-события',
        field: 'publicLogId',
      },
      {
        title: 'Время события',
        field: 'createDate',
        type: 'datetime',
      },
      {
        title: 'Текст',
        field: 'eventAsText',
      },
      {
        title: 'DNS',
        field: 'hostDNS',
      },
      {
        title: 'IP',
        field: 'hostIP',
      },
    ];
  }
}
