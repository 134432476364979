import { CheckCircle, FlagOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

export interface IPassingAccordionItemProperties {
  title: string;
  passed: boolean;
  isActive: boolean;
  subHeader: ISubHeader[];
  navigationRedirect: (title: string) => void;
}

export interface IPassingAccordionItemProps {
  title: string;
  passed: boolean;
  isActive: boolean;
  subHeader: ISubHeader[];
  isActiveStep: boolean;
  hash?: string;
  activeAccordion: string;
  onExpandIconClick: (title: string) => void;
  scrollToAnchor: (title: string, hash: string) => void;
  navigationRedirect: (title: string) => void;
}
export interface ISubHeader {
  title: string;
  type: string;
}
export const PassingTheoryAccordionContent = observer(
  ({
    title,
    passed,
    isActive,
    subHeader,
    navigationRedirect,
    isActiveStep,
    hash,
    activeAccordion,
    scrollToAnchor,
    onExpandIconClick,
  }: IPassingAccordionItemProps) => {
    const isExpanded = title === activeAccordion;
    return (
      <Box
        onClick={() => {
          navigationRedirect(title);
        }}
        sx={{
          padding: '4px 10px',
          display: 'flex',
          gap: '10px',
          color: 'white',
          cursor: 'pointer',
          '&:last-child': {
            marginBottom: '10px',
          },
          overflow: 'hidden',
          alignItems: 'center',
        }}
      >
        {!passed ? (
          <FlagOutlined
            sx={{ marginBottom: 'auto', marginTop: '10px', color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }}
          />
        ) : (
          <CheckCircle
            sx={{ marginBottom: 'auto', marginTop: '10px', color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }}
          />
        )}
        {subHeader.length !== 0 ? (
          <Accordion
            expanded={isExpanded}
            onClick={(e) => {
              e.stopPropagation();
              navigationRedirect(title);
              onExpandIconClick(title);
            }}
            sx={{
              width: '100%',
              background: 'transparent',
              boxShadow: 'none',
              margin: 0,
              '& > div:first-of-type': { height: '48px' },
              '&.Mui-expanded > div:first-of-type': { height: '48px', minHeight: '48px !important' },
            }}
          >
            <AccordionSummary>
              <Typography sx={{ color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }}>{title}</Typography>
            </AccordionSummary>
            <Box sx={{ maxHeight: '304px', overflowY: 'scroll' }}>
              {subHeader.map((subtitle) => {
                return (
                  <AccordionDetails
                    sx={{
                      padding: 0,
                      marginBottom: '8px',
                      fontSize: '14px',
                      paddingLeft: subtitle.type,
                      color: hash === subtitle.title ? 'rgba(144, 202, 249, 1)' : '',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      scrollToAnchor(title, subtitle.title);
                    }}
                  >
                    {subtitle.title}
                  </AccordionDetails>
                );
              })}
            </Box>
          </Accordion>
        ) : (
          <Typography
            onClick={() => {
              navigationRedirect(title)
              onExpandIconClick('');
            }}
            sx={{ alignSelf: 'end', paddingLeft: '16px', color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }}
          >
            {title}
          </Typography>
        )}
      </Box>
    );
  },
);
