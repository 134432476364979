import { AddBox, Close, MoreVert } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { ICommonProperties } from '../../../../../common/properties/ICommonProperties';
import { IUnitDomain } from '../../admin/page/unit/store/IUnitDomain';
import { ElementFactory } from './store/elements/ElementFactory';
import { Chapter } from './store/elements/text/Chapter';
import { IConsturctorDomain } from './store/IConstructorDomain';

export interface IConstructorProperties extends ICommonProperties {
  domain: IConsturctorDomain;
  unitDomain: IUnitDomain;
}

const DropItem = observer(({ provided, snapshot, domain, item, index }: any) => {
  return (
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={() => {
        domain.ui.activeChapter.setValue(item);
      }}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        background: item.pseudoId === domain.ui.activeChapter.value.pseudoId ? 'rgba(0,0,0,0.25)' : '',
        padding: '8px 12px',
        marginBottom: '8px',
        borderRadius: '8px',
        marginTop: '8px',
        '&:hover': { background: 'rgba(0,0,0,0.25)' },
        '&:hover .chapter__delete': { visibility: 'visible' },
      }}
    >
      <Typography>{item.title}</Typography>
      <Close
        className="chapter__delete"
        sx={{ visibility: 'hidden', cursor: 'pointer' }}
        onClick={() => domain.onDeleteChapter(index)}
      />
    </Box>
  );
});
export const Constructor = observer(({ domain, unitDomain }: IConstructorProperties) => {
  useEffect(() => {
    domain.ui.activeChapter.setValue(unitDomain.ui.unit.entity.theory.chapters[0]);
  }, [domain, unitDomain]);
  return (
    <>
      <Box sx={{ paddingBottom: '8px', display: 'flex', alignItems: 'center', paddingTop: '16px' }}></Box>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Box
          sx={{
            flex: '0.7',
            background: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            padding: '16px 0px 32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
            height: '100%',
            overflowY: 'scroll',
          }}
        >
          <Button
            variant="contained"
            sx={{ marginLeft: '24px', padding: '5px 10px', alignSelf: 'self-start' }}
            size="small"
            onClick={() => domain.onClearAll()}
          >
            Очистить главу
          </Button>
          <Box sx={{ padding: '0px 24px', width: '100%' }}>
            <Chapter data={domain.ui.activeChapter.value} />
          </Box>
          {domain.ui.activeChapter.value.theoryBlocks.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography>Двойной щелчок для добавления компонента</Typography>
            </Box>
          ) : (
            domain.ui.activeChapter.value.theoryBlocks.map((block, index) => {
              const Element = ElementFactory[block.block];
              const elementData = { data: block.data, pseudoId: block.pseudoId };
              return (
                <Box sx={{ padding: '0px 24px', width: '100%' }} key={block.pseudoId}>
                  <Element
                    layoutDomain={domain.layoutDomain}
                    data={elementData}
                    onDelete={() => {
                      domain.onDelete(index);
                    }}
                    onUp={() => domain.onUp(index)}
                    onDown={() => domain.onDown(index)}
                  />
                </Box>
              );
            })
          )}
        </Box>
        <Divider orientation="vertical" />
        <Box
          sx={{
            flex: '0.3',
            padding: '32px',
            height: '100%',
            overflowY: 'scroll',
            background: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          }}
        >
          <Box sx={{ paddingBottom: '32px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">Главы</Typography>
              <AddBox
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  domain.onAddChapter();
                }}
              />
            </Box>
            {unitDomain.ui.unit.entity.theory.chapters.length !== 0 && (
              <DragDropContext onDragEnd={domain.onDrugEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={{ maxHeight: '220px', overflowY: 'scroll' }}
                    >
                      {unitDomain.ui.unit.entity.theory.chapters.map((item, index) => (
                        <Draggable key={item.pseudoId} draggableId={item.pseudoId} index={index}>
                          {(provided, snapshot) => (
                            <DropItem
                              provided={provided}
                              snapshot={snapshot}
                              domain={domain}
                              item={item}
                              index={index}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Box>
          {domain.ui.componentList.list.map((item) => {
            return (
              <Box sx={{ marginBottom: '12px' }}>
                <Typography variant="h6" sx={{ marginBottom: '12px' }}>
                  {item.type}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  {item.list.map((element) => {
                    return (
                      <Box
                        onDoubleClick={() => {
                          domain.onAddComponent(element);
                        }}
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          background: '#93999C',
                          borderRadius: '8px',
                          color: 'black',
                          padding: '4px 0px',
                          cursor: 'pointer',
                        }}
                      >
                        <MoreVert />
                        <Typography>{element.name}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
});
