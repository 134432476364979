import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { AnalyticsPageHeader } from './parts/AnalyticsPageHeader'
import { AnalyticsDomain } from "./store/AnalyticsDomain";
import { AnalyticsPageContent } from "./parts/AnalyticsPageContent"

export const AnalyticsPage = observer(({ layoutDomain }) => {
  const [analyticsDomain] = useState(new AnalyticsDomain(layoutDomain));

  useEffect(() => {
    analyticsDomain.loadReqData()
  }, [])

  return (
    <Grid container display={'flex'} flexDirection={'column'} width={'100%'}>
      <AnalyticsPageHeader domain={analyticsDomain} />
      <AnalyticsPageContent domain={analyticsDomain} />
    </Grid >
  )
})
