import React from 'react';
import { observer } from 'mobx-react';
import { Clear, Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

const TableHeaderSearchBar = observer(({ tableDomain }) => {
  return (
    <div style={{ width: '100%', marginRight: 'auto', margin: 0, fontSize: '13px', padding: '10px 10px 0px 15px' }}>
      <TextField
        size="small"
        variant="outlined"
        maxRows={1}
        autoComplete={'off'}
        style={{ width: '100%' }}
        color="secondary"
        value={tableDomain.ui.rows.searchTerm.value}
        onChange={(event) => {
          tableDomain.requestSearch(event?.target?.value || '');
        }}
        placeholder='Поиск по таблице'
        autoFocus
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                tableDomain.requestSearch('');
              }}
            >
              <Clear
                sx={{
                  height: '18px',
                }}
              />
            </InputAdornment>
          ),
          style: { fontSize: 13, paddingLeft: 10 }
        }}
        InputLabelProps={{ style: { fontSize: 13 } }}
      />
    </div>
  )
})

export default TableHeaderSearchBar