import { TableCell, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { UserProjectsView } from '../../../../../view/user/page/project/list/parts/UserProjectTable';
import { IRiskManagerProjectListDomain } from './domain/IRiskManagerProjectListDomain';
import LinearProgress from '@mui/material/LinearProgress';

export interface RiskManagerDataInfoInProjectListHeaderProperties {
  userProjectsData: UserProjectsView;
  domain: IRiskManagerProjectListDomain;
}

export const RiskManagerDataInfoInProjectListHeader = observer(
  ({ domain: projectListDomain }: RiskManagerDataInfoInProjectListHeaderProperties) => {
    const classes = useStyles();
    return (
      <TableCell className={classes.header}>
        Риски
        {projectListDomain.isLoadRisks.value && <LinearProgress color="secondary" />}
      </TableCell>
    );
  },
);

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: '200px',
    fontWeight: 'bold',
  },
}));
