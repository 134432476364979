import {
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { Colors } from '../../../../../../design/color/Colors';

const specificationCategoryAdminDetailComponent = observer(({ row, detailsList, redirect }) => {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        paper: Colors.detailsPanel('0.1'),
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{ py: 2, height: 1, boxSizing: 'border-box' }} direction="column">
        <Typography variant="h5" sx={{ py: 2, paddingLeft: '28px' }}>
          Сущности категории "{row.row.name}"
        </Typography>
        <Grid container>
          <Grid item sx={{ width: '100%', padding: '0px 28px' }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" sx={{ width: '100%' }}>
                <TableHead
                  sx={{
                    background: Colors.tableRowDisabled,
                  }}
                >
                  <TableRow>
                    <TableCell align="left">Название</TableCell>
                    <TableCell align="left">Описание</TableCell>
                    <TableCell align="right">Статус</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsList.map((row) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        align="left"
                        component="th"
                        scope="row"
                        onClick={() => {
                          redirect(row.id, '/settings/specification/entity');
                        }}
                      >
                        <Chip label={row.name} sx={{ cursor: 'pointer' }} />
                      </TableCell>
                      <TableCell align="left" sx={{ width: '35%' }}>
                        {row.description}
                      </TableCell>
                      <TableCell align="right">{row.isActive ? 'Активен' : 'Не Активен'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Stack>
    </ThemeProvider>
  );
});

export default specificationCategoryAdminDetailComponent;
