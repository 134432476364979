import React, { useState } from 'react';
import { ApplicationAcceptStatus } from '../../../service/application/entity/IApplicationModel';
import { UserProjectRoleAction } from '../../../service/projectRole/entity/actions/UserProjectRoleAction';
import {
  UserSystemRoleModelPermissionMap
} from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { Button, Grid, Theme } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { makeStyles } from '@mui/styles';
import { Colors } from '../../../view/design/color/Colors';
import { GPBIsCanFinishAcceptanceTests } from '../acceptanceTests/GPBIsCanFinishAcceptanceTests';
import { GPBApplicationDomain } from './store/GPBApplicationDomain';

export const ActionButtons = ({ domain }: { domain: ApplicationDetailDomain }) => {
  const isCanFinishAcceptanceTestsByRole = GPBIsCanFinishAcceptanceTests(domain);

  return <>
    <SendToManager domain={domain}/>
    {isCanFinishAcceptanceTestsByRole && <AcceptanceTestsFinish domain={domain} />}
  </>;
};

const SendToManager = ({ domain }: { domain: ApplicationDetailDomain }) => {
  const classes = useStyles();

  return domain.ui.application.entity.acceptStatus === ApplicationAcceptStatus.review &&
    (domain.ui.userProjectRoleActions.value.includes(UserProjectRoleAction.acceptCancelRequirementAccess) ||
      domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]) ||
      domain.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-accept-cancel-access'],
      ])) ? (
    <Grid key="sendSystemToManager" className={classes.button} item>
      <Button
        variant="outlined"
        onClick={() => {
          domain.setApplicationAcceptStatus(ApplicationAcceptStatus.accepted);
          domain.saveApplication();
        }}
        color="secondary"
      >
        Отправить Менеджеру АС
      </Button>
    </Grid>
  ) : (
    <></>
  );
};

const AcceptanceTestsFinish = ({ domain }: { domain: ApplicationDetailDomain }) => {
  const classes = useStyles();
  const GPBDomain = new GPBApplicationDomain(domain.layoutDomain);
  let [buttonReload, setReload] = useState(false);
  const reload = () => {
    setReload(true);
    setTimeout(() => {setReload(false)}, 2000);
  }

  return (
    <Grid key="AcceptanceTestsFinish" className={classes.button} item>
      <Button
        variant="outlined"
        onClick={async () => {
          reload();
          domain.ui.editApplication.setEntity(domain.ui.application.entity);
          await domain.saveApplication();
          GPBDomain.setRatingSecondValue(domain.ui.applicationData.entity.id || '');
        }}
        color="secondary"
        startIcon={<DoneAllIcon />}
        disabled={buttonReload}
      >
        Завершить ПСИ и сформировать протокол
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(1),
  },
  removeButton: {
    color: Colors.negativePassive,
  },
  acceptButton: {
    color: Colors.positivePassive,
  },
}));