import { loader } from '@monaco-editor/react';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import Application from './application/Application';
import { ConfigLoader } from './application/config/ConfigLoader';
import ErrorBundary from './common/errorPage/ErrorBoundary/ErrorBundary';
import * as serviceWorker from './serviceWorker';

export const cacheDb: IDBOpenDBRequest = indexedDB.open('cache', 1);

loader.config({ paths: { vs: '/monaco-editor/min/vs' } });
LicenseInfo.setLicenseKey(
  '25fc48f7a5b2db68d06d29326273ba94Tz02NTYyOCxFPTE3MTQ2NTM5MjExNzgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <ErrorBundary>
      <ConfigLoader>
        <Application />
      </ConfigLoader>
    </ErrorBundary>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
