import { IChatModel } from '../../chat/entity/IChatModel';
import { IMessageModel } from '../../chat/message/IMessageModel';
import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IApplicationModel } from '../entity/IApplicationModel';
import { IApplicationDataChatCounterModel } from './IApplicationDataChatCounterModel';
import {
  IApplicationAdditionalColumnData,
  IApplicationDataModel,
  IApplicationStatusData,
} from './IApplicationDataModel';
import { IApplicationDataSearch } from './IApplicationDataSearch';
import { IApplicationDataService } from './IApplicationDataService';

export class RestApplicationDataService
  extends RestCRUDService<IApplicationDataModel, IApplicationDataSearch>
  implements IApplicationDataService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getApplicationJiraLinks(
    applicationDataId: string,
  ): Promise<{ links: { requirementId: string; link: string; isNotExists: boolean }[] }> {
    const url = `${this.getEndpoint()}/${applicationDataId}/jira/links`;
    return this.request()
      .url(url)
      .get()
      .json<{ links: { requirementId: string; link: string; isNotExists: boolean }[] }>()
      .catch(this.handleError);
  }

  async getApplicationDataChatCounters(applicationDataId: string): Promise<IApplicationDataChatCounterModel> {
    const url = `${this.getEndpoint()}/${applicationDataId}/chat/counter`;
    return this.request().url(url).get().json<IApplicationDataChatCounterModel>().catch(this.handleError);
  }

  async loadUserChat(requirementId: string, applicationDataId: string): Promise<IChatModel> {
    const url = `${this.getEndpoint()}/${applicationDataId}/requirement/${requirementId}/chat`;
    return this.request().url(url).get().json<IChatModel>().catch(this.handleError);
  }

  async loadUserChatMessages(requirementId: string, applicationDataId: string): Promise<IMessageModel[]> {
    const url = `${this.getEndpoint()}/${applicationDataId}/requirement/${requirementId}/chat/messages`;
    return this.request().url(url).get().json<IMessageModel[]>().catch(this.handleError);
  }

  async addMessage(requirementId: string, applicationDataId: string, message: IMessageModel): Promise<IMessageModel> {
    const url = `${this.getEndpoint()}/${applicationDataId}/requirement/${requirementId}/chat/comment`;
    return await this.request().url(url).post(message).json<IMessageModel>().catch(this.handleError);
  }

  async editMessage(requirementId: string, applicationDataId: string, message: IMessageModel): Promise<IMessageModel> {
    const url = `${this.getEndpoint()}/${applicationDataId}/requirement/${requirementId}/chat/comment`;
    return await this.request().url(url).put(message).json<IMessageModel>().catch(this.handleError);
  }

  async deleteMessage(requirementId: string, applicationDataId: string, messageId: string): Promise<boolean> {
    const url = `${this.getEndpoint()}/${applicationDataId}/requirement/${requirementId}/chat/comment/${messageId}`;
    await this.request().url(url).delete().json().catch(this.handleError);
    return true;
  }

  async readMessages(userChatId: string, messagesIds: string[]): Promise<any> {
    const url = `${this.getEndpoint()}/requirement/chat/${userChatId}/comment/read`;

    return await this.request().url(url).post({ messagesIds: messagesIds }).json<any>().catch(this.handleError);
  }

  async acceptRequirementsUpdate(applicationDataId: string): Promise<any> {
    const url = `${this.getEndpoint()}/${applicationDataId}/requirement/accept/all`;
    await this.request().url(url).post().json().catch(this.handleError);

    return true;
  }

  async acceptRequirementNewUpdate(applicationDataId: string, requirementIds: string[]): Promise<any> {
    const url = `${this.getEndpoint()}/${applicationDataId}/requirement/new`;
    await this.request().url(url).post({ requirementIds }).json().catch(this.handleError);

    return true;
  }

  async acceptRequirementDeleteUpdate(applicationDataId: string, requirementIds: string[]): Promise<any> {
    const url = `${this.getEndpoint()}/${applicationDataId}/requirement/delete`;
    await this.request().url(url).post({ requirementIds }).json().catch(this.handleError);

    return true;
  }

  async updateStatuses(statuses: IApplicationStatusData, applicationDataId: string): Promise<any> {
    await this.request()
      .url(`${this.getEndpoint()}/${applicationDataId}/statuses`)
      .put(statuses)
      .json()
      .catch(this.handleError);

    return true;
  }

  async updateAdditionalColumn(
    additionalColumnData: IApplicationAdditionalColumnData,
    applicationDataId: string,
  ): Promise<any> {
    await this.request()
      .url(`${this.getEndpoint()}/${applicationDataId}/additional/column`)
      .put(additionalColumnData)
      .json()
      .catch(this.handleError);

    return true;
  }

  async preview(applicationModel: IApplicationModel): Promise<IApplicationDataModel> {
    const result = await this.request()
      .url(`${this.getEndpoint()}/preview`)
      .post(applicationModel)
      .json<IApplicationDataModel>()
      .catch(this.handleError);

    return result;
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/application/data`;
  }
}
