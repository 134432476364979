import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { sortCallBack } from './sortOrder';

function mergeArrays(fields: any[] = [], categories: any[] = [], domain) {
  const mappedFields = fields.map((data) => {
    return { name: data.fieldName, value: data.value };
  });

  const mappedCategories = categories.map((data) => {
    const specifications = domain.ui
      .specificationsByCategoryId(data.id)
      .map((specification) => specification.name)
      .join('\n');
    return { name: data.name, value: specifications };
  });

  return [...mappedCategories, ...mappedFields];
}

const sortedAllFields = (data) => data.sort(sortCallBack);

export const ViewModeListsExtended = observer(({ domain }) => {
  const sortedData = sortedAllFields(
    mergeArrays(
      domain.ui.application.entity.customInformation?.fields,
      domain.ui.applicationSpecificationsCategories.list,
      domain,
    ),
  );

  return (
    <>
      {sortedData.map((item) => (
        <Grid item key={item.name}>
          <Typography variant="h5">{item.name}: </Typography>
          <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body1">
            {item.value}
          </Typography>
        </Grid>
      ))}
    </>
  );
});
