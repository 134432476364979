import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ConnectionPage } from '../../mail/connectionPage/ConnectionPage';
import { MailAdminForm } from '../../mail/form/MailAdminForm';
import { TaskManagerServerWebhookInfoPage } from '../../taskManager/server/webhook/info/TaskManagerServerWebhookInfoPage';
import { ActiveDirectoryAdminTable } from '../../user/activeDirectory/table/ActiveDirectoryAdminTable';
import { ISettingsItem } from '../ISettingsItem';
import { TaskManagerAdminTable } from '../../taskManager/server/TaskManagerAdminTable'
import { ConnectionTempalte } from '../../taskManager/server/connectinoTemplate/connectionTemplate';

export const IntegrationSettingsItem: ISettingsItem = {
  id: 'integration',
  settingsItem: {
    key: 'integration',
    title: 'Интеграции',
    buttons: [
      {
        title: 'Jira',
        url: `/settings/taskManager/server`,
        permissions: [UserSystemRoleModelPermissionMap['settings-integrations-read']],
      },
      {
        title: 'Active Directory',
        url: `/settings/activedirectory/server`,
        permissions: [UserSystemRoleModelPermissionMap['settings-integrations-read']],
      },
      {
        title: 'Почта',
        url: `/settings/mail/server`,
        permissions: [UserSystemRoleModelPermissionMap['settings-integrations-read']],
      },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-integrations-read']],
    infoText: 'Содержит перечень соединений с JIRA, Active Directory и Почтовой системой',
    orderIndex: 800,
  },
  routers: [
    {
      key: 'integration-jira-list-settings',
      path: '/settings/taskManager/server',
      pageTitle: 'Jira соединения',
      component: TaskManagerAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-integrations-read']],
    },
    {
      key: 'integration-jira-edit-settings',
      path: '/settings/taskManager/server/:entityId/webhook/info',
      pageTitle: 'Jira Webhook',
      component: TaskManagerServerWebhookInfoPage,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-integrations-view']],
    },
    {
      key: 'integration-jira-edit-settings',
      path: '/settings/taskManager/server/:entityId/template',
      pageTitle: 'Jira connection template',
      component: ConnectionTempalte,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-integrations-view']],
    },
    {
      key: 'integration-ad-list-settings',
      path: '/settings/activedirectory/server',
      pageTitle: 'Active Directory сервера',
      component: ActiveDirectoryAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-integrations-read']],
    },
    {
      key: 'integration-mail-list-settings',
      path: '/settings/mail/server',
      pageTitle: 'Mail соединения',
      component: ConnectionPage,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-integrations-read']],
    },
    {
      key: 'integration-mail-new-settings',
      path: '/settings/mail/server/new',
      pageTitle: 'Добавить соединение с Mail',
      component: MailAdminForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-integrations-create']],
    },
    {
      key: 'integration-mail-edit-settings',
      path: '/settings/mail/server/:entityId/edit',
      pageTitle: 'Редактировать соединение с Mail',
      component: MailAdminForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-integrations-update']],
    },
  ],
};
