import { GridColumns } from '@mui/x-data-grid/models';
import React from 'react';

import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationCategorySearch } from '../../../../../../service/specification/category/ISpecificationCategorySearch';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IconButton, Tooltip } from '@mui/material';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';

export class SpecificationCategoryAdminUI
  extends DataTableUI<ISpecificationCategoryModel, ISpecificationCategorySearch>
  implements IDataTableUI<ISpecificationCategoryModel, ISpecificationCategorySearch>
{
  constructor(
    public domain: IDataTableDomain<ISpecificationCategoryModel, ISpecificationCategorySearch>,
    public layoutDomain: IMainLayoutDomainStore,

  ) {
    super(domain, null);
  }
  setDomainId(): string {
    return 'settings/specification/category';
  }
  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        headerName: 'Название',
        field: 'name',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Описание',
        field: 'description',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Порядок отображения',
        type: 'numeric',
        field: 'orderIndex',
        flex: 0.7,
      },
      {
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 0.5,
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.5,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
