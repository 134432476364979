import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { ContainerContext } from '../../../../common/container/ContainerContext';
import { ICommonProperties } from '../../../../common/properties/ICommonProperties';
import { IMainLayoutDomainStore } from '../../../layout/main/store/domain/IMainLayoutDomainStore';
import { requirementFilterExtension } from './extensions/RequirementFilterExtension';
import { SpecificationsFilter } from './parts/SpecificationsFilter';
import { StatusesFilter } from './parts/StatusesFilter';
import { TagsFilter } from './parts/TagsFilter';
import { TextFilter } from './parts/TextFilter';
import { VendorsFilter } from './parts/VendorFilter';
import { CommonFilterDomain } from './store/CommonFilterDomain';

export interface ICommonFilterProperties extends ICommonProperties {
  domain: CommonFilterDomain;
  layoutDomain?: IMainLayoutDomainStore;
}

export const CommonFilter = observer(({ domain }: ICommonFilterProperties) => {
  const container = useContext(ContainerContext);
  const extensionFilters = container.get(requirementFilterExtension);
  return (
    <Grid item xs={12}>
      <Grid container direction="column">
        {domain.ui.isShowTextSearch.value && (
          <Grid item>
            <TextFilter
              currentText={domain.ui.searchText}
              setNewValue={(text: string) => {
                domain.ui.searchText.setValue(text);
                domain.triggerSearch();
              }}
              style={{ marginLeft: '15px' }}
            />
          </Grid>
        )}
        {domain.ui.isShowStatusesSearch.value && domain.ui.statuses.list.length > 0 && (
          <Grid item>
            <StatusesFilter
              statuses={domain.ui.statuses}
              statusesValues={domain.ui.statusesValues}
              searchStatusesIds={domain.ui.searchStatusesIds}
              setNewValue={(statusId: string, statusValueId: string | null, isChecked: boolean) => {
                domain.ui.setStatus(statusId, statusValueId, isChecked);
                domain.triggerSearch();
              }}
              //@ts-ignore
              rootDomain={domain?.rootDomain}
            />
          </Grid>
        )}
        {domain.ui.isShowSpecificationsSearch.value && (
          <Grid item>
            <SpecificationsFilter
              specificationsCategories={domain.ui.specificationsCategories}
              specifications={domain.ui.specifications}
              searchSpecificationsIds={domain.ui.searchSpecificationsIds}
              setNewValue={(id: string, isChecked: boolean) => {
                isChecked
                  ? domain.ui.searchSpecificationsIds.value.push(id)
                  : //@ts-ignore
                    domain.ui.searchSpecificationsIds.value.remove(id);
                domain.triggerSearch();
              }}
              //@ts-ignore
              rootDomain={domain?.rootDomain}
            />
          </Grid>
        )}
        {domain.ui.isShowTagsSearch.value && (
          <Grid item>
            <TagsFilter
              tagsCategories={domain.ui.tagsCategories}
              tags={domain.ui.tags}
              searchTagsIds={domain.ui.searchTagsIds}
              setNewValue={(id: string, isChecked: boolean) => {
                isChecked
                  ? domain.ui.searchTagsIds.value.push(id)
                  : //@ts-ignore
                    domain.ui.searchTagsIds.value.remove(id);
                domain.triggerSearch();
              }}
              //@ts-ignore
              rootDomain={domain?.rootDomain}
            />
          </Grid>
        )}
        {domain.ui.isShowVendorsSearch.value && (
          <Grid item>
            <VendorsFilter
              vendors={domain.ui.vendors}
              searchIds={domain.ui.searchVendorsIds}
              setNewValue={(id: string, isChecked: boolean) => {
                isChecked
                  ? domain.ui.searchVendorsIds.value.push(id)
                  : //@ts-ignore
                    domain.ui.searchVendorsIds.value.remove(id);
                domain.triggerSearch();
              }}
            />
          </Grid>
        )}
        {extensionFilters.map((Filter) => {
          return (
            <Grid item>
              <Filter.Component key={Filter.id} rootDomain={domain} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
});
