import wretch from 'wretch';

import { ILearningReportService } from './ILearningReportService';
import { ILearningConfig } from '../../application/config/ILearningConfig';
import { LearningEnvConfigManager } from '../../application/config/LearningEnvConfigManager';
import { IUserEntityModel } from '../../../../service/user/IUserEntityModel';
import { IUserSystemRoleModel } from '../../../../service/systemRole/entity/IUserSystemRoleModel';
import { IProjectModel } from '../../../../service/project/IProjectModel';
import { CoreRestService } from '../../../../service/coreCommon/common/rest/CoreRestService';
import { CoreRestErrorHandler } from '../../../../service/coreCommon/common/rest/CoreRestErrorHandler';

wretch().errorType('json');

export class RestLearningReportService  extends CoreRestService implements ILearningReportService {
  constructor(
    protected config: ILearningConfig = LearningEnvConfigManager.getConfig(),
    protected errorHandler = new CoreRestErrorHandler(),
  ) {
    super();
  }

  async generateProjectUnitSummuryByUsers(userList:IUserEntityModel[],systemRoles:IUserSystemRoleModel[],projects:IProjectModel[]): Promise<any> {
    const url = `${this.getEndpoint()}/learning/docx`;
    
    const doc = await this.post(url,{
      userList:userList,
      systemRoles:systemRoles,
      projects:projects
    })


    const blobDoc = await doc.blob();
    return blobDoc;
  }
  async generateProjectUnitSummuryByUsersPdf(userList:IUserEntityModel[],systemRoles:IUserSystemRoleModel[],projects:IProjectModel[]): Promise<any> {
    const url = `${this.getEndpoint()}/learning/pdf`;
    
    const doc = await this.post(url,{
      userList:userList,
      systemRoles:systemRoles,
      projects:projects
    })

    const blobDoc = await doc.blob();
    return blobDoc;
  }
  getEndpoint(): string {
    const url = this.config.url.api.learning;
    return `${url}/report`;
  }

  async post(url: string, data: any): Promise<any> {
    return this.request().url(url).post(data);
  }

 
}
