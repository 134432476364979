import { ConfigManager } from '../../../../../application/config/ConfigManager';
import { IConfig } from '../../../../../application/config/IConfig';
import { inject } from '../../../../../common/container/inject';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { UserProjectRoleAction } from '../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { IActiveUserModel } from '../../../../../service/public/activeUser/IActiveUserModel';
import { IRootPublicService, RootPublicServiceToken } from '../../../../../service/public/RootPublicService';
import { ServiceType } from '../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { INotificationDomain } from '../../../../user/page/notification/store/INotificationDomain';
import { NotificationDomain } from '../../../../user/page/notification/store/NotificationDomain';
import { LayoutNotificationType } from '../../../common/notification/store/ILayoutNotification';
import { CommonLayoutDomainStore } from '../../../common/store/CommonLayoutDomain';
import { ILayoutUser } from '../ui/ILayoutUser';
import { IMainLayoutUIStore } from '../ui/IMainLayoutUIStore';
import { MainLayoutUIStore } from '../ui/MainLayoutUIStore';
import { IEditProjectPermission } from './IEditProjectPermission';
import { IMainLayoutDomainStore } from './IMainLayoutDomainStore';

export class MainLayoutDomainStore extends CommonLayoutDomainStore implements IMainLayoutDomainStore {
  static refreshTokenInterval: any = null;

  constructor(
    private uiStore = new MainLayoutUIStore(),
    private publicRootService: IRootPublicService = inject<IRootPublicService>(RootPublicServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    public serviceType = injectPrimitive<ServiceType>(ServiceType.user),
    public config: IConfig = ConfigManager.getConfig(),
    public notificationDomain: INotificationDomain = null as any,
    public isSelected = injectPrimitive<string>(''),
  ) {
    super();
    this.subscribeSocket();
    this.notificationDomain = new NotificationDomain(this);
    this.isSelected.setValue(this.getActiveUrl().split('/')[1] || 'project')
  }

  async subscribeSocket() {
    await this.publicRootService.notification.subscribeSocket((data) => {
      this.notifications.showNotification({
        type: LayoutNotificationType.text,
        text: data.action,
      });
    });
  }

  async loadLayoutData(): Promise<void> {
    this.uiStore.isBootLoading.setValue(true);
    this.refreshToken();
    const activeUser = await this.publicRootService.activeUser.getActiveUser();

    this.isValidActiveUser(activeUser);
    this.uiStore.activeUser.setEntity(this.mapServiceActiveUserToLayout(activeUser));
    this.serviceType.setValue(ServiceType.admin);

    const [userProjects, projectRoles] = await Promise.all([
      this.publicRootService.project.entity.search({fields: ['id','rolesMap']}),
      this.publicRootService.projectRole.entity.search({fields: ['id','permissions']}),
    ]);

    this.uiStore.projectRoles.setList(projectRoles.data);
    this.uiStore.userProjects.setList(userProjects.data);

    this.uiStore.isBootLoading.setValue(false);
  }

  async refreshToken() {
    if (!MainLayoutDomainStore.refreshTokenInterval) {
      this.callRefreshToken();
      MainLayoutDomainStore.refreshTokenInterval = setInterval(() => {
        this.callRefreshToken();
      }, 60000);
    }
  }

  private callRefreshToken() {
    const isHiddenBrowserTab = document.hidden;
    if (!isHiddenBrowserTab) {
      this.publicRootService.activeUser.refreshToken();
    }
  }

  async checkPermissions(allowedPermissions: UserSystemRoleModelPermissionMap[]): Promise<void> {
    this.setAllowedPermissions(allowedPermissions);
  }

  private mapServiceActiveUserToLayout(serviceActiveUser: IActiveUserModel): ILayoutUser {
    return {
      avatarUrl: serviceActiveUser.avatarUrl,
      id: serviceActiveUser.id,
      displayName: `${serviceActiveUser.firstName} ${serviceActiveUser.lastName}`,
      systemRoleId: serviceActiveUser.roleId,
      systemRoleName: serviceActiveUser.roleName,
      allowedPermissions: serviceActiveUser.allowedPermissions || [],
      newMessagesCount: serviceActiveUser.newMessagesCount,
    };
  }

  private isValidActiveUser(activeUser: IActiveUserModel) {
    if (!activeUser.isLogged) {
      this.router.goTo('/login');
    }
  }

  async logout(): Promise<void> {
    await this.publicRootService.activeUser.logoutActiveUser();
    this.router.goTo('/login');
  }

  setPageTitle(title: string): void {
    this.uiStore.pageTitle.setValue(title);
    document.title = title;
  }

  clearActiveUser(): void {
    this.uiStore.isBootLoading.setValue(true);
    this.uiStore.activeUser.setEntity(null as any);
  }

  setAllowedPermissions(permissions: UserSystemRoleModelPermissionMap[]): void {
    if (permissions && permissions.length > 0) {
      const userPermissions = this.uiStore.activeUser.entity.allowedPermissions;

      const isAllowedUser =
        permissions.some((item) => {
          return userPermissions.includes(item);
        }) || userPermissions.includes(UserSystemRoleModelPermissionMap['global-allow-any']);

      this.uiStore.isAllowedUser.setValue(isAllowedUser);
    } else {
      this.uiStore.isAllowedUser.setValue(true);
    }
  }

  getActiveUrl = () => {
    return this.router.getActiveUrl();
  };

  get ui(): IMainLayoutUIStore {
    return this.uiStore;
  }

  userHaveAnyAccess(
    systemPermissions: string[] = [],
    projectPermissions: { id: string; permissions: string[] },
  ): boolean {
    let isAllowed: boolean | null = null;
    if (systemPermissions && systemPermissions.length > 0) {
      isAllowed =
        isAllowed ||
        this.ui.activeUser.entity.allowedPermissions.some((item) => systemPermissions.includes(item)) ||
        this.ui.activeUser.entity.allowedPermissions.includes(UserSystemRoleModelPermissionMap['global-allow-any']);
    }

    if (projectPermissions) {
      const project = this.ui.userProjects.list.find((project) => project.id === projectPermissions.id);
      const userRoleId = project?.rolesMap?.data?.find(
        (roleData) => roleData.userId === this.ui.activeUser.entity.id,
      )?.roleId;
      if (userRoleId) {
        const role = this.ui.projectRoles.list.find((role) => role.id === userRoleId);
        if (role) {
          const roleActions = role.permissions.data.map((item) => item.action);
          isAllowed = isAllowed || roleActions.some((action) => projectPermissions.permissions.includes(action));
        } else {
          isAllowed = isAllowed || false;
        }
      } else {
        isAllowed = isAllowed || false;
      }
    }
    return isAllowed ?? true;
  }

  userHaveAnyAccessToEditProject(
    projectId?: string,
  ): IEditProjectPermission {
    const editProjectPermission: IEditProjectPermission = { isCanEditFields: false, isCanEditTeam: false };
    const isGlobalAllowAny = this.ui.activeUser.entity.allowedPermissions.includes(UserSystemRoleModelPermissionMap['global-allow-any'])
    if (isGlobalAllowAny) {
      editProjectPermission.isCanEditFields = true
      editProjectPermission.isCanEditTeam = true
      return editProjectPermission
    }

    editProjectPermission.isCanEditFields = this.ui.activeUser.entity.allowedPermissions.includes(UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-fields-project'])
    editProjectPermission.isCanEditTeam = this.ui.activeUser.entity.allowedPermissions.includes(UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-team-project'])

    if (projectId) {
      const project = this.ui.userProjects.list.find((project) => project.id === projectId);

      const userRoleId = project?.rolesMap?.data?.find(
        (roleData) => roleData.userId === this.ui.activeUser.entity.id,
      )?.roleId;
      if (userRoleId) {
        const role = this.ui.projectRoles.list.find((role) => role.id === userRoleId);
        if (role) {
          const roleActions = role.permissions.data.map((item) => item.action);
          editProjectPermission.isCanEditFields = roleActions.includes(UserProjectRoleAction.updateFieldsProject);
          editProjectPermission.isCanEditTeam = roleActions.includes(UserProjectRoleAction.updateTeamProject);
        }
      }
    }
    return editProjectPermission

  }
}
