import React from 'react';

import { inject } from '../../../../../../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../../../../../../service/route/IRouterService';
import { IMainLayoutDomainStore } from '../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../../../service/LearningRootService';
import { IQuestionsQuizResult } from '../../../../../../../service/unitResult/IUnitResultModel';
import { PassingQuizLongread } from '../PassingQuizLongread';
import { IPassingQuizDomain } from './IPassingQuizDomain';
import { PassingQuizUI } from './PassingQuizUI';

export class PassingQuizDomain implements IPassingQuizDomain {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public ui = new PassingQuizUI(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {
    this.ui.additionalActions.setList([{ 'Contact Us': (index: number) => {} }]);
    this.layoutDomain = layoutDomain;
  }
  redirect = (page: string) => {};
  redirectInNavigation = (page: string) => {};

  totalSteps = () => {
    return this.ui.stepsTitles.list.length;
  };

  completedSteps = () => {
    return Object.keys(this.ui.completed.value).length;
  };

  isLastStep = () => {
    return this.ui.activeStep.value === this.totalSteps() - 1;
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  };

  handleBack = () => {
    this.ui.activeStep.setValue(this.ui.activeStep.value - 1);
  };

  shuffleAnswers = () => {
    this.ui.passingQuestionList.list.forEach((question) => {
      if (!question.randomize) {
        let currentIndex = question.answers.length,
          randomIndex: number;
        while (currentIndex !== 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [question.answers[currentIndex], question.answers[randomIndex]] = [
            question.answers[randomIndex],
            question.answers[currentIndex],
          ];
        }
      }
    });
  };

  boot = async () => {
    await this.getCurrentUnitResult();
    this.redirectInNavigation('quiz');
    this.ui.activeStep.setValue(0);
    this.ui.isExpanded.setValue(true);
    this.shuffleAnswers();
    this.getIsEnableRePassing();
    this.preCreateAnswersList();
    this.ui.stepperList.setList(this.ui.passingQuestionList.list);
    this.ui.stepContent.setList([
      {
        component: <PassingQuizLongread rootDomain={this} />,
        isEneble: true,
      },
    ]);
  };

  getIsEnableRePassing = async () => {
    if (this.ui.currentUnitResult.value.quizResult.lastPassingDate !== null) {
      const twentyMinutesInMls = 20 * 60 * 1000;
      const dayDiff = this.getDayDiff();
      const timeLeftInMinutes = (twentyMinutesInMls - dayDiff) / (1000 * 60);
      this.ui.timeLeftToRePass.setValue(Math.round(timeLeftInMinutes).toString());
      if (dayDiff > twentyMinutesInMls) {
        this.ui.isEbaleToRePass.setValue(true);
      } else {
        this.ui.isEbaleToRePass.setValue(false);
      }
    }
  };

  getDayDiff = () => {
    const today = new Date();
    const lastPassingDate = this.ui.currentUnitResult.value.quizResult?.lastPassingDate;
    const dayDiff = today.getTime() - (lastPassingDate?.getTime() || 0);
    return dayDiff;
  };

  getCurrentUnitResult = async () => {
    const unitResultModel = await this.learningRootService.unitResult.search({
      filter: [
        {
          fieldName: 'unitId',
          type: 'equal',
          value: this.ui.passingId.value,
        },
        {
          fieldName: 'userId',
          type: 'equal',
          value: this.layoutDomain.ui.activeUser.entity.id,
        },
      ],
    });
    this.ui.currentUnitResult.setValue(unitResultModel.data[0]);
    if (Math.round(this.ui.currentUnitResult.value.quizResult.totalQuizResult / 15) > 0.7) {
      this.ui.isQuizPassed.setValue(true);
    }
    const correctAnswersAmount = this.ui.currentUnitResult.value.quizResult.questions.filter((question) => {
      return question.result > 0.5;
    });
    this.ui.scoreDiff.setValue({
      totalQuestionsAmount: this.ui.passingQuestionList.list.length,
      userCorrectAnswers: correctAnswersAmount.length,
      score: this.ui.currentUnitResult.value.quizResult.totalQuizResult,
    });
  };

  preCreateAnswersList = () => {
    if (!this.ui.isEbaleToRePass.value && this.ui.currentUnitResult.value.quizResult.questions.length > 0) {
      this.ui.passingAnswersList.setList(this.ui.currentUnitResult.value.quizResult.questions);
    }
    if (this.ui.isEbaleToRePass.value) {
      this.ui.passingQuestionList.list.forEach((question) => {
        const newAnswerModel: IQuestionsQuizResult = {
          answers: [],
          question: question.name,
          result: 0,
          pseudoId: question?.pseudoId,
        };
        this.ui.passingAnswersList.list.push(newAnswerModel);
      });
    }
  };

  addAnswer = (newAnswers: string[], pseudoId: string) => {
    this.ui.passingAnswersList.list.forEach((question) => {
      if (question.pseudoId === pseudoId) {
        question.answers = newAnswers;
      }
    });
    this.areAllAnswersNotEmpty();
  };

  areAllAnswersNotEmpty = () => {
    for (const answerModel of this.ui.passingAnswersList.list) {
      if (answerModel.answers.length === 0) {
        return this.ui.isCheckResultActive.setValue(false);
      }
    }
    return this.ui.isCheckResultActive.setValue(true);
  };

  compareAnswers = async () => {
    this.ui.isEbaleToCheck.setValue(false);

    let totalScore = 0;
    let correctAnswersScore = 0;

    this.ui.passingQuestionList.list.forEach((question) => {
      const correctAnswers = question.answers.filter((ans) => ans.isCorrect);
      const userAnswers = this.ui.passingAnswersList.list.find(
        (answer) => answer.pseudoId === question.pseudoId,
      )?.answers;
      if (userAnswers) {
        let questionScore = 0;
        if (question.type === 'multi') {
          let userScore = 0;
          userAnswers.forEach((userAns) => {
            if (correctAnswers.some((correctAns) => correctAns.answer === userAns)) {
              userScore += 1;
            }
          });
          questionScore = userScore === correctAnswers.length && userAnswers.length === correctAnswers.length ? 1 : 0;
        } else {
          if (correctAnswers.some((correctAns) => correctAns.answer === userAnswers[0])) {
            questionScore = 1;
          }
        }
        const answerModel = this.ui.passingAnswersList.list.find((answer) => answer.pseudoId === question.pseudoId);
        if (answerModel) {
          answerModel.result = questionScore;
        }
        totalScore += questionScore;
      }
    });
    this.ui.passingAnswersList.list.forEach((answer) => {
      correctAnswersScore += answer.result;
    });
    totalScore = (15 * correctAnswersScore) / this.ui.passingQuestionList.list.length;

    await this.learningRootService.unitResult.passQuiz({
      ...this.ui.currentUnitResult.value,
      quizResult: {
        questions: this.ui.passingAnswersList.list,
        totalQuizResult: totalScore,
      },
    });
    await this.getCurrentUnitResult();
    this.getIsEnableRePassing();
    this.ui.isEbaleToRePass.setValue(false);
    this.ui.isCheckResultActive.setValue(false);
    if (Math.round(totalScore / 15) > 0.7) {
      this.ui.isQuizPassed.setValue(true);
    }
    this.ui.scoreDiff.setValue({
      totalQuestionsAmount: this.ui.passingQuestionList.list.length,
      userCorrectAnswers: correctAnswersScore,
      score: totalScore,
    });
  };

  checkAnswers = async () => {
    await this.compareAnswers();
    const quizTitleElement = document.getElementById('quiz_title');
    quizTitleElement?.scrollIntoView({ behavior: 'smooth' });
  };

  handleNext = async () => {
    const newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
        ? this.ui.stepsTitles.list.findIndex((step, i) => !(i in this.ui.completed.value))
        : this.ui.activeStep.value + 1;
    this.ui.activeStep.setValue(newActiveStep);
  };

  onRedirectTo = async () => {
    this.redirect('practice');
    this.ui.isExpanded.setValue(false);
    this.ui.activeStep.setValue(null);
  };

  navigationRedirect = (hash: string) => {
    this.redirectInNavigation('quiz');
    const element = document.getElementById(hash);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  expandHandler = () => {
    this.ui.isExpanded.setValue(!this.ui.isExpanded.value);
  };
}
