import React from 'react';

import { inject } from '../../../../../../common/container/inject';
import { IServiceModel } from '../../../../../../service/common/model/IServiceModel';
import { ICRUDService } from '../../../../../../service/common/service/ICRUDService';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { LayoutNotificationType } from '../../../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ConfirmModal } from '../../../../modal/confirm/ConfirmModal';
import { IDataTableDomain } from '../../../store/IDataTableDomain';
import { IRowContextMenuDomain } from './IRowContextMenuDomain';
import { IRowContextMenuUI } from './IRowContextMenuUI';
import { RowContextMenuUI } from './RowContextMenuUI';

export class RowContextMenuDomain<
  RowType extends IServiceModel,
  EntityService extends ICRUDService<RowType, any> = ICRUDService<RowType, any>,
> implements IRowContextMenuDomain<RowType>
{
  public ui: IRowContextMenuUI<RowType>;
  public router: IRouterService;

  constructor(
    private entityService: EntityService,
    public dataTableDomain: IDataTableDomain<RowType, any>,
    public layoutDomain: IMainLayoutDomainStore,
    ui?: IRowContextMenuUI<RowType> | null,
    router?: IRouterService | null,
  ) {
    this.ui = ui || new RowContextMenuUI<RowType>(this);
    this.router = router || inject<IRouterService>(RouterServiceToken);
  }

  async onEditRow(row: any): Promise<void> {
    row && (await this.dataTableDomain.modalDomain.loadData(row.id));
    this.dataTableDomain.modalDomain.onOpenModal(this.dataTableDomain.modalDomain.ui.formComponent.value);
  }

  async onEditRowInDrawer(row: any): Promise<void> {
    // todo
    row && (await this.dataTableDomain.drawerDomain.loadData(row.id));
    this.dataTableDomain.drawerDomain.onOpenDrawer(await this.entityService.getById(row.id));
  }

  deleteConfirmation = (id: any) => {
    const Component = () => {
      return (
        <ConfirmModal
          title="Удалить запись?"
          text={`Вы уверены, что хотите удалить запись ?`}
          onCancel={async () => {
            this.layoutDomain.modalWindow.closeModalWindow();
          }}
          onOk={async () => {
            await this.deleteById(id);
            await this.dataTableDomain.boot();
            //   @ts-ignore
            this.dataTableDomain.modalDomain.ui.model.setEntity({});
            this.layoutDomain.modalWindow.closeModalWindow();
          }}
        />
      );
    };
    return Component;
  };

  async onDeleteRow(row: RowType) {
    this.layoutDomain.modalWindow.showModalWindow(this.deleteConfirmation(row.id));
    const activeUrl = this.router.getActiveUrl();
    this.router.goTo(activeUrl);
  }

  async deleteById(rowId: string) {
    await this.entityService.deleteById(rowId).catch((error) => {
      if (error.webCode === '500') {
        this.layoutDomain.notifications.showNotification({
          type: LayoutNotificationType.error,
          text: error.message || 'Неизвестная ошибка',
        });
      }
    });
  }

  async onBulkDeleteRow(rows: RowType[]) {
    throw new Error('Method not implemented.');
  }

  onBulkDeactivateRow(rows: RowType[]): void {
    throw new Error('Method not implemented.');
  }

  onBulkActivateRow(rows: RowType[]): void {
    throw new Error('Method not implemented.');
  }

  onRedirectDetail(row: RowType) { }

  selectRows(rows: RowType[]) {
    this.ui.selectRows(rows);
  }
}
