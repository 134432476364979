import { injectEntity } from '../../../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../../../common/store/base/injectPrimitive';
import { IMainLayoutDomainStore } from '../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ITaskResult } from '../../../../../../../service/unit/interface/IUnitPractice';
import { IPassingFinalResultsUI } from './IPassingFinalResultsUI';

export class PassingFinalResultsUI implements IPassingFinalResultsUI {
  constructor(
    public resultData = injectEntity<any>({}),
    public finalResults = injectEntity<any>({}),
    public activeStep = injectPrimitive<number>(0),
    public stepContent = injectEntityList<{
      component: any;
      isEneble: boolean;
    }>([] as any),
    public stepsTitles = injectEntityList<string>([] as any),
    public redirectTo = injectPrimitive<string>(''),
    public results = injectEntity<ITaskResult>({} as any),
    public layoutDomain = injectEntity<IMainLayoutDomainStore>(null as any),
    public passingId = injectPrimitive<string>(''),
    public isExpanded = injectPrimitive<boolean>(false),
    public isEnebled = injectPrimitive<boolean>(false),
  ) {
    this.redirectTo.setValue('Выход');
  }
}
