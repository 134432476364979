import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { IUserSystemRoleModel } from '../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { IUserSystemRoleSearch } from '../../../../../../service/systemRole/entity/IUserSystemRoleSearch';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { SystemRoleAdminDomain } from './SystemRoleAdminDomain';
import { SystemRoleAdminModel } from './SystemRoleAdminModel';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';

export class SystemRoleAdminUI
  extends DataTableUI<IUserSystemRoleModel, IUserSystemRoleSearch>
  implements IDataTableUI<IUserSystemRoleModel, IUserSystemRoleSearch>
{
  public domain: SystemRoleAdminDomain;

  constructor(domain, rowsUI, ...params) {
    super(domain, rowsUI, ...params);
    this.domain = domain;
  }

  setDomainId(): string {
    return 'settings/role/system';
  }

  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          if (this.domain.contextMenuDomain.ui.activeRow.entity?.name === 'Администратор' &&
            this.domain.contextMenuDomain.ui.activeRow.entity?.permissions.allowedPermissions.includes(UserSystemRoleModelPermissionMap['global-allow-any'])) {
            this.domain.setNoDeletePermission();
          } else {
            this.domain.setPermissions();
          }
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'permissions',
        headerName: 'Права',
        flex: 1,
        type: 'string',
        renderCell: (params: { row: SystemRoleAdminModel }) => {
          const translatedPermissions = params.row.permissions.allowedPermissions
            .filter((item) =>
              item !== null
              && item !== UserSystemRoleModelPermissionMap['project-in-team-delete']
              && item !== UserSystemRoleModelPermissionMap['project-can-create']
              && item !== UserSystemRoleModelPermissionMap['project-in-team-update']
            )
            .map((permission) => this.domain.i18n.translate(`systemRoles.${permission}`))
            .join('\n');
          return <MultiLineText words={this.rows.searchTerm.value} value={translatedPermissions || ''} />;
        },
      },
      {
        field: 'notificationPermissions',
        headerName: 'Уведомления',
        flex: 1,
        type: 'string',
        renderCell: (params: { row: SystemRoleAdminModel }) => {
          const translatedPermissions = params.row.notificationPermissions
            .filter((item) => item !== null)
            .map((notificationType) => this.domain.i18n.translate(`notificationPermissions.${notificationType}`))
            .join('\n\n');
          return <MultiLineText words={this.rows.searchTerm.value} value={translatedPermissions || ''} />;
        },
      },
      {
        field: 'description',
        headerName: 'Описание',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            {
              row?.row?.name !== 'Администратор' &&
              <Tooltip title="Удалить">
                <IconButton
                  disabled={!this.isCanDelete.value}
                  // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                  onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
                >
                  <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            }
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
