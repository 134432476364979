import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { ISpecificationCategoryModel } from '../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../service/specification/entity/ISpecificationEntityModel';
import { IStatusEntityModel } from '../../../../../service/status/entity/IStatusEntityModel';
import { IStatusValueModel } from '../../../../../service/status/value/IStatusValueModel';
import { ITagCategoryModel } from '../../../../../service/tag/category/ITagCategoryModel';
import { ITagEntityModel } from '../../../../../service/tag/entity/ITagEntityModel';
import { IVendorEntityModel } from '../../../../../service/vendor/entity/IVendorEntityModel';

export class CommonFilterUI {
  constructor(
    public isLoading = injectPrimitive<boolean>(false),
    public isShow = injectPrimitive<boolean>(false),
    public isShowTextSearch = injectPrimitive<boolean>(false),
    public isShowSpecificationsSearch = injectPrimitive<boolean>(false),
    public isShowTagsSearch = injectPrimitive<boolean>(false),
    public isShowStatusesSearch = injectPrimitive<boolean>(false),
    public isShowVendorsSearch = injectPrimitive<boolean>(false),
    public searchText = injectPrimitive<string>(''),
    public searchSpecificationsIds = injectPrimitive<string[]>([]),
    public searchTagsIds = injectPrimitive<string[]>([]),
    public searchVendorsIds = injectPrimitive<string[]>([]),
    public searchStatusesIds = injectPrimitive<StatusSearchIds>({
      statuses: [],
      totalCount: 0,
    }),
    public specifications: IEntityListStore<ISpecificationEntityModel> = injectEntityList<ISpecificationEntityModel>(
      [],
    ),
    public specificationsCategories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
    public isCleanSearchExtension = injectPrimitive<boolean>(false),
    public tags: IEntityListStore<ITagEntityModel> = injectEntityList<ITagEntityModel>([]),
    public tagsCategories: IEntityListStore<ITagCategoryModel> = injectEntityList<ITagCategoryModel>([]),
    public statuses: IEntityListStore<IStatusEntityModel> = injectEntityList<IStatusEntityModel>([]),
    public statusesValues: IEntityListStore<IStatusValueModel> = injectEntityList<IStatusValueModel>([]),
    public vendors: IEntityListStore<IVendorEntityModel> = injectEntityList<IVendorEntityModel>([]),
  ) {}

  clearSearch() {
    this.searchText.setValue('');
    this.searchSpecificationsIds.setValue([]);
    this.searchTagsIds.setValue([]);
    this.searchVendorsIds.setValue([]);
    this.searchStatusesIds.setValue({ statuses: [], totalCount: 0 });
  }

  setStatus(statusId: string, statusValueId: string | null, isChecked: boolean) {
    const statusIndex = this.searchStatusesIds.value.statuses.findIndex((status) => status.id === statusId);
    if (statusIndex === -1) {
      this.searchStatusesIds.value.statuses.push({
        id: statusId,
        isIncludeEmpty: statusValueId ? false : true,
        values: statusValueId && statusValueId ? [] : [],
      });
    } else {
      if (statusValueId) {
        if (isChecked) {
          this.searchStatusesIds.value.statuses[statusIndex].values.push(statusValueId);
        } else {
          this.searchStatusesIds.value.statuses[statusIndex].values = this.searchStatusesIds.value.statuses[
            statusIndex
          ].values.filter((valueId) => valueId !== statusValueId);
        }
      } else {
        this.searchStatusesIds.value.statuses[statusIndex].isIncludeEmpty = isChecked ? true : false;
      }
    }

    let totalCount = this.searchStatusesIds.value.statuses.reduce((count, searchStatus) => {
      count += searchStatus.isIncludeEmpty ? 1 : 0;
      count += searchStatus.values.length;
      return count;
    }, 0);

    this.searchStatusesIds.value.totalCount = totalCount;
  }
}

export interface StatusSearchIds {
  statuses: StatusSearchItemIds[];
  totalCount: number;
}

export interface StatusSearchItemIds {
  id: string;
  values: string[];
  isIncludeEmpty: boolean;
}
