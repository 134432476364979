import { AccessTime, ArrowBack, ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ITask } from '../../../service/unit/interface/IUnitPractice';
import { IPassingStepperDomain } from '../passingStepper/store/IPassingStepperDomain';

export interface IFooterButtonsProperties {
  stepperDomain: IPassingStepperDomain;
}

export const FooterButtons = observer(({ stepperDomain }: IFooterButtonsProperties) => {
  const classes = useStyles();
  const activeStepIndex = stepperDomain.rootDomain.ui.activeStep.value;
  const stepsTitles = stepperDomain.rootDomain.ui.stepsTitles.list;
  const redirectTo = stepperDomain.rootDomain.ui.redirectTo?.value;

  return (
    <div className={classes.buttonsContainer}>
      {activeStepIndex !== 0 && (
        <Button
          onClick={stepperDomain.rootDomain.handleBack}
          sx={{ marginRight: 'auto' }}
          variant="contained"
          color="secondary"
          startIcon={<ArrowBack />}
        >
          {stepsTitles[activeStepIndex - 1]}
        </Button>
      )}
      {stepperDomain.isQuizAdditionalAtions?.value &&
        stepperDomain.quizAdditionalActions?.list.map((action) => {
          return (
            <Button
              onClick={action.action}
              variant="contained"
              sx={{ marginRight: '12px' }}
              disabled={
                !stepperDomain.quizDomain?.ui.isCheckResultActive.value ||
                !stepperDomain.quizDomain?.ui.isEbaleToRePass.value
              }
              endIcon={!stepperDomain.quizDomain?.ui.isEbaleToRePass.value && <AccessTime />}
            >
              {stepperDomain.quizDomain?.ui.isEbaleToRePass.value
                ? action.name
                : `Пересдать через ${
                    stepperDomain.quizDomain?.ui.timeLeftToRePass.value
                      ? stepperDomain.quizDomain?.ui.timeLeftToRePass.value
                      : '20'
                  } мин`}
            </Button>
          );
        })}
      {stepperDomain.isPracticeAdditionalAtions?.value &&
        activeStepIndex !== 0 &&
        stepperDomain.practiceAdditionalActions?.list.map((action) => {
          const currentTask = stepperDomain?.practiceDomain?.ui.passingTaskList.list.find(
            (task) => task.sortIndex === activeStepIndex,
          );
          const timeDiff = stepperDomain.practiceDomain ? stepperDomain.practiceDomain.ui.timeIsLeft.value : 0;
          const currentAnswer =
            currentTask &&
            stepperDomain?.practiceDomain?.ui.answersList.list.find((answer) => answer.task === currentTask.pseudoId);
          const canCheck = currentAnswer && stepperDomain.practiceDomain?.canCheckTask(currentAnswer);
          const timeLeft = canCheck ? 0 : 20 - timeDiff;
          const buttonLabel = canCheck ? action.name : `Перепройти через ${Math.ceil(timeLeft)} мин`;
          return (
            <Button
              onClick={() => {
                stepperDomain.practiceDomain?.checkPassTask(currentTask as ITask);
              }}
              variant="contained"
              sx={{ marginRight: '12px' }}
              disabled={!canCheck}
              endIcon={!canCheck && <AccessTime />}
            >
              {buttonLabel}
            </Button>
          );
        })}

      {activeStepIndex !== stepsTitles.length - 1 && stepsTitles.length !== 0 && (
        <Button
          onClick={stepperDomain.rootDomain.handleNext}
          sx={{ mr: 1 }}
          variant="contained"
          endIcon={<ArrowForward />}
        >
          {stepsTitles[activeStepIndex + 1]}
        </Button>
      )}
      {redirectTo && (activeStepIndex === stepsTitles.length - 1 || stepsTitles.length === 0) && (
        <Button
          onClick={stepperDomain.rootDomain.onRedirectTo}
          sx={{ mr: 1 }}
          variant="contained"
          endIcon={<ArrowForward />}
        >
          {redirectTo}
        </Button>
      )}
    </div>
  );
});

export const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',

    justifyContent: 'end',
    width: '100%',
    padding: '12px 24px 0px 24px',
  },
}));
