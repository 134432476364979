import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { FormTextFieldStandard } from '../../../../../../design/form/FormTextFieldStandard';
import { IUnitDomain } from '../../../store/IUnitDomain';

interface IPracticeResult {
  unitDomain: IUnitDomain;
}
export const PracticeResult = observer(({ unitDomain }: IPracticeResult) => {
  const { success, neutral, failure } = unitDomain.ui.unit.entity.practice.results;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '66px', flex: 1, padding: '24px', overflowY: 'scroll' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">Успешно</Typography>
        <Typography variant="body1">Выполнил все задания правильно или допустил одну ошибку</Typography>
        <FormTextFieldStandard
          multiline
          label={'Название'}
          value={success.title}
          onChange={(newValue) => {
            success.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={'Подзаголовок'}
          value={success.subtitle}
          onChange={(newValue) => {
            success.subtitle = newValue;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">Нейтрально</Typography>
        <Typography variant="body1">Допущено более одной ошибки</Typography>
        <FormTextFieldStandard
          multiline
          label={'Название'}
          value={neutral.title}
          onChange={(newValue) => {
            neutral.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={'Подзаголовок'}
          value={neutral.subtitle}
          onChange={(newValue) => {
            neutral.subtitle = newValue;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">Провал</Typography>
        <Typography variant="body1">На все практические задания отвечено неверно</Typography>
        <FormTextFieldStandard
          multiline
          label={'Название'}
          value={failure.title}
          onChange={(newValue) => {
            failure.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={'Подзаголовок'}
          value={failure.subtitle}
          onChange={(newValue) => {
            failure.subtitle = newValue;
          }}
        />
      </Box>
    </Box>
  );
});
