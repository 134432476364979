import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { PassingContext } from '../../../../PassingContext';

export const PassingSidebarFinalResults = observer(() => {
  const classes = useStyles();
  const { finalResultsDomain } = useContext(PassingContext);

  if (!finalResultsDomain.ui.isEnebled.value) {
    return <></>;
  }

  return (
    <Accordion expanded={false} className={classes.passingAccordion}>
      <AccordionSummary>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography>Результаты</Typography>
        </Box>
      </AccordionSummary>
    </Accordion>
  );
});

const useStyles = makeStyles(() => ({
  passingAccordion: {
    width: '100%',
    overflow: 'hidden',
    '&:hover': { background: 'rgba(25, 118, 210, 0.08)' },
    '&.Mui-expanded:first-of-type': { background: 'rgba(25, 118, 210, 0.08)' },
  },
}));
