import { inject } from '../../../common/container/inject';
import { IRestService, RestServiceToken } from '../../common/rest/IRestService';
import { WrongCredentialError } from './error/WrongCredentialError';
import { IActiveUserModel } from './IActiveUserModel';
import { IActiveUserService } from './IActiveUserService';

export class RestActiveUserService implements IActiveUserService {
  private jwtStorageName = 'jwtToken';

  private unloggedActiveUser: IActiveUserModel = {
    allowedPermissions: [],
    isLogged: false,
    avatarUrl: '',
    firstName: '',
    lastName: '',
    id: '',
    isLoggedState: false,
    newMessagesCount: 0,
    roleId: '',
    roleName: '',
  };

  constructor(protected restService: IRestService = inject<IRestService>(RestServiceToken)) {}

  async getActiveUser(): Promise<IActiveUserModel> {
    const jwtToken = localStorage.getItem(this.jwtStorageName);

    let result = this.unloggedActiveUser;
    if (jwtToken) {
      try {
        const userData = await this.restService.get(this.makeUrl('/user/active/data'));
        result = {
          isLogged: true,
          avatarUrl: '',
          firstName: userData.displayName,
          lastName: '',
          id: userData.id,
          isLoggedState: true,
          allowedPermissions: userData.allowedPermissions || [],
          newMessagesCount: userData.newMessagesCount,
          roleName: userData.roleName || '',
          roleId: userData.roleName || '',
        };
      } catch (error) {
        await this.logoutActiveUser();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }

    return result;
  }

  async loginUserByPassword(login: string, password: string, loginUserByPassword: string | null): Promise<boolean> {
    const result = await this.restService
      .post<any, any>(this.makeUrl('/user/active/login'), { login, password, adServerId: loginUserByPassword })
      .catch(this.errorHandler);

    localStorage.setItem(this.jwtStorageName, result.jwt);
    return true;
  }

  async logoutActiveUser(): Promise<boolean> {
    try {
      await this.restService.post<any, any>(this.makeUrl('/user/active/logout'), {});
    } catch (error) {}
    localStorage.removeItem(this.jwtStorageName);
    return true;
  }

  async refreshToken(): Promise<boolean> {
    const jwtToken = localStorage.getItem(this.jwtStorageName);
    if (jwtToken) {
      const newTokenData = await this.restService.post<any, { newJwt: string }>(
        this.makeUrl('/user/active/refresh/jwt'),
        {},
      );

      if (newTokenData?.newJwt) {
        localStorage.setItem(this.jwtStorageName, newTokenData?.newJwt);
      }
    }
    return true;
  }

  makeUrl(uri: string) {
    const domain = this.restService.getConfig().url.api.public;
    return `${domain}${uri}`;
  }

  errorHandler = (error: any) => {
    if (error?.webCode && error?.webCode === '404') {
      throw new WrongCredentialError([
        {
          message: 'Неверный логин или пароль',
          target: 'login',
        },
      ]);
    } else {
      throw error;
    }
  };
}
