import { StarRounded } from '@mui/icons-material';
import { Box, LinearProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { IUserDataModel, UserDataLevel } from '../../../../../service/userData/IUserDataModel';

export interface IUserInfoCard {
  fio: string;
  role: string | null;
  userData: IUserDataModel;
}
export const UserInfoCard = observer(
  ({ fio, role, userData: { toNextLevelScore, level, totalScore, maxScore } }: IUserInfoCard) => {
    const [nextLevelScore, setNextLevelScore] = useState<string>('');
    useEffect(() => {
      if (toNextLevelScore === 0) {
        setNextLevelScore(maxScore === 0 ? 'У вас нет назначенных юнитов ' : 'Вы прошли все назначенные юниты');
      } else {
        setNextLevelScore(
          level === UserDataLevel.SENIOR
            ? 'Баллов до полного прохождения: ' + toNextLevelScore
            : level === UserDataLevel.MIDDLE
            ? 'Баллов до следующего уровня: ' + toNextLevelScore
            : 'Баллов до следующего уровня: ' + toNextLevelScore,
        );
      }
    }, [toNextLevelScore, totalScore, maxScore, level]);
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '48px 0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">{fio}</Typography>
          <Typography>{role}</Typography>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Box sx={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
              <StarRounded
                sx={{
                  width: '24px',
                  height: '24px',
                  background: 'rgba(144, 202, 249, 1)',
                  borderRadius: '50%',
                  color: '#29363D',
                }}
              />
              <Typography>{totalScore}</Typography>
            </Box>
            <Typography color="rgba(255,255,255,0.5)">{nextLevelScore}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <LinearProgress
                variant="determinate"
                value={
                  level === UserDataLevel.SENIOR
                    ? 100
                    : level === UserDataLevel.MIDDLE
                    ? 100
                    : totalScore === 0
                    ? 0
                    : Math.round(totalScore / ((maxScore * 0.35) / 100))
                }
                sx={{ width: '117px' }}
              />
              <Typography sx={{ color: level === UserDataLevel.JUNIOR ? 'white' : 'rgba(255,255,255,0.5)' }}>
                Новичок
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <LinearProgress
                variant="determinate"
                value={
                  level === UserDataLevel.SENIOR
                    ? 100
                    : level === UserDataLevel.MIDDLE
                    ? Math.round((totalScore - Math.round((maxScore * 35) / 100 + 1)) / ((maxScore * 0.34) / 100))
                    : 0
                }
                sx={{ width: '117px' }}
              />
              <Typography sx={{ color: level === UserDataLevel.MIDDLE ? 'white' : 'rgba(255,255,255,0.5)' }}>
                Мидл
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <LinearProgress
                variant="determinate"
                value={
                  level === UserDataLevel.SENIOR
                    ? toNextLevelScore === 0
                      ? 100
                      : Math.round((totalScore - Math.round((maxScore * 69) / 100 + 1)) / ((maxScore * 0.31) / 100))
                    : 0
                }
                sx={{ width: '117px' }}
              />
              <Typography sx={{ color: level === UserDataLevel.SENIOR ? 'white' : 'rgba(255,255,255,0.5)' }}>
                Чемпион
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
);
