import { Container, CssBaseline, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';

import { ContainerContext } from '../container/ContainerContext';
import { MainLayoutBottomLogoMenu } from '../../view/layout/main/parts/LeftNavigation/parts/MainLayoutBottomLogoMenu';

const ErrorPage = () => {
  const context = useContext(ContainerContext);
  const ReqEduLogoComponent = context.get(MainLayoutBottomLogoMenu);
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container disableGutters maxWidth={false}>
        <Typography className={classes.background}>
          <Typography className={classes.topIcons}>
            <ReqEduLogoComponent />
          </Typography>

          <Typography>
            <img src='/pictures/renderError.svg' />
          </Typography>

          <Typography variant='h3' className={classes.headlineText}>
            Данная функция недоступна
          </Typography>

          <Typography className={classes.mainText}>
            Мы уже делаем все возможное, чтобы возобновить работу. Если у вас есть вопросы обратитесь
            в службу поддержки:
          </Typography>

          <Typography className={classes.bottomContainer}>
            <Typography className={classes.supportText}>
              <Typography className={classes.supportRegular}>
                Напишите нам
              </Typography>

              <Typography variant='h6' className={classes.supportBold}>
                support@startx.team
              </Typography>
            </Typography>

            <Typography className={classes.supportText}>
              <Typography className={classes.supportRegular}>
                Или позвоните
              </Typography>

              <Typography variant='h6' className={classes.supportBold}>
                +7 499 677 19 07
              </Typography>
            </Typography>
          </Typography>
          <Typography variant='body2' className={classes.footerText}>
            © ООО «Антифишинг», {new Date().getFullYear()}
          </Typography>
        </Typography>
      </Container>
    </>
  );
};

export default ErrorPage;

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    margin: '0px',
    padding: '0px',
    background: '#344046 !important',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topIcons: {
    paddingBottom: '20px',
    width: '220px'
  },
  mainText: {
    width: '400px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingBottom: '50px'
  },
  headlineText: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '48',
    paddingTop: '60px',
    paddingBottom: '30px',
  },
  bottomContainer: {
    display: 'flex',
    maxWidth: '900px',
    width: '100%',
    justifyContent: 'space-between',
  },
  supportText: {
    border: '1px solid #47575F',
    borderRadius: '12px',
    width: '100%',
    maxWidth: '438px',
    height: '108px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  supportBold: {
    color: '#FFF'
  },
  supportRegular: {
    color: '#9C9C9C'
  },
  footerText: {
    color: '#9C9C9C',
    fontSize: '12px',
    fontWeight: '200',
    position: 'absolute',
    bottom: '30px'
  }
}));
