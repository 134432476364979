import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { IAdminPageProperties } from '../../IAdminPageProperties';
import { AdminTable } from '../../table/AdminTable';
import { StatusValueAdminDomain } from './store/StatusValueAdminDomain';

export interface IStatusValueAdminTableProperties extends IAdminPageProperties {}

export const StatusValueAdminTable = observer(({ layoutDomain }: IStatusValueAdminTableProperties) => {
  const [domain] = useState(new StatusValueAdminDomain(layoutDomain));

  return <AdminTable tableDomain={domain} />;
});
