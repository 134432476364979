import { ArrowDownward, ArrowUpward, DeleteForever, ExpandMore, VisibilityOff } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { MainLayoutDomainStore } from '../../../../../../../../view/layout/main/store/domain/MainLayoutDomainStore';
import { BlockType, ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { IHidden } from '../../../../../../service/unit/interface/theoryBlocks/IHidden';
import { generateUUID } from '../../../../../../utils/generateUUID';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';
import { ElementFactory } from '../ElementFactory';
import { NestingButton } from './NestingButton';

export interface IAdditionalElementsList {
  title: string;
  element: ITheoryBlock<any>;
}

export const additionalElementsList: IAdditionalElementsList[] = [
  {
    title: 'Абзац',
    element: {
      block: BlockType.PARAGRAPH,
      data: {
        text: '',
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Абзац',
    },
  },
  {
    title: 'Список',
    element: {
      block: BlockType.LIST,
      data: {
        type: 'Numbered',
        elementCount: 0,
        listData: [],
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Список',
    },
  },
  {
    title: 'Таблица',
    element: {
      block: BlockType.TABLE,
      data: {
        columnsCount: 0,
        rowsCount: 0,
        tableData: {
          headerText: [],
          rows: [],
        },
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Таблица',
    },
  },
  {
    title: 'Пример кода',
    element: {
      block: BlockType.CODE_EXAMPLE,
      data: {
        languages: [],
        example: {
          language: '',
          content: '',
        },
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Пример кода',
    },
  },
  {
    title: 'Специальный блок',
    element: {
      block: BlockType.SPECIAL_BLOCK,
      data: {
        name: '',
        color: '',
        contentBlocks: [],
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Специальный блок',
    },
  },
  {
    title: 'Изображение',
    element: {
      block: BlockType.IMAGE,
      data: {
        name: '',
        originalName: '',
        data: '',
        indexName: '',
        type: 'image',
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Изображение',
    },
  },
  {
    title: 'Видео',
    element: {
      block: BlockType.VIDEO,
      data: {
        name: '',
        originalName: '',
        data: '',
        indexName: '',
        type: 'video',
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Видео',
    },
  },
  {
    title: 'Вкладки',
    element: {
      block: BlockType.TABS,
      data: {
        tabsCount: 0,
        tabsData: {
          title: '',
          contentBlocks: [],
          sortIndex: 0,
          pseudoId: '',
        },
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Вкладки',
    },
  },
  {
    title: 'Теги',
    element: {
      block: BlockType.TAGS,
      data: {
        tagsCount: 0,
        tagsData: {
          title: '',
          contentBlocks: [],
          sortIndex: 0,
          pseudoId: '',
        },
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Теги',
    },
  },
];
export interface IHiddenBlock {
  data: ITheoryBlock<IHidden>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}
export const HiddenBlock = observer(({ data: { data, pseudoId }, onDelete, onUp, onDown }: IHiddenBlock) => {
  const layoutDomain = new MainLayoutDomainStore();
  const addNewElement = (block) => {
    const newBlock: ITheoryBlock<any> & { Element?: any } = JSON.parse(JSON.stringify(block));
    newBlock.pseudoId = generateUUID();
    newBlock.Element = ElementFactory[newBlock.block];

    data.subBlocks.push(newBlock);
  };
  const onDeleteSubBlock = (id: string) => {
    const filteredSubBlocks = data.subBlocks.filter((block) => {
      return block.pseudoId !== id;
    });
    data.subBlocks = filteredSubBlocks;
  };
  const onUpSubBlock = (index: number) => {
    if (index === 0 && data.subBlocks.length !== 0) {
      const firstEl = data.subBlocks.shift();
      // @ts-ignore
      data.subBlocks.push(firstEl);
    } else {
      [data.subBlocks[index], data.subBlocks[index - 1]] = [data.subBlocks[index - 1], data.subBlocks[index]];
    }
  };
  const onDownSubBlock = (index: number) => {
    if (index === data.subBlocks.length - 1 && data.subBlocks.length !== 0) {
      const lastEl = data.subBlocks.pop();
      // @ts-ignore
      data.subBlocks.unshift(lastEl);
    } else {
      [data.subBlocks[index], data.subBlocks[index + 1]] = [data.subBlocks[index + 1], data.subBlocks[index]];
    }
  };
  return (
    <Box
      key={pseudoId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
        '&:hover .action': { visibility: 'visible' },
      }}
    >
      <Accordion sx={{ background: 'transparent', boxShadow: 'none' }} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            display: 'flex',
            '& .MuiAccordionSummary-expandIconWrapper': { order: -1 },
            alignItems: 'flex-start',
            '& .MuiAccordionSummary-content': {
              margin: 0,
              width: '100%',
              flex: 1,
            },
            '.Mui-expanded': {
              margin: '0 !important',
            },
            '&.Mui-expanded': {
              minHeight: '32px',
            },
            padding: 0,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
            <Typography sx={{ marginRight: 'auto' }}>Скрытый блок</Typography>
            <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
              <DeleteForever
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onDelete();
                }}
              />
              <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {}} />
              <ArrowUpward
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onUp();
                }}
              />
              <ArrowDownward
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onDown();
                }}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <FormTextFieldStandard
            variant="outlined"
            label={'Заголовок'}
            multiline
            value={data.name}
            onChange={(newValue) => {
              data.name = newValue;
            }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {data.subBlocks.map((block, index) => {
              const Element = ElementFactory[block.block];
              const elementData = { data: block.data, pseudoId: block.pseudoId };
              return (
                <Box
                  sx={{
                    width: '100%',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    '& > div': {
                      background: 'transparent',
                    },
                  }}
                  key={block.pseudoId}
                >
                  <Element
                    layoutDomain={layoutDomain}
                    data={elementData}
                    onDelete={() => onDeleteSubBlock(block.pseudoId)}
                    onUp={() => onUpSubBlock(index)}
                    onDown={() => onDownSubBlock(index)}
                  />
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
            {additionalElementsList.map((button) => {
              return <NestingButton title={button.title} action={() => addNewElement(button.element)} />;
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
