import React from 'react';
import { GridColumns } from '@mui/x-data-grid-pro';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IDataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../../design/dataTable/store/IDataTableUI';
import { ActiveDirectoryAdminModel } from './ActiveDirectoryAdminModel';
import { ActiveDirectoryRowsUI } from './rows/ActiveDirectoryRowsUI';
import { riskTimeFormat } from '../../../../../../../service/date/since';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';


export class ActiveDirectoryAdminUI
  extends DataTableUI<ActiveDirectoryAdminModel, any>
  implements IDataTableUI<ActiveDirectoryAdminModel, any>
{
  constructor(
    public domain: IDataTableDomain<ActiveDirectoryAdminModel, any>,
    rows: IDataTableRowsUI<ActiveDirectoryAdminModel, any> | null,
    public activeDirectory: IEntityListStore<ActiveDirectoryAdminModel> = injectEntityList<ActiveDirectoryAdminModel>(
      [],
    ),
  ) {
    super(domain, rows);
    this.rows = rows || new ActiveDirectoryRowsUI(this);
  }

  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        headerName: 'Название',
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'URL',
        field: 'url',
        flex: 1,
      },
      {
        field: 'lastSyncDate',
        headerName: 'Последняя синхронизация',
        flex: 1,
        type: 'Date',
        valueGetter: (params) => {
          return riskTimeFormat(params.value) || null;
        },
      },

      {
        headerName: 'Активен',
        field: 'isActive',
        flex: 1,
        type: 'boolean',
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.5,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Синхронизировать">
              <IconButton
                disabled={!this.isCanEdit.value || !this.isCanDelete.value || !row.row.isActive}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => {
                  //@ts-ignore
                  this.domain.rootPrivateServices.activeDirectory.server.syncById(row.id);
                }}
              >
                <SyncAltIcon sx={{ cursor: 'pointer', fontSize: '20px', marginRight: '2px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px', marginRight: '2px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },

    }

    this.actionColumnSettings.setValue(actionColumn)
  }
}
