import AddIcon from '@mui/icons-material/Add';
import { FormHelperText, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { ApplicationModelCustomInformationType } from '../../../service/application/entity/IApplicationCustomInformation';
import { SpecificationItem } from '../../../view/admin/page/specification/parts/formSelection/SpecificationFormSelection';
import { SpecificationFormSelectionDomain } from '../../../view/admin/page/specification/parts/formSelection/SpecificationFormSelectionDomain';
import { FormBox } from '../../../view/design/form/FormBox';
import { FormTextField } from '../../../view/design/form/FormTextField';
import { ProjectButton } from '../../../view/design/link/ProjectButton';
import { ApplicationDetailsType } from '../../../view/user/page/application/info/store/ApplicationDetailsType';
import { sortCallBack } from './sortOrder';
import {
  IApplicationInfoDetailsFormProperties
} from '../../../view/user/page/application/info/parts/application/ApplicationInfoDetailsForm';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { getProtectionLevel, protectLevels, specificationsNames } from '../protectLevel/protectLevelSpecifications';

const getCustomFieldsWithOptions = (domain) => {
  const result = domain.ui.editApplication.entity.customInformation?.fields.map((customInformationField) => {
    return {
      name: customInformationField.fieldName,
      component: (
        <Grid container flexDirection={'row'}>
          <Grid item style={{ height: 200, width: '100%' }}>
            <FormTextField
              required={false}
              multiline={true}
              rows={4}
              label={customInformationField.fieldName}
              value={customInformationField.value}
              onChange={(newValue) => {
                customInformationField.value = newValue;
              }}
            />
            <div style={{ marginLeft: 25 }}>
              <FormHelperText>{customInformationField.fieldDescription}</FormHelperText>
            </div>
          </Grid>
        </Grid>
      ),
    };
  });
  return result || [];
};

const getSpecificationsWithOptions = (domain, specificationDomain) => {
  const result = domain.ui.editSpecificationsCategories.list.map((specificationCategory) => {
    if (specificationCategory.name?.toLocaleLowerCase() === 'уровень защищенности пдн') {
      return {
        name: specificationCategory.name,
        component: (
          <SpecificationItemExtended
            disabled={true}
            domain={domain}
            specificationCategory={specificationCategory}
            specificationDomain={specificationDomain}
          />
        ),
      };
    }

    return {
      name: specificationCategory.name,
      component: (
        <SpecificationItemExtended
          domain={domain}
          specificationCategory={specificationCategory}
          specificationDomain={specificationDomain}
        />
      ),
    };
  });
  return result || [];
};

export interface IApplicationInfoDetailsFormExtendedProperties extends IApplicationInfoDetailsFormProperties {
  domain: ApplicationDetailDomain;
  isHaveAnyCombineSpecs?: number;
}

const cleanProtectLevel = ({ domain, isHaveAnyCombineSpecs }: IApplicationInfoDetailsFormExtendedProperties) => {
  const allLevels = domain.ui.editSpecifications.list
    .filter((specification) => {
      return protectLevels.includes(specification.name.toLocaleLowerCase());
    })
    .map((specification) => specification.id);

  let isNeedClean = false;
  domain.ui.editApplication.entity.specificationsIds?.forEach((item) => {
    allLevels.includes(item) && (isNeedClean = true);
  });

  if (isNeedClean && !isHaveAnyCombineSpecs) {
    domain.ui.editApplication.setEntity({
      ...domain.ui.editApplication.entity,
      specificationsIds: domain.ui.editApplication.entity.specificationsIds
        ? [...domain.ui.editApplication.entity.specificationsIds.filter((item) => !allLevels.includes(item))]
        : [],
    });
  }
};


const getProtectLevel = ({ domain }: IApplicationInfoDetailsFormProperties) => {
  const specifications = domain.ui.editApplication.entity?.specificationsIds?.map((specificationId) => {
    const specification = domain.ui.editSpecifications.list.find((specification) => {
      return specification.id === specificationId;
    });
    if (specification && specificationsNames?.includes(specification.name.toLocaleLowerCase())) {
      return specification.name.toLocaleLowerCase();
    }
  });
  const level = getProtectionLevel(specifications);

  const allLevels = domain.ui.editSpecifications.list.filter((specification) => {
    return protectLevels.includes(specification.name.toLocaleLowerCase())
  });

  cleanProtectLevel({ domain, isHaveAnyCombineSpecs: level });

  if (level !== 0) {
    const result = allLevels.find((specification) => specification.name.toLocaleLowerCase() === `уз ${level}`);
    if (result?.id != null && !domain.ui.editApplication.entity.specificationsIds?.includes(result.id)) {
      domain.ui.editApplication.setEntity({
        ...domain.ui.editApplication.entity,
        specificationsIds: domain.ui.editApplication.entity.specificationsIds ? [...domain.ui.editApplication.entity.specificationsIds, result.id] : [],
      });
    }
  }
};

export const EditModeListsExtended = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const [specificationDomain] = useState<SpecificationFormSelectionDomain>(new SpecificationFormSelectionDomain());
  const customFields = getCustomFieldsWithOptions(domain);
  const specifications = getSpecificationsWithOptions(domain, specificationDomain);
  const resultArray = [...customFields, ...specifications];
  resultArray.sort(sortCallBack);

  useEffect(() => {
    getProtectLevel({ domain });
  }, [domain.ui.editApplication.entity.specificationsIds, domain]);

  return (
    <>
      {domain.ui.type.value === ApplicationDetailsType.template && (
        <Grid item xs={6} key="addCustomField">
          <div style={{ width: 350 }}>
            <FormBox>
              <ProjectButton
                icon={<AddIcon />}
                action={() => {
                  if (!domain.ui.editApplication.entity.customInformation) {
                    domain.ui.editApplication.entity.customInformation = { fields: [] };
                  }
                  domain.ui.editApplication.entity.customInformation.fields.push({
                    fieldDescription: '',
                    fieldName: '',
                    sortIndex: domain.ui.editApplication.entity.customInformation.fields.length + 1,
                    type: ApplicationModelCustomInformationType.multiline,
                    value: '',
                  });
                }}
              >
                Добавить дополнительное поле
              </ProjectButton>
            </FormBox>
          </div>
        </Grid>
      )}
      {domain.ui.type.value === ApplicationDetailsType.entity ? (
        <Grid item key="allFieldsData">
          {resultArray.map((item) => item.component)}
        </Grid>
      ) : (
        <Grid item key="allFieldsData">
          {specifications.map((item) => item.component)}
        </Grid>
      )}
    </>
  );
});

export const SpecificationItemExtended = observer(({ domain, specificationCategory, specificationDomain, disabled = false }) => {
  return (
    <SpecificationItem
      disabled={disabled}
      onChange={(specificationIds) => domain.onChangeApplicationSpecifications(specificationIds)}
      selectedSpecificationsIds={domain.ui.editApplication.entity.specificationsIds || []}
      specificationCategories={domain.ui.editSpecificationsCategories.list}
      specifications={domain.ui.editSpecifications.list}
      domain={specificationDomain}
      specificationCategory={specificationCategory}
      key={specificationCategory.id}
    />
  );
});
