import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { ContainerContext } from '../../../../common/container/ContainerContext';
import { IUserPageProperties } from '../IUserPageProperties';
import { ProjectListPage } from '../project/list/ProjectListPage';
import { MainPageComponent } from './MainPageInjects';

export interface IMainPageProperties extends IUserPageProperties {}

export const MainPage = observer(({ layoutDomain }: IMainPageProperties) => {
  const container = useContext(ContainerContext);
  const Component = container.get(MainPageComponent) || ProjectListPage;
  return <Component layoutDomain={layoutDomain} />;
});
