import { MainLayoutDomainStore } from '../../../../../../../../../../view/layout/main/store/domain/MainLayoutDomainStore';
import { IPassingStepperDomain } from '../../../../../../../design/passingStepper/store/IPassingStepperDomain';
import { PassingStepperDomain } from '../../../../../../../design/passingStepper/store/PassingStepperDomain';
import { IPassingQuizDomain } from '../IPassingQuizDomain';
import { PassingQuizDomain } from '../PassingQuizDomain';

export class PassingQuizStepperDomain extends PassingStepperDomain implements IPassingStepperDomain {
  public rootDomain: IPassingQuizDomain;
  public layoutDomain = new MainLayoutDomainStore();
  constructor(rootDomain: IPassingQuizDomain) {
    super();
    this.rootDomain = rootDomain || new PassingQuizDomain(this.layoutDomain);
    this.quizDomain = rootDomain;
    this.isQuizAdditionalAtions.setValue(true);
    this.quizAdditionalActions.setList([
      {
        name: 'Проверить',
        action: this.rootDomain.checkAnswers,
      },
    ]);
  }
}
