import { Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { DataTableFormViewMode } from '../../../../../../../design/dataTable/store/DataTableFormViewMode';
import { CommonRowContextMenuComponentProperties } from '../../../../../../../design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { ActiveDirectoryServerAdminModel } from '../modal/ActiveDirectoryServerAdminModel';
import { ActiveDirectoryContextMenuDomain } from './ActiveDirectoryContextMenuDomain';

export interface IActiveDirectoryRowContextMenuProperties extends CommonRowContextMenuComponentProperties<ActiveDirectoryServerAdminModel> {
  domain: ActiveDirectoryContextMenuDomain;
  dataCy?: string
}

export const ActiveDirectoryRowContextMenu = observer(({
  domain,
  isOpened,
  event,
  row,
  dataCy
}: IActiveDirectoryRowContextMenuProperties) => {
  const syncActiveDirectoryConnection = async (rowData) => {
    //@ts-ignore
    await domain.dataTableDomain.rootPrivateServices.activeDirectory.server.syncById(rowData.id);
  }

  return (
    <Menu
      open={isOpened}
      onClose={() => {
        row && domain.ui.closeContextMenu(row);
      }}
      anchorReference="anchorPosition"
      //@ts-ignore

      anchorPosition={isOpened === true ? { top: event?.clientY, left: event?.clientX } : undefined}
      componentsProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            row && domain.ui.closeContextMenu(row);
          },
        },
      }}
    >
      {domain.dataTableDomain.ui.features.value.isCanEdit && domain.dataTableDomain.ui.isCanEdit.value && (
        <MenuItem
          data-cy={`action-edit`}
          onClick={() => {
            row && domain.ui.closeContextMenu(row);
            row &&
              domain.dataTableDomain.ui.formMode.value === DataTableFormViewMode.modalWindow &&
              domain.onEditRow(row);
            row &&
              domain.dataTableDomain.ui.formMode.value === DataTableFormViewMode.drawer &&
              domain.onEditRowInDrawer(row);
          }}
        >
          Редактировать
        </MenuItem>
      )}
      {domain.dataTableDomain.ui.features.value.isCanDelete && domain.dataTableDomain.ui.isCanDelete.value && (
        <MenuItem
          // data-cy={`action-sync`} //
          onClick={async () => {
            row && domain.ui.closeContextMenu(row);
            row && await syncActiveDirectoryConnection(row)
            row && await domain.dataTableDomain.boot()
          }}
        >
          Синхронизировать
        </MenuItem>
      )}

      {domain.dataTableDomain.ui.features.value.isCanDelete && domain.dataTableDomain.ui.isCanDelete.value && (
        <MenuItem
          data-cy={`action-delete`}
          onClick={() => {
            row && domain.ui.closeContextMenu(row);
            row && domain.onDeleteRow(row);
          }}
        >
          Удалить
        </MenuItem>
      )}

      {domain.dataTableDomain.ui.features.value.isCanRedirectDetail && (
        <MenuItem onClick={() => row && domain.onRedirectDetail(row)}>Детали</MenuItem>
      )}
    </Menu>
  );
},
);
