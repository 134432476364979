import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { UserRating } from '../../admin/page/usersRating/UserRating';
import space from './assets/space.png';
import { ILearningApplicationDetailDomain } from './domain/ILearningApplicationDetailDomain';
import { UnitListAdminCard } from './parts/UnitListAdminCard';
import { UnitListUserCard } from './parts/UnitListUserCard';

export const LearningApplicationInfoPage = observer(({ domain }: { domain: ILearningApplicationDetailDomain }) => {
  const { applicationId = '' } = useParams<any>();

  useEffect(() => {
    domain.loadLearning(applicationId);
  }, [applicationId, domain]);

  return (
    <>
      {!domain.ui.isLoading.value && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '64px', padding: '24px 15px' }}>
          {domain.ui.unitList.list.length > 0 ? (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                <Typography variant="h4">Карта компетенций команды</Typography>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', columnGap: '24px', rowGap: '8px' }}>
                  {domain.ui.groupedUnits.list.map((group) => {
                    return (
                      <>
                        {group.unitsList.length !== 0 && (
                          <Box>
                            <Typography variant="h6" sx={{ marginBottom: '16px' }}>
                              {group.groupName}
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                              {group.unitsList.map((unit) => {
                                const unitResult = domain.ui.passingReslutList.list.filter(
                                  (unitRes) => unitRes.unitId === unit.id,
                                );
                                return domain.ui.isAdmin.value ? (
                                  <UnitListAdminCard
                                    unit={unit}
                                    applicationId={applicationId}
                                    handleIncludeUnit={domain.handleIncludeUnit}
                                  />
                                ) : (
                                  <UnitListUserCard
                                    card={unit}
                                    action={domain.redirect}
                                    score={unit.score}
                                    passingUnitResult={
                                      unitResult[0] ?? {
                                        totalUnitResult: 0,
                                        isPassed: false,
                                      }
                                    }
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        )}
                      </>
                    );
                  })}
                </Box>
              </Box>
            </>
          ) : domain.ui.isAdmin.value ? (
            <Box sx={{ padding: '24px 16px', display: 'flex', flexDirection: 'column', gap: '32px' }}>
              <Typography variant="h4">Карта компетенций команды</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '24px 0px',
                  gap: '24px',
                }}
              >
                <img src={space} alt="space" />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
                  <Typography variant="h5">Нет подходящих юнитов</Typography>
                  <Typography variant="body2" textAlign={'center'}>
                    Выберите другие характеристики системы <br></br> или привяжите характеристики к обучающему юниту
                  </Typography>
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ background: '#90CAF9' }}
                  onClick={domain.redirectToKnowledge}
                >
                  Перейти в Знания
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant="h4">У вас нет назначенных юнитов</Typography>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
            {domain.ui.teamUserIds.list && domain.ui.teamUserIds.list.length > 0 && (
              <>
                <Typography variant="h4">Команда</Typography>
                <UserRating includedTeam={domain.ui.teamUserIds.list} layoutDomain={domain.layoutDomain} />
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
});
