import { AutocompleteChangeReason } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Alert, Autocomplete, Checkbox, FormControl, FormHelperText, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IFormMultiAutocompleteProperties extends ICommonProperties {
  values: AutocompleteSelectItem[];
  selected?: string[];
  label: string;
  placeholder?: string;
  onChangeSelect: (
    options: AutocompleteSelectItem[],
    event?: any,
    reason?: AutocompleteChangeReason,
    details?: any,
  ) => void;
  disabled?: boolean;
  helperText?: string;
  errorMessage?: string | null;
  warningMessage?: string | null;
  disableCloseOnSelect?: boolean;
  isSelection?: boolean;
  dataCy?: string;
  selectAllOption?: boolean;
}

export interface AutocompleteSelectItem {
  value?: any;
  name?: string;
  disabled?: boolean;
  types?: AutocompleteSelectItemType[];
  selectAll?: boolean;
}

export enum AutocompleteSelectItemType {
  selection,
  noSelection,
}

export const FormMultiAutocomplete = observer(
  ({
    values,
    label,
    onChangeSelect,
    placeholder = '',
    selected = [],
    disabled = false,
    helperText = undefined,
    errorMessage = null,
    warningMessage = null,
    disableCloseOnSelect = true,
    isSelection = true,
    dataCy,
    selectAllOption = false,
  }: IFormMultiAutocompleteProperties) => {
    const classes = useStyles();

    let options = values;
    if (selectAllOption) {
      options = [
        {
          selectAll: true,
          name: 'Выбрать всех',
          disabled: false,
        },
        ...values,
      ];
    }
    const onChange: any = (
      event: any,
      value: AutocompleteSelectItem[] | [],
      reason: AutocompleteChangeReason,
      details: any,
    ) => {
      if (details?.option?.selectAll) {
        setSelectAll(!selectAll);
        value = selectAll ? [] : values;
      } else {
        setSelectAll(value.length === values.length && values.length > 0);
      }

      value ? onChangeSelect(value, event, reason, details) : onChangeSelect([]);
    };

    const selectedValues = options.filter((value) => selected.indexOf(value.value) !== -1);
    const [selectAll, setSelectAll] = React.useState(selectedValues.length === values.length && values.length > 0);

    return (
      <FormControl fullWidth className={classes.formControl}>
        <div className={classes.root}>
          <Autocomplete
            filterOptions={(options, state) => {
              if (state.inputValue) {
                return options.filter(option =>
                  option.name?.toLocaleLowerCase().includes(state.inputValue.toLocaleLowerCase())
                )
              }
              return options
            }}
            multiple
            limitTags={3}
            disableCloseOnSelect={disableCloseOnSelect}
            color="secondary"
            onChange={onChange}
            id={`${label}`}
            data-cy={dataCy ? dataCy : label}
            options={values}
            getOptionLabel={(option) => {
              if (option.name && option.name.length > 45) {
                return option?.name?.slice(0, 45) + '...'
              }
              return option?.name || 'undefined'
            }}
            value={selectedValues}
            getOptionDisabled={(option) => option?.disabled === true}
            renderInput={(params) => (
              <TextField 
              color="secondary" 
              {...params} 
              variant="standard" 
              label={label} 
              placeholder={placeholder}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props} data-cy={dataCy ? `${dataCy}-${`${props.id}`.split('-')[2]}` : `${option.name}`}>
                {option.selectAll ? (
                  <Checkbox checked={selectAll} onChange={onChange} />
                ) : (
                  !option.types?.includes(AutocompleteSelectItemType.noSelection) && (
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  )
                )}
                {option.name || '(Нет названия)'}
              </li>
            )}
            disabled={disabled}
          />
        </div>
        {errorMessage && (
          <Alert icon={false} variant="outlined" severity="error">
            {errorMessage}
          </Alert>
        )}
        <FormHelperText>{helperText}</FormHelperText>
        {warningMessage && (
          <Alert icon={false} variant="outlined" severity="warning">
            {warningMessage}
          </Alert>
        )}
      </FormControl>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  formControl: {
    padding: theme.spacing(2),
  },
}));
