import { Divider, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ICodeContextMenu } from './ICodeContextMenu';

export const CodeContextMenu = observer(
  ({ open, onClose, event, newFolder, currentFile, newFile, onDelete, onRename, toggleQuestion }: ICodeContextMenu) => {
    return (
      <Menu
        open={open}
        onClose={onClose}
        anchorReference="anchorPosition"
        //@ts-ignore

        anchorPosition={open === true ? { top: event?.clientY, left: event?.clientX } : undefined}
      >
        <MenuItem
          disabled={!currentFile.isQuestion}
          onClick={() => {
            toggleQuestion(currentFile);
            onClose();
          }}
        >
          Убрать вопрос
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => onRename(currentFile)}>Переименовать</MenuItem>
        <MenuItem>Создать копию</MenuItem>
        <MenuItem onClick={() => onDelete(currentFile.id)}>Удалить</MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            newFile(currentFile);
          }}
        >
          Новый файл
        </MenuItem>
        <MenuItem
          onClick={() => {
            newFolder(currentFile);
          }}
        >
          Новая папка
        </MenuItem>
      </Menu>
    );
  },
);
