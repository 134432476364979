import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { IAdminPageProperties } from '../../IAdminPageProperties';
import { AdminTable } from '../../table/AdminTable';
import { AdditionalColumnEntityAdminDomain } from './store/AdditionalColumnEntityAdminDomain';

export interface IAdditionalColumnEntityAdminTableProperties extends IAdminPageProperties {}

export const AdditionalColumnEntityAdminTable = observer(
  ({ layoutDomain }: IAdditionalColumnEntityAdminTableProperties) => {
    const [domain] = useState(new AdditionalColumnEntityAdminDomain(layoutDomain));

    return <AdminTable tableDomain={domain} />;
  },
);
