import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { inject } from '../../../../../../common/container/inject';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IPerformerTypeModel } from '../../../../../../service/performer/type/IPerformerTypeModel';
import { IRequirementCategoryModel } from '../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementModel } from '../../../../../../service/requirement/entity/IRequirementModel';
import { IRequirementSearch } from '../../../../../../service/requirement/entity/IRequirementSearch';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ITagEntityModel } from '../../../../../../service/tag/entity/ITagEntityModel';
import { IUserEntityModel } from '../../../../../../service/user/IUserEntityModel';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { RequirementEntityRowsUI } from './rows/RequirementEntityRowsUI';
import { DataTableFormViewMode } from '../../../../../design/dataTable/store/DataTableFormViewMode';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../../../common/store/interface/IPrimitiveStore';
import { RichTextArea } from '../../../../../design/RichTextEditor/RichTextEditor';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';

export class RequirementEntityAdminUI
  extends DataTableUI<IRequirementModel, IRequirementSearch>
  implements IDataTableUI<IRequirementModel, IRequirementSearch>
{
  constructor(
    public domain: IDataTableDomain<IRequirementModel, IRequirementSearch>,
    rows: IDataTableRowsUI<IRequirementModel, IRequirementSearch> | null,
    public categories: IEntityListStore<IRequirementCategoryModel> = injectEntityList<IRequirementCategoryModel>([]),
    public specifications: IEntityListStore<ISpecificationEntityModel> = injectEntityList<ISpecificationEntityModel>(
      [],
    ),
    public tags: IEntityListStore<ITagEntityModel> = injectEntityList<ITagEntityModel>([]),
    public users: IEntityListStore<IUserEntityModel> = injectEntityList<IUserEntityModel>([]),
    public performers: IEntityListStore<IPerformerTypeModel> = injectEntityList<IPerformerTypeModel>([]),
    public router: IRouterService = inject<IRouterService>(RouterServiceToken),
    public formMode: IPrimitiveStore<DataTableFormViewMode> = injectPrimitive<DataTableFormViewMode>(DataTableFormViewMode.drawer),
  ) {
    super(domain, rows);
    this.formMode = formMode;
    this.rows = rows || new RequirementEntityRowsUI(this);
  }
  setDomainId(): string {
    return 'settings/requirement/entity';
  }
  async getColumns(): Promise<GridColumns> {
    const entityList = this.domain.ui.rows.entities.list
    this.setActionColumn()
    return [
      {
        minWidth: 150,
        headerName: 'Название',
        field: 'shortName',
        flex: 0.5,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value} />;
        },
        sortComparator: (a, b) => {
          let aPrefix = "";
          let bPrefix = "";
          let aSuffix = "";
          let bSuffix = "";

          const aPrefixMatch = a.match(/[a-zA-Z]+/);
          if (aPrefixMatch) aPrefix = aPrefixMatch[0];

          const bPrefixMatch = b.match(/[a-zA-Z]+/);
          if (bPrefixMatch) bPrefix = bPrefixMatch[0];

          const aSuffixMatch = a.match(/\d+/);
          if (aSuffixMatch) aSuffix = aSuffixMatch[0];

          const bSuffixMatch = b.match(/\d+/);
          if (bSuffixMatch) bSuffix = bSuffixMatch[0];

          if (aPrefix < bPrefix) return -1;
          if (aPrefix > bPrefix) return 1;

          return parseInt(aSuffix) - parseInt(bSuffix);
        }
      },

      {
        minWidth: 190,
        headerName: 'Описание',
        field: 'description',
        flex: 1,
        renderCell: (params) => {
          const cureentRawEntity = entityList.find(item => item.id === params.id)

          return <RichTextArea data={params.value || ''} dataWithStyles={cureentRawEntity?.descriptionWithStyles || ''} />
        },
      },
      {
        minWidth: 170,
        headerName: 'Категория',
        field: 'categoryId',
        flex: 0.5,
        valueGetter: (params) => {
          const tagsName = this.domain.ui.rows.getFieldValueAsString('categoryId', params.value);
          return tagsName;
        },
        renderCell: (params: { value?: string; row?: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies('categoryId', params.row?.categoryId);
          return (
            <DataTableRowDependency
              onClick={() => {
                this.domain.onLinkAction(params.row.categoryId, '/settings/requirement/category');
              }}
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        minWidth: 200,
        headerName: 'Характеристики',
        field: 'specificationsIds',
        flex: 0.5,
        valueGetter: (params) => {
          const tagsName = this.domain.ui.rows.getFieldValueAsString('specificationsIds', params.value);
          return tagsName;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies(
            'specificationsIds',
            params.row.specificationsIds,
          );
          return (
            <DataTableRowDependency
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        minWidth: 170,
        headerName: 'Тэги',
        field: 'tagsIds',
        flex: 0.5,
        valueGetter: (params) => {
          const tagsName = this.domain.ui.rows.getFieldValueAsString('tagsIds', params.value);
          return tagsName;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies('tagsIds', params.row.tagsIds);
          return (
            <DataTableRowDependency
              onClick={(item: any) => {
                this.domain.onLinkAction(item.id, '/settings/tag/entity');
              }}
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        minWidth: 200,
        headerName: 'Тип исполнителей',
        field: 'performersTypesIds',
        flex: 0.5,
        valueGetter: (params) => {
          const performersName = this.domain.ui.rows.getFieldValueAsString('performersTypesIds', params.value);
          return performersName;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies(
            'performersTypesIds',
            params.row.performersTypesIds,
          );
          return (
            <DataTableRowDependency
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        minWidth: 200,
        headerName: 'Автор',
        field: 'authorId',
        flex: 0.5,
        valueGetter: (params) => {
          const userName = this.domain.ui.rows.getFieldValueAsString('authorId', params.value);
          return userName;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies('authorId', params.row?.authorId);

          return (
            <DataTableRowDependency
              getTextValue={(item: IUserEntityModel) => item.displayName || 'Без автора'}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
              backupValue={'Без автора'}
            />
          );
        },
      },
      {
        minWidth: 140,
        headerName: 'Порядок отображения',
        type: 'string',
        field: 'orderIndex',
        headerAlign: 'left',
        flex: 0.5,
        align: 'center',
        renderCell: (params: { value?: number }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value} />;
        },
      },
      {
        minWidth: 140,
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 0.3,
      },
    ];
  }


  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => {
                  this.domain.drawerDomain.ui.isEditMode.setValue(true)
                }}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => {
                  event.stopPropagation()
                  this.domain.contextMenuDomain.onDeleteRow(row)
                }
                }
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>

          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
