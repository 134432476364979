import { CssBaseline, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { inject } from '../../../common/container/inject';
import { ICommonProperties } from '../../../common/properties/ICommonProperties';
import { UserSystemRoleModelPermissionMap } from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { LayoutDrawer } from '../common/drawer/LayoutDrawer';
import { LayoutModalWindow } from '../common/modalWindow/LayoutModalWindows';
import { NotificationHandler, NotificationWrapper } from '../common/notification/Notification';
import { LayoutLoading } from './parts/LayoutLoading';
import { LeftNavigation } from './parts/LeftNavigation/LeftNavigation';
import { Scroller } from './parts/Scroller/Scroller';
import { IMainLayoutDomainStore, MainLayoutDomainStoreToken } from './store/domain/IMainLayoutDomainStore';

export interface IMainLayoutProperties extends ICommonProperties {
  children?: any;
  pageTitle?: string;
  allowedPermissions?: UserSystemRoleModelPermissionMap[];
  showNavBar?: boolean;
  showPaddings?: boolean;
  fullHeight?: boolean;
  overflowY?: boolean;
}

export const MainLayout = observer(
  ({
    children,
    pageTitle = '',
    allowedPermissions = [],
    showNavBar = true,
    showPaddings = true,
    fullHeight = false,
    overflowY = false,
  }: IMainLayoutProperties) => {
    const [layoutDomainStore] = useState<IMainLayoutDomainStore>(inject(MainLayoutDomainStoreToken));
    const uiStore = layoutDomainStore.ui;
    const classes = useStyles();
    const isLoading = layoutDomainStore.ui.isBootLoading.value;
    const isRenderContent = !isLoading;
    useEffect(() => {
      layoutDomainStore.loadLayoutData();
    }, [layoutDomainStore]);

    useEffect(() => {
      layoutDomainStore.checkPermissions(allowedPermissions);
    }, [layoutDomainStore, allowedPermissions, layoutDomainStore.ui.activeUser.entity]);
    useEffect(() => {
      layoutDomainStore.setPageTitle(pageTitle);
    }, [layoutDomainStore, pageTitle]);

    return (
      <Scroller
        detections={layoutDomainStore.ui.scrollDetection.value}
        removeElement={(element) => {
          //@ts-ignore
          layoutDomainStore.ui.removeDetections([element]);
        }}
      >
        <NotificationWrapper>
          {isRenderContent && (
            <div className={classes.root}>
              <CssBaseline />
              {showNavBar && <LeftNavigation layoutUser={uiStore.activeUser.entity} layoutDomain={layoutDomainStore} />}
              <main
                style={{
                  padding: showPaddings ? layoutDomainStore.ui.pagePadding.value : 0,
                  height: fullHeight ? '100vh' : '',
                  overflowY: overflowY ? 'hidden' : 'auto',
                }}
                className={classes.content}
              >
                {layoutDomainStore.ui.isAllowedUser.value &&
                  React.cloneElement(children, {
                    layoutDomain: layoutDomainStore,
                  })}
                {!layoutDomainStore.ui.isAllowedUser.value && <div>Страница недоступна</div>}
              </main>
            </div>
          )}
          {isLoading && <LayoutLoading />}
          <LayoutModalWindow layoutDomain={layoutDomainStore} />
          <LayoutDrawer layoutDomain={layoutDomainStore} />
          <NotificationHandler layoutDomain={layoutDomainStore} />
        </NotificationWrapper>
      </Scroller>
    );
  },
);

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(2),
      overflowX: 'hidden',
      width: '100%',
    },
    toolbar: theme.mixins.toolbar,
  };
});
