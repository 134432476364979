import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { IAdditionalColumnSearch } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnSearch';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdminTableDomain } from '../../../table/store/AdminTableDomain';
import { IAdminTableColumn } from '../../../table/store/IAdminTableUI';
import { AdditionalColumnEntityAdminModel } from './AdditionalColumnEntityAdminModel';

export class AdditionalColumnEntityAdminDomain extends AdminTableDomain<
  AdditionalColumnEntityAdminModel,
  IAdditionalColumnSearch
> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    protected types: IEntityStore<any> = injectEntity<any>({}),
  ) {
    super(layoutDomain, rootPrivateServices.additionalColumn.entity);
  }

  setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-update']]),
    );
  }

  protected async loadDependencies() {
    try {
      this.ui.isLoading.setValue(true);
      const typesListResult = await this.rootPrivateServices.additionalColumn.type.getTypes();
      this.ui.isLoading.setValue(false);

      this.types.setEntity(typesListResult);
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  async loadData() {
    await this.loadDependencies();
    const columns = await this.getColumns();
    this.ui.columns.setList(columns);
    await super.loadData();
  }

  private async getColumns(): Promise<IAdminTableColumn[]> {
    return [
      {
        title: 'Название',
        field: 'name',
        isRequired: true,
      },
      {
        title: 'Описание',
        field: 'description',
        custom: {
          multiline: true,
        },
        isRequired: true,
      },
      {
        title: 'Тип',
        field: 'type',
        lookup: this.types.entity,
      },
      {
        title: 'Порядок отображения',
        type: 'numeric',
        field: 'orderIndex',
        initialEditValue: 1,
        validate: (rowData) =>
          Number.isInteger(rowData.orderIndex)
            && !Number.isNaN(rowData.orderIndex)
              ? true
              : { isValid: false, helperText: 'Введите целое число' }
      },
      {
        title: 'Активен',
        field: 'isActive',
        type: 'boolean',
        initialEditValue: true,
      },
    ];
  }
}
