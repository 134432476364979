import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { TagCategoryAdminModalDomain } from './TagCategoryAdminModalDomain';

export interface ITagCategoryAdminModalProperties {
  domain: TagCategoryAdminModalDomain;
}

export const TagCategoryAdminModal = observer(({ domain }: ITagCategoryAdminModalProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <TagNameCustomFieldText domain={domain} />
      <TagDescriptionCustomFieldArea domain={domain} />
      <TagActiveStatusCustomSwitch domain={domain} />
      <TagOrderCustomFieldText domain={domain} />
      <FormActions
        onSave={() => {
          domain.onSaveUpdates();
        }}
        onCancel={() => domain.onCancelUpdates()}
      />
    </FormModalContainer>
  );
});

const TagNameCustomFieldText = observer(({ domain }: { domain: TagCategoryAdminModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const TagDescriptionCustomFieldArea = observer(({ domain }: { domain: TagCategoryAdminModalDomain }) => {
  return (
    <FormTextArea
      required={true}
      label={'Описание'}
      value={domain.ui.model.entity.description}
      onChange={domain.getUpdateFieldHandler('description')}
      errorMessage={domain.getValidationErrorFor('description')?.message}
    />
  );
});

const TagActiveStatusCustomSwitch = observer(({ domain }: { domain: TagCategoryAdminModalDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isActive);
  }, [domain.ui.model.entity.isActive]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
      label="Статус"
    />
  );
});

const TagOrderCustomFieldText = observer(({ domain }: { domain: TagCategoryAdminModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
    />
  );
});
