import { inject } from '../../../../common/container/inject';
import { IError } from '../../../../common/error/IError';
import { IValidationErrorData } from '../../../../common/error/IValidationErrorData';
import { IServiceModel } from '../../../../service/common/model/IServiceModel';
import { ISearchRequestData } from '../../../../service/common/search/ISearchRequestData';
import { ICRUDService } from '../../../../service/common/service/ICRUDService';
import { IRouterService, RouterServiceToken } from '../../../../service/route/IRouterService';
import {
    UserSystemRoleModelPermissionMap
} from '../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { LayoutNotificationType } from '../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../layout/main/store/domain/IMainLayoutDomainStore';
import { DataGridDetailDomain } from '../parts/detail/DataGridDetailDomain';
import { IDataGridDetailDomain } from '../parts/detail/IDataGridDetailDomain';
import { DataTableFormDomain } from '../parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../parts/form/IDataTableFormDomain';
import { IRowContextMenuDomain } from '../parts/rowContextMenu/domain/IRowContextMenuDomain';
import { RowContextMenuDomain } from '../parts/rowContextMenu/domain/RowContextMenuDomain';
import { DataTableUI } from './DataTableUI';
import { IDataTableDomain } from './IDataTableDomain';
import { IDataTableUI } from './IDataTableUI';
import { ICoreCRUDService } from "../../../../service/coreCommon/common/service/ICoreCRUDService";
import { ICoreSearchRequestData } from "../../../../service/coreCommon/common/search/ICoreSearchRequestData";
import { DataTableDrawerDomain } from '../parts/drawer/DataTableDrawerDomain';
import { IDataTableDrawerDomain } from '../parts/drawer/IDataTableDrawerDomain';
import { DataTableFormViewMode } from './DataTableFormViewMode';

export abstract class DataTableDomain<
    ServiceModelType extends IServiceModel,
    SearchRequestType extends ISearchRequestData,
> implements IDataTableDomain<ServiceModelType, SearchRequestType> {
    public ui: IDataTableUI<ServiceModelType, SearchRequestType>;
    public modalDomain: IDataTableFormDomain<ServiceModelType>;
    public drawerDomain: IDataTableDrawerDomain<ServiceModelType>;
    public contextMenuDomain: IRowContextMenuDomain<ServiceModelType>;
    public detailDomain: IDataGridDetailDomain<ServiceModelType>;

    constructor(
        protected layoutDomain: IMainLayoutDomainStore,
        protected entityService: ICRUDService<ServiceModelType, ISearchRequestData>,
        ui: IDataTableUI<ServiceModelType, SearchRequestType> | null = null,
        modalDomain: IDataTableFormDomain<ServiceModelType> | null = null,
        drawerDomain: IDataTableDrawerDomain<ServiceModelType> | null = null,
        contextMenuDomain: IRowContextMenuDomain<ServiceModelType> | null = null,
        detailDomain: IDataGridDetailDomain<ServiceModelType> | null = null,
        public router: IRouterService = inject<IRouterService>(RouterServiceToken),
    ) {
        this.ui = ui || new DataTableUI<ServiceModelType, SearchRequestType>(this, null);
        this.modalDomain = modalDomain || new DataTableFormDomain(entityService, layoutDomain, this);
        this.drawerDomain = drawerDomain || new DataTableDrawerDomain(entityService, layoutDomain, this);
        this.contextMenuDomain = contextMenuDomain || new RowContextMenuDomain(entityService, this, layoutDomain);
        this.detailDomain = detailDomain || new DataGridDetailDomain();
    }

    requestSearch(searchValue: string): void {
        this.ui.rows.requestSearch(searchValue);
        this.ui.isLoading.setValue(false);
    }

    async boot() {
        this.ui.isLoading.setValue(true);
        await this.ui.renderTable();
        await this.loadData();
        this.requestSearch(this.ui.rows.searchTerm.value || '')
        await this.loadDetail();
        await this.setPermissions();
        let params = new URLSearchParams(document.location.search);
        const detailId = params.get('detailId');
        detailId && this.onRedirectFilterRows(detailId);
        this.setFilterModel();
        this.setVisibilityModel();
        this.setSortModel();
    }

    resetVisibilityAndFilterModel = () => {
        this.ui.visibilityModel.setValue({});
        this.ui.filterModel.setList([]);
        this.ui.rules?.setList([]);
        this.ui.isShowResetFilters.setValue(false);
        localStorage.removeItem(`visibility/${this.ui.domainID.value}`);
        localStorage.removeItem(this.ui.domainID.value);
        localStorage.removeItem(`sort/${this.ui.domainID.value}`);
        localStorage.removeItem(`filter/${this.ui.domainID.value}`);
    };
    setFilterModel = () => {
        if (localStorage.getItem(this.ui.domainID.value)) {
            // @ts-ignore
            this.ui.filterModel.setList(JSON.parse(localStorage.getItem(this.ui.domainID.value)));
            this.ui.isShowResetFilters.setValue(true);
        }
    };
    setVisibilityModel = () => {
        if (localStorage.getItem(`visibility/${this.ui.domainID.value}`)) {
            // @ts-ignore
            this.ui.visibilityModel.setValue(JSON.parse(localStorage.getItem(`visibility/${this.ui.domainID.value}`)));
            this.ui.isShowResetFilters.setValue(true);
            this.ui.isDefaultSortModel.setValue(false);
        }
    };
    setSortModel = () => {
        if (localStorage.getItem(`sort/${this.ui.domainID.value}`)) {
            // @ts-ignore
            this.ui.rules?.setList(JSON.parse(localStorage.getItem(`sort/${this.ui.domainID.value}`)));
            this.ui.isShowResetFilters.setValue(true);
        }
    };

    async loadData() {
        await this.loadList();
        await this.ui.renderTable();
        this.requestSearch(this.ui.rows.searchTerm.value || '')
        this.ui.isLoading.setValue(false);
    }

    async loadDetail() {
        this.detailDomain.ui.detailEntities.setList([]);
        this.detailDomain.ui.isDetail.setValue(false);
        this.detailDomain.ui.detailKey.setValue('');
    }

    async loadList(request?: SearchRequestType) {
        if (request) {
            this.ui.rows.searchRequest.setEntity(request);
        }
        try {
            this.ui.isLoading.setValue(true);
            let searchResult: any = null;
            if ((this.entityService as ICoreCRUDService<any>).isCoreService) {
                const request = {
                    ...this.ui.rows.searchRequest.entity,
                    filter: [
                        ...(this.ui.rows.searchRequest.entity?.filter as any[] || []),
                        // ...(this.ui.rows.searchRequestForAnyLoadData?.value?.filter || []),
                    ],
                } as ICoreSearchRequestData<any>;

                searchResult = await this.entityService.search(request as any);
            } else {
                searchResult = await (this.entityService).search({
                    ...this.ui.rows.searchRequest.entity,
                    filter: {
                        ...this.ui.rows.searchRequest.entity?.filter,
                        ...(this.ui.rows.searchRequestForAnyLoadData.value?.filter || {}),
                    },
                } as any);
            }
            const transformedRules = this.ui.rows.sortRulesToGridSortModel(searchResult.request.sort?.rules);
            !localStorage.getItem(`sort/${this.ui.domainID.value}`) && this.ui.rules?.setList(transformedRules || []);
            this.ui.rows.totalEntitiesCount.setValue(searchResult.totalCount);
            const data = searchResult.data.map((item) => this.transformServerToView(item));
            const sortEggsInNest = (a: number, b: number) => {
                return a > b ? 1 : b > a ? -1 : 0;
            };
            this.ui.rows.entities.setList(data);
            this.ui.tableOptions.rowsPerPageOptions.setList(
                [this.ui.rows.entities.list.length, 20, 100].sort(sortEggsInNest),
            );
            this.ui.rows.pageSize.setValue(this.ui.rows.entities.list.length);
            this.ui.rows.filteredEntities.setList(data);
            this.ui.rows.searchRequest.setEntity(searchResult.request);
            this.ui.isLoading.setValue(false);
            return true;
        } catch (error) {
            this.ui.isLoading.setValue(false);
            return this.errorsHandler(error as IError<any>);
        }
    }

    onHandleAdd = () => {
        if (this.ui.formMode.value === DataTableFormViewMode.drawer) {
            this.modalDomain.loadData(null);
            this.drawerDomain.onOpenDrawer();
        } else {
            this.modalDomain.loadData(null);
            this.modalDomain.onOpenModal();
        }
    };

    async removeValidationErrors() {
        this.ui.validationErrors.setList([]);
    }

    async removeValidationErrorFor(fieldName: string, id?: string) {
        const item = this.ui.validationErrors.list.find((validationError) => validationError.target === fieldName);
        if (item) {
            this.ui.validationErrors.removeByEntity(item);
        }
    }

    handleFilter = (entities: ServiceModelType[]) => {
        this.ui.rows.rowsCounter.setValue({ counter: entities.length, filteredRow: entities });
    };

    handleClearCheckBox = () => {
        this.ui.rows.setSelectionEntities([]);
        this.ui.rows.rowsCounter.setValue({ counter: 0, filteredRow: [] });
        this.ui.isShown.setValue(false);
    };

    transformServerToView(item: ServiceModelType): any {
        return item;
    }

    transformViewToServer(item: any): ServiceModelType {
        return item;
    }

    async setPermissions() {
        const isAdmin = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
        this.ui.isCanEdit.setValue(isAdmin || this.ui.isCanEdit.value);
        this.ui.isCanCreate.setValue(isAdmin || this.ui.isCanCreate.value);
        this.ui.isCanDelete.setValue(isAdmin || this.ui.isCanDelete.value);
    }

    errorsHandler = async (error: IError): Promise<boolean> => {
        if (error.webCode === '400') {
            this.addValidationErrors(error.data);
            this.layoutDomain.notifications.showNotification({
                type: LayoutNotificationType.error,
                text: 'Ошибка валидации',
            });
        } else {
            this.layoutDomain.notifications.showNotification({
                type: LayoutNotificationType.error,
                text: 'Неизвестная ошибка',
            });
        }

        return false;
    };

    addValidationErrors(errors: IValidationErrorData[]) {
        this.ui.validationErrors.setList(errors);
    }

    protected async callService(serviceHandler: Promise<any>, isUpdateList: boolean = true): Promise<boolean> {
        try {
            this.ui.isLoading.setValue(true);
            await serviceHandler;
            if (isUpdateList) {
                await this.loadList();
            }

            this.ui.isLoading.setValue(false);
            return true;
        } catch (error) {
            this.ui.isLoading.setValue(false);
            return this.errorsHandler(error as IError<any>);
        }
    }

    onRedirectFilterRows = (detailId) => {
        const firstElement = this.ui.rows.entities.list.find((row) => row.id === detailId);
        const reIndexRows = [
            firstElement,
            ...this.ui.rows.entities.list.filter((row) => {
                return row.id !== detailId;
            }),
        ];
        this.resetVisibilityAndFilterModel();
        this.ui.rules?.setList([]);
        // @ts-ignore
        this.ui.rows.entities.setList(reIndexRows);
        // @ts-ignore
        this.ui.rows.filteredEntities.setList(reIndexRows);
        // @ts-ignore
        this.ui.rows.selectionEntities.setList([firstElement]);
    };
    onLinkAction = async (id: string, url: string) => {
        this.ui.detailId.setValue(id);
        this.ui.detailURL.setValue(url);
        this.router.goTo(`${url}?detailId=${id}`);
    };
}
