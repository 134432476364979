import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Button, CssBaseline, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { inject } from '../../../../common/container/inject';
import { FormAutocomplete } from '../../../design/form/FormAutocomplete';
import { ISignInPageDomain, SignInPageDomainToken } from './store/ISignInPageDomain';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignInPage = observer(() => {
  const classes = useStyles();
  const [signInPageDomain] = useState<ISignInPageDomain>(inject(SignInPageDomainToken));

  useEffect(() => {
    signInPageDomain.boot();
  }, [signInPageDomain]);

  return (
    <div>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Войти в систему
        </Typography>
        <form
          onSubmit={(event: any) => {
            event.preventDefault();
            signInPageDomain.login();
          }}
          className={classes.form}
        >
          <TextField
            id="email"
            label="Логин"
            variant="outlined"
            color="secondary"
            autoComplete="email"
            margin="normal"
            value={signInPageDomain.ui.loginField.value}
            onChange={(event: any) => {
              if (signInPageDomain.ui.loginField.value !== event.target.value) {
                signInPageDomain.ui.loginField.setValue(event.target.value);
              }
            }}
            disabled={signInPageDomain.ui.isLockForm.value}
            required
            fullWidth
            autoFocus
            error={signInPageDomain.ui.loginErrorMessage.value.length > 0}
            helperText={signInPageDomain.ui.loginErrorMessage.value}
          />
          <TextField
            id="password"
            label="Пароль"
            variant="outlined"
            color="secondary"
            autoComplete="current-password"
            margin="normal"
            type="password"
            value={signInPageDomain.ui.passwordField.value}
            onChange={(event: any) => {
              if (signInPageDomain.ui.passwordField.value !== event.target.value) {
                signInPageDomain.ui.passwordField.setValue(event.target.value);
              }
            }}
            disabled={signInPageDomain.ui.isLockForm.value}
            required
            fullWidth
            autoFocus
            error={signInPageDomain.ui.passwordErrorMessage.value.length > 0}
            helperText={signInPageDomain.ui.passwordErrorMessage.value}
          />
          {signInPageDomain.ui.loginMethods.list.length > 1 && (
            <FormAutocomplete
              values={signInPageDomain.ui.loginMethods.list}
              onChangeSelect={(newValue: any) => {
                signInPageDomain.changeLoginMethod(newValue.value);
              }}
              key="loginMethods"
              selected={signInPageDomain.ui.loginMethodValue.value}
              label="Метод входа"
              size="medium"
              controlPadding={'16px 0px'}
            />
          )}

          <Button
            disabled={signInPageDomain.ui.isLockForm.value}
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Войти
          </Button>
        </form>
      </div>
    </div>
  );
});
