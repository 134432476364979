import { Theme } from '@emotion/react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { IUnitModel } from '../../../../../../service/unit/interface/IUnitModel';
import { LearningUnitPreviewPageContext } from '../../store/LearningUnitPreviewPageContext';

const TITLES_MAP = {
  theory: 'К теории',
  quiz: 'К квизу',
  practice: 'К практике',
  finalResults: 'К результатам',
};

export const LearningUnitPreviewFooter = observer(() => {
  const classes = useStyles();
  const { unitPreviewDomain } = useContext(LearningUnitPreviewPageContext);
  const unit = unitPreviewDomain?.ui?.unit.entity as IUnitModel;
  const currentStep = unitPreviewDomain?.ui?.currentStep?.value as string;
  const activeSteps = unitPreviewDomain?.ui?.activeSteps?.list as string[];
  const setCurrentStep = unitPreviewDomain?.ui?.currentStep.setValue;
  const setCurrentSubstep = unitPreviewDomain?.ui?.currentSubstep.setValue;
  const currentStepIndex = activeSteps?.findIndex((item) => item === currentStep);

  const onClick = (value: 'next' | 'back') => {
    const nextStep = activeSteps[value === 'next' ? currentStepIndex + 1 : currentStepIndex - 1];
    setCurrentStep?.(nextStep);
    setCurrentSubstep?.(nextStep === 'theory' ? unit?.theory?.chapters[0]?.title : 0);
  };

  return (
    <footer className={classes.footer}>
      {currentStepIndex !== 0 && currentStepIndex !== -1 ? (
        <Button
          title={activeSteps[currentStepIndex - 1]}
          startIcon={<ArrowBack />}
          onClick={() => onClick('back')}
          variant="contained"
          color="secondary"
        >
          {TITLES_MAP[activeSteps[currentStepIndex - 1]]}
        </Button>
      ) : (
        <div></div>
      )}
      {currentStepIndex !== activeSteps?.length - 1 && currentStepIndex !== -1 ? (
        <Button
          title={activeSteps[currentStepIndex + 1]}
          endIcon={<ArrowForward />}
          variant="contained"
          color="secondary"
          onClick={() => onClick('next')}
        >
          {TITLES_MAP[activeSteps[currentStepIndex + 1]]}
        </Button>
      ) : (
        <div></div>
      )}
    </footer>
  );
});

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    background: '#263238',
    width: '100%',
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    padding: '12px',
    justifyContent: 'space-between',
  },
}));
