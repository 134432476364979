import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { ITagCategoryModel } from '../../../../../../service/tag/category/ITagCategoryModel';
import { ITagCategorySearch } from '../../../../../../service/tag/category/ITagCategorySearch';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';

export class TagCategoryAdminUI
  extends DataTableUI<ITagCategoryModel, ITagCategorySearch>
  implements IDataTableUI<ITagCategoryModel, ITagCategorySearch>
{
  setDomainId(): string {
    return 'settings/tag/category';
  }
  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'description',
        headerName: 'Описание',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'orderIndex',
        headerName: 'Порядок отображения',
        flex: 0.5,
        type: 'number',
        headerAlign: 'left',
        align: 'center',
        renderCell: (params: { value?: string | number | boolean | object | Date | null | undefined }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value} />;
        },
      },
      {
        field: 'isActive',
        headerName: 'Статус',
        flex: 0.3,
        type: 'boolean',
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
