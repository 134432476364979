import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { Button, CircularProgress, Drawer, Grid, IconButton, Theme } from '@mui/material';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';
import { RequirementEntityDrawerDomain } from './RequirementEntityDrawerDomain';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RightArrowsIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { makeStyles } from '@mui/styles';
import { RequirementInfo } from './parts/InfoModeComponent';
import { RequirementEdit } from './parts/EditModeComponent';
import { FormActions } from './parts/Actions/EditModeActions';
import { RequirementFormExtensionsInject } from '../modal/inject/RequirementFormExtensionsInject';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';

export interface IRequirementEntityTableProperties {
  domain: RequirementEntityDrawerDomain;
}

export const RequirementEntityDrawer = observer(({ domain }: IRequirementEntityTableProperties) => {
  const classes = useStyles();

  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity?.id ? `Редактировать ` : `Добавить `);
    domain.loadData(domain.ui.model.entity?.id || null);
  }, [domain]);
  const container = useContext(ContainerContext);
  const isCanUpdate = domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-requirements-update']]);
  const isCanDelete = domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-requirements-delete']]);

  const requirementFormExtensionComponents = container.get(RequirementFormExtensionsInject);
  return (
    <Drawer
      PaperProps={{ sx: { width: '50%' } }}
      ModalProps={{ onBackdropClick: () => domain.onCancelUpdates }}
      open={domain.layoutDomain.drawer.ui.activeDrawer.isOpen}
      variant='persistent'
      anchor='right'
      onClose={domain.onCancelUpdates}
    >
      {domain.isSaving.entity &&
        <div className={classes.loader}>
          <CircularProgress color="secondary" />
        </div>
      }
      <Grid sx={{ overflowY: 'scroll', maxHeight: '100%' }}>
        <Grid sx={{ display: 'flex' }}>
          <Grid item className={classes.leftButton} xs={1}>
            <IconButton
              onClick={domain.onCancelUpdates}
            >
              <RightArrowsIcon />
            </IconButton>
          </Grid>
          <Grid item className={classes.containerRight} xs={11}>
            {!domain.ui.isEditMode.value && <>
              {isCanUpdate &&
                <Button
                  variant='contained'
                  startIcon={<EditIcon />}
                  color='secondary'
                  className={classes.actionButton}
                  onClick={() => {
                    domain.ui.isEditMode.setValue(!domain.ui.isEditMode.value);
                  }}
                >
                  Редактировать
                </Button>
              }
              {isCanDelete &&
                <Button
                  variant='contained'
                  startIcon={<DeleteIcon />}
                  color='secondary'
                  className={classes.actionButton}
                  onClick={() => {
                    domain.deleteEntity();
                  }}
                >
                  Удалить
                </Button>
              }
            </>}
          </Grid>
        </Grid>
        {!domain.ui.isEditMode.value &&
          <RequirementInfo
            domain={domain}
            requirementFormExtensionComponents={requirementFormExtensionComponents}
          />}
        {domain.ui.isEditMode.value &&
          <RequirementEdit
            domain={domain}
            requirementFormExtensionComponents={requirementFormExtensionComponents}
          />
        }
      </Grid>
      {domain.ui.isEditMode.value && <RequirementFormActions onSave={async () => {
        const columns = await domain.getAdditionalColumnContentsToUpdateByAction();
        const vendors = await domain.getVendorsToUpdateByAction();
        // @ts-ignore
        await domain.onSaveUpdates({ additionalColumns: columns, vendors: vendors });
        await domain.dataTableDomain.ui.renderTable()
      }} onCancel={() => {
        domain.ui.model.entity?.id ? domain.ui.isEditMode.setValue(false) : domain.onCloseDrawer()
      }} domain={domain} />}
    </Drawer>
  );
});

export const RequirementFormActions = ({ onSave, onCancel, domain }: { onSave: any; onCancel: any, domain: RequirementEntityDrawerDomain }) => {
  return (
    <FormActions
      onSave={() => {
        onSave();
      }}
      onCancel={() => onCancel()}
      domain={domain}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  containerRight: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  leftButton: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '69px'
  },
  actionButton: {
    margin: theme.spacing(2),
  },
  loader: {
    width: '100%',
    height: '100%',
    background: 'black',
    position: 'absolute',
    zIndex: 1201,
    opacity: 0.2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
