import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react';
// @ts-ignore
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';
import { FileType } from '../../../service/fileStore/IFileStoreService';
import { IFileUploadDomain } from './store/IFileUploadDomain';

export interface IFileUpload extends ICommonProperties {
  uploadDomain: IFileUploadDomain;
  onChange: ({ url, fileName }: { url: string; fileName: string }) => any;
  sx?: any;
}
export const UploadFileColumn = observer(({ uploadDomain, onChange, sx = {} }: IFileUpload) => {
  const onFileUploadChange = (url: string, fileName: string = '') => {
    onChange({ url, fileName });
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onFileDialogOpen: () => {
      uploadDomain.ui.error.setValue({ code: '', message: '' });
    },
    onDrop: async (files) => {
      uploadDomain.ui.error.setValue({ code: '', message: '' });
      const fileUrl = await uploadDomain.uploadToFileStore(files[0], FileType.DOC);
      uploadDomain.ui.currentFile.setEntity({ fileUrl: fileUrl, fileName: files[0].name });

      onFileUploadChange(fileUrl, files[0].name);
    },
    accept: { [uploadDomain.ui.key.value]: uploadDomain.ui.accept.list },
    maxFiles: uploadDomain.ui.maxFiles.value,
    maxSize: uploadDomain.ui.maxSize.value,
  });
  useEffect(() => {
    const files = acceptedFiles.map((file) => {
      return file;
    });
    if (fileRejections.length !== 0) {
      const errors = fileRejections.map((file: { errors: any[] }) => {
        const errors = file.errors.map((error) => error);
        return errors[0];
      });
      uploadDomain.ui.error.setValue(errors[0]);
    }
    uploadDomain.ui.files.setList(files);
  }, [acceptedFiles, fileRejections, uploadDomain]);
  return (
    <React.Fragment>
      <section className="container" style={{ marginTop: '10px' }}>
        <input {...getInputProps()} />
        {uploadDomain.ui.files.list.length === 0 && (
          <Box
            sx={{
              padding: '16px 12px',
              borderRadius: '8px',
              border: `2px dashed ${uploadDomain.ui.error.value.code === '' ? 'rgba(0, 0, 0, 0.12)' : '#AD7C6E'} `,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              background: 'rgba(0, 0, 0, 0.04)',
              width: '30%',
              ...sx,
            }}
          >
            <Typography sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <div
                style={{
                  color: '#1976D2',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onChange={(event) => onFileUploadChange(event as any)}
                {...getRootProps({ className: 'dropzone' })}
              >
                выберите файл
              </div>
              <div>
                {`${uploadDomain.uppercaseFormater(uploadDomain.ui.accept.list)} не более ${Math.ceil(
                  uploadDomain.ui.maxSize.value / 1024 / 1000,
                )} MB`}
              </div>
            </Typography>
          </Box>
        )}

        {uploadDomain.ui.error.value.code === 'file-too-large' && (
          <Typography color="error" sx={{ fontSize: '12px', padding: '8px' }}>
            {'Файл превышает допустимый лимит'}
          </Typography>
        )}
        {uploadDomain.ui.error.value.code === 'file-invalid-type' && (
          <Typography color="error" sx={{ fontSize: '12px', padding: '8px' }}>
            {'Недопустимый формат файла'}
          </Typography>
        )}
        {uploadDomain.ui.files.list.length > 0 && !uploadDomain.ui.currentFile.entity.fileName && (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '30%', ...sx }}>
            <CircularProgress />
          </Box>
        )}
      </section>
    </React.Fragment>
  );
});
