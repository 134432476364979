import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITable } from '../../../../../../service/unit/interface/theoryBlocks/ITable';

export interface ITableProperties {
  data: ITable;
  isChild?: boolean;
}
export const TableComponent = observer(({ data, isChild }: ITableProperties) => {
  return (
    <Box sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
      <TableContainer component={Paper} sx={{ border: '1px solid rgba(81, 81, 81, 1)' }}>
        <Table>
          <TableHead>
            <TableRow>
              {data.tableData.headerText.map((value) => {
                return <TableCell sx={{ fontSize: '20px', lineHeight: 1.6, fontWeight: 700 }}>{value}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.tableData.rows.map((row) => {
              return (
                <TableRow>
                  {row.map((value) => {
                    return (
                      <TableCell sx={{ borderBottom: 'none', fontSize: '20px', lineHeight: 1.6 }}>{value}</TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});
