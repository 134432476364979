import React from "react";
import { Tab, Tabs } from "@mui/material";
import { observer } from "mobx-react";
import { AnalyticsDomain } from "../store/AnalyticsDomain";

export const AnalyticsPageTabs = observer(({ domain }: { domain: AnalyticsDomain }) => {

  return (
    <>
      <Tabs
        value={domain.ui.analyticsPageTabs.value}
        onChange={(e, value) => {
          domain.ui.analyticsPageTabs.setValue(value)
        }}
        indicatorColor="secondary"
        textColor="secondary"
        style={{ width: '100%' }}
      >
        <Tab key={'requirementsAnalytics'} label={'Системы'} value={'requirementsAnalytics'} />
        {/* <Tab key={'learningAnalytics'} label={'Обучение'} value={'learningAnalytics'} /> */}
        {/* Включить для вкладки обучение */}
      </Tabs>
    </>
  )
})


