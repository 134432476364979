import { BlockType, ITheoryBlock } from '../../../service/unit/interface/IUnitTheory';

export interface IComponentList {
  type: string;
  list: ITheoryBlock<any>[];
}
export const SettingList: IComponentList[] = [
  {
    type: 'Текст',
    list: [
      {
        block: BlockType.HEADING_1,
        data: {
          text: '',
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Заголовок 1',
      },
      {
        block: BlockType.HEADING_2,
        data: {
          text: '',
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Заголовок 2',
      },
      {
        block: BlockType.HEADING_3,
        data: {
          text: '',
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Заголовок 3',
      },
      {
        block: BlockType.PARAGRAPH,
        data: {
          text: '',
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Абзац',
      },
      {
        block: BlockType.LIST,
        data: {
          type: 'Numbered',
          elementCount: 0,
          listData: [],
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Список',
      },
      {
        block: BlockType.TABLE,
        data: {
          columnsCount: 0,
          rowsCount: 0,
          tableData: {
            headerText: [],
            rows: [],
          },
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Таблица',
      },
    ],
  },
  {
    type: 'Медиа',
    list: [
      {
        block: BlockType.IMAGE,
        data: {
          name: '',
          originalName: '',
          data: '',
          indexName: '',
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Изображение',
      },
      {
        block: BlockType.VIDEO,
        data: {
          name: '',
          originalName: '',
          data: '',
          indexName: '',
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Видео',
      },
      {
        block: BlockType.CODE_EXAMPLE,
        data: {
          language: '',
          example: {
            language: '',
            content: '',
          },
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Пример кода',
      },
    ],
  },
  {
    type: 'Интервал',
    list: [
      {
        block: BlockType.SPACING_48,
        sortIndex: 0,
        data: '',
        pseudoId: '',
        parentId: '',
        name: 'Интервал 48px',
      },
      {
        block: BlockType.SPACING_64,
        sortIndex: 0,
        data: '',
        pseudoId: '',
        parentId: '',
        name: 'Интервал 64px',
      },
    ],
  },

  {
    type: 'Вложенность',
    list: [
      {
        block: BlockType.HIDDEN_BLOCK,
        data: {
          name: '',
          subBlocks: [],
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Скрытый блок',
      },
      {
        block: BlockType.TABS,
        data: {
          tabsCount: 0,
          tabsData: {
            title: '',
            contentBlocks: [],
            sortIndex: 0,
            pseudoId: '',
          },
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Вкладки',
      },
      {
        block: BlockType.TAGS,
        data: {
          tagsCount: 0,
          tagsData: {
            title: '',
            contentBlocks: [],
            sortIndex: 0,
            pseudoId: '',
          },
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Теги',
      },

      {
        block: BlockType.SPECIAL_BLOCK,
        data: {
          name: '',
          color: '',
          contentBlocks: [],
        },
        sortIndex: 0,
        pseudoId: '',
        parentId: '',
        name: 'Специальный блок',
      },
    ],
  },
  // {
  //   type: 'Additional',
  //   list: [
  //     {
  //       block: BlockType.HTML,
  //       data: { htmlData: '' },
  //       sortIndex: 0,
  //       pseudoId: '',
  //       parentId: '',
  //     },
  //   ],
  // },
];
