import { Close } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { ICommonProperties } from '../../../../../common/properties/ICommonProperties';
import { FileType } from '../../../../../service/fileStore/IFileStoreService';
import { IFileUploadDomain } from './store/IFileUploadDomain';

export interface IFileUpload extends ICommonProperties {
  uploadDomain: IFileUploadDomain;
  onChange: any;
  fileType: FileType;
}
export const FileUpload = observer(({ uploadDomain, onChange, fileType }: IFileUpload) => {
  const onFileUploadChange = (files: any) => {
    onChange(files);
  };

  const classes = useStyles();
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onFileDialogOpen: () => {
      uploadDomain.ui.error.setValue({ code: '', message: '' });
    },
    onDrop: async (files) => {
      uploadDomain.ui.error.setValue({ code: '', message: '' });
      const imageUrl = await uploadDomain.uploadToFileStore(files[0], fileType);

      onFileUploadChange(imageUrl);
    },
    accept: { [uploadDomain.ui.key.value]: uploadDomain.ui.accept.list },
    maxFiles: uploadDomain.ui.maxFiles.value,
    maxSize: uploadDomain.ui.maxSize.value,
  });
  useEffect(() => {
    const files = acceptedFiles.map((file) => {
      return file;
    });
    if (fileRejections.length !== 0) {
      const errors = fileRejections.map((file: { errors: any[] }) => {
        const errors = file.errors.map((error) => error);
        return errors[0];
      });
      uploadDomain.ui.error.setValue(errors[0]);
    }
    uploadDomain.ui.files.setList(files);
  }, [acceptedFiles, fileRejections, uploadDomain]);
  return (
    <React.Fragment>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} onChange={onFileUploadChange} />
          <Box
            sx={{
              padding: '16px 12px',
              borderRadius: '8px',
              border: `2px dashed ${uploadDomain.ui.error.value.code === '' ? 'rgba(0, 0, 0, 0.12)' : '#AD7C6E'} `,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              background: 'rgba(0, 0, 0, 0.04)',
            }}
          >
            <Typography>
              Перетащите или <label className={classes.blueSpan}>выберите файл</label>
            </Typography>
            <Typography>{`${uploadDomain.uppercaseFormater(uploadDomain.ui.accept.list)}, не более ${Math.ceil(
              uploadDomain.ui.maxSize.value / 1024 / 1000,
            )} MB`}</Typography>
          </Box>
          {uploadDomain.ui.error.value.code === 'file-too-large' && (
            <Typography color="error" sx={{ fontSize: '12px', padding: '8px' }}>
              {'Файл превышает допустимый лимит'}
            </Typography>
          )}
          {uploadDomain.ui.error.value.code === 'file-invalid-type' && (
            <Typography color="error" sx={{ fontSize: '12px', padding: '8px' }}>
              {'Недопустимый формат файла'}
            </Typography>
          )}
        </div>
        {uploadDomain.ui.files.list.map((file) => (
          <Box
            sx={{
              padding: '16px 12px',
              background: 'rgba(25, 118, 210, 0.08)',
              borderRadius: '4px',
              margin: '12px 0px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{file.name}</Typography>
              <Close onClick={() => uploadDomain.deleteFile()} sx={{ cursor: 'pointer' }} />
            </Box>
            <Typography sx={{ fontSize: '12px', color: 'rgba(255,255,255,0.6)' }}>
              {file.type.toUpperCase()}, размер {Math.ceil(file.size / 1024 / 1000)} MB
            </Typography>
          </Box>
        ))}
      </section>
    </React.Fragment>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  blueSpan: {
    color: '#1976D2',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
