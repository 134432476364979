import { Search } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { GroupCardComponentHeader } from './parts/GroupCardComponent/GroupCardComponentHeader';
import { GroupCardComponentList } from './parts/GroupCardComponent/GroupCardComponentList';
import { SystemsList } from './parts/SystemsList';
import { UserInfoCard } from './parts/UserInfoCard';
import { IPassingListPageDomain } from './store/IPassingListPageDomain';
import { PassingListPageDomain } from './store/PassingListPageDomain';

enum FilterValues {
  All = 'all',
  Important = 'important',
  Unimportant = 'unimportant',
}

export interface IPassingListPage {
  layoutDomain: IMainLayoutDomainStore;
}
export const PassingListPage = observer(({ layoutDomain }: IPassingListPage) => {
  const [domain] = useState<IPassingListPageDomain>(new PassingListPageDomain(layoutDomain));
  const [filter, setFilter] = useState<FilterValues>(FilterValues.All);

  useEffect(() => {
    domain.loadData();
  }, [domain]);
  return (
    <>
      {domain.ui.isLoading.value ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <UserInfoCard
            fio={layoutDomain.ui.activeUser.entity.displayName}
            role={layoutDomain.ui.activeUser.entity.systemRoleName}
            userData={domain.ui.userData.value}
          />
          <SystemsList systems={domain.ui.applicationsWithUnits.list} />
          <Typography variant="h4" sx={{ marginTop: '64px', marginBottom: '64px' }}>
            Карта компетенций
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', marginTop: '48px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px', alignItems: 'flex-end' }}>
              <TextField
                onChange={(event) => {
                  domain.handleSearchByGroupsAndName(event.target.value);
                }}
                variant="standard"
                sx={{ flex: '1' }}
                label="Название контекста или юнита"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <Search />
                    </IconButton>
                  ),
                }}
              />
              <FormControl variant="standard" sx={{ flex: '1' }}>
                <InputLabel>Показывать</InputLabel>
                <Select
                  value={filter}
                  onChange={(event) => {
                    setFilter(event.target.value as FilterValues);
                  }}
                >
                  <MenuItem value={FilterValues.All}>Все</MenuItem>
                  <MenuItem value={FilterValues.Important}>Обязательные</MenuItem>
                  <MenuItem value={FilterValues.Unimportant}>Дополнительные</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3,1fr)',
                columnGap: '24px',
                rowGap: '32px',
                paddingBottom: '64px',
              }}
            >
              {domain.ui.filtredGroupedUnits.list.map((group) => {
                if (
                  (group.importantUnitList.length === 0 && group.unimportantUnitList.length === 0) ||
                  (filter === FilterValues.Important && group.importantUnitList.length === 0) ||
                  (filter === FilterValues.Unimportant && group.unimportantUnitList.length === 0)
                ) {
                  return null;
                }
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '16px',
                      gap: '16px',
                      borderRadius: '16px',
                      background: '#2B363C',
                      height: 'fit-content',
                    }}
                  >
                    <GroupCardComponentHeader groupName={group.groupName} passingPercent={group.passingPercent} />
                    {filter === FilterValues.All ? (
                      <>
                        <GroupCardComponentList
                          title={'Обязательные'}
                          unitList={group.importantUnitList}
                          domain={domain}
                        />
                        <GroupCardComponentList
                          title={'Дополнительные'}
                          unitList={group.unimportantUnitList}
                          domain={domain}
                        />
                      </>
                    ) : filter === FilterValues.Important ? (
                      <GroupCardComponentList
                        title={'Обязательные'}
                        unitList={group.importantUnitList}
                        domain={domain}
                      />
                    ) : (
                      <GroupCardComponentList
                        title={'Дополнительные'}
                        unitList={group.unimportantUnitList}
                        domain={domain}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
});
