import { ConfigManager } from '../../../../../../../application/config/ConfigManager';
import { IConfig } from '../../../../../../../application/config/IConfig';
import { inject } from '../../../../../../../common/container/inject';
import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../service/LearningRootService';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { IPassingFinalResultsDomain } from '../parts/finalResults/store/IPassingFinalResultsDomain';
import { PassingFinalResultsDomain } from '../parts/finalResults/store/PassingFinalResultsDomain';
import { IPassingPracticeDomain } from '../parts/practice/store/IPassingPracticeDomain';
import { PassingPracticeDomain } from '../parts/practice/store/PassingPracticeDomain';
import { IPassingQuizDomain } from '../parts/quiz/store/IPassingQuizDomain';
import { PassingQuizDomain } from '../parts/quiz/store/PassingQuizDomain';
import { IPassingTheoryDomain } from '../parts/theory/store/IPassingTheoryDomain';
import { PassingTheoryDomain } from '../parts/theory/store/PassingTheoryDomain';
import { IPassingDomain } from './IPassingDomain';
import { PassingUi } from './PassingUi';

export class PassingDomain implements IPassingDomain {
  public theoryDomain: IPassingTheoryDomain;
  public quizDomain: IPassingQuizDomain;
  public practiceDomain: IPassingPracticeDomain;
  public finalResultsDomain: IPassingFinalResultsDomain;

  constructor(
    theoryDomain: IPassingTheoryDomain,
    quizDomain: IPassingQuizDomain,
    practiceDomain: IPassingPracticeDomain,
    finalResultsDomain: IPassingFinalResultsDomain,

    public layoutDomain: IMainLayoutDomainStore,
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    public ui = new PassingUi(),
    public config: IConfig = ConfigManager.getConfig(),
  ) {
    this.theoryDomain = theoryDomain || new PassingTheoryDomain();
    this.quizDomain = quizDomain || new PassingQuizDomain(this.layoutDomain);
    this.practiceDomain = practiceDomain || new PassingPracticeDomain();
    this.finalResultsDomain = finalResultsDomain || new PassingFinalResultsDomain();
  }
  loadData = async (unitId: string) => {
    this.ui.unitId.setValue(unitId);
    const data = await this.learningRootService.unitResult.search({
      filter: [
        {
          fieldName: 'unitId',
          type: 'equal',
          value: unitId,
        },
        {
          fieldName: 'userId',
          type: 'equal',
          value: this.layoutDomain.ui.activeUser.entity.id,
        },
      ],
    });
    if (data.data.length !== 0) {
      await this.learningRootService.unitResult.updateByModel({
        ...data.data[0],
        quizResult: { questions: data.data[0].quizResult.questions, totalQuizResult: data.data[0].quizResult.totalQuizResult, lastPassingDate: data.data[0].quizResult.lastPassingDate },
        theoryResult: { totalTheoryResult: 0 },
        practiceResult: { tasks: data.data[0].practiceResult.tasks, totalPracticeResult: data.data[0].practiceResult.totalPracticeResult },
      });
      const unitResultModel = await this.learningRootService.unitResult.search({
        filter: [
          {
            fieldName: 'unitId',
            type: 'equal',
            value: unitId,
          },
          {
            fieldName: 'userId',
            type: 'equal',
            value: this.layoutDomain.ui.activeUser.entity.id,
          },
        ],
      });
      this.theoryDomain.ui.unitResultModel.setEntity(unitResultModel.data[0]);
    }
    if (data.data.length === 0) {
      await this.learningRootService.unitResult.createByModel({
        quizResult: { questions: [], totalQuizResult: 0, lastPassingDate: null },
        theoryResult: { totalTheoryResult: 0 },
        practiceResult: { tasks: [], totalPracticeResult: 0 },
        totalUnitResult: 0,
        unitId: unitId,
        userId: this.layoutDomain.ui.activeUser.entity.id,
        isPassed: null,
      });
      const unitResultModel = await this.learningRootService.unitResult.search({
        filter: [
          {
            fieldName: 'unitId',
            type: 'equal',
            value: unitId,
          },
          {
            fieldName: 'userId',
            type: 'equal',
            value: this.layoutDomain.ui.activeUser.entity.id,
          },
        ],
      });
      this.theoryDomain.ui.unitResultModel.setEntity(unitResultModel.data[0]);
    }

    const modelById = await this.learningRootService.unit.getById(unitId);

    this.ui.title.setValue(modelById.settings.titleHeading);
    this.quizDomain.ui.layoutDomain.setEntity(this.layoutDomain);
    this.quizDomain.ui.passingId.setValue(unitId);
    this.theoryDomain.ui.passingTheoryList.setList(modelById.theory.chapters);
    this.theoryDomain.ui.isEnebled.setValue(modelById.theory.isEnabled);
    this.quizDomain.ui.passingQuestionList.setList(modelById.quiz.questions);
    this.quizDomain.ui.passageTime.setEntity(modelById.quiz.passageTime);
    this.quizDomain.ui.results.setEntity(modelById.quiz.results);
    this.quizDomain.ui.isEnebled.setValue(modelById.quiz.isEnabled);
    this.practiceDomain.ui.passingTaskList.setList(modelById.practice.tasks);
    this.practiceDomain.ui.passageTime.setEntity(modelById.practice.passageTime);
    this.practiceDomain.ui.results.setEntity(modelById.practice.results);
    this.practiceDomain.ui.isEnebled.setValue(modelById.practice.isEnabled);
    this.practiceDomain.redirect = this.passingPageChange;
    this.practiceDomain.ui.layoutDomain.setEntity(this.layoutDomain);
    this.practiceDomain.ui.passingId.setValue(unitId);
    this.practiceDomain.redirectInNavigation = this.passingPageChange;

    this.finalResultsDomain.ui.isEnebled.setValue(modelById.finalResult.isEnabled);
    this.finalResultsDomain.redirect = this.passingPageChange;
    this.finalResultsDomain.ui.layoutDomain.setEntity(this.layoutDomain);
    this.finalResultsDomain.ui.passingId.setValue(unitId);
    this.finalResultsDomain.redirectInNavigation = this.passingPageChange;
    this.finalResultsDomain.ui.results.setEntity(modelById.finalResult?.results);
    
    this.theoryDomain.redirectInNavigation = this.passingPageChange;
    this.quizDomain.redirectInNavigation = this.passingPageChange;

    if (!modelById.practice.isEnabled && !modelById.quiz.isEnabled) {
      this.theoryDomain.redirect = this.onRedirectTo;
      this.theoryDomain.ui.redirectTo?.setValue('Завершить Юнит');
    } else if (modelById.practice.isEnabled && !modelById.quiz.isEnabled) {
      this.theoryDomain.redirect = this.changePageToPractice;
      this.theoryDomain.ui.redirectTo?.setValue('Перейти к практике');
    } else {
      this.theoryDomain.redirect = this.passingPageChange;
    }
    if (!modelById.practice.isEnabled) {
      this.quizDomain.redirect = this.onRedirectTo;
      this.quizDomain.ui.redirectTo?.setValue('Завершить Юнит');
    } else {
      this.quizDomain.redirect = this.passingPageChange;
    }

    this.setCurrentModuleUnit(modelById)
  };

  passingPageChange = (page: string) => {
    this.ui.passingPage.setValue(page);
  };
  changePageToPractice = () => {
    this.ui.passingPage.setValue('practice');
  };

  setCurrentModuleUnit = (modelById:IUnitModel) => {
    if(!modelById.theory.isEnabled) {
      this.ui.passingPage.setValue('quiz')
    } 
   if (!modelById.theory.isEnabled && !modelById.quiz.isEnabled){
      this.ui.passingPage.setValue('practice')
    }
  }

  finishPassing = async () => {
    const currentUnitResult = await this.learningRootService.unitResult.search({
      filter: [
        { fieldName: 'userId', type: 'equal', value: this.layoutDomain.ui.activeUser.entity.id },
        { fieldName: 'unitId', type: 'equal', value: this.ui.unitId.value },
      ],
    });
    const currentUnit = await this.learningRootService.unit.search({
      limit: 100000,
      filter: [{ fieldName: 'id', type: 'equal', value: this.ui.unitId.value }],
    });
    
    const totalUnitResult =
      currentUnitResult.data[0].theoryResult.totalTheoryResult +
      currentUnitResult.data[0].quizResult.totalQuizResult +
      currentUnitResult.data[0].practiceResult.totalPracticeResult;

    await this.learningRootService.unitResult.updateByModel({
      ...currentUnitResult.data[0],
      totalUnitResult,
      isPassed: totalUnitResult / currentUnit?.data[0]?.score >= 0.7,
    });
  };

  onRedirectTo = async () => {
    await this.finishPassing();
    this.ui.passingPage.setValue('finalResults');
  };
}
