import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IApplicationTemplateModel } from './IApplicationTemplateModel';
import { IApplicationTemplateSearch } from './IApplicationTemplateSearch';
import { IApplicationTemplateService } from './IApplicationTemplateService';

export class RestApplicationTemplateService
  extends RestCRUDService<IApplicationTemplateModel, IApplicationTemplateSearch>
  implements IApplicationTemplateService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/application/template`;
  }
}
