import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITask, ITaskLanguage } from '../../../../../../service/unit/interface/IUnitPractice';
import { FormMultiAutocompleteStandard } from '../../../../../design/form/FormMultiAutocompleteStandard';
import { FormTextFieldStandard } from '../../../../../design/form/FormTextFieldStandard';
import { languagesList } from './store/Languages';

export interface IPracticeTask {
  task: ITask;
  addLanguages: any;
  pickedLang: ITaskLanguage;
  pickLang: any;
}

export const PracticeTask = observer(({ task, addLanguages, pickedLang, pickLang }: IPracticeTask) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '32px 24px',
        height: '100%',
        overflowY: 'auto',
        flex: 1,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '33px', marginBottom: '64px' }}>
        <Typography variant="h6">{`Задача ${task.sortIndex}`}</Typography>
        <FormTextFieldStandard
          label={'Вопрос'}
          value={task.name}
          onChange={(newValue) => {
            task.name = newValue;
          }}
        />
        <FormTextFieldStandard
          label={'Пояснение'}
          value={task.explanation}
          multiline={true}
          rows={4}
          onChange={(newValue) => {
            task.explanation = newValue;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '64px' }}>
        <Typography>Тип</Typography>
        <FormControl>
          <RadioGroup value={task.type}>
            <FormControlLabel value="review" control={<Radio />} label="Проверка кода" />
            {/* <FormControlLabel value="writing" control={<Radio />} label="Write the code" /> */}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography>Проект</Typography>
        <FormMultiAutocompleteStandard
          selected={task.languages.map((language: { name: string }) => language.name)}
          values={[...languagesList]}
          label={'Языки'}
          onChangeSelect={(newValue) => {
            for (let i = 0; i < task.languages.length; i++) {
              const language = task.languages[i].name;
              // @ts-ignore
              if (!newValue.find((lang: { value: string }) => lang.value === language)) {
                task.languages.splice(i, 1);
              }
            }
            for (let i = 0; i < newValue.length; i++) {
              const language = newValue[i].value;
              if (!task.languages.find((lang: { name: string }) => lang.name === language)) {
                const newLang = { name: language, files: [], options: [] };
                task.languages.push(newLang);
              }
            }
            addLanguages(task.pseudoId, task.languages);
          }}
        />
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {task.languages.map((language: { name: string }) => {
            return (
              <Box
                onClick={() => {
                  pickLang(language);
                }}
                sx={{
                  padding: '8px',
                  cursor: 'pointer',
                  borderRadius: '16px',
                  background:
                    pickedLang !== null && pickedLang.name === language.name ? '#1976D2' : 'rgba(255, 255, 255, 0.16)',
                }}
              >
                {language.name}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
});
