import { getServerNowDate } from './../../../../../../../../service/date/since';
import { ActiveDirectoryServerConnectionState } from '../../../../../../../../service/activeDirectory/server/IActiveDirectoryServerEntityModel';
import { DataTableFormUI } from '../../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { ActiveDirectoryServerAdminForm } from './ActiveDirectoryServerAdminForm';
import { ActiveDirectoryServerAdminModel } from './ActiveDirectoryServerAdminModel';
import { injectPrimitive } from '../../../../../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../../../../../common/store/interface/IPrimitiveStore';

export interface IActiveDirectoryModalUI<ModelType> extends IDataTableFormUI<ModelType> {
  isLoading: IPrimitiveStore<boolean>
}

export class ActiveDirectoryModalUI
  extends DataTableFormUI<ActiveDirectoryServerAdminModel>
  implements IActiveDirectoryModalUI<ActiveDirectoryServerAdminModel>
{  
  constructor(
    public isLoading = injectPrimitive<boolean>(false)
  ) {
    super();
    this.formComponent.setValue(ActiveDirectoryServerAdminForm);
  }

  getDefaultModel() {
    return {
      systemRoleToAdGroup:{ mapping: [] },
      url: '',
      baseDN: '',
      username:'',
      password:'',
      parsedDC: undefined,
      name:'',
      isMainLoginMethod:false,
      isBackgroundUserSync:true,
      isConnected:ActiveDirectoryServerConnectionState.notConnected,
      lastSyncDate: getServerNowDate(),
      id: undefined,
      createDate:undefined,
      lastUpdateDate:undefined,
      isDeleted: undefined,
      isActive: true,
    };
  }
}
