import { EditComponentProps } from '@material-table/core';
import React from 'react';

import { FormAutocomplete } from '../../form/FormAutocomplete';

export const makeSingleAutoCompleteForTable = (label: string, data: any) => (props: EditComponentProps<any>) => {
  return (
    <FormAutocomplete
      selected={props.value}
      label={label}
      onChangeSelect={function (newValue: any) {
        props.onChange(newValue?.value || null);
      }}
      values={data}
      key={label}
    />
  );
};
