import { Box, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitDomain } from '../../../store/IUnitDomain';

interface IPassageTimeAwards {
  unitDomain: IUnitDomain;
}

export const PassageTimeAward = observer(({ unitDomain }: IPassageTimeAwards) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">Время прохождения</Typography>
        <Typography variant="body1">
          Укажите примерное количество времени, которое сотрудник потратит на прохождение теста. Это помогает
          спланировать обучение.
        </Typography>
        <TextField
          label={'Минуты'}
          variant={'standard'}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          value={unitDomain.ui.unit.entity.quiz.passageTime}
          onChange={(event) => {
            unitDomain.ui.unit.entity.quiz.passageTime = event.target.value;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '66px' }}>
        <Typography variant="h6">Награда</Typography>
        <Typography variant="body1">
          Общее количество баллов за юнит - 100. За прохождение теста сотрудник может получить максимум 15 баллов.
          Итоговый результат зависит от количества вопросов, на которые он или она ответили правильно.
        </Typography>
      </Box>
    </Box>
  );
});
