import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryBlock, ITheoryBlocks } from '../../../../../../service/unit/interface/IUnitTheory';
import { TheoryComponentList } from '../../theoryComponents/TheoryComponentList';
import { ITheoryComponent } from '../ITheoryComponent';

export interface IHiddenBlock {
  name?: string;
  subBlocks: ITheoryBlock<ITheoryBlocks>[];
}
export const HiddenBlock = observer(({ data }: ITheoryComponent<IHiddenBlock>) => {
  return (
    <Box sx={{ width: '100%', marginBottom: '16px' }}>
      <Accordion sx={{ boxShadow: 'none' }}>
        {data.name !== '' && (
          <AccordionSummary
            sx={{
              maxWidth: 'auto',
              width: 'auto',
              fontSize: '20px',
              lineHeight: 1.6,
              padding: '0px',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              '& .MuiSvgIcon-root': {
                fontSize: '20px',
                marginRight: '4px',
              },
            }}
            expandIcon={<ExpandMore />}
          >
            {data.name}
          </AccordionSummary>
        )}
        <AccordionDetails
          sx={{
            padding: '8px 16px 0px 50px',
            borderLeft: '1px solid #d7d7d7',
            '& > div': { background: 'transparent', boxShadow: 'none' },
            '& .MuiTableContainer-root': { background: 'transparent', boxShadow: 'none' },
          }}
        >
          {data.subBlocks.map((block) => {
            const Element: ITheoryComponent<any> = TheoryComponentList[block.block];
            // @ts-ignore
            return <Element data={block.data} />;
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
