import { Download } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { ProjectListDomain } from '../../../../../view/user/page/project/list/store/ProjectListDomain';
import { LearningReportDomain } from './domain/LearningReportDomain';

export const ReportButton = observer(({ domain }: { domain: ProjectListDomain }) => {
  const [reportDomain] = useState<LearningReportDomain>(new LearningReportDomain(domain.layoutDomain));

  useEffect(() => {
    reportDomain.boot();
  }, [domain.layoutDomain.ui.activeUser.entity.id]);

  return (
    <React.Fragment>
      {reportDomain.isCanDownload.value && (
        <>
          <Button
            aria-controls="context-menu"
            aria-haspopup="true"
            variant="contained"
            startIcon={<Download />}
            size="large"
            onClick={(event) => reportDomain.handleOpen(event)}
          >
            Выгрузить
          </Button>
          <Menu
            id="context-menu"
            anchorEl={reportDomain.anchorEl.value}
            keepMounted
            open={Boolean(reportDomain.anchorEl.value)}
            onClose={reportDomain.handleClose}
          >
            <MenuItem onClick={reportDomain.handleReportDocx}>Отчет по обучению (docx)</MenuItem>
            <MenuItem onClick={reportDomain.handleReportPdf}>Отчет по обучению (pdf)</MenuItem>
          </Menu>
        </>
      )}
    </React.Fragment>
  );
});
