import { inject } from '../../../../../../../common/container/inject';
import { IProjectModel } from '../../../../../../../service/project/IProjectModel';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IUserSystemRoleModel } from '../../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { IUserEntityModel } from '../../../../../../../service/user/IUserEntityModel';
import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../service/LearningRootService';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { UserDataLevel } from '../../../../../service/userData/IUserDataModel';
import { IUserRatingDomain } from './IUserRatingDomain';
import { IUserRatingData } from './IUserRatingUI';
import { UserRatingUI } from './UserRatingUI';

const DISPLAY_UNRELATED_PROJECTS_PERMISSIONS = new Set([
  'global-allow-any',
  'project-not-in-team-access',
  'project-not-in-team-permission-create-project',
  'project-not-in-team-permission-update-team-project',
  'project-not-in-team-permission-update-fields-project',
  'project-not-in-team-permission-update-project',
  'project-not-in-team-permission-delete-project',
  'project-not-in-team-permission-create-application',
  'project-not-in-team-permission-update-application',
  'project-not-in-team-permission-delete-application',
  'project-not-in-team-permission-report-access',
  'project-not-in-team-permission-custom-requirement-access',
  'project-not-in-team-permission-accept-cancel-access',
  'project-not-in-team-permission-show-unverified-requirements-access',
  'project-not-in-team-permission-show-regulators',
  'project-not-in-team-permission-new-comments-access',
]);

export class UserRatingDomain implements IUserRatingDomain {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public ui = new UserRatingUI(),
    public router = inject<IRouterService>(RouterServiceToken),
    private rootService = injectRootService(layoutDomain.serviceType.value),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {}

  boot = async (includedTeam?: string[]) => {
    this.ui.isLoading.setValue(true);

    const userSearchPromise = this.rootService.user.entity
      .search({
        limit: 10000,
        fields: ['displayName', 'systemRoleId', 'id'],
      })
      .then((result) => result.data.filter((user) => user.displayName !== 'Admin'));

    const systemRoleSearchPromise = this.rootService.systemRole.entity.search({
      limit: 100000,
      fields: ['id', 'name'],
    });
    const projectSearchPromise = this.rootService.project.entity.search({
      limit: 100000,
      fields: ['rolesMap', 'name'],
    });

    const projectRoleSearchPromise = this.rootService.projectRole.entity.search({
      limit: 100000,
      fields: ['name'],
    });

    const userDataSearchPromise = this.learningRootService.userData.search({
      limit: 100000,
      fields: { include: ['userId', 'level', 'totalScore'] },
    });

    const [
      userSearchResult,
      systemRoleSearchResult,
      projectSearchResult,
      userDataSearchResult,
      projectRoleSearchResult,
    ] = await Promise.all([
      userSearchPromise,
      systemRoleSearchPromise,
      projectSearchPromise,
      userDataSearchPromise,
      projectRoleSearchPromise,
    ]);

    const projectToRolesMap = projectSearchResult.data.reduce(
      (acc, item) => ({ ...acc, [item.name as string]: { id: item.id, rolesMap: item.rolesMap } }),
      {},
    );

    const projectRolesIdToNameMap = projectRoleSearchResult.data.reduce(
      (acc, item) => ({ ...acc, [item.id as string]: item.name }),
      {},
    );

    if (includedTeam && includedTeam?.length > 0) {
      const filteredUsers: any[] = [];
      for (const user of userSearchResult) {
        if (includedTeam.includes(user.id as string)) {
          filteredUsers.push(user);
        }
      }

      this.ui.users.setList(filteredUsers);
    } else {
      this.ui.users.setList(userSearchResult);
    }

    const currentUser = this.layoutDomain.ui.activeUser.entity;
    const hasDisplayUnrelatedProjectsPermissions =
      currentUser.allowedPermissions.filter((item) => DISPLAY_UNRELATED_PROJECTS_PERMISSIONS.has(item)).length > 0;

    if (this.ui.users.list.length > 0) {
      const usersRatingPromises = this.ui.users.list.map(async (user) => {
        const currentUserData = userDataSearchResult.data.filter((data) => {
          return data.userId === user.id;
        });
        let userProjects = this.getUserProjects(user.id as string, projectSearchResult.data);
        const projectRolesSet = new Set();
        const currentUserProject = this.getUserProjects(currentUser.id, projectSearchResult.data);

        //start | сокрытие проектов (и ролей из них), в которых не участвует currentUser
        if (!hasDisplayUnrelatedProjectsPermissions) {
          userProjects = userProjects.filter((item) => currentUserProject.includes(item));
        }
        //end | сокрытие проектов (и ролей из них), в которых не участвует currentUser (end)

        const projectRoles = userProjects.map((item) => projectToRolesMap[item]);
        projectRoles
          .map((item) => item.rolesMap.data)
          .forEach((item) => {
            item.forEach((item) => {
              if (item.userId === user.id) {
                projectRolesSet.add(item.roleId);
              }
            });
          });

        const projectRolesNames = Array.from(projectRolesSet).map((item) => projectRolesIdToNameMap[item as string]);
        const newUserRating: IUserRatingData = {
          id: user.id as string,
          fullName: user.displayName as string,
          projects: userProjects,
          projectRolesNames,
          teamRole: this.getUserSystemRole(user, systemRoleSearchResult.data),
          userLevel: currentUserData.length > 0 ? currentUserData[0].level : UserDataLevel.JUNIOR,
          score: currentUserData.length > 0 ? currentUserData[0].totalScore : 0,
        };

        return newUserRating;
      });

      const usersRatingList = await Promise.all(usersRatingPromises);
      this.ui.usersRatingList.setList(usersRatingList);
      this.ui.usersRatingFiltredList.setList(usersRatingList);
    }
    this.setFilter(this.ui.usersRatingFiltredList.list);

    setTimeout(() => {
      this.ui.isLoading.setValue(false);
    }, 300);
  };

  // change to back side
  fullTextSearch = () => {
    const searchText = this.ui.searchText.value.trim();
    this.ui.searchText.setValue(searchText);
    const searchResult = this.ui.usersRatingList.list.filter((user) => {
      return (
        user.fullName.toLowerCase().includes(this.ui.searchText.value.toLowerCase()) ||
        user.projectRolesNames.join('').toLowerCase().includes(this.ui.searchText.value.toLowerCase()) ||
        user.projects.some((project) => project.toLowerCase().includes(this.ui.searchText.value.toLowerCase()))
      );
    });
    this.setFilter(searchResult);
  };

  setFilter = (sortForListing: IUserRatingData[]) => {
    let sortedList: IUserRatingData[] = [];

    const listToSort = sortForListing || this.ui.usersRatingList.list;

    switch (this.ui.filterType.value) {
      case 'champions':
        sortedList = listToSort.slice().sort((a, b) => {
          const levelDifference = this.getLevelIndex(a.userLevel) - this.getLevelIndex(b.userLevel);
          if (levelDifference === 0) {
            return b.score - a.score;
          }
          return levelDifference;
        });
        break;

      case 'newbies':
        sortedList = listToSort.slice().sort((a, b) => {
          const levelDifference = this.getLevelIndex(b.userLevel) - this.getLevelIndex(a.userLevel);
          if (levelDifference === 0) {
            return b.score - a.score;
          }
          return levelDifference;
        });
        break;
    }

    this.ui.usersRatingFiltredList.setList(sortedList);
  };

  getLevelIndex = (level: UserDataLevel): number => {
    switch (level) {
      case UserDataLevel.SENIOR:
        return 0;
      case UserDataLevel.MIDDLE:
        return 1;
      case UserDataLevel.JUNIOR:
        return 2;
      default:
        return -1;
    }
  };

  getUserProjects = (userId: string, projectSearchResult: IProjectModel[]) => {
    let userProjectList: string[] = [];

    if (projectSearchResult.length > 0) {
      projectSearchResult.forEach((project) => {
        if (project.rolesMap?.data) {
          const matchingRole = project.rolesMap?.data.find((role) => role.userId === userId);
          if (matchingRole) {
            userProjectList.push(project.name as string);
          }
        }
      });
    }

    return userProjectList;
  };

  getUserSystemRole = (user: IUserEntityModel, systemRoles: IUserSystemRoleModel[]): string => {
    const matchingRoles = systemRoles.filter((role) => role.id === user.systemRoleId);
    if (matchingRoles.length > 0) {
      return matchingRoles[0].name;
    }
    return '';
  };

  getUserProjectRole = (user: IUserEntityModel) => {};

  onTableRedirect = (userId: string) => {
    const isAdmin = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
    if (isAdmin) {
      this.router.goTo(`/learning/user-rating/${userId}`);
    }
  };
}
