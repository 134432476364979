import { ApplicationDetailUI } from '../../../../../../view/user/page/application/info/store/ApplicationDetailUI';
import { ConstructorType, inject, injectValue } from '../../../../../../common/container/inject';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ApplicationDetailDomain } from '../../../../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ILearningRootService, LearningRootServiceToken } from '../../../../service/LearningRootService';
import { ILearningApplicationDetailDomain } from './ILearningApplicationDetailDomain';
import { ILearningApplicationDetailUI } from './ILearningApplicationDetailUI';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { ApplicationDetailsMode } from '../../../../../../view/user/page/application/info/store/ApplicationDetailsMode';

export const extendApplicationDetailDomainConstructor = (
  Ref: new (...args: any[]) => ApplicationDetailDomain,
): new (...args: any[]) => ILearningApplicationDetailDomain => {
  class ExtendedDomainRef extends Ref {
    constructor(
      public layoutDomain: IMainLayoutDomainStore,
      public ui: ILearningApplicationDetailUI = new (injectValue<ConstructorType<ILearningApplicationDetailUI>>(
        ApplicationDetailUI,
      ))(),
      protected rootService = injectRootService(layoutDomain.serviceType.value),
      private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    ) {
      super(layoutDomain, ui);
    }

    async loadLearning(applicationId) {
      let specificationsIds;
      if (applicationId !== '') {
        specificationsIds = (await this.rootService.application.entity.getById(applicationId)).specificationsIds;
      }
      const applicationSpecifications = new Set(
        this.ui.mode.value === ApplicationDetailsMode.edit
          ? this.ui.editApplication.entity.specificationsIds
          : specificationsIds,
      );
      this.ui.isLoading.setValue(true);
      this.ui.unitList.setList([]);
      this.ui.groupedUnits.setList([{ groupName: 'Без Контекста', unitsList: [] }]);
      const isAdmin = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
      this.ui.isAdmin.setValue(isAdmin);

      const searchResult = await this.learningRootService.unit.search({
        limit: 1000000,
        fields: {
          include: ['settings.characteristicsIds', 'settings.titleHeading', 'isPublished', 'includedIn', 'score'],
        },
      });
      const unitsWithCertainSpecification = searchResult.data.filter((unit) => {
        return unit.settings.characteristicsIds.some((chatacteristics) =>
          applicationSpecifications.has(chatacteristics),
        );
      });

      this.ui.unitList.setList(unitsWithCertainSpecification.filter((unit) => unit.isPublished));
      this.ui.passingList.setList(unitsWithCertainSpecification.filter((unit) => unit.isPublished));

      const { data } = await this.learningRootService.unitResult.search({
        filter: [
          {
            fieldName: 'userId',
            type: 'equal',
            value: this.layoutDomain.ui.activeUser.entity.id,
          },
        ],
      });

    

      this.ui.passingReslutList.setList(data);
      const specificationSearchResult = await this.rootService.specification.entity.search({
        limit: 100000,
        fields: ['name','categoryId'],
        filter: {
          ids: {
            in:
              this.ui.mode.value === ApplicationDetailsMode.edit
                ? this.ui.editApplication.entity.specificationsIds
                : specificationsIds,
          },
        },
      });
      this.ui.unitList.setList(searchResult.data);
      const { data: specificationCategoryData } = await this.rootService.specification.category.search({
        fields: ['name', 'id'],
        limit: 100000,
      });
      this.ui.specificationCategory.setList(specificationCategoryData);

      this.ui.characteristicList.setList(specificationSearchResult.data);
      this.createdGroupedList();
      this.getUserId();
      this.ui.isLoading.setValue(false);
    }

  

    handleIncludeUnit = async (unitId: string, applicationId: string, check: boolean) => {
      const res = await this.learningRootService.unit.getById(unitId);

      if (check) {
        await this.learningRootService.unit.updateByModel({ ...res, includedIn: [...res.includedIn, applicationId] });
        this.ui.groupedUnits.setList(
          this.ui.groupedUnits.list.map((group) => {
            return {
              ...group,
              unitsList: group.unitsList.map((unit) => {
                if (unit.id === unitId) {
                  return { ...unit, includedIn: [...unit.includedIn, applicationId] };
                }
                return unit;
              }),
            };
          }),
        );
      } else {
        let includedIn = res.includedIn.filter((application) => application !== applicationId);
        await this.learningRootService.unit.updateByModel({ ...res, includedIn });
        this.ui.groupedUnits.setList(
          this.ui.groupedUnits.list.map((group) => {
            return {
              ...group,
              unitsList: group.unitsList.map((unit) => {
                if (unit.id === unitId) {
                  return { ...unit, includedIn: unit.includedIn.filter((appId) => appId !== applicationId) };
                }
                return unit;
              }),
            };
          }),
        );
      }
    };

    createdGroupedList = () => {
      const charIdToCategoryMap = new Map();
      this.ui.characteristicList.list.forEach(char => {
        charIdToCategoryMap.set(char.id, this.ui.specificationCategory.list.find(cat => cat.id === char.categoryId));
      });
    
      const groups = {};
    
      this.ui.unitList.list.forEach(unit => {
        if (unit.isPublished === false) {
          return;
        }
        let hasContext = false;
    
        unit.settings.characteristicsIds.forEach(charId => {
          const category = charIdToCategoryMap.get(charId);
          if (category) {
            hasContext = true;
            if (!groups[category.name]) {
              groups[category.name] = [];
            }
            groups[category.name].push(unit);
          }
        });
    
        if (!hasContext && unit.settings.characteristicsIds.length === 0) {
        }
      });
    
      const sortedGroupList = Object.keys(groups).map(groupName => ({
        groupName,
        unitsList: groups[groupName]
      })).sort((a, b) => a.groupName.localeCompare(b.groupName));
    
      this.ui.groupedUnits.setList(sortedGroupList);
    }
    

    redirect = async (id: string) => {
      this.router.goTo(`/learning/${id}`);
    };

    getUserId = () => {
      const ids = this.ui.project.entity.rolesMap?.data?.map((user) => user.userId);
      this.ui.teamUserIds.setList(ids as string[]);
    };

    redirectToKnowledge = () => {
      this.router.goTo('/learning/admin');
    };
  }

  return ExtendedDomainRef;
};
