import EditIcon from '@mui/icons-material/Edit';
import React from 'react';

import { inject, injectValue } from '../../../../../../../common/container/inject';
import { UserProjectRoleAction } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../../service/RootAdminService';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { UserSourceType } from '../../../../../../../service/user/UserSourceType';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdminTableDomain } from '../../../../table/store/AdminTableDomain';
import { IAdminTableColumn } from '../../../../table/store/IAdminTableUI';
import { UserProjectRoleTranslationInjection } from './inject/TranslationExtensions';
import { IUserProjectRoleAdminModel } from './UserProjectRoleAdminModel';

export class UserProjectRoleAdminDomain extends AdminTableDomain<IUserProjectRoleAdminModel, any> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private translationExtensions = injectValue<typeof UserProjectRoleTranslationInjection>(
      UserProjectRoleTranslationInjection,
    ),
  ) {
    super(layoutDomain, rootPrivateServices.projectRole.entity);
    this.ui.isInlineEdit.setValue(false);
  }

  setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-user-project-role-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-user-project-role-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-user-project-role-update']]),
    );
  }

  async loadData() {
    const columns = await this.getColumns();
    this.setActions();

    this.ui.columns.setList(columns);
    await super.loadData();
  }

  private setActions() {
    const actions: any[] = [];
    if (this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-user-project-role-update']])) {
      actions.push((rowData: any) => ({
        disabled: rowData.sourceType === UserSourceType.activeDirectory,
        icon: () => <EditIcon />,
        tooltip: 'Редактировать',
        onClick: (event, rowData: any) => {
          this.router.goTo(`/settings/user/project/role/${rowData?.id}/edit`);
        },
      }));
    }
    this.ui.actions.setList(actions);
  }

  private async getColumns(): Promise<IAdminTableColumn[]> {
    const translateActions = (actions: { data: { action: string }[] }) => {
      const translations = new Map();
      translations.set(UserProjectRoleAction.updateFieldsProject, 'Редактирование полей проекта');
      translations.set(UserProjectRoleAction.updateTeamProject, 'Редактирование команды проекта');
      translations.set(UserProjectRoleAction.creteApplication, 'Создание новых анкет');
      translations.set(UserProjectRoleAction.editApplication, 'Редактирование анкет');
      translations.set(UserProjectRoleAction.reportsAccess, 'Формирование отчётов');
      translations.set(UserProjectRoleAction.customRequirementsAccess, 'Создание требований');
      translations.set(UserProjectRoleAction.acceptCancelRequirementAccess, 'Принятие и исключение требований');
      translations.set(
        UserProjectRoleAction.newMessagesNotification,
        'Может получать уведомления о новых комментариях',
      );
      translations.set(UserProjectRoleAction.showRegulators, 'Просмотр требований внешних регуляторов');
      translations.set(UserProjectRoleAction.jiraIntegration, 'Возможность интегрировать АС с Jira');
      translations.set(UserProjectRoleAction.showUnverifiedRequirements, 'Просмотр неверифицированных требований');
      this.translationExtensions.forEach((translation) => {
        translations.set(translation.value, translation.name);
      });

      return actions.data.map((item) => translations.get(item.action)).join(', ');
    };
    return [
      {
        title: 'Название',
        field: 'name',
      },
      {
        title: 'Описание',
        field: 'description',
      },
      {
        title: 'Права',
        field: 'permission',
        render: (rowData) => <div> {translateActions(rowData.permissions)}</div>,
      },
      // {
      //   title: 'Активен',
      //   field: 'isActive',
      //   type: 'boolean',
      // },
    ];
  }
}
