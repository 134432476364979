import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IRequirementModel } from './IRequirementModel';
import { IRequirementSearch } from './IRequirementSearch';
import { IRequirementService } from './IRequirementService';

export class RestRequirementService
  extends RestCRUDService<IRequirementModel, IRequirementSearch>
  implements IRequirementService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/requirement`;
  }

  async createLocal(newEntity: IRequirementModel): Promise<string> {
    const result = await this.request()
      .url(`${this.getEndpoint()}/local`)
      .post(newEntity)
      .json<any>()
      .catch(this.handleError);

    return result?.id;
  }

  async updateLocal(newEntity: IRequirementModel): Promise<string> {
    const result = await this.request()
      .url(`${this.getEndpoint()}/local`)
      .put(newEntity)
      .json<any>()
      .catch(this.handleError);

    return result?.id;
  }

  async deleteLocal(id: string): Promise<boolean> {
    await this.request()
      .url(`${this.getEndpoint()}/local/${id}`)
      .delete()
      .fetchError(this.handleError)
      .json()
      .catch(this.handleError);

    return true;
  }

  async post(url: string, data: any): Promise<any> {
    return this.request().url(url).post(data);
  }

  async getReport(reportFormat = 'docx'): Promise<any> {
    const url = `${this.getEndpoint()}/entity/${reportFormat}`;

    const doc = await this.post(url, {});

    const blobDoc = await doc.blob();
    return blobDoc;
  }
}
