import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { FormTextFieldStandard } from '../../../../../../design/form/FormTextFieldStandard';
import { IUnitDomain } from '../../../store/IUnitDomain';

interface IResults {
  unitDomain: IUnitDomain;
}

export const Results = observer(({ unitDomain }: IResults) => {
  const { success, neutral, failure } = unitDomain.ui.unit.entity.quiz.results;
  return (
    <Box sx={{ overflowY: 'scroll', height: '100%', maxHeight: '550px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">Успешно</Typography>
        <Typography variant="body1">Ответили правильно на все вопросы или допустили одну ошибку</Typography>
        <FormTextFieldStandard
          multiline
          label={'Название'}
          value={success.title}
          onChange={(newValue) => {
            success.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={'Подзаголовок'}
          value={success.subtitle}
          onChange={(newValue) => {
            success.subtitle = newValue;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '66px' }}>
        <Typography variant="h6">Нейтрально</Typography>
        <Typography variant="body1">Допущено более одной ошибки и более 50% ответов отвечено верно.</Typography>
        <FormTextFieldStandard
          multiline
          label={'Название'}
          value={neutral.title}
          onChange={(newValue) => {
            neutral.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={'Подзаголовок'}
          value={neutral.subtitle}
          onChange={(newValue) => {
            neutral.subtitle = newValue;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '66px' }}>
        <Typography variant="h6">Провал</Typography>
        <Typography variant="body1">Допущено более 50% неверных ответов</Typography>
        <FormTextFieldStandard
          multiline
          label={'Название'}
          value={failure.title}
          onChange={(newValue) => {
            failure.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={'Подзаголовок'}
          value={failure.subtitle}
          onChange={(newValue) => {
            failure.subtitle = newValue;
          }}
        />
      </Box>
    </Box>
  );
});
