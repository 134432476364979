import { Box, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

interface Props {
    groupName: string; 
    passingPercent: number;   
}

export const GroupCardComponentHeader = ({ groupName, passingPercent }: Props) => {
  const classes = useStyles();

  return (
    <Box className="group_indicator" sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography variant="h6">{groupName}</Typography>
        <Typography variant="subtitle1">{passingPercent}%</Typography>
      </Box>
      <LinearProgress
        className={classes.background_progress_bar}
        color="success"
        sx={{
          borderRadius: '8px',
          height: '8px',
        }}
        variant="determinate"
        value={passingPercent}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  background_progress_bar: {
    '&.MuiLinearProgress-root': {
      backgroundColor: 'rgba(87, 94, 98, 1)',
    },
  },
}));
