import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { UNIT_LIST_ITEMS_STYLES, getUnitStatus } from './utils/passingListUserCard';

export interface IPassingListUserCardProperties {
  title: string;
  id: string;
  action: any;
  score: number;
  totalUnitResult: number;
  isPassed: boolean | null;
}
export const PassingListUserCard = observer(
  ({ title, id, action, score, totalUnitResult, isPassed }: IPassingListUserCardProperties) => {
    const unitStatus = getUnitStatus(isPassed);

    return (
      <Box
        onClick={() => {
          action(id);
        }}
        sx={{
          cursor: 'pointer',
          borderRadius: '8px',
          padding: '8px 16px',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          background: UNIT_LIST_ITEMS_STYLES[unitStatus].baseColor,
          '&:hover': {
            background: UNIT_LIST_ITEMS_STYLES[unitStatus].hoverColor,
          },
        }}
      >
        <Typography sx={{ maxWidth: '250px' }}>{title}</Typography>
        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            {Math.round(totalUnitResult)}
            <span style={{ color: 'rgba(255,255,255,0.5)' }}> / {Math.round(score)}</span>
          </Typography>
          {UNIT_LIST_ITEMS_STYLES[unitStatus].icon}
        </Box>
      </Box>
    );
  },
);
