import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationCategoryService } from '../../../../../../../service/specification/category/ISpecificationCategoryService';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { SpecificationCategoryModalUI } from './SpecificationCategoryModalUI';

export class SpecificationCategoryModalDomain
  extends DataTableFormDomain<ISpecificationCategoryModel, ISpecificationCategoryService>
  implements IDataTableFormDomain<ISpecificationCategoryModel>
{
  public ui: SpecificationCategoryModalUI;
  constructor(
    entityService: ISpecificationCategoryService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<ISpecificationCategoryModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new SpecificationCategoryModalUI());
    this.ui = new SpecificationCategoryModalUI();
  }
  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependency();
    if (!id) {
      this.ui.model.setEntity({
        name: '',
        description: '',
        orderIndex: 0,
        conditionsMap: {
          useIfSpecificationEntitiesIds: [],
          useIfNotSpecificationEntitiesIds: [],
        },
      });
    }
  }

  async loadDependency() {
    const entitiesSearchResult = await this.rootPrivateServices.specification.entity.search({ limit: 10000000 });
    const categoriesSearchResult = await this.rootPrivateServices.specification.category.search({ limit: 1000000 });
    this.ui.entities.setList(entitiesSearchResult.data);
    this.ui.categories.setList(categoriesSearchResult.data);
  }
}
