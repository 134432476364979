import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react';

import { ICommonProperties } from '../../../../../../../common/properties/ICommonProperties';
import { UserProjectRoleAction } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { Colors } from '../../../../../../design/color/Colors';
import { ProjectButton } from '../../../../../../design/link/ProjectButton';
import { ProjectButtonLink } from '../../../../../../design/link/ProjectButtonLink';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { ApplicationDetailsMode } from '../../store/ApplicationDetailsMode';
import { ApplicationDetailsType } from '../../store/ApplicationDetailsType';
import { ApplicationInfoReport } from '../report/ApplicationInfoReport';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';

export interface IActiveRequirementsActionsProperties extends ICommonProperties {
  domain: ApplicationDetailDomain;
}

export const ExtendButtons = observer(({ domain }: IActiveRequirementsActionsProperties) => {
  return <></>;
});

export const ActiveRequirementsActions = observer(({ domain }: IActiveRequirementsActionsProperties) => {
  const classes = useStyles();
  const isEditMode = domain.ui.mode.value === ApplicationDetailsMode.edit;
  const isSelectNewest = domain.ui.selectedNewRequirementsIds.value.length > 0;
  const isSelectDelete = domain.ui.selectedDeleteRequirementsIds.value.length > 0;
  let isNeedToShow = !isEditMode;

  const container = useContext(ContainerContext);
  const AdditionalButtons = container.get(ExtendButtons);

  const isShowRequirementsButton =
    (domain.ui.userProjectRoleActions.value.includes(UserProjectRoleAction.customRequirementsAccess) ||
      domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]) ||
      domain.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-custom-requirement-access'],
      ])) &&
    domain.ui.type.value === ApplicationDetailsType.entity;

  const isCanAcceptOrCancelRequirements =
    domain.ui.userProjectRoleActions.value.includes(UserProjectRoleAction.acceptCancelRequirementAccess) ||
    domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]) ||
    domain.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['project-not-in-team-permission-accept-cancel-access'],
    ]);

  const isCleanSearch =
    domain.filterDomain.ui.searchSpecificationsIds.value.length > 0 ||
    domain.filterDomain.ui.searchStatusesIds.value.totalCount > 0 ||
    domain.filterDomain.ui.searchTagsIds.value.length > 0 ||
    domain.filterDomain.ui.searchVendorsIds.value.length > 0 ||
    domain.filterDomain.ui.searchText.value.length > 0;
  const applicationId = domain.ui.application?.entity.id;

  return (
    <div>
      {isNeedToShow && (
        <Fragment>
          <Grid justifyContent="flex-start" direction="row" item container>
            {isShowRequirementsButton && (
              <Grid key="new" className={classes.button} item>
                <ProjectButtonLink icon={<AddIcon />} url={`/application/${applicationId}/requirement/new`}>
                  Добавить Требование
                </ProjectButtonLink>
              </Grid>
            )}
            {(domain.filterDomain.ui.isCleanSearchExtension.value || isCleanSearch) && (
              <Grid key="clearSearch" className={classes.button} item>
                <ProjectButton
                  icon={<ClearIcon />}
                  action={() => {
                    domain.filterDomain.clearSearch();
                  }}
                >
                  Очистить поиск
                </ProjectButton>
              </Grid>
            )}
            {isSelectNewest && isCanAcceptOrCancelRequirements && (
              <Grid key="accept" className={classes.button} item>
                <ProjectButton
                  className={classes.acceptButton}
                  icon={<SaveIcon />}
                  action={() => {
                    domain.acceptSelectedNewRequirements();
                  }}
                >
                  Принять в работу
                </ProjectButton>
              </Grid>
            )}
            {isSelectDelete && isCanAcceptOrCancelRequirements && (
              <Grid key="delete" className={classes.button} item>
                <ProjectButton
                  className={classes.removeButton}
                  icon={<DeleteIcon />}
                  action={() => {
                    domain.acceptSelectedDeleteRequirements();
                  }}
                >
                  Исключить
                </ProjectButton>
              </Grid>
            )}
            <ApplicationInfoReport domain={domain} />
            <AdditionalButtons domain={domain} />
          </Grid>
        </Fragment>
      )}
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(1),
  },
  removeButton: {
    color: Colors.negativePassive,
  },
  acceptButton: {
    color: Colors.positivePassive,
  },
}));
