import { ILocalization, ILocalizationToken } from '../../../../../../application/language/ILocalization';
import { inject } from '../../../../../../common/container/inject';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IUserSystemRoleSearch } from '../../../../../../service/systemRole/entity/IUserSystemRoleSearch';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { SystemRoleAdminContextMenuDomain } from './context/SystemRoleAdminContextMenuDomain';
import { SystemRoleAdminModalDomain } from './modal/SystemRoleAdminModalDomain';
import { SystemRoleAdminModel } from './SystemRoleAdminModel';
import { SystemRoleAdminUI } from './SystemRoleAdminUI';
export class CustomAppBar {
  Toolbar = TableHeader;
}
export class SystemRoleAdminDomain extends DataTableDomain<SystemRoleAdminModel, IUserSystemRoleSearch> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    public i18n = inject<ILocalization>(ILocalizationToken),
  ) {
    super(layoutDomain, rootPrivateServices.systemRole.entity);
    this.ui = new SystemRoleAdminUI(this, null);
    this.modalDomain = new SystemRoleAdminModalDomain(rootPrivateServices.systemRole.entity, layoutDomain, this);
    this.contextMenuDomain = new SystemRoleAdminContextMenuDomain({
      entityService: rootPrivateServices.systemRole.entity,
      dataTableDomain: this,
      layoutDomain,
    });
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });

    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle?.setValue('Управление пользователями');
    this.ui.tableSubtitle?.setValue('Системные роли');
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-user-system-role-create'],
    ]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-user-system-role-delete'],
    ]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-user-system-role-update'],
    ]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
  }

  async setNoDeletePermission() {
    this.ui.isCanDelete.setValue(false);
  }
}
