import { ArrowDownward, ArrowUpward, DeleteForever, ExpandMore, VisibilityOff } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { MainLayoutDomainStore } from '../../../../../../../../view/layout/main/store/domain/MainLayoutDomainStore';
import { BlockType, ITheoryBlock, ITheoryBlocks } from '../../../../../../service/unit/interface/IUnitTheory';
import { ITags } from '../../../../../../service/unit/interface/theoryBlocks/ITags';
import { generateUUID } from '../../../../../../utils/generateUUID';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';
import { ElementFactory } from '../ElementFactory';
import { NestingButton } from './NestingButton';

export interface IAdditionalElementsList {
  title: string;
  element: ITheoryBlock<any>;
}

export const additionalElementsList: IAdditionalElementsList[] = [
  {
    title: 'Абзац',
    element: {
      block: BlockType.PARAGRAPH,
      data: {
        text: '',
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Абзац',
    },
  },
  {
    title: 'Список',
    element: {
      block: BlockType.LIST,
      data: {
        type: 'Numbered',
        elementCount: 0,
        listData: [],
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Список',
    },
  },
  {
    title: 'Таблица',
    element: {
      block: BlockType.TABLE,
      data: {
        columnsCount: 0,
        rowsCount: 0,
        tableData: {
          headerText: [],
          rows: [],
        },
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Таблица',
    },
  },
  {
    title: 'Пример кода',
    element: {
      block: BlockType.CODE_EXAMPLE,
      data: {
        languages: [],
        example: {
          language: '',
          content: '',
        },
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Пример кода',
    },
  },
  {
    title: 'Специальный код',
    element: {
      block: BlockType.SPECIAL_BLOCK,
      data: {
        name: '',
        color: '',
        contentBlocks: [],
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Специальный код',
    },
  },
  {
    title: 'Изображение',
    element: {
      block: BlockType.IMAGE,
      data: {
        name: '',
        originalName: '',
        data: '',
        indexName: '',
        type: 'image',
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Изображение',
    },
  },
  {
    title: 'Видео',
    element: {
      block: BlockType.VIDEO,
      data: {
        name: '',
        originalName: '',
        data: '',
        indexName: '',
        type: 'video',
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Видео',
    },
  },
];
export interface ITagsBlock {
  data: ITheoryBlock<ITags>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}
export interface IPickedTag {
  name: string;
  subBlocks: ITheoryBlock<ITheoryBlocks>[];
}
export const TagsBlock = observer(({ data: { data, pseudoId }, onDelete, onUp, onDown }: ITagsBlock) => {
  const [pickedtag, setPickedTag] = useState<IPickedTag>(null as any);
  const layoutDomain = new MainLayoutDomainStore();

  const addNewElement = (block) => {
    const newBlock: ITheoryBlock<any> & { Element?: any } = JSON.parse(JSON.stringify(block));
    newBlock.pseudoId = generateUUID();
    newBlock.Element = ElementFactory[newBlock.block];
    data.tagsData.contentBlocks.forEach((tag) => {
      if (tag.name === pickedtag.name) {
        tag.subBlocks.push(newBlock);
      }
    });
  };

  const onDeleteSubBlock = (block, name: string) => {
    data.tagsData.contentBlocks.forEach((tag) => {
      if (tag.name === name) {
        const index = tag.subBlocks.indexOf(block);
        if (index !== -1) {
          tag.subBlocks.splice(index, 1);
        }
      }
    });
  };
  const onUpSubBlock = (index: number, name: string) => {
    data.tagsData.contentBlocks.forEach((tag) => {
      if (tag.name === name) {
        if (index === 0 && tag.subBlocks.length !== 0) {
          const firstEl = tag.subBlocks.shift();
          // @ts-ignore
          tag.subBlocks.push(firstEl);
        } else {
          [tag.subBlocks[index], tag.subBlocks[index - 1]] = [tag.subBlocks[index - 1], tag.subBlocks[index]];
        }
      }
    });
  };
  const onDownSubBlock = (index: number, name: string) => {
    data.tagsData.contentBlocks.forEach((tag) => {
      if (tag.name === name) {
        if (index === tag.subBlocks.length - 1 && tag.subBlocks.length !== 0) {
          const lastEl = tag.subBlocks.pop();
          // @ts-ignore
          tag.subBlocks.unshift(lastEl);
        } else {
          [tag.subBlocks[index], tag.subBlocks[index + 1]] = [tag.subBlocks[index + 1], tag.subBlocks[index]];
        }
      }
    });
  };
  return (
    <Box
      key={pseudoId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
        '&:hover .action': { visibility: 'visible' },
      }}
    >
      <Accordion sx={{ background: 'transparent', boxShadow: 'none' }} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            display: 'flex',
            '& .MuiAccordionSummary-expandIconWrapper': { order: -1 },
            alignItems: 'flex-start',
            '& .MuiAccordionSummary-content': {
              margin: 0,
              width: '100%',
              flex: 1,
            },
            '.Mui-expanded': {
              margin: '0 !important',
            },
            '&.Mui-expanded': {
              minHeight: '32px',
            },
            padding: 0,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
            <Typography sx={{ marginRight: 'auto' }}>Теги</Typography>
            <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
              <DeleteForever
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onDelete();
                }}
              />
              <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {}} />
              <ArrowUpward
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onUp();
                }}
              />
              <ArrowDownward
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onDown();
                }}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            // @ts-ignore
            onWheel={(e) => e.target.blur()}
            variant="outlined"
            type="number"
            value={data.tagsCount}
            label="Количество"
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(event) => {
              if (+event.target.value > data.tagsCount) {
                data.tagsCount = +event.target.value;
                data.tagsData.contentBlocks.push({ name: 'Тег ' + data.tagsCount, subBlocks: [] });
              } else {
                data.tagsCount = +event.target.value;
                data.tagsData.contentBlocks.pop();
              }
            }}
          />
          <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            {data.tagsData.contentBlocks.map((field, index) => {
              return (
                <FormTextFieldStandard
                  variant="outlined"
                  inputProps={{ maxLength: 30 }}
                  label={'Заголовок'}
                  value={field.name}
                  helperText="Не более 30 символов"
                  onChange={(newValue) => {
                    data.tagsData.contentBlocks[index].name = newValue;
                  }}
                />
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            {data.tagsData.contentBlocks.map((tag) => {
              return (
                <Box
                  onClick={() => setPickedTag({ name: tag.name, subBlocks: tag.subBlocks })}
                  sx={{
                    padding: '8px 16px',
                    borderRadius: '16px',
                    background: pickedtag !== null && tag.name === pickedtag.name ? '#1976D2' : '#93999C',
                    cursor: 'pointer',
                  }}
                >
                  {tag.name}
                </Box>
              );
            })}
          </Box>
          {pickedtag !== null && (
            <Box className="tag_content" sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {pickedtag.subBlocks.map((block, index) => {
                  const Element = ElementFactory[block.block];
                  const elementData = { data: block.data, pseudoId: block.pseudoId };
                  return (
                    <Box
                      sx={{
                        width: '100%',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        '& > div': {
                          background: 'transparent',
                        },
                      }}
                      key={block.pseudoId}
                    >
                      <Element
                        layoutDomain={layoutDomain}
                        data={elementData}
                        onDelete={() => onDeleteSubBlock(block, pickedtag.name)}
                        onUp={() => onUpSubBlock(index, pickedtag.name)}
                        onDown={() => onDownSubBlock(index, pickedtag.name)}
                      />
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                {additionalElementsList.map((button) => {
                  return <NestingButton title={button.title} action={() => addNewElement(button.element)} />;
                })}
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
