import {IEntityListStore} from "../../../../../../../../common/store/interface/IEntityListStore";
import {injectEntityList} from "../../../../../../../../common/store/base/injectEntityList";
import {IApplicationModel} from "../../../../../../../../service/application/entity/IApplicationModel";
import {IPrimitiveStore} from "../../../../../../../../common/store/interface/IPrimitiveStore";
import {injectPrimitive} from "../../../../../../../../common/store/base/injectPrimitive";
import {IApplicationChanges} from "./IApplicationChanges";

export interface IApplicationModelWithUser extends IApplicationModel {
    auditUser?: {
        login?: string,
        displayName?: string,
        email?: string,
    };
}

export class ApplicationHistoryUI{
    constructor(
        public auditApplications: IEntityListStore<IApplicationModelWithUser> = injectEntityList<IApplicationModelWithUser>([]),
        public fullAuditApplications: IEntityListStore<IApplicationModelWithUser> = injectEntityList<IApplicationModelWithUser>([]),
        public selectedApplicationsIds: IPrimitiveStore<string[]> = injectPrimitive<string[]>([]),
        public selectedAuditApplication: IEntityListStore<IApplicationModelWithUser> = injectEntityList<IApplicationModelWithUser>([]),
        public auditApplicationChanges: IEntityListStore<IApplicationChanges> = injectEntityList<IApplicationChanges>([]),
    ) { }
}