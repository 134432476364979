import React, { Fragment } from 'react';

import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { SettingsItemView } from '../../../../../view/admin/page/settings/SettingsPage';
import { IPageWithLayoutProperties } from '../../../../../view/layout/page/IPageWithLayoutProperties';

export const AdminLogsSettingsMenu = ({ layoutDomain }: IPageWithLayoutProperties) => {
  return (
    <Fragment>
      {layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow']]) && (
        <SettingsItemView
          key="logs"
          title="Журналирование"
          buttons={[
            {
              title: 'Журнал событий',
              url: `/settings/logs`,
            },
          ]}
          infoText={'Содержит журнал событий'}
        />
      )}
    </Fragment>
  );
};
