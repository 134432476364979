import { inject } from '../../../../../../common/container/inject';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../service/LearningRootService';

export class LearningReportDomain {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public anchorEl = injectPrimitive<HTMLButtonElement | null>(null),
    public isCanDownload = injectPrimitive<boolean>(false),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    private rootService = injectRootService(layoutDomain.serviceType.value),
  ) {}

  boot = async () => {
    const isCanDownload = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
    this.isCanDownload.setValue(isCanDownload);
  };

  handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.anchorEl.setValue(event.currentTarget);
  };
  handleClose = () => {
    this.anchorEl.setValue(null);
  };

  genereteDocx = async () => {
    const userList = (await this.rootService.user.entity.search({ limit: 1000000 })).data; ///, fields: ['id', 'displayName', 'systemRoleId']
    const systemRoleList = (await this.rootService.systemRole.entity.search({ limit: 1000000, fields: ['id', 'name'] }))
      .data;
    const projects = (
      await this.rootService.project.entity.search({
        limit: 100000,
        fields: ['id', 'name', 'rolesMap'],
      })
    ).data;
    const blobDocx = await this.learningRootService.report.generateProjectUnitSummuryByUsers(
      userList,
      systemRoleList,
      projects,
    );

    this.saveFile(`Отчет по обучению.docx`, blobDocx);
  };

  generatePdf = async () => {
    const userList = (await this.rootService.user.entity.search({ limit: 1000000 })).data; //, fields: ['id', 'displayName', 'systemRoleId']
    const systemRoleList = (await this.rootService.systemRole.entity.search({ limit: 1000000, fields: ['id', 'name'] }))
      .data;
    const projects = (
      await this.rootService.project.entity.search({
        limit: 100000,
        fields: ['id', 'name', 'rolesMap'],
      })
    ).data;
    const blobPdf = await this.learningRootService.report.generateProjectUnitSummuryByUsersPdf(
      userList,
      systemRoleList,
      projects,
    );
    this.saveFile(`Отчет по обучению.pdf`, blobPdf);
  };

  saveFile(filename: string, blob: any) {
    const blobUrl = URL.createObjectURL(blob);
    let link: any = document.createElement('a');
    link.download = filename;
    link.href = blobUrl;

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      link = null;
    }, 0);
  }

  handleReportDocx = () => {
    this.genereteDocx();
    this.handleClose();
  };

  handleReportPdf = () => {
    this.generatePdf();
    this.handleClose();
  };
}
