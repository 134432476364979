import { IValidationErrorData } from '../../../../../../common/error/IValidationErrorData';
import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { IPrimitiveStore } from '../../../../../../common/store/interface/IPrimitiveStore';
import {
  AdditionalColumnRequirementRelationType,
} from '../../../../../../service/additionalColumn/content/AdditionalColumnRequirementRelationType';
import {
  IAdditionalColumnContentModel,
} from '../../../../../../service/additionalColumn/content/IAdditionalColumnContentModel';
import { IAdditionalColumnModel } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import {
  IApplicationDataChatCounterModel,
} from '../../../../../../service/application/data/IApplicationDataChatCounterModel';
import { IApplicationDataModel } from '../../../../../../service/application/data/IApplicationDataModel';
import {
  ApplicationAcceptStatus,
  IApplicationModel,
} from '../../../../../../service/application/entity/IApplicationModel';
import { IJiraIssueFields } from '../../../../../../service/application/jira/IJiraIssueFieldsData';
import { IApplicationTemplateModel } from '../../../../../../service/application/template/IApplicationTemplateModel';
import { getServerNowDate } from '../../../../../../service/date/since';
import { IPerformerTypeModel } from '../../../../../../service/performer/type/IPerformerTypeModel';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { IUserProjectRoleModel } from '../../../../../../service/projectRole/entity/IUserProjectRoleModel';
import { IRequirementCategoryModel } from '../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementModel } from '../../../../../../service/requirement/entity/IRequirementModel';
import { RequirementType } from '../../../../../../service/requirement/entity/RequirementType';
import { IVendorRequirementModel } from '../../../../../../service/requirement/vendor/IVendorRequirementModel';
import {
  ISpecificationCategoryModel,
} from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { IStatusEntityModel } from '../../../../../../service/status/entity/IStatusEntityModel';
import {
  ImplementationStateStatusValueType,
} from '../../../../../../service/status/system/valueTypes/ImplementationStateStatusValueType';
import { IStatusValueModel } from '../../../../../../service/status/value/IStatusValueModel';
import { ITagCategoryModel } from '../../../../../../service/tag/category/ITagCategoryModel';
import { ITagEntityModel } from '../../../../../../service/tag/entity/ITagEntityModel';
import { IJiraApplicationSettingsType } from '../../../../../../service/taskManager/IJiraTaskManager';
import { ITaskManagerType, TaskManagerConnectionState } from '../../../../../../service/taskManager/ITaskManager';
import { ITaskManagerServer } from '../../../../../../service/taskManager/server/ITaskManagerServer';
import { IUserEntityModel } from '../../../../../../service/user/IUserEntityModel';
import { IVendorEntityModel } from '../../../../../../service/vendor/entity/IVendorEntityModel';
import { StatusValueColor } from '../../../../../admin/page/status/value/color/StatusValueColor';
import { Colors } from '../../../../../design/color/Colors';
import { ApplicationInfoDetails } from '../parts/application/ApplicationInfoDetails';
import { ApplicationRequirementContainer } from '../parts/requirements/ApplicationRequirementContainer';
import { ApplicationDetailDomain } from './ApplicationDetailDomain';
import { ApplicationDetailsMode } from './ApplicationDetailsMode';
import { ApplicationDetailsType } from './ApplicationDetailsType';
import { RequirementViewModel, RequirementViewModelState } from './RequirementViewModel';
import { TaskManagerViewModel } from './TaskManagerViewModel';
import { ApplicationInfoHistory } from '../parts/applicationInfoHistory/ApplicationInfoHistory';
import { ConfigManager } from '../../../../../../application/config/ConfigManager';

export const defaultEditApplication = {};

const sortByIndex = (current: any, next: any) => (current.sortIndex < next.sortIndex ? -1 : 1);

export class ApplicationDetailUI {
  constructor(
    public isLoading: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false),
    public isCompletePageStartLoading: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false),
    public isFirstBootLoaded = injectPrimitive<boolean>(false),
    public isSupportJira = injectPrimitive<boolean>(true),
    public mode: IPrimitiveStore<ApplicationDetailsMode> = injectPrimitive<ApplicationDetailsMode>(
      ApplicationDetailsMode.view,
    ),
    public type: IPrimitiveStore<ApplicationDetailsType> = injectPrimitive<ApplicationDetailsType>(
      ApplicationDetailsType.entity,
    ),
    public isCanSeeRegulators: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false),
    public isCanUpdate: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false),
    public isCanDelete: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false),
    public isCanCreate: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false),
    public isCanEditJira: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false),
    // public isAdminAccess: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false),
    public activeUserId: IPrimitiveStore<string> = injectPrimitive<string>(''),
    public activeUserProjectRoleId: IPrimitiveStore<string> = injectPrimitive<string>(''),
    public tableRequirements: IEntityListStore<RequirementViewModel> = injectEntityList<RequirementViewModel>([]),
    public renderRequirements: IEntityListStore<RequirementViewModel> = injectEntityList<RequirementViewModel>([]),
    public requirementsIdsToRender: IPrimitiveStore<string[] | null> = injectPrimitive<string[] | null>(null),
    public activeStatusFilter: IPrimitiveStore<string | null> = injectPrimitive<string | null>(null),
    public project: IEntityStore<IProjectModel> = injectEntity<IProjectModel>({}),
    public application: IEntityStore<IApplicationModel> = injectEntity<IApplicationModel>({}),
    public applicationData: IEntityStore<IApplicationDataModel> = injectEntity<IApplicationDataModel>({}),
    public applicationTemplates: IEntityListStore<IApplicationTemplateModel> = injectEntityList<IApplicationTemplateModel>(
      [],
    ),
    public selectedApplicationTemplate: IPrimitiveStore<string> = injectPrimitive<string>(''),
    public previewApplicationData: IEntityStore<IApplicationDataModel> = injectEntity<IApplicationDataModel>({}),
    public requirements: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([]),
    public requirementsCategories: IEntityListStore<IRequirementCategoryModel> = injectEntityList<IRequirementCategoryModel>(
      [],
    ),
    public statuses: IEntityListStore<IStatusEntityModel> = injectEntityList<IStatusEntityModel>([]),
    public statusesValues: IEntityListStore<IStatusValueModel> = injectEntityList<IStatusValueModel>([]),
    public additionalColumns: IEntityListStore<IAdditionalColumnModel> = injectEntityList<IAdditionalColumnModel>([]),
    public additionalColumnsContent: IEntityListStore<IAdditionalColumnContentModel> = injectEntityList<IAdditionalColumnContentModel>(
      [],
    ),
    public vendorRequirements: IEntityListStore<IVendorRequirementModel> = injectEntityList<IVendorRequirementModel>(
      [],
    ),
    public vendors: IEntityListStore<IVendorEntityModel> = injectEntityList<IVendorEntityModel>([]),
    public applicationSpecifications: IEntityListStore<ISpecificationEntityModel> = injectEntityList<ISpecificationEntityModel>(
      [],
    ),
    public applicationSpecificationsCategories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
    public applicationPerformerType: IEntityStore<IPerformerTypeModel> = injectEntity<IPerformerTypeModel>({}),
    public editPerformerTypes: IEntityListStore<IPerformerTypeModel> = injectEntityList<IPerformerTypeModel>([]),
    public editSpecifications: IEntityListStore<ISpecificationEntityModel> = injectEntityList<ISpecificationEntityModel>(
      [],
    ),
    public editSpecificationsCategories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
    public editTags: IEntityListStore<ITagEntityModel> = injectEntityList<ITagEntityModel>([]),
    public editUsers: IEntityListStore<IUserEntityModel> = injectEntityList<IUserEntityModel>([]),
    public editTagsCategories: IEntityListStore<ITagCategoryModel> = injectEntityList<ITagCategoryModel>([]),
    public editStatuses: IEntityListStore<IStatusEntityModel> = injectEntityList<IStatusEntityModel>([]),
    public editStatusesValues: IEntityListStore<IStatusValueModel> = injectEntityList<IStatusValueModel>([]),
    public editApplication: IEntityStore<IApplicationModel> = injectEntity<IApplicationModel>(defaultEditApplication),
    public applicationValidationErrors = injectEntityList<IValidationErrorData>([]),
    public jiraValidationErrors = injectEntityList<IValidationErrorData>([]),
    public selectedRequirementsIds = injectPrimitive<string[]>([]),
    public selectedNewRequirementsIds = injectPrimitive<string[]>([]),
    public selectedDeleteRequirementsIds = injectPrimitive<string[]>([]),
    public selectedActiveRequirementsIds = injectPrimitive<string[]>([]),
    public requirementCategoriesValues = injectPrimitive<any[]>([]),
    public statusesColumns = injectPrimitive<any[]>([]),
    public extraStatusesColumns = injectPrimitive<any[]>([]),
    public userProjectRoleActions = injectPrimitive<string[]>([]),
    public allProjectRoles = injectPrimitive<IUserProjectRoleModel[]>([]),
    public editJiraTaskManager = injectEntity<TaskManagerViewModel>({
      settings: {
        value: {
          customLabels: [],
          statusesMap: [],
          allowedInternalStatusesIds: [],
          projectId: null,
          projectKey: null,
          prioritiesMap: [],
          deletedStatus: {
            externalPriorityId: '',
            name: '',
          },
          issue: {
            namePrefix: null,
            type: { id: null, name: null },
          },
        },
        type: IJiraApplicationSettingsType.applicationCommon,
      },
      type: ITaskManagerType.jira,
      connectionState: TaskManagerConnectionState.notConnected,
    }),
    public jiraProjects = injectPrimitive<any[]>([]),
    public jiraProjectStatuses = injectPrimitive<any[]>([]),
    public jiraProjectPriorities = injectPrimitive<any[]>([]),
    public jiraIssuesFields = injectPrimitive<IJiraIssueFields>({ fields: [] }),
    public jiraProjectTagsForPriority = injectPrimitive<
      { name: string; orderIndex: number; isDefault: boolean; tag: ITagEntityModel }[]
    >([]),
    public jiraIssueTypes = injectPrimitive<any[]>([]),
    public isLoadingJiraProjects = injectPrimitive<boolean>(false),
    public isLoadingJiraStatuses = injectPrimitive<boolean>(false),
    public isLoadingJiraPriorities = injectPrimitive<boolean>(false),
    public isLoadingJiraIssuesFields = injectPrimitive<boolean>(false),
    public isLoadingJiraIssueTypes = injectPrimitive<boolean>(false),
    public isHaveActionButtons = injectPrimitive<boolean>(false),
    public isSelectAll = injectPrimitive<boolean>(false),
    public isPartialSelect = injectPrimitive<boolean>(false),
    public taskManagerServers: IEntityListStore<ITaskManagerServer> = injectEntityList<ITaskManagerServer>([]),
    public isNeedVerifyAllRequirementsOnCreate = injectPrimitive<boolean>(false),
    public readMessageSetInterval = injectPrimitive<any>(null),
    public readMessagesPoolIds = injectPrimitive<string[]>([]),
    public applicationNewMessagesState = injectPrimitive<IApplicationDataChatCounterModel | null>(null),
    public applicationJiraLinks = injectPrimitive<
      {
        requirementId: string;
        link: string;
        isNotExists: boolean;
      }[]
    >([]),
    public isDisabledSaveButton = injectPrimitive<boolean>(false),
    public isSetDefaultTemplate = injectPrimitive<boolean>(false),
    public tabsData = injectPrimitive<{
      state: number;
      defaultViewKey: string;
      tabs: {
        key: string;
        Component: any;
        isShow?: (domain: ApplicationDetailDomain) => boolean;
        getTitle: (domain: ApplicationDetailDomain) => string;
      }[];
    }>({
      state: 0,
      defaultViewKey: 'requirement',
      tabs: [
        {
          key: 'form',
          Component: ApplicationInfoDetails,
          getTitle: (domain: ApplicationDetailDomain) => 'Анкета',
        },
        {
          key: 'requirement',
          Component: ApplicationRequirementContainer,
          getTitle: (domain: ApplicationDetailDomain) =>
            domain.ui.mode.value === ApplicationDetailsMode.view
              ? this.getRequirementCount()
              : `Предпросмотр Требований`,
        },
        {
          key: 'history',
          Component: ApplicationInfoHistory,
          isShow: (domain: ApplicationDetailDomain) =>
            domain.ui.mode.value === ApplicationDetailsMode.view &&
            domain.ui.type.value === ApplicationDetailsType.entity,
          getTitle: (domain: ApplicationDetailDomain) => 'История изменений',
        },
      ],
    }),
  ) {
  }

  changeTab(key: string) {
    const index = this.tabsData.value.tabs.findIndex((item) => item.key === key);
    if (index !== -1) {
      this.tabsData.value.state = index;
    }
  }

  getRequirementCount() {
    if (!this.applicationData.entity.requirementCount) {
      return `Требования (0)`;
    }

    return `Требования (${this.applicationData.entity.requirementCount})`;
  };


  getAcceptStatusName() {
    if (ConfigManager.getConfig().ui.application.acceptWithVersion) {
      if (this.application?.entity?.name && this.application?.entity?.name?.length > 0) {
        if (this.application?.entity?.acceptStatus === ApplicationAcceptStatus.draft) {
          return ' (черновик) ';
        }
        if (this.application?.entity?.acceptStatus === ApplicationAcceptStatus.review) {
          return ' (на проверке) ';
        }
        if (this.application?.entity?.acceptStatus === ApplicationAcceptStatus.accepted) {
          const fullVersion = this.application?.entity?.versionNumber?.split('.');
          if (fullVersion) {
            const versionWithoutAcceptanceTests = [fullVersion[0], fullVersion[1]].join('.');
            return ` (версия ${versionWithoutAcceptanceTests}) `;
          } else {
            return ` (версия ${this.application?.entity?.versionNumber}) `;
          }
        }
      }
    }
    return '';
  }

  getRequirementByIds(requirementIds: string[] = []): IRequirementModel[] {
    return (
      requirementIds?.reduce((requirements: IRequirementModel[], requirementId) => {
        const requirementData = this.getRequirementById(requirementId);
        if (requirementData) {
          requirements.push(requirementData);
        }
        return requirements;
      }, []) || []
    );
  }

  getRequirementById(requirementId: string): IRequirementModel | null {
    return this.requirements.list.find((requirement) => requirement.id === requirementId) || null;
  }

  setRequirementCategoriesValues() {
    const values = this.requirementsCategories.list.map((requirementCategory) => ({
      value: requirementCategory.id,
      name: requirementCategory.name,
    }));

    this.requirementCategoriesValues.setValue(values);
  }

  getAuthorModel(authorId: string | null): IUserEntityModel | null {
    return this.editUsers.list.find((user) => user.id === authorId) || null;
  }

  getStatusesValues(statusId: string, activeStatusValueId: string | null): { value: string; name: string }[] {
    const sortByIndex = (current: any, next: any) => (current.orderIndex < next.orderIndex ? -1 : 1);
    const statusValues = this.statusesValues.list
      .filter((statusValue) => statusValue.statusId === statusId)
      .filter((statusValue) => {
        return statusValue.isActive === true || statusValue.id === activeStatusValueId;
      })
      .sort(sortByIndex);
    return statusValues.map((statusValue) => ({
      value: statusValue.id || '',
      name: statusValue.name || '',
    }));
  }

  getStatusValue(statusValueId?: string): IStatusValueModel | undefined {
    return this.statusesValues.list.find((statusValue) => statusValue.id === statusValueId);
  }

  setAdditionalColumnData = (
    requirementId: string = '',
    additionalColumnId: string = '',
    additionalColumnContentId: string = '',
    value: string | null,
    originalFileName: string = '',
  ) => {
    const dataIndex = this.applicationData.entity.additionalColumnData?.data?.findIndex((data) => {
      return data.requirementId === requirementId && data.additionalColumnContentId === additionalColumnContentId;
    });

    const additionalColumnData = !originalFileName
      ? {
        requirementId,
        additionalColumnId,
        additionalColumnContentId,
        value,
        lastChangeDate: getServerNowDate(),
      }
      : {
        requirementId,
        additionalColumnId,
        additionalColumnContentId,
        value,
        originalFileName,
        lastChangeDate: getServerNowDate(),
      };

    if (dataIndex !== -1) {
      const newData = this.applicationData.entity.additionalColumnData || {
        data: [],
      };
      newData.data = newData.data || [];
      newData.data[dataIndex || 0] = additionalColumnData;
      this.applicationData.entity.additionalColumnData = newData;
      this.applicationData.setEntity(this.applicationData.entity);
    } else {
      this.applicationData.entity.additionalColumnData?.data.push(additionalColumnData);
      this.applicationData.setEntity(this.applicationData.entity);
    }
  };

  getStatusData = (requirementId?: string, statusId?: string) => {
    const result = this.applicationData.entity.statusesData?.data?.find((statusData) => {
      return statusData.requirementId === requirementId && statusData.statusId === statusId;
    });
    return result;
  };

  getStatusesData = (requirementId?: string) => {
    const result = this.applicationData.entity.statusesData?.data?.filter((statusData) => {
      return statusData.requirementId === requirementId;
    });
    return result;
  };

  getAdditionalColumnsContent = (requirementId?: string): { title: string; text: string; type: string }[] => {
    const result: { title: string; text: string; type: string }[] = [];
    const sortByIndex = (current: any, next: any) => (current.orderIndex < next.orderIndex ? -1 : 1);

    this.additionalColumnsContent.list.forEach((additionalColumnContent) => {
      const isHaveRequirement =
        additionalColumnContent.requirementsIds.includes(requirementId || 'unknown') ||
        additionalColumnContent.requirementsRelationType === AdditionalColumnRequirementRelationType.all;
      const isHaveAdditionalColumn = this.applicationData.entity.additionalColumnsIds?.includes(
        additionalColumnContent.additionalColumnId,
      );

      const biTesterFiledData = this.getTesterAdditionalColumnData(additionalColumnContent, requirementId || '');

      if (isHaveRequirement && isHaveAdditionalColumn && biTesterFiledData.isShow) {
        const additionalColumn = this.additionalColumns?.list.find(
          (additionalColumn) => additionalColumn.id === additionalColumnContent.additionalColumnId,
        );
        const title = additionalColumn?.name || '';
        const applicationData = this.applicationData.entity.additionalColumnData?.data?.find((data) => {
          return (
            data.requirementId === requirementId &&
            data.additionalColumnId === additionalColumn?.id &&
            data.additionalColumnContentId === additionalColumnContent?.id
          );
        });

        result.push({
          //@ts-ignore
          orderIndex: additionalColumn?.orderIndex || 100,
          title,
          isCanEdit: biTesterFiledData.isEdit,
          text: additionalColumnContent.content,
          //@ts-ignore
          type: additionalColumn?.type || 'enum',
          uploadedFileUrl: additionalColumnContent?.uploadedFileUrl,
          editData: {
            requirementId,
            additionalColumnContentId: additionalColumnContent.id,
            additionalColumnId: additionalColumn?.id,
            value: applicationData?.value || '',
            originalFileName: applicationData?.originalFileName || '',
          },
        });
      }
    });
    result.sort(sortByIndex);
    return result;
  };

  getTesterAdditionalColumnData(
    additionalColumnContent: IAdditionalColumnContentModel,
    requirementId: string,
  ): { isShow: boolean; isEdit: boolean } {
    const securityTesterRoleName = 'эксперт по тестированию';
    const securityTesterRole = this.allProjectRoles.value.find(
      (role) => role.name.toLocaleLowerCase() === securityTesterRoleName,
    );
    let isUserSecurityTester = this.activeUserProjectRoleId.value === securityTesterRole?.id;

    const dateAdditionalColumnContentId = this.additionalColumnsContent.list.find(
      (additionalColumnContent) => additionalColumnContent.content.toLowerCase() === 'плановая дата устранения',
    )?.id;

    const commentsAdditionalColumnId = this.additionalColumns.list.find(
      (additionalColumnContent) =>
        additionalColumnContent.name.toLocaleLowerCase() === 'описание выявленного недостатка',
    )?.id;

    const testingStatus = this.statuses.list.find(
      (status) => status.name.toLocaleLowerCase() === 'результат тестирования',
    );

    const isHaveTestingStatus = !!testingStatus;
    const activeStatusesNames = [
      'некритичное замечание',
      'критичное замечание',
      'соответствует с замечанием',
      'не соответствует',
    ];
    const activeTestingStatusValuesIds = this.statusesValues.list
      .filter(
        (statusValue) =>
          statusValue.statusId === testingStatus?.id &&
          activeStatusesNames.includes(statusValue.name.toLocaleLowerCase()),
      )
      .map((statusValue) => statusValue.id);

    const isCanEditTesterFields = isUserSecurityTester || this.isCanUpdate.value;

    const testingStatusValueIdForRequirement = this.getStatusData(requirementId, testingStatus?.id)?.statusValueId;
    const isActiveStatusValueForRequirement = activeTestingStatusValuesIds.includes(testingStatusValueIdForRequirement);
    const isTestingField =
      additionalColumnContent.additionalColumnId === commentsAdditionalColumnId ||
      additionalColumnContent.id === dateAdditionalColumnContentId;
    if (isTestingField && isHaveTestingStatus) {
      return {
        isShow: isActiveStatusValueForRequirement,
        isEdit: isCanEditTesterFields && isActiveStatusValueForRequirement,
      };
    }
    return {
      isShow: true,
      isEdit: true,
    };
  }

  getVendorColumnsContent = (requirementId?: string): { title: string; text: string; textWithStyles?: string }[] => {
    const result: { title: string; text: string; textWithStyles?: string }[] = [];

    this.vendorRequirements.list.forEach((vendorRequirement) => {
      const isTargetRequirement = vendorRequirement.requirementsIds.includes(requirementId || '');
      if (isTargetRequirement) {
        const vendorName = this.vendors?.list.find((vendor) => vendor.id === vendorRequirement.vendorId)?.name || '';
        const title = `${vendorName} - ${vendorRequirement.shortName}`;

        result.push({
          title,
          text: vendorRequirement.description,
          textWithStyles: vendorRequirement.descriptionWithStyles || '',
        });
      }
    });

    return result;
  };

  specificationsAutocompleteValues(categoryId?: string | null): any {
    return this.editSpecifications.list
      .filter((entity) => {
        return entity.categoryId === categoryId;
      })
      .map((entity) => ({ value: entity.id, name: entity.name }));
  }

  specificationsByCategoryId(categoryId?: string | null): ISpecificationCategoryModel[] {
    return this.applicationSpecifications.list.filter((entity) => entity.categoryId === categoryId);
  }

  performerTypesAutocompleteValues(): { value: any; name: any }[] {
    return this.editPerformerTypes.list.map((entity) => ({
      value: entity.id,
      name: entity.name,
    }));
  }

  applicationTemplateAutocompleteValues(): any {
    const items = this.applicationTemplates.list.map((entity) => ({
      value: entity.id,
      name: entity.name,
    }));

    return [{ name: 'Без шаблона', value: null }, ...items];
  }

  taskManagerServersValues(): any {
    return this.taskManagerServers.list.map((entity) => ({
      value: entity.id,
      name: entity.name,
    }));
  }

  setTableRequirements(
    savedRequirements: IRequirementModel[],
    newestRequirements: IRequirementModel[],
    deletedRequirements: IRequirementModel[],
  ) {
    const sortByIndex = (current: IRequirementModel, next: IRequirementModel) => {
      return Number(current?.orderIndex) > Number(next?.orderIndex) ? 1 : -1;
    };
    const isHaveAdditionalStatuses = this.statuses.list.length > 2;
    const requirementViewBuilder =
      (state: RequirementViewModelState) =>
        (requirement: IRequirementModel): RequirementViewModel => {
          const statusesData: any = this.getStatusesData(requirement.id) || [];
          const statusesIds = statusesData.map((statusData: any) => {
            return statusData.statusValueId;
          });
          const isSupportRequirementForm = requirement.type === RequirementType.local;
          const additionalColumnContentData = this.getAdditionalColumnsContent(requirement.id);
          const vendorContentData = this.getVendorColumnsContent(requirement.id);
          const isHaveComments = this.editJiraTaskManager.entity.connectionState === TaskManagerConnectionState.connected;
          const isSupportAdditionalInfo =
            isHaveAdditionalStatuses ||
            additionalColumnContentData.length > 0 ||
            vendorContentData.length > 0 ||
            isHaveComments ||
            !!requirement.authorId;

          let stateColor: any = null;
          stateColor = state === RequirementViewModelState.newest ? Colors.newestActiveOpacity('0.5') : stateColor;
          stateColor = state === RequirementViewModelState.deleted ? Colors.negativeActiveOpacity('0.5') : stateColor;

          if (this.isHaveActionButtons.value === false && (isSupportAdditionalInfo || isSupportRequirementForm)) {
            this.isHaveActionButtons.setValue(true);
          }
          const prevState = this.renderRequirements.list.find(
            (renderRequirement) => renderRequirement.id === requirement.id,
          );
          return {
            ...requirement,
            statusesData,
            backgroundColor: stateColor || this.getColorByStatusesIds(statusesIds),
            state,
            isSelected: false,
            isHaveComments: isHaveComments,
            isHaveInfoDetailPanel: isSupportAdditionalInfo,
            isOpenInfoDetailPanel: !!prevState?.isOpenInfoDetailPanel,
            isHaveRequirementFormDetailPanel: isSupportRequirementForm,
            isOpenRequirementFormDetailPanel: !!prevState?.isOpenRequirementFormDetailPanel,
            isShow: true,
            id: requirement.id || '',
          };
        };

    savedRequirements = savedRequirements.slice().sort(sortByIndex);

    const savedRequirementsView = savedRequirements.map(requirementViewBuilder(RequirementViewModelState.saved));
    const newestRequirementsView = newestRequirements.map(requirementViewBuilder(RequirementViewModelState.newest));
    const deletedRequirementsView = deletedRequirements.map(requirementViewBuilder(RequirementViewModelState.deleted));
    const sortedSavedRequirementsView = this.sortRequirementArray(savedRequirementsView);
    const sortedNewestRequirementsView = this.sortRequirementArray(newestRequirementsView);
    const sortedDeletedRequirementsView = this.sortRequirementArray(deletedRequirementsView);
    this.tableRequirements.setList([
      ...sortedDeletedRequirementsView,
      ...sortedNewestRequirementsView,
      ...sortedSavedRequirementsView,
    ]);

    this.renderRequirements.setList([
      ...sortedDeletedRequirementsView,
      ...sortedNewestRequirementsView,
      ...sortedSavedRequirementsView,
    ]);
  }

  getColorByStatusesIds(statusesIds: any[]): string | null {
    const statuses = this.statuses.list.sort(sortByIndex);
    const primaryStatus = statuses.find((status) => status.isPrimaryApplicationStatus === true) || this.statuses[0];

    const primaryStatusValues = this.statusesValues.list.filter((value) => value.statusId === primaryStatus?.id);
    const activePrimaryStatusValue = primaryStatusValues.find((item) => statusesIds.includes(item.id));
    const color = StatusValueColor.find((item) => item.id === activePrimaryStatusValue?.colorId);

    //@ts-ignore
    return color?.rgbaTemplate ? Colors.rgbaOpacityTemplate(color?.rgbaTemplate || null, '0.3') : null;
  }

  getStatusValueBySystemType(type: ImplementationStateStatusValueType) {
    return this.statusesValues.list.find((statusValue) => statusValue.systemType === type) || null;
  }

  getStatusValueByName(name: string): IStatusValueModel | null {
    return this.statusesValues.list.find((statusValue) => statusValue.name === name) || null;
  }

  sortStatusesColumn = (status: IStatusEntityModel) => (current: any, next: any) => {
    const currentStatusData = this.getStatusData(current.id, status.id) || {
      statusValueId: '',
    };
    const nextStatusData = this.getStatusData(next.id, status.id) || {
      statusValueId: '',
    };
    const currentStatusValue = this.getStatusValue(currentStatusData?.statusValueId);
    const nextStatusValue = this.getStatusValue(nextStatusData?.statusValueId);
    //@ts-ignore
    const sortIndex = (currentStatusValue?.orderIndex || 0) - (nextStatusValue?.orderIndex || 0);
    return sortIndex;
  };

  sortCategoryColumn = (current: RequirementViewModel, next: RequirementViewModel) => {
    const currentCategoryName =
      this.requirementsCategories.list.find((category) => category.id === current.categoryId)?.name || '';
    const nextCategoryName =
      this.requirementsCategories.list.find((category) => category.id === next.categoryId)?.name || '';
    return nextCategoryName.localeCompare(currentCategoryName, undefined, { numeric: true, sensitivity: 'base' });
  };

  sortByCreateType = (current: RequirementViewModel, next: RequirementViewModel, orderBy: any, order: string) => {
    const result = current.type === RequirementType.local ? 1000 : -1;
    return result;
  };

  isSelectedRequirement = (requirementId?: string): boolean => {
    return !!this.selectedRequirementsIds.value.find((currentId) => requirementId === currentId);
  };

  toggleSelectRequirement = (requirementId: string): void => {
    this.selectedRequirementsIds.setValue(this.addOrRemoveInArray(this.selectedRequirementsIds.value, requirementId));
    this.selectRequirementByType(requirementId);
  };

  toggleSelectAllRequirements = (): void => {
    if (this.isSelectAll.value === false) {
      this.isSelectAll.setValue(true);
      const requirementsIds = this.renderRequirements.list.map((requirement) => requirement.id || '');
      this.selectedRequirementsIds.setValue(requirementsIds);
      requirementsIds.forEach((requirementId) => this.selectRequirementByType(requirementId));
    } else {
      this.isSelectAll.setValue(false);
      this.removeSelection();
    }
  };

  removeSelection = (): void => {
    this.selectedRequirementsIds.setValue([]);
    this.selectedActiveRequirementsIds.setValue([]);
    this.selectedNewRequirementsIds.setValue([]);
    this.selectedDeleteRequirementsIds.setValue([]);
  };

  toggleDetailPanel(requirementId: string) {
    const requirement = this.renderRequirements.list.find((requirement) => requirement.id === requirementId);
    if (requirement) {
      requirement.isOpenInfoDetailPanel = !requirement.isOpenInfoDetailPanel;
    }
  }

  toggleRequirementForm(requirementId: string) {
    const requirement = this.renderRequirements.list.find((requirement) => requirement.id === requirementId);
    if (requirement) {
      requirement.isOpenRequirementFormDetailPanel = !requirement.isOpenRequirementFormDetailPanel;
    }
  }

  updateByFilter(requirements: RequirementViewModel[]) {
    const requirementIdsToRender = requirements.map((requirement) => requirement.id || '');
    this.requirementsIdsToRender.setValue(requirementIdsToRender);
    // this.renderRequirements.list.forEach((filteredRequirement) => {
    //   filteredRequirement.isShow = requirementIdsToRender.includes(filteredRequirement.id || '');
    // });
  }

  private selectRequirementByType(requirementId: any) {
    const isNew = this.applicationData.entity.newestRequirementsIds?.includes(requirementId);
    const isDelete = this.applicationData.entity.deletedRequirementsIds?.includes(requirementId);
    const isActive = !isNew && !isDelete;
    if (isNew) {
      this.selectedNewRequirementsIds.setValue(
        this.addOrRemoveInArray(this.selectedNewRequirementsIds.value, requirementId),
      );
    }

    if (isDelete) {
      this.selectedDeleteRequirementsIds.setValue(
        this.addOrRemoveInArray(this.selectedDeleteRequirementsIds.value, requirementId),
      );
    }

    if (isActive || isDelete) {
      this.selectedActiveRequirementsIds.setValue(
        this.addOrRemoveInArray(this.selectedActiveRequirementsIds.value, requirementId),
      );
    }
  }

  private addOrRemoveInArray(array: any[], value: any) {
    return array.includes(value) ? array.filter((i) => i !== value) : [...array, value];
  }

  private sortRequirementArray(reqArray: RequirementViewModel[]) {
    try {
      const categoryMap = new Map();
      this.requirementsCategories.list.forEach((category) => categoryMap.set(category.id, category));

      const sortedArray = [...reqArray].sort((a, b) => {
        const aCategory = categoryMap.get(a.categoryId);
        const bCategory = categoryMap.get(b.categoryId);

        if (aCategory && bCategory) {
          const orderIndexDiff = (aCategory.orderIndex ?? 0) - (bCategory.orderIndex ?? 0);
          if (orderIndexDiff !== 0) return orderIndexDiff;

          const nameDiff = aCategory.name.localeCompare(bCategory.name);
          if (nameDiff !== 0) return nameDiff;

          return (a.orderIndex ?? 0) - (b.orderIndex ?? 0);
        }
        return 0;
      });

      return sortedArray;
    } catch (error) {
      return reqArray;
    }
  }
}
