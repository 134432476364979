export interface IApplicationModelCustomInformationData {
  fields: IApplicationModelCustomInformationMultiline[];
}

export type IApplicationModelCustomInformation = IApplicationModelCustomInformationMultiline;

export interface IApplicationModelCustomInformationMultiline {
  fieldName: string;
  fieldDescription: string;
  sortIndex: number;
  value: string;
  type: ApplicationModelCustomInformationType.multiline;
}

export enum ApplicationModelCustomInformationType {
  multiline = 'multiline',
}
