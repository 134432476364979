import { Box, Checkbox, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../service/unit/interface/IUnitModel';

export interface IUnitListAdminCardProperties {
  unit: IUnitModel;
  applicationId: string;
  handleIncludeUnit: (unitId: string, applicationId: string, check: boolean) => void;
}
export const UnitListAdminCard = observer(
  ({ unit, applicationId, handleIncludeUnit }: IUnitListAdminCardProperties) => {
    return (
      <Box
        sx={{
          padding: '8px 10px 8px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          background: 'rgba(255, 255, 255, 0.12)',
          alignItems: 'center',
          width: '100%',
          borderRadius: '8px',
        }}
      >
        <Typography sx={{ cursor: 'pointer' }}>{unit.settings.titleHeading}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={unit.includedIn?.includes(applicationId)}
            sx={{ padding: '0px 11px', '&:hover': { background: 'none' } }}
            onChange={(event) => {
              handleIncludeUnit(unit.id, applicationId, event.target.checked);
            }}
          />
        </Box>
      </Box>
    );
  },
);
