import { IApplicationStatusDataItem } from '../../../../../../service/application/data/IApplicationDataModel';
import { IRequirementModel } from '../../../../../../service/requirement/entity/IRequirementModel';

export interface RequirementViewModel extends IRequirementModel {
  statusesData: IApplicationStatusDataItem[];
  backgroundColor: null | string;
  state: RequirementViewModelState;
  isSelected: boolean;
  isHaveInfoDetailPanel: boolean;
  isHaveComments: boolean;
  isOpenInfoDetailPanel: boolean;
  isHaveRequirementFormDetailPanel: boolean;
  isOpenRequirementFormDetailPanel: boolean;
  isShow: boolean;
  id: string;
}

export enum RequirementViewModelState {
  newest = 'newest',
  deleted = 'deleted',
  saved = 'saved',
}
