import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { PassingContext } from '../../../../PassingContext';
import { PassingSidebarAccordion } from '../PassingSidebarAccordion';
import { PassingPracticeNavigationItem } from './PassingPracticeNavigationItem';

export const PassingSidebarPractice = observer(() => {
  const { passingDomain, practiceDomain, redirectToStep } = useContext(PassingContext);

  if (!practiceDomain.ui.isEnebled.value) {
    return <></>;
  }

  return (
    <PassingSidebarAccordion
      onChange={passingDomain.practiceDomain.expandHandler}
      isExpanded={passingDomain.practiceDomain.ui.isExpanded.value}
      onClick={(e) => {
        e.stopPropagation();
        redirectToStep(e, 'practice');
        passingDomain.theoryDomain.onExpandIconClick('');
      }}
      title="Практика"
      timeLimitLabel={`${passingDomain.practiceDomain.ui.passageTime.entity} мин`}
    >
      {passingDomain.practiceDomain.ui.navigationList.list.map((task, index) => {
        return (
          // @ts-ignore
          <PassingPracticeNavigationItem
            isActiveStep={passingDomain.practiceDomain.ui.activeStep.value === index + 1 ? true : false}
            title={task.title}
            pickedLang={passingDomain.practiceDomain.ui.pickedLang.value}
            isActive={task.isActive}
            passed={task.passed}
            navigationRedirect={task.navigationRedirect}
          />
        );
      })}
    </PassingSidebarAccordion>
  );
});
