import { Grid, Paper } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContainerContext } from '../../../../../../common/container/ContainerContext';
import { UserProjectRoleAction } from '../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { FormActions } from '../../../../../design/form/FormActions';
import { FormMultiAutocomplete } from '../../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../design/form/FormTextField';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { UserProjectRoleAdminFormDomain } from './store/UserProjectRoleAdminFormDomain';

export interface IUserProjectRoleAdminFormProperties extends IAdminPageProperties {}

export const UserProjectRoleAdminForm = observer(({ layoutDomain }: IUserProjectRoleAdminFormProperties) => {
  const container = useContext(ContainerContext);
  const [domain] = useState(new UserProjectRoleAdminFormDomain(layoutDomain, container));
  const { roleId = null } = useParams<any>();
  const uiEntity = domain.ui.model.entity;
  useEffect(() => {
    domain.loadData(roleId);
  }, [domain, roleId]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
      <Grid item xs={8}>
        <Paper elevation={3}>
          <FormTextField
            errorMessage={domain.getValidationErrorFor('name')?.message}
            value={uiEntity.name}
            onChange={domain.getUpdateFieldHandler('name')}
            label="Название"
            required={true}
          />

          <FormTextArea
            errorMessage={domain.getValidationErrorFor('description')?.message}
            value={uiEntity.description}
            onChange={domain.getUpdateFieldHandler('description')}
            label="Описание"
            required={true}
          />

          <FormMultiAutocomplete
            values={domain.actionsValues}
            onChangeSelect={(values) => {
              if (!domain.ui.model.entity.permissions?.data) {
                domain.ui.model.setEntity({
                  ...domain.ui.model.entity,
                  permissions: {
                    data: [],
                  },
                });
                // const actionValue = domain.actionsValues.find(
                //   (value) => value.value === UserProjectRoleAction.showRegulators,
                // );
              }
              const permissions = values.map((item) => {
                return {
                  isGranted: true,
                  action: item.value,
                };
              });
              domain.ui.model.setEntity({ ...domain.ui.model.entity, permissions: { data: permissions } });
            }}
            key="permissions"
            selected={
              !domain.ui.model.entity.permissions?.data
                ? [UserProjectRoleAction.showRegulators]
                : uiEntity.permissions?.data
                    ?.filter((item) => {
                      const actionValue = domain.actionsValues.find((value) => value.value === item.action);
                      return actionValue?.value && item.isGranted;
                    })
                    .map((item) => {
                      const actionValue = domain.actionsValues.find((value) => value.value === item.action);
                      return actionValue?.value || '';
                    })
            }
            label="Права"
          />

          {/* <FormCheckboxField
            value={uiEntity.isActive}
            defaultValue={true}
            onChange={domain.getUpdateFieldHandler('isActive')}
            label="Активен"
          /> */}
        </Paper>

        <FormActions onSave={domain.save} onCancel={domain.cancelEdit} />
      </Grid>
    </Grid>
  );
});
