import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGridPro, enUS, ruRU } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import FilterDrawer from './parts/filterDrawer/FilterDrawer'
import { ICommonProperties } from '../../../common/properties/ICommonProperties';
import { IServiceModel } from '../../../service/common/model/IServiceModel';
import { ISearchRequestData } from '../../../service/common/search/ISearchRequestData';
import { Colors } from '../color/Colors';
import { DataTableFormViewMode } from './store/DataTableFormViewMode';
import { IDataTableDomain } from './store/IDataTableDomain';

export interface IDataTableProperties extends ICommonProperties {
  dataTableDomain: IDataTableDomain<IServiceModel, ISearchRequestData>;
  tableHeight?: number | null;
}
export const DataTable = observer(({ dataTableDomain, tableHeight = null }: IDataTableProperties) => {
  const classes = useStyles();

  const handleDrawer = (event: any) => {
    const targetId = event.currentTarget.getAttribute('data-id');
    const entity = dataTableDomain.ui.rows.entities.list.find((entity) => entity.id === targetId);
    entity && dataTableDomain.drawerDomain.onOpenDrawer(entity);
  };

  const handleContextMenu = (event: any) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('data-id');
    const entity = dataTableDomain.ui.rows.entities.list.find((entity) => entity.id === targetId);
    entity && dataTableDomain.contextMenuDomain.ui.showContextMenu(entity, event);
  };

  const onCellClick = (param: { field: string }, event: { defaultMuiPrevented: boolean }) => {
    if (param.field === 'action') {
      event.defaultMuiPrevented = true;
    }
  };

  useEffect(() => {
    dataTableDomain.boot();
  }, [dataTableDomain]);

  const RowContextMenu = dataTableDomain.contextMenuDomain.ui.component;

  const contextMenuActiveRowId = dataTableDomain.contextMenuDomain.ui.activeRow?.entity?.id;
  const detailPanelOptions = dataTableDomain.detailDomain.ui.isDetail.value
    ? {
      getDetailPanelContent: (row) => {
        const detailsList = dataTableDomain.detailDomain.ui.detailEntities.list.filter((detail) => {
          if (dataTableDomain.detailDomain.ui.isArray.value === false) {
            return detail[dataTableDomain.detailDomain.ui.detailKey.value] === row.id;
          } else {
            const arrayDetailList = detail[dataTableDomain.detailDomain.ui.detailKey.value]?.some((item) => {
              return item === row.row.id;
            });
            return arrayDetailList;
          }
        });
        const secondDetailList = dataTableDomain.detailDomain.ui.isMultipleDetail.value
          ? dataTableDomain.detailDomain.ui.secondDetailEntities.list.filter((detail) => {
            if (dataTableDomain.detailDomain.ui.isSecondArray.value === false) {
              return detail[dataTableDomain.detailDomain.ui.secondDetailKey.value] === row.id;
            } else {
              const arrayDetailList = detail[dataTableDomain.detailDomain.ui.secondDetailKey.value].some((item) => {
                return item === row.row.id;
              });
              return arrayDetailList;
            }
          })
          : [];
        if (detailsList.length === 0 && secondDetailList.length === 0) {
          return null;
        } else {
          const Component = dataTableDomain.detailDomain.ui.getDetailPanelContent.value;
          return (
            <Component
              row={row}
              detailsList={detailsList}
              redirect={dataTableDomain.onLinkAction}
              secondDetailList={secondDetailList}
              rest={dataTableDomain.detailDomain.ui}
            />
          );
        }
      },
    }
    : {};

  return (
    <>
      <Paper elevation={dataTableDomain.ui.tableRootPaperElevation.value} className={classes.dataTable}>
        <div style={{ width: '100%', height: tableHeight || window.innerHeight - 59 }}>
          <DataGridPro
            pagination={dataTableDomain.ui.tableOptions.pagination.value}
            disableColumnPinning={dataTableDomain.ui.tableOptions.disableColumnPinning.value}
            checkboxSelection={dataTableDomain.ui.tableOptions.checkboxSelection.value}
            autoHeight={dataTableDomain.ui.tableOptions.autoHeight.value}
            rowsPerPageOptions={dataTableDomain.ui.tableOptions.rowsPerPageOptions.list}
            disableColumnResize={dataTableDomain.ui.tableOptions.disableColumnResize.value}
            getRowHeight={() => 'auto'}
            getDetailPanelHeight={() => 'auto'}
            style={{ ...dataTableDomain.ui.tableOptions.style }}
            {...detailPanelOptions}
            components={{ ...dataTableDomain.ui.tableSlots.entity }}
            onRowClick={(params, event) =>
              dataTableDomain.ui.formMode.value === DataTableFormViewMode.drawer ? handleDrawer(event) : () => { }
            }
            componentsProps={{
              toolbar: {
                tableDomain: dataTableDomain,
              },
              row: {
                onContextMenu: handleContextMenu,
              },
            }}
            className={classes.pagination}
            rows={
              [...dataTableDomain.ui.rows.filteredEntities.list]
                ? [...dataTableDomain.ui.rows.filteredEntities.list]
                : [...dataTableDomain.ui.rows.entities.list]
            }
            columns={dataTableDomain.ui.columns.value}
            getRowClassName={(params: any) => {
              let classNameForActiveStatus = params.row.isActive ? 'row-active' : 'row-not-active';
              classNameForActiveStatus =
                params.row?.id === contextMenuActiveRowId ? 'row-context' : classNameForActiveStatus;
              return classNameForActiveStatus;
            }}
            disableSelectionOnClick
            //Комент - убрал выделение в таблице по клику на строку
            // @ts-ignore
            // selectionModel={dataTableDomain.ui.rows.selectionEntities.list.map((entity) => entity.id)}
            // onSelectionModelChange={(ids: any[]) => {
            //   const entities =
            //     dataTableDomain.ui.rows.entities.list.filter((entity) => ids.includes(entity?.id || '')) || [];
            //   dataTableDomain.ui.rows.setSelectionEntities(entities);
            //   if (ids.length !== 0) {
            //     dataTableDomain.ui.isShown.setValue(true);
            //     dataTableDomain.handleFilter(entities);
            //   } else {
            //     dataTableDomain.ui.isShown.setValue(false);
            //     dataTableDomain.ui.rows.rowsCounter.setValue({ filteredRow: [], counter: 0 });
            //   }
            // }}
            // @ts-ignore
            onCellClick={onCellClick}
            // @ts-ignore
            sortModel={dataTableDomain.ui.rules?.list}
            onSortModelChange={(props) => {
              localStorage.setItem(`sort/${dataTableDomain.ui.domainID.value}`, JSON.stringify(props));
              dataTableDomain.setSortModel();
            }}
            page={dataTableDomain.ui.rows.page.value}
            onPageChange={(newPage) => dataTableDomain.ui.rows.page.setValue(newPage)}
            pageSize={dataTableDomain.ui.rows.pageSize.value}
            onPageSizeChange={(newPageSize) => dataTableDomain.ui.rows.pageSize.setValue(newPageSize)}
            filterModel={{
              // @ts-ignore
              items: dataTableDomain.ui.filterModel.list,
            }}
            onFilterModelChange={(props: { items: any[] }) => {
              localStorage.setItem(dataTableDomain.ui.domainID.value, JSON.stringify(props.items));
              dataTableDomain.setFilterModel();
            }}
            columnVisibilityModel={{
              ...dataTableDomain.ui.visibilityModel.value,
            }}
            onColumnVisibilityModelChange={(props) => {
              localStorage.setItem(`visibility/${dataTableDomain.ui.domainID.value}`, JSON.stringify(props));
              dataTableDomain.setVisibilityModel();
            }}
            localeText={
              dataTableDomain.ui.languagePackage.i18n.language === 'ru'
                ? {
                  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: dataTableDomain.ui.noRowsLabel?.value || 'Нет данных',
                  filterOperatorIsAnyOf: 'любой из',
                }
                : { ...enUS.components.MuiDataGrid.defaultProps.localeText }
            }
            loading={dataTableDomain.ui.isLoading.value}
          />
        </div>
        {dataTableDomain.contextMenuDomain.ui.activeRow?.entity &&
          // @ts-ignore
          dataTableDomain.contextMenuDomain.ui.activeRow?.entity.sourceType !== 'activeDirectory' && (
            <RowContextMenu domain={dataTableDomain.contextMenuDomain} />
          )}
        {
          dataTableDomain.ui.filterType?.value === 'drawerFilter'
          // @ts-ignore
          && (<FilterDrawer tableDomain={dataTableDomain} />)
        }
      </Paper>
      { }
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    width: '100%',
  },
  pagination: {
    color: 'white !important',
    '& .MuiDataGrid-footerContainer': {
      justifyContent: 'start !important',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0px',
    },
    '& .MuiDataGrid-row	': {
      fontSize: '16px !important',
      cursor: 'context-menu',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none !important',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: '1px solid rgba(81, 81, 81, 1) !important',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid rgba(81, 81, 81, 1) !important',
      display: 'flex',
      alignItems: 'flex-start',
      padding: '18px 9px 9px',
      '& span': {
        padding: 0,
      },
      '&:focus': {
        outline: 0,
      },
      overflow: 'auto',
      whiteSpace: 'initial !important',
      lineHeight: '16px !important',
      paddingTop: '25px !important',
      paddingBottom: '25px !important',
    },
    '& .row-not-active': {
      backgroundColor: Colors.negativeActiveOpacity('0.1'),
    },
    '& .row-context': {
      backgroundColor: Colors.blackOpacity('0.08'),
    },
    '& .Mui-selected': {
      backgroundColor: `${Colors.newestActiveOpacity('0.5')} !important`,
    },
    '& .MuiDataGrid-iconButtonContainer': {
      width: 'auto !important',
      visibility: 'visible',
    },
    '& .MuiDataGrid-menuIcon': {
      width: 'auto !important',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },

    '& [data-field="action"] button': {
      padding: 0,
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      height: 6,
      width: 0,
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:horizontal': {
      backgroundColor: 'rgba(255, 255, 255, 0.56)',
      borderRadius: '4px',
    },
  },
}));
