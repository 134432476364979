import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';

import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { FormMultiAutocomplete } from '../../../../../design/form/FormMultiAutocomplete';
import { SpecificationFormSelectionDomain } from './SpecificationFormSelectionDomain';

export interface SpecificationFormSelectionProperties {
  selectedSpecificationsIds: string[];
  specificationCategories: ISpecificationCategoryModel[];
  specifications: ISpecificationEntityModel[];
  onChange: (specificationIds: string[]) => void;
  disabled?: boolean;
  isShowAllConditionCategories?: boolean;
}

export const SpecificationFormSelection = observer((props: SpecificationFormSelectionProperties) => {
  const [domain] = useState<SpecificationFormSelectionDomain>(new SpecificationFormSelectionDomain());

  return (
    <Fragment>
      {props.specificationCategories.map((specificationCategory) => (
        <SpecificationItem
          {...props}
          domain={domain}
          specificationCategory={specificationCategory}
          key={specificationCategory.id}
        />
      ))}
    </Fragment>
  );
});

export const SpecificationItem = observer(
  ({
    selectedSpecificationsIds,
    specifications,
    onChange,
    disabled = false,
    domain,
    specificationCategory,
    isShowAllConditionCategories = false,
   }: {
    selectedSpecificationsIds: string[],
    specifications: any,
    onChange: any,
    disabled?: boolean,
    domain: SpecificationFormSelectionDomain,
    specificationCategory: any,
    isShowAllConditionCategories?: boolean,
  }) => {
    const categoryValues = domain.specificationCategoriesValues(specifications, specificationCategory.id);
    let categorySelectedValues = selectedSpecificationsIds.filter((selectedSpecificationId) =>
      categoryValues.find((categoryValue) => categoryValue.value === selectedSpecificationId),
    );

    const isIncludeValueForCategoryTrueCondition =
      specificationCategory.conditionsMap?.useIfSpecificationEntitiesIds?.length || 0 > 0
        ? specificationCategory.conditionsMap?.useIfSpecificationEntitiesIds?.some((specificationId) =>
            selectedSpecificationsIds.includes(specificationId),
          )
        : true;

    const isIncludeValueForCategoryFalseCondition =
      specificationCategory.conditionsMap?.useIfNotSpecificationEntitiesIds?.length || 0 > 0
        ? specificationCategory.conditionsMap?.useIfNotSpecificationEntitiesIds?.some((specificationId) =>
            selectedSpecificationsIds.includes(specificationId),
          )
        : false;

    const conditionRenderStatus = isIncludeValueForCategoryTrueCondition && !isIncludeValueForCategoryFalseCondition;
    const isNeedToRender =
      (isShowAllConditionCategories || conditionRenderStatus) &&
      categoryValues.length > 0 &&
      (specificationCategory.isActive || categorySelectedValues.length > 0);

    if (!isNeedToRender) {
      const index = selectedSpecificationsIds.findIndex((item) => categorySelectedValues.includes(item));
      if (index !== -1) {
        selectedSpecificationsIds.splice(index, 1);
      }

      categorySelectedValues = [];
    }
    return isNeedToRender ? (
      <FormMultiAutocomplete
        key={specificationCategory.id}
        disabled={disabled}
        values={categoryValues}
        onChangeSelect={domain.getComplexMultiSelectFieldHandler(
          {
            selectedIds: selectedSpecificationsIds,
            selectedCategoryId: specificationCategory.id,
            selectedCategoryValues: categoryValues,
            onChange
          },
        )}
        selected={categorySelectedValues}
        label={
          specificationCategory.isActive
            ? specificationCategory.name || ''
            : `${specificationCategory.name} (неактивен)`
        }
        helperText={specificationCategory.description}
      />
    ) : (
      <div key={specificationCategory.id} />
    );
  },
);
