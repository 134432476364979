import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box, IconButton, Paper, Theme, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { serverToLocalDate, timeFormat } from '../../../../service/date/since';
import { INotificationModel } from '../../../../service/notification/INotificationModel';
import { Colors } from '../../../design/color/Colors';
import { IMainLayoutDomainStore } from '../../../layout/main/store/domain/IMainLayoutDomainStore';
import { NotificationIcon } from './parts/NotificationIcon';
import { NotificationNav } from './parts/NotificationNav';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  emptyContainer: {
    width: '100%',
    height: '590px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainText: {
    width: '550px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.70)'
  },
  headlineText: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '48',
    paddingTop: '60px',
    paddingBottom: '30px',
  },
}));

interface INotificationPageProperties {
  layoutDomain: IMainLayoutDomainStore;
}

export const NotificationPage = observer(({ layoutDomain }: INotificationPageProperties) => {
  const notificationDomain = layoutDomain.notificationDomain;
  useEffect(() => {
    notificationDomain.loadData();
  }, [notificationDomain]);

  useEffect(() => {
    const unreadNotifications = notificationDomain.ui.notifications.list.filter((notification) => !notification.isRead);
    const detectionData =
      unreadNotifications.map((unreadedNotification) => ({
        handler: async () => {
          const notification =
            notificationDomain.ui.notifications.list.find((item) => item.id === unreadedNotification.id) || ({} as any);
          if (!unreadedNotification.isRead) {
            notification.isRead = true;
            notificationDomain.readNotification(notification);
          }
        },
        idName: `notification-${unreadedNotification?.id}`,
        isOnce: true,
      })) || [];

    notificationDomain.layoutDomain.ui.addDetections(detectionData);
    return () => {
      notificationDomain.layoutDomain.ui.removeDetections(detectionData);
    };
  }, [notificationDomain, notificationDomain.ui.notifications.list.length]);

  const classes = useStyles();
  return (
    <>
      <Typography variant="h4" sx={{ padding: '16px' }}>
        Уведомления
      </Typography>
      <NotificationNav layoutDomain={layoutDomain} notificationDomain={notificationDomain} />
      {notificationDomain.ui.notifications.list.length ? (
        <Timeline>
          {notificationDomain.ui.notifications.list.map((notification: INotificationModel) => {
            return (
              <TimelineItem id={`notification-${notification?.id}`}>
                <TimelineOppositeContent sx={{ m: 'auto 0', flex: '0.1' }} align="right" color="text.secondary">
                  {timeFormat(serverToLocalDate(new Date(notification.createdAt || 0)))}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot>
                    <NotificationIcon event={notification.event} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <Paper
                    className={classes.root}
                    elevation={4}
                    sx={{
                      marginBottom: 5,
                      ...(!notification.isRead && {
                        backgroundColor: blueGrey[800],
                        '-webkit-transition': `background-color '500ms' linear`,
                        '-ms-transition': `background-color '500ms' linear`,
                        transition: `background-color '500ms' linear`,
                      }),
                      ...(notification.isRead && {
                        backgroundColor: Colors.mainPassive,
                        '-webkit-transition': `background-color '500ms' linear`,
                        '-ms-transition': `background-color '500ms' linear`,
                        transition: `background-color '500ms' linear`,
                      }),
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box>
                        <Typography variant="h6" component="span">
                          {notificationDomain.i18n.translate(`notificationEvents.${notification.event}`)}
                        </Typography>
                        <Typography>{notification.action}</Typography>
                      </Box>
                      {!notification.isImportant ? (
                        <IconButton
                          aria-label="important"
                          size="medium"
                          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                          onClick={() => {
                            notificationDomain.stared(notification);
                            notification.isImportant = !notification.isImportant;
                          }}
                        >
                          <StarBorderIcon fontSize="inherit" color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="important"
                          size="medium"
                          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                          onClick={() => {
                            notificationDomain.stared(notification);
                            notification.isImportant = !notification.isImportant;
                          }}
                        >
                          <StarIcon
                            sx={{
                              color: '#90CAF9',
                            }}
                            fontSize="inherit"
                            color="primary"
                          />
                        </IconButton>
                      )}
                    </Box>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      ) : (!layoutDomain.notificationDomain.isLoading.value ? (
        <Typography className={classes.emptyContainer}>
          <Typography>
            <img src='/pictures/emptyNotification.svg'></img>
          </Typography>

          <Typography variant='h5' className={classes.headlineText}>
            У вас пока нет уведомлений
          </Typography>

          <Typography className={classes.mainText}>
            Тут будут уведомления о новых проектах, системах и прочих событиях, которые настроены для системной роли
          </Typography>
        </Typography>
      ) : <> </>
      )}
    </>
  );
});
