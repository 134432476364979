import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';

import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';

export const Chapter = observer(({ data }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <Typography sx={{ marginRight: 'auto' }}>Название главы</Typography>
      </Box>
      <FormTextFieldStandard
        variant = 'outlined'
        multiline
        label={'Заголовок'}
        value={data.title}
        onChange={(event) => {
          data.title = event;
        }}
      />
    </Box>
  );
});
