import { Download } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Menu, MenuItem, Tab, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { inject } from '../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../service/route/IRouterService';
import { ApplicationTheme } from './application/theme/ApplicationTheme';
import { IAdminPageProperties } from './view/admin/page/IAdminPageProperties';
import { UnitDomain } from './view/admin/page/unit/store/UnitDomain';
import { UnitListAdminPage } from './view/admin/page/unitListPage/UnitListAdminPage';
import { UserRating } from './view/admin/page/usersRating/UserRating';
import { Colors } from './view/design/colors/Colors';
import { LearningReportDomain } from './view/user/projectList/domain/LearningReportDomain';

export const LearningAdminPage = observer(({ layoutDomain }: IAdminPageProperties) => {
  const router = inject<IRouterService>(RouterServiceToken);

  const routes = ['/learning/admin', '/learning/admin/analytics'];
  const [unitDomain] = useState<UnitDomain>(new UnitDomain(layoutDomain));
  const [tabValue, setTabValue] = useState(router.getActiveUrl());
  const [reportDomain] = useState<LearningReportDomain>(new LearningReportDomain(layoutDomain));
  const history = useHistory();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    history.push(newValue);
  };

  const classes = useStyles();

  useEffect(() => {
    reportDomain.boot();
  }, [layoutDomain.ui.activeUser.entity.id]);

  return (
    <ApplicationTheme>
      <Typography variant="h4" sx={{ paddingLeft: '16px' }}>
        Знания
      </Typography>
      <Box sx={{ width: '100%', typography: 'body1', height: '100%' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <TabList onChange={handleChangeTab} classes={{ indicator: classes.indicator }}>
              <Tab sx={{ textTransform: 'capitalize' }} label="Карта компетенций" value={routes[0]} />
              <Tab sx={{ textTransform: 'capitalize' }} label="Рейтинг участников" value={routes[1]} />
            </TabList>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Box>
                {' '}
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  color="secondary"
                  variant="contained"
                  startIcon={<Download />}
                  onClick={(event) => reportDomain.handleOpen(event)}
                >
                  Выгрузить
                </Button>
                <Menu
                  id="context-menu"
                  anchorEl={reportDomain.anchorEl.value}
                  keepMounted
                  open={Boolean(reportDomain.anchorEl.value)}
                  onClose={reportDomain.handleClose}
                >
                  <MenuItem onClick={reportDomain.handleReportDocx}>Отчет по обучению (docx)</MenuItem>
                  <MenuItem onClick={reportDomain.handleReportPdf}>Отчет по обучению (pdf)</MenuItem>
                </Menu>
              </Box>
              <Box>
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    unitDomain.createUnit().then((unitId) => {
                      router.goTo(`/learning/admin/create/${unitId}`);
                    });
                  }}
                >
                  Новый Юнит
                </Button>
              </Box>
            </Box>
          </Box>
          <TabPanel value={routes[0]} className={classes.tabPanelPassing}>
            <UnitListAdminPage layoutDomain={layoutDomain} />
          </TabPanel>
          <TabPanel value={routes[1]} className={classes.tabPanelRating}>
            <UserRating layoutDomain={layoutDomain} />
          </TabPanel>
        </TabContext>
      </Box>
    </ApplicationTheme>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: Colors.selectedTabs('1'),
  },
  tabPanelPassing: {
    padding: '0px',
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
  tabPanelRating: {
    padding: '0px',
    height: '100%',
    '& > div': {
      height: 'calc(100% - 150px)',
    },
  },
}));
