import { ArrowDownward, ArrowUpward, DeleteForever, VisibilityOff } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { IList } from '../../../../../../service/unit/interface/theoryBlocks/IList';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';

export interface ListProperties {
  data: ITheoryBlock<IList>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}

export const ListComponent = observer(({ data: { data, pseudoId }, onDelete, onUp, onDown }: ListProperties) => {
  return (
    <Box
      key={pseudoId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
        '&:hover .action': { visibility: 'visible' },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'flex-end' }}>
        <Typography sx={{ marginRight: 'auto' }}>Список</Typography>
        <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
          <DeleteForever
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDelete();
            }}
          />
          <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {}} />
          <ArrowUpward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onUp();
            }}
          />
          <ArrowDownward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDown();
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap:'8px', marginBottom: '16px' }}>
        <Button
          variant="outlined"
          onClick={() => (data.type = 'Numbered')}
          sx={{
            background: data.type === 'Numbered' ? 'rgba(25, 118, 210, 0.12)' : '',
          }}
        >
          Нумерованный
        </Button>
        <Button
          variant="outlined"
          onClick={() => (data.type = 'Bulleted')}
          sx={{
            background: data.type === 'Bulleted' ? 'rgba(25, 118, 210, 0.12)' : '',
          }}
        >
          Маркерованный
        </Button>
      </Box>
      <TextField
        sx={{ marginBottom: '16px' }}
        variant="outlined"
        type="number"
        defaultValue={data.elementCount}
        InputProps={{ inputProps: { min: 0 } }}
        onChange={(event) => {
          if (+event.target.value > data.elementCount) {
            data.elementCount = +event.target.value;
            data.listData.push({ text: '' });
          } else {
            data.elementCount = +event.target.value;
            data.listData.pop();
          }
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {data.listData.map((data) => {
          return (
            <FormTextFieldStandard
              variant="outlined"
              multiline
              label={'Текст'}
              value={data.text}
              onChange={(value) => {
                data.text = value;
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
});
