import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router';

import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { LearningRoutes } from '../../../../constants/LearningRoutes';
import { UnitHeader } from '../../../shared/components/UnitHeader';
import { LearningUnitPreviewFooter } from './components/LearningUnitPreviewFooter';
import { PreviewFinalResults, PreviewPractice, PreviewQuiz, PreviewTheory } from './components/LearningUnitPreviewMain';
import { LearningUnitPreviewSidebar } from './components/LearningUnitPreviewSidebar';
import { LearningUnitPreviewPageContext } from './store/LearningUnitPreviewPageContext';
import { ILearningUnitPreviewPageDomain, LearningUnitPreviewPageDomain } from './store/LearningUnitPreviewPageDomain';

const STEPS = {
  theory: <PreviewTheory />,
  quiz: <PreviewQuiz />,
  practice: <PreviewPractice />,
  finalResults: <PreviewFinalResults />,
};

export const LearningUnitPreviewPage = observer(({ layoutDomain }: { layoutDomain: IMainLayoutDomainStore }) => {
  const [unitPreviewDomain] = useState<ILearningUnitPreviewPageDomain>(new LearningUnitPreviewPageDomain(layoutDomain));
  const [showSidebar, setShowSidebar] = useState(true);
  const { unitId: id } = useParams<{ unitId: string }>();
  const currentStep = unitPreviewDomain?.ui?.currentStep.value as string;
  const classes = useStyles({ currentStep });
  const unit = unitPreviewDomain?.ui.unit.entity;

  useEffect(() => {
    unitPreviewDomain.loadData(id);
  }, []);

  return (
    <LearningUnitPreviewPageContext.Provider value={{ unitPreviewDomain, showSidebar, setShowSidebar }}>
      <UnitHeader
        classes={classes.header}
        title={unit?.settings?.titleHeading}
        backLink={generatePath(LearningRoutes.LearningUnitEdit, { unitId: String(unit?.id) })}
        backButtonTitle="Редактировать"
      />
      <div className={classes.container}>
        <main className={classes.main}>
          <div className={classes.mainContent}>{STEPS[currentStep]}</div>
          <LearningUnitPreviewFooter />
        </main>
        {showSidebar && (
          <nav className={classes.sidebar}>
            <LearningUnitPreviewSidebar />
          </nav>
        )}
      </div>
    </LearningUnitPreviewPageContext.Provider>
  );
});
interface StyleProps {
  currentStep: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    height: 'calc(100vh - 70px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: { flexDirection: 'row' },
  },
  main: {
    flex: '1 0',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    borderRight: 'none',
    [theme.breakpoints.up('md')]: { borderRight: '1px solid rgba(255, 255, 255, 0.12)' },
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'center',
    height: (props) => (props.currentStep === 'practice' ? '100%' : 'auto'),
    '& > div > a > .MuiBox-root ': {
      overflowY: 'hidden',
      height: 'auto',
    },
  },
  sidebar: {
    overflowY: 'auto',
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    padding: '16px',
    [theme.breakpoints.up('md')]: { width: '372px' },
  },
  header: {
    paddingTop: '16px',
  },
}));
