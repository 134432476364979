import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { inject } from '../../common/container/inject';
import { ApplicationTheme } from './application/theme/ApplicationTheme';
import { IRouterService, RouterServiceToken } from './service/route/IRouterService';
import { PassingListPage } from './view/admin/page/passingListPage/PassingListPage';
import { UserRating } from './view/admin/page/usersRating/UserRating';
import { Colors } from './view/design/colors/Colors';

export const LearningUserPage = observer(({ layoutDomain }) => {
  const routes = [`/learning/user`, `/learning/user/analytics`];
  const router = inject<IRouterService>(RouterServiceToken);
  const [tabValue, setTabValue] = useState(router.getActiveUrl());
  const history = useHistory();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    history.push(newValue);
  };
  const classes = useStyles();

  return (
    <ApplicationTheme>
      <Typography variant="h4" sx={{ paddingLeft: '16px' }}>
        Знания
      </Typography>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <TabList onChange={handleChangeTab} classes={{ indicator: classes.indicator }}>
              <Tab sx={{ textTransform: 'capitalize' }} label="Карта компетенций" value={routes[0]} />
              <Tab sx={{ textTransform: 'capitalize' }} label="Рейтинг участников" value={routes[1]} />
            </TabList>
          </Box>
          <TabPanel value={routes[0]} className={classes.tabPanelPassing}>
            <PassingListPage layoutDomain={layoutDomain} />
          </TabPanel>
          <TabPanel value={routes[1]} className={classes.tabPanelRating}>
            <UserRating layoutDomain={layoutDomain} />
          </TabPanel>
        </TabContext>
      </Box>
    </ApplicationTheme>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: Colors.selectedTabs('1'),
  },
  tabPanelPassing: {
    padding: '0px',
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
  tabPanelRating: {
    padding: '0px',
    height: '100%',
    '& > div': {
      height: 'calc(100vh - 175px)',
    },
  },
}));
