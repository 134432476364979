import { Service } from '../common/store/base/Service';
import { IService } from '../common/store/interface/IService';
import { IActiveDirectoryServerEntityService } from './activeDirectory/server/IActiveDirectoryServerEntityService';
import { RestActiveDirectoryServerEntityService } from './activeDirectory/server/RestActiveDirectoryServerEntityService';
import { IAdditionalColumnContentService } from './additionalColumn/content/IAdditionalColumnContentService';
import { RestAdditionalColumnContentService } from './additionalColumn/content/RestAdditionalColumnContentService';
import { IAdditionalColumnService } from './additionalColumn/entity/IAdditionalColumnService';
import { RestAdditionalColumnService } from './additionalColumn/entity/RestAdditionalColumnService';
import { IAdditionalColumnTypeService } from './additionalColumn/type/IAdditionalColumnTypeService';
import { RestAdditionalColumnTypeService } from './additionalColumn/type/RestAdditionalColumnTypeService';
import { AnalyticsDataService } from './analytics/data/AnalyticsDataService';
import { IApplicationAffectService } from './application/affect/IApplicationAffectService';
import { RestApplicationAffectService } from './application/affect/RestApplicationAffectService';
import { IApplicationDataService } from './application/data/IApplicationDataService';
import { RestApplicationDataService } from './application/data/RestApplicationDataService';
import { IApplicationService } from './application/entity/IApplicationService';
import { RestApplicationService } from './application/entity/RestApplicationService';
import { IApplicationJiraService } from './application/jira/IApplicationJiraService';
import { RestApplicationJiraService } from './application/jira/RestApplicationJiraService';
import { IApplicationTemplateService } from './application/template/IApplicationTemplateService';
import { RestApplicationTemplateService } from './application/template/RestApplicationTemplateService';
import { CRUDServiceType } from './common/service/CRUDServiceType';
import { IFileStoreService } from './fileStore/IFileStoreService';
import { RestFileStoreService } from './fileStore/RestFileStoreService';
import { IMailEntityService } from './mail/IMailEntityService';
import { RestMailEntityService } from './mail/RestMailEntityService';
import { INotificationService } from './notification/INotificationService';
import { RestNotificationService } from './notification/RestNotificationService';
import { IPerformerTypeService } from './performer/type/IPerformerTypeService';
import { RestPerformerTypeService } from './performer/type/RestPerformerTypeService';
import { IProjectService } from './project/IProjectService';
import { RestProjectService } from './project/RestProjectService';
import { IUserProjectRoleService } from './projectRole/entity/IUserProjectRoleService';
import { RestUserProjectRoleService } from './projectRole/entity/RestUserProjectRoleService';
import { IReportEntityService } from './reports/IReportEntityService';
import { RestReportEntityService } from './reports/RestReportEntityService';
import { IRequirementCategoryService } from './requirement/category/IRequirementCategoryService';
import { RestRequirementCategoryService } from './requirement/category/RestRequirementCategoryService';
import { IRequirementService } from './requirement/entity/IRequirementService';
import { RestRequirementService } from './requirement/entity/RestRequirementService';
import { IVendorRequirementService } from './requirement/vendor/IVendorRequirementService';
import { RestVendorRequirementService } from './requirement/vendor/RestVendorRequirementService';
import { ISpecificationCategoryService } from './specification/category/ISpecificationCategoryService';
import { RestSpecificationCategoryService } from './specification/category/RestSpecificationCategoryService';
import { ISpecificationEntityService } from './specification/entity/ISpecificationEntityService';
import { RestSpecificationEntityService } from './specification/entity/RestSpecificationEntityService';
import { IStatusEntityService } from './status/entity/IStatusEntityService';
import { RestStatusEntityService } from './status/entity/RestStatusEntityService';
import { IStatusValueService } from './status/value/IStatusValueService';
import { RestStatusValueService } from './status/value/RestStatusValueService';
import { IUserSystemRoleService } from './systemRole/entity/IUserSystemRoleService';
import { RestUserSystemRoleService } from './systemRole/entity/RestUserSystemRoleService';
import { ITagCategoryService } from './tag/category/ITagCategoryService';
import { RestTagCategoryService } from './tag/category/RestSpecificationCategoryService';
import { ITagEntityService } from './tag/entity/ITagEntityService';
import { RestTagEntityService } from './tag/entity/RestTagEntityService';
import { ITaskManagerServerService } from './taskManager/server/ITaskManagerServerService';
import { RestTaskManagerServerService } from './taskManager/server/RestTaskManagerServerService';
import { IUserEntityService } from './user/IUserEntityService';
import { RestUserEntityService } from './user/RestUserEntityService';
import { IVendorEntityService } from './vendor/entity/IVendorEntityService';
import { RestVendorEntityService } from './vendor/entity/RestStatusEntityService';

export interface IRootAdminService extends IService {
  activeDirectory: {
    server: IActiveDirectoryServerEntityService;
  };
  specification: {
    category: ISpecificationCategoryService;
    entity: ISpecificationEntityService;
  };
  tag: {
    category: ITagCategoryService;
    entity: ITagEntityService;
  };
  status: {
    entity: IStatusEntityService;
    value: IStatusValueService;
  };
  requirement: {
    category: IRequirementCategoryService;
    entity: IRequirementService;
    vendor: IVendorRequirementService;
  };
  performer: {
    type: IPerformerTypeService;
  };
  additionalColumn: {
    type: IAdditionalColumnTypeService;
    entity: IAdditionalColumnService;
    content: IAdditionalColumnContentService;
  };
  user: {
    entity: IUserEntityService;
  };
  project: {
    entity: IProjectService;
  };
  application: {
    entity: IApplicationService;
    data: IApplicationDataService;
    template: IApplicationTemplateService;
    jira: IApplicationJiraService;
    affect: IApplicationAffectService;
  };
  vendor: {
    entity: IVendorEntityService;
  };
  taskManager: {
    server: ITaskManagerServerService;
  };
  projectRole: {
    entity: IUserProjectRoleService;
  };
  systemRole: {
    entity: IUserSystemRoleService;
  };
  notification: INotificationService;
  mail: {
    entity: IMailEntityService;
  };
  reports: IReportEntityService;
  fileStore: IFileStoreService;
  analytics: {
    data: AnalyticsDataService
  }
}

export const RootAdminServiceToken = Symbol.for('RootAdminServiceToken');
const type = CRUDServiceType.admin;

export class RootAdminService extends Service implements IRootAdminService {
  constructor(
    public activeDirectory = {
      server: new RestActiveDirectoryServerEntityService(type),
    },
    public specification = {
      category: new RestSpecificationCategoryService(type),
      entity: new RestSpecificationEntityService(type),
    },
    public tag = {
      category: new RestTagCategoryService(type),
      entity: new RestTagEntityService(type),
    },
    public status = {
      entity: new RestStatusEntityService(type),
      value: new RestStatusValueService(type),
    },
    public requirement = {
      category: new RestRequirementCategoryService(type),
      entity: new RestRequirementService(type),
      vendor: new RestVendorRequirementService(type),
    },
    public additionalColumn = {
      type: new RestAdditionalColumnTypeService(type),
      entity: new RestAdditionalColumnService(type),
      content: new RestAdditionalColumnContentService(type),
    },
    public performer = {
      type: new RestPerformerTypeService(type),
    },
    public user = {
      entity: new RestUserEntityService(type),
    },
    public project = {
      entity: new RestProjectService(type),
    },
    public application = {
      data: new RestApplicationDataService(type),
      entity: new RestApplicationService(type),
      template: new RestApplicationTemplateService(type),
      jira: new RestApplicationJiraService(type),
      affect: new RestApplicationAffectService(type),
    },
    public vendor = {
      entity: new RestVendorEntityService(type),
    },
    public taskManager = {
      server: new RestTaskManagerServerService(type),
    },
    public projectRole = {
      entity: new RestUserProjectRoleService(type),
    },
    public systemRole = {
      entity: new RestUserSystemRoleService(type),
    },
    public notification = new RestNotificationService(type),
    public mail = {
      entity: new RestMailEntityService(type),
    },
    public reports = new RestReportEntityService(type),
    public fileStore = new RestFileStoreService(type),
    public analytics = {
      data: new AnalyticsDataService(type),
    },
  ) {
    super();
  }
}
