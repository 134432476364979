import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

interface Props {
  finalResultsVerbose: {
    title: string;
    subtitle: string;
  };
  maximumScore?: number;
  correctQuizAnswersCount?: number;
  correctPracticeAnswersCount?: number;
  correctQuizMaximumScore?: number;
  correctPracticeMaximumScore?: number;
  totalUnitResult: number;
}

export const FinalResultsVerbose = observer(
  ({
    finalResultsVerbose,
    maximumScore,
    correctQuizAnswersCount,
    correctPracticeAnswersCount,
    correctQuizMaximumScore,
    correctPracticeMaximumScore,
    totalUnitResult,
  }: Props) => {
    const classes = useStyles();

    return (
      <div className={classes.resultsVerboseContainer}>
        <Typography className={classes.resultsVerboseTitle} variant={'h3'}>
          {finalResultsVerbose?.title}
        </Typography>
        <Typography variant={'h5'}>
          Правильных ответов на квиз: {correctQuizAnswersCount} из {correctQuizMaximumScore}
        </Typography>
        <Typography variant={'h5'}>
          Выполненных заданий: {correctPracticeAnswersCount} из {correctPracticeMaximumScore}
        </Typography>
        <Typography variant={'h5'}>
          Общий счет: {Math.round(totalUnitResult)} из {maximumScore}
        </Typography>
        <p>{finalResultsVerbose?.subtitle}</p>
      </div>
    );
  },
);

const useStyles = makeStyles(() => ({
  resultsVerboseContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  resultsVerboseTitle: {
    marginBottom: '16px',
  },
}));
