import { CheckOutlined, MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitListAdminCard } from '../store/IUnitListAdminCard';

export interface IUnitListAdminCardProperties {
  card: IUnitListAdminCard;
  handleEdit: any;
  handlePublish: any;
  handleUnpublish: any;
  handleDelete: any;
}
export const UnitListItem = observer(
  ({ card, handleEdit, handleDelete, handlePublish, handleUnpublish }: IUnitListAdminCardProperties) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
      event.stopPropagation();

      setAnchorEl(null);
    };

    return (
      <Box
        sx={{
          cursor: 'pointer',
          padding: '8px 10px 8px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          background: 'rgba(255, 255, 255, 0.12)',
          alignItems: 'center',
          width: '100%',
          borderRadius: '8px',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.18)',
          },
        }}
        onClick={() => handleEdit(card.id)}
      >
        <Typography sx={{ cursor: 'pointer' }}>{card.name}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {card.isPublished && <CheckOutlined sx={{ color: 'rgba(255,255,255,0.56)' }} />}
          <IconButton
            sx={{ color: 'rgba(255,255,255,0.56)' }}
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event);
            }}
          >
            <MoreVert />
          </IconButton>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={(event) => handleClose(event)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {card.isPublished && (
            <MenuItem
              onClick={async (event) => {
                handleClose(event);
                await handleUnpublish(card.id);
              }}
            >
              Снять с публикации
            </MenuItem>
          )}
          {!card.isPublished && (
            <MenuItem
              onClick={async (event) => {
                handleClose(event);
                await handlePublish(card.id);
              }}
            >
              Опубликовать
            </MenuItem>
          )}
          <MenuItem
            onClick={(event) => {
              handleClose(event);
              handleEdit(card.id);
            }}
          >
            Редактировать
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              handleClose(event);
              handleDelete(card.id);
            }}
          >
            Удалить
          </MenuItem>
        </Menu>
      </Box>
    );
  },
);
