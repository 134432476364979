import React from 'react';

import { RequirementEntityDrawerDomain } from '../RequirementEntityDrawerDomain';
import { Grid, Container, FormControl, Theme } from '@mui/material';
import { observer } from 'mobx-react';
import { FormAutocomplete } from '../../../../../../../design/form/FormAutocomplete';
import { FormTextField } from '../../../../../../../design/form/FormTextField';
import { FormTextLabel } from '../../../../../../../design/form/FormTextLabel';
import { SpecificationFormSelection } from '../../../../../specification/parts/formSelection/SpecificationFormSelection';
import { TagFormSelection } from '../../../../../tag/parts/formSelection/TagFormSelection';
import { FormMultiAutocomplete } from '../../../../../../../design/form/FormMultiAutocomplete';
import { RequirementAdditionalColumnEdit } from './EditMode/AdditionalColumnsEditV2';
import { VerticalStepper } from './EditMode/Stepper';
import { stepKey, steps } from './EditMode/Step';
import { useContext } from 'react';
import { ContainerContext } from '../../../../../../../../common/container/ContainerContext';
import { makeStyles } from '@mui/styles';
import { RichTextEditor } from '../../../../../../../design/RichTextEditor/RichTextEditor'

export const RequirementEdit = ({ domain, requirementFormExtensionComponents }: { domain: RequirementEntityDrawerDomain, requirementFormExtensionComponents: any[] }) => {
  const container = useContext(ContainerContext);
  const customSteps = steps;

  const RequirementPerformersCustomFieldExtended = container.get(RequirementPerformersCustomField);
  const RequirementAuthorFieldExtended = container.get(RequirementAuthorField);

  customSteps[stepKey.info].content = (
    <>
      <RequirementCategoryCustomFieldText domain={domain} />
      <RequirementShortNameCustomFieldText domain={domain} />
      <RequirementDescriptionCustomFieldText domain={domain} />
      <RequirementOrderCustomFieldNumber domain={domain} />
      <RequirementPerformersCustomFieldExtended domain={domain} />
      <RequirementAuthorFieldExtended domain={domain} />
      {requirementFormExtensionComponents.map((FormExtensionComponent) => (
        <FormExtensionComponent isDrawer={true} domain={domain} />
      ))}
    </>
  );
  customSteps[stepKey.specifications].content = (<>
    <RequirementSpecificationCustomField domain={domain} />
  </>
  );

  customSteps[stepKey.tags].content = (<>
    <RequirementTagCustomField domain={domain} />
  </>);

  customSteps[stepKey.vendor].content = (<>
    <RequirementVendorCustomFieldText domain={domain} />
  </>);

  customSteps[stepKey.additionalColumns].content = (<>
    <RequirementAdditionalColumnEdit domain={domain} />
  </>);


  return (
    <Grid>
      <Container>
        <VerticalStepper steps={steps} />
      </Container>
    </Grid>
  );
};

const RequirementCategoryCustomFieldText = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const categoryValues = [
    ...domain.ui.categories.list.map((category) => ({
      value: category.id,
      name: `${category.name}  ${category.isActive ? '' : '(не активно)'}`,
    })),
  ];

  return (
    <FormAutocomplete
      errorMessage={domain.getValidationErrorFor('category')?.message}
      label='Категория'
      selected={domain.ui.model.entity?.categoryId}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.categoryId = newValue?.value;
        domain.generateShortName();
      }}
      required={true}
      values={categoryValues}
      variant={'standard'}
    />
  );
});

export const RequirementAuthorField = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const usersValues = [
    { value: null, name: 'Без автора' },
    ...domain.ui.users.list.map((user) => ({
      value: user.id,
      name: `${user.displayName}${user.isActive ? '' : ' (не активен)'}`,
    })),
  ];

  return (
    <FormAutocomplete
      errorMessage={domain.getValidationErrorFor('category')?.message}
      label='Автор'
      selected={domain.ui.model.entity?.authorId}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.authorId = newValue?.value;
      }}
      variant={'standard'}
      required={true}
      values={usersValues}
    />
  );
});

const RequirementShortNameCustomFieldText = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  return (
    <FormTextField
      errorMessage={domain.getValidationErrorFor('shortName')?.message}
      value={domain.ui.model.entity?.shortName}
      onChange={(newValue: any) => {
        domain.ui.model.entity.shortName = newValue;
      }}
      label='Короткое название'
      disabled={!domain.ui.model.entity?.categoryId}
      required={true}
      variant={'standard'}
    />
  );
});

const RequirementDescriptionCustomFieldText = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
      padding: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const lableColor = !domain.ui.model.entity?.categoryId ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.7)'
  const handleChange = (styledText, cleatText) => {
    const changeFuncStyled = domain.getUpdateFieldHandler('descriptionWithStyles')
    changeFuncStyled(styledText);
    const changeFunc = domain.getUpdateFieldHandler('description')
    changeFunc(cleatText);
  }
  const defaultDataForInput = domain.ui.model.entity?.descriptionWithStyles ?? domain.ui.model.entity?.description

  return (
    <FormControl fullWidth className={classes.formControl} variant="outlined">
      <div style={{ color: lableColor, paddingBottom: 2 }}>
        Описание*
      </div>
      <RichTextEditor
        defaultInputValue={defaultDataForInput || ''}
        changeHandler={handleChange}
        minHeight='300px'
        disabled={!domain.ui.model.entity?.categoryId}
        error={domain.getValidationErrorFor('description')?.message}
      />
    </FormControl>
  );
});

const RequirementOrderCustomFieldNumber = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  return (
    <FormTextField
      type='text'
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
      value={domain.ui.model.entity?.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      label='Порядок отображения'
      disabled={!domain.ui.model.entity?.categoryId}
      variant={'standard'}
    />
  );
});

const RequirementSpecificationCustomField = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  return (
    <>
      <SpecificationFormSelection
        isShowAllConditionCategories={true}
        onChange={(ids) => {
          domain.ui.model.entity.specificationsIds = ids;
        }}
        selectedSpecificationsIds={domain.ui.model.entity?.specificationsIds || []}
        specificationCategories={domain.ui.specificationsCategories.list}
        specifications={domain.ui.specifications.list}
        disabled={!domain.ui.model.entity?.categoryId}
      />
    </>
  );
});

const RequirementTagCustomField = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  return (
    <>
      <FormTextLabel text={'Тэги'} />
      <TagFormSelection
        onChange={(ids) => {
          domain.ui.model.entity.tagsIds = ids;
        }}
        selectedTagsIds={domain.ui.model.entity?.tagsIds || []}
        tagCategories={domain.ui.tagCategory.list}
        tags={domain.ui.tags.list}
        disabled={!domain.ui.model.entity?.categoryId}
      />
    </>
  );
});

export const RequirementPerformersCustomField = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const performersTypes = [
    ...domain.ui.performers.list.map((performer) => ({
      value: performer.id,
      name: performer.name,
    })),
  ];
  return (
    <FormMultiAutocomplete
      values={performersTypes}
      onChangeSelect={(options) => {
        domain.ui.model.entity.performersTypesIds = options.map((newValue: any) => {
          return newValue?.value;
        });
      }}
      key='performersTypesIds'
      selected={domain.ui.model.entity?.performersTypesIds}
      label='Тип исполнителей'
      disabled={!domain.ui.model.entity?.categoryId}
    />
  );
});

const RequirementVendorCustomFieldText = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const getVendorContent = (vendorId) => {
    return domain.ui.vendorsRequirements.list
      .filter((filterItem) => filterItem.vendorId === vendorId)
      .map((mapItem) => ({
        value: mapItem.id,
        name: mapItem.shortName,
        vendorId: mapItem.vendorId,
      }))
  }

  return (
    <>
      {domain.ui.vendors.list.map(vendor => (
        <FormMultiAutocomplete
          errorMessage={domain.getValidationErrorFor('vendor')?.message}
          label={vendor.name}
          selected={domain.ui.requirementVendorsToSet.list}
          onChangeSelect={(newValue: any, event, reason, details) => {
            if (reason === 'selectOption') {
              domain.ui.requirementVendorsToSet.setList([...domain.ui.requirementVendorsToSet.list, details.option.value]);
            }
            else if (reason === 'removeOption') {
              domain.ui.requirementVendorsToSet.setList(domain.ui.requirementVendorsToSet.list.filter(item => item !== details.option.value));
            } else if (reason === 'clear') {
              const currentCategoryIds = getVendorContent(vendor.id).map((vendor) => vendor.value);
              domain.ui.requirementVendorsToSet.setList(domain.ui.requirementVendorsToSet.list.filter(item => !currentCategoryIds.includes(item)));
            }
          }}
          values={getVendorContent(vendor.id)}
        />))}
    </>
  );
});