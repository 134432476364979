import Add from '@mui/icons-material/Add';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react';
import React from 'react';

import { Colors } from '../color/Colors';
import { useButtonStyles } from './ButtonStyle';

export interface AddButtonProperties {
  className?: string;
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  label?: string;
  dataCy?: string;
}

export const AddButton = observer(({ className = '', label = 'Добавить', onClick, dataCy }: AddButtonProperties) => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();
  className = className || `${buttonClasses.button} ${classes.add}`;
  return (
    <Button data-cy={dataCy} className={className} variant="outlined" startIcon={<Add />} onClick={onClick} color="success">
      {label}
    </Button>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  add: {
    backgroundColor: `${Colors.greenButtonOpacity('0.7')} `,
    marginBottom: '20px',
    marginRight: '10px',
  },
}));
