import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { ILocalization } from './ILocalization';

export class Translation implements ILocalization {
  constructor(public i18n) {} // public i18n = ,

  translate = (key: string): string => {
    return this.i18n.t(key);
  };

  changeLanguage = (language: string) => {
    this.i18n.changeLanguage(language);
  };
}

export const createTranslation = async () => {
  return new Promise((resolve) => {
    i18next
      .use(Backend)
      .use(LanguageDetector)
      // .use(initReactI18next)
      .init({
        fallbackLng: ['ru', 'en'],
        debug: false,
        detection: {
          order: ['queryString', 'cookie'],
          // cache: ['cookie'],
        },
        interpolation: {
          escapeValue: false,
        },
        preload: ['ru', 'en'],
      });

    i18next.on('initialized', () => {
      resolve(new Translation(i18next));
    });
  });
};
