import { injectEntity } from '../../../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../../../common/store/base/injectPrimitive';
import { IMainLayoutDomainStore } from '../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IQuestion, IUnitQuizResult } from '../../../../../../../service/unit/interface/IUnitQuiz';
import { IQuestionsQuizResult, IUnitResultModel } from '../../../../../../../service/unitResult/IUnitResultModel';
import { IPassingQuizUI, IPassingScoreDiff, IPassingStepContent } from './IPassingQuizUI';

export class PassingQuizUI implements IPassingQuizUI {
  constructor(
    public passingQuestionList = injectEntityList<IQuestion>([]),
    public passingAnswersList = injectEntityList<IQuestionsQuizResult>([]),
    public stepperList = injectEntityList<IQuestion>([] as any),
    public activeStep = injectPrimitive<number | any>(0),
    public startStep = injectPrimitive<number>(0),
    public stepContent = injectEntityList<IPassingStepContent>([] as any),
    public stepsTitles = injectEntityList<string>([] as any),
    public completed = injectPrimitive<any>({}),
    public redirectTo = injectPrimitive<string>(''),
    public additionalActions = injectEntityList<any>([]),
    public passageTime = injectEntity<string>(''),
    public results = injectEntity<IUnitQuizResult>({} as any),
    public layoutDomain = injectEntity<IMainLayoutDomainStore>(null as any),
    public passingId = injectPrimitive<string>(''),
    public isExpanded = injectPrimitive<boolean>(false),
    public isEnebled = injectPrimitive<boolean>(false),
    public isCheckResultActive = injectPrimitive<boolean>(false),
    public isEbaleToCheck = injectPrimitive<boolean>(true),
    public isEbaleToRePass = injectPrimitive<boolean>(true),
    public currentUnitResult = injectPrimitive<IUnitResultModel>(null as any),
    public timeLeftToRePass = injectPrimitive<string>(''),
    public isQuizPassed = injectPrimitive<boolean>(false),
    public scoreDiff = injectPrimitive<IPassingScoreDiff>({
      totalQuestionsAmount: 0,
      userCorrectAnswers: 0,
      score: 0,
    }),
  ) {
    this.redirectTo.setValue('Практика');
  }
}
