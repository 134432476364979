import { injectEntity } from '../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../../common/store/interface/IEntityStore';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { generateUUID } from '../../../../../utils/generateUUID';
import { IGroupedUnit } from './IGroupedUnit';
import { IUnitListAdminPageUI } from './IUnitListAdminPageUI';

export class UnitListAdminPageUI implements IUnitListAdminPageUI {
  constructor(
    public unitList = injectEntityList<IUnitModel>([]),

    public characteristic: IEntityListStore<ISpecificationEntityModel> = injectEntityList([]),
    public specificationCategory: IEntityListStore<ISpecificationCategoryModel> = injectEntityList([]),

    public isLoading = injectPrimitive<boolean>(false),

    public groupedUnits: IEntityListStore<IGroupedUnit> = injectEntityList([
      { groupName: 'Без Контекста', unitsList: [] },
    ]),

    public filtredGroupedUnits: IEntityListStore<IGroupedUnit> = injectEntityList([]),

    // TODO: refactore this part of code (isIncluded field)
    public unit: IEntityStore<IUnitModel> = injectEntity({
      id: '',
      createDate: undefined,
      isDeleted: false,
      isActive: true,
      includedIn: [],
      isPublished: false,
      quiz: {
        isEnabled: true,
        passageTime: '0',
        results: {
          success: {
            title: '',
            subtitle: '',
          },
          neutral: {
            title: '',
            subtitle: '',
          },
          failure: {
            title: '',
            subtitle: '',
          },
        },
        questions: [
          {
            name: `Question 1`,
            explanation: '',
            type: 'single',
            randomize: true,
            answers: [],
            sortIndex: 1,
            pseudoId: generateUUID(),
            isCodeExample: false,
            codeExample: [],
          },
        ],
      },
      practice: {
        isEnabled: true,
        passageTime: '',
        results: {
          success: {
            title: '',
            subtitle: '',
          },
          neutral: {
            title: '',
            subtitle: '',
          },
          failure: {
            title: '',
            subtitle: '',
          },
        },
        tasks: [
          {
            name: 'Task 1',
            explanation: '',
            type: 'review',
            languages: [],
            sortIndex: 1,
            pseudoId: generateUUID(),
          },
        ],
      },
      theory: {
        isEnabled: true,
        chapters: [
          {
            title: 'Chapter 1',
            theoryBlocks: [],
            sortIndex: 0,
            pseudoId: generateUUID(),
          },
        ],
      },
      settings: {
        contextId: '',
        characteristicsId: '',
        characteristicsIds: [],
        teamRolesIds: [],
        titleHeading: '',
        titleLead: '',
      },
      lastUpdateDate: undefined,
      score: 0,
    }),
  ) {}
}
