import { ArrowDownward, ArrowUpward, DeleteForever, ExpandMore, VisibilityOff } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';

import { BlockType, ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { ISpecial } from '../../../../../../service/unit/interface/theoryBlocks/ISpecial';
import { generateUUID } from '../../../../../../utils/generateUUID';
import { FormAutocompleteStandard } from '../../../../form/FormAutocompleteStandard';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';
import { ElementFactory } from '../ElementFactory';
import { NestingButton } from './NestingButton';

export interface ISpecialBlock {
  data: ITheoryBlock<ISpecial>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}
export interface IAdditionalElementsList {
  title: string;
  element: ITheoryBlock<any>;
}
export const additionalElementsList: IAdditionalElementsList[] = [
  {
    title: 'Абзац',
    element: {
      block: BlockType.PARAGRAPH,
      data: {
        text: '',
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Абзац',
    },
  },
  {
    title: 'Список',
    element: {
      block: BlockType.LIST,
      data: {
        type: 'Numbered',
        elementCount: 0,
        listData: [],
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Список',
    },
  },
  {
    title: 'Таблица',
    element: {
      block: BlockType.TABLE,
      data: {
        columnsCount: 0,
        rowsCount: 0,
        tableData: {
          headerText: [],
          rows: [],
        },
      },
      sortIndex: 0,
      pseudoId: '',
      parentId: '',
      name: 'Таблица',
    },
  },
];
export const SpecialBlock = observer(({ data: { data, pseudoId }, onDelete, onUp, onDown }: ISpecialBlock) => {
  const addNewElement = (block) => {
    const newBlock: ITheoryBlock<any> & { Element?: any } = JSON.parse(JSON.stringify(block));
    newBlock.pseudoId = generateUUID();
    newBlock.Element = ElementFactory[newBlock.block];
    data.contentBlocks.push(newBlock);
  };
  const onDeleteSubBlock = (block) => {
    const index = data.contentBlocks.indexOf(block);
    if (index !== -1) {
      data.contentBlocks.splice(index, 1);
    }
  };
  const onUpSubBlock = (index: number) => {
    if (index === 0 && data.contentBlocks.length !== 0) {
      const firstEl = data.contentBlocks.shift();
      // @ts-ignore
      data.contentBlocks.push(firstEl);
    } else {
      [data.contentBlocks[index], data.contentBlocks[index - 1]] = [
        data.contentBlocks[index - 1],
        data.contentBlocks[index],
      ];
    }
  };
  const onDownSubBlock = (index: number) => {
    if (index === data.contentBlocks.length - 1 && data.contentBlocks.length !== 0) {
      const lastEl = data.contentBlocks.pop();
      // @ts-ignore
      data.contentBlocks.unshift(lastEl);
    } else {
      [data.contentBlocks[index], data.contentBlocks[index + 1]] = [
        data.contentBlocks[index + 1],
        data.contentBlocks[index],
      ];
    }
  };
  const colors = [
    {
      name: 'Предупреждение',
      value: 'warning',
    },
    {
      name: 'Определение',
      value: 'secondary',
    },
    {
      name: 'Таблица',
      value: 'main',
    },
    {
      name: 'Поощрение',
      value: 'success',
    },
    {
      name: 'Ошибка',
      value: 'error',
    },
    {
      name: 'Информация',
      value: 'info',
    },
  ]
  return (
    <Box
      key={pseudoId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
        '&:hover .action': { visibility: 'visible' },
      }}
    >
      <Accordion sx={{ background: 'transparent', boxShadow: 'none' }} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            display: 'flex',
            '& .MuiAccordionSummary-expandIconWrapper': { order: -1 },
            alignItems: 'flex-start',
            '& .MuiAccordionSummary-content': {
              margin: 0,
              width: '100%',
              flex: 1,
            },
            '.Mui-expanded': {
              margin: '0 !important',
            },
            '&.Mui-expanded': {
              minHeight: '32px',
            },
            padding: 0,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
            <Typography sx={{ marginRight: 'auto' }}>Специальный блок</Typography>
            <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
              <DeleteForever
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onDelete();
                }}
              />
              <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {}} />
              <ArrowUpward
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onUp();
                }}
              />
              <ArrowDownward
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onDown();
                }}
              />
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
            <FormTextFieldStandard
              variant="outlined"
              key={pseudoId}
              multiline
              label={'Заголовок'}
              value={data.name}
              onChange={(newValue) => {
                data.name = newValue;
              }}
            />
            <FormAutocompleteStandard
              size='m'
              variant="outlined"
              values={colors}
              label={'Цвет'}
              selected={data.color}
              onChangeSelect={(newValue) => {
                // @ts-ignore
                data.color = newValue.value;
              }}
            />
          </Box>
          <FormTextFieldStandard
            variant="outlined"
            key={pseudoId}
            label={'Текст'}
            value={data.text}
            multiline={true}
            rows={4}
            onChange={(newValue) => {
              data.text = newValue;
            }}
          />
          <Box className="tab_content" sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {data.contentBlocks.map((block, index) => {
                const Element = ElementFactory[block.block];
                const elementData = { data: block.data, pseudoId: block.pseudoId };
                return (
                  <Box
                    sx={{
                      width: '100%',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      '& > div': {
                        background: 'transparent',
                      },
                    }}
                    key={block.pseudoId}
                  >
                    <Element
                      data={elementData}
                      onDelete={() => onDeleteSubBlock(block)}
                      onUp={() => onUpSubBlock(index)}
                      onDown={() => onDownSubBlock(index)}
                    />
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
              {additionalElementsList.map((button) => {
                return (
                  <NestingButton
                    title={button.title}
                    action={() => {
                      addNewElement(button.element);
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
