import { FormControlLabel, Grid, Paper, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { ContainerContext } from '../../../../../../../common/container/ContainerContext';
import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../../design/form/FormAutocomplete';
import { FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { FormTextLabel } from '../../../../../../design/form/FormTextLabel';
import { SpecificationFormSelection } from '../../../../specification/parts/formSelection/SpecificationFormSelection';
import { TagFormSelection } from '../../../../tag/parts/formSelection/TagFormSelection';
import { RequirementFormExtensionsInject } from './inject/RequirementFormExtensionsInject';
import { RequirementEntityModalDomain } from './RequirementEntityModalDomain';

export interface IRequirementEntityTableProperties {
  domain: RequirementEntityModalDomain;
}

export const RequirementEntityModal = observer(({ domain }: IRequirementEntityTableProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);

  const container = useContext(ContainerContext);

  const requirementFormExtensionComponents = container.get(RequirementFormExtensionsInject);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <Grid sx={{ overflowY: 'scroll', maxHeight: '703px' }}>
        <RequirementCategoryCustomFieldText domain={domain} />
        <RequirementShortNameCustomFieldText domain={domain} />
        <RequirementDescriptionCustomFieldText domain={domain} />
        <RequirementOrderCustomFieldNumber domain={domain} />
        <RequirementSpecificationCustomField domain={domain} />
        <RequirementTagCustomField domain={domain} />
        <RequirementPerformersCustomField domain={domain} />
        <RequirementIsActiveCustomField domain={domain} />
        <RequirementAuthorField domain={domain} />
        {requirementFormExtensionComponents.map((FormExtensionComponent) => (
          <FormExtensionComponent domain={domain} />
        ))}
      </Grid>
      <RequirementFormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

export const RequirementFormActions = ({ onSave, onCancel }: { onSave: any; onCancel: any }) => {
  return (
    <FormActions
      onSave={() => {
        onSave();
      }}
      onCancel={() => onCancel()}
    />
  );
};

const RequirementCategoryCustomFieldText = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  const categoryValues = [
    ...domain.ui.categories.list.map((category) => ({
      value: category.id,
      name: `${category.name}  ${category.isActive ? '' : '(не активно)'}`,
    })),
  ];

  return (
    <FormAutocomplete
      errorMessage={domain.getValidationErrorFor('category')?.message}
      label="Категория"
      selected={domain.ui.model.entity.categoryId}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.categoryId = newValue?.value;
        domain.generateShortName();
      }}
      required={true}
      values={categoryValues}
    />
  );
});

const RequirementAuthorField = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  const usersValues = [
    { value: null, name: 'Без автора' },
    ...domain.ui.users.list.map((user) => ({
      value: user.id,
      name: `${user.displayName}${user.isActive ? '' : ' (не активен)'}`,
    })),
  ];

  useEffect(() => {
    if (!domain.ui.model.entity.id && !domain.ui.model.entity?.authorId) {
      domain.ui.model.entity.authorId = domain.layoutDomain.ui.activeUser.entity.id;
    }
  }, []);

  return (
    <FormAutocomplete
      errorMessage={domain.getValidationErrorFor('category')?.message}
      label="Автор"
      selected={domain.ui.model.entity?.authorId}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.authorId = newValue?.value;
      }}
      required={true}
      values={usersValues}
    />
  );
});

const RequirementShortNameCustomFieldText = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  return (
    <FormTextField
      errorMessage={domain.getValidationErrorFor('shortName')?.message}
      value={domain.ui.model.entity.shortName}
      onChange={(newValue: any) => {
        domain.ui.model.entity.shortName = newValue;
      }}
      label="Короткое название"
      disabled={!domain.ui.model.entity.categoryId}
      required={true}
      dataCy='req-short-name'
    />
  );
});

const RequirementDescriptionCustomFieldText = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  return (
    <FormTextArea
      errorMessage={domain.getValidationErrorFor('description')?.message}
      value={domain.ui.model.entity.description}
      onChange={domain.getUpdateFieldHandler('description')}
      label="Описание"
      disabled={!domain.ui.model.entity.categoryId}
      required={true}
    />
  );
});

const RequirementOrderCustomFieldNumber = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  return (
    <FormTextField
      type="text"
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      label="Порядок отображения"
      disabled={!domain.ui.model.entity.categoryId}
    />
  );
});

const RequirementSpecificationCustomField = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  return (
    <Paper elevation={3} style={{ margin: 10 }}>
      <FormTextLabel text={'Характеристики'} />
      <SpecificationFormSelection
        isShowAllConditionCategories={true}
        onChange={(ids) => {
          domain.ui.model.entity.specificationsIds = ids;
        }}
        selectedSpecificationsIds={domain.ui.model.entity.specificationsIds || []}
        specificationCategories={domain.ui.specificationsCategories.list}
        specifications={domain.ui.specifications.list}
        disabled={!domain.ui.model.entity.categoryId}
      />
    </Paper>
  );
});

const RequirementTagCustomField = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  return (
    <Paper elevation={3} style={{ margin: 10 }}>
      <FormTextLabel text={'Тэги'} />
      <TagFormSelection
        onChange={(ids) => {
          domain.ui.model.entity.tagsIds = ids;
        }}
        selectedTagsIds={domain.ui.model.entity.tagsIds || []}
        tagCategories={domain.ui.tagCategory.list}
        tags={domain.ui.tags.list}
        disabled={!domain.ui.model.entity.categoryId}
      />
    </Paper>
  );
});

const RequirementPerformersCustomField = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  const performersTypes = [
    ...domain.ui.performers.list.map((performer) => ({
      value: performer.id,
      name: performer.name,
    })),
  ];
  return (
    <FormMultiAutocomplete
      values={performersTypes}
      onChangeSelect={(options) => {
        domain.ui.model.entity.performersTypesIds = options.map((newValue: any) => {
          return newValue?.value;
        });
      }}
      key="performersTypesIds"
      selected={domain.ui.model.entity.performersTypesIds}
      label="Тип исполнителей"
      disabled={!domain.ui.model.entity.categoryId}
    />
  );
});

const RequirementIsActiveCustomField = observer(({ domain }: { domain: RequirementEntityModalDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isActive);
  }, [domain.ui.model.entity.isActive]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color="success" checked={isChecked} onChange={handleChange} defaultChecked={true} />}
      label="Статус"
    />
  );
});
