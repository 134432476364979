import { RestService } from '../common/rest/RestService';
import { CRUDServiceType } from '../common/service/CRUDServiceType';
import { IReportEntityService } from './IReportEntityService';

export class RestReportEntityService extends RestService implements IReportEntityService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/reports`;
  }

  async post(url: string, data: any): Promise<any> {
    return this.request().url(url).post(data);
  }

  async getReport(projectName, reportType, reportFormat, applicationId, audit, isNeedColor = false): Promise<any> {
    const url = `${this.getEndpoint()}/${reportType}/${reportFormat}`;

    const doc = await this.post(url, {
      name: projectName || 'Отчёт',
      applicationId,
      audit,
      isNeedColor,
    });

    const blobDoc = await doc.blob();
    return blobDoc;
  }
}
