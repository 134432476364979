import { IContainer } from '../../../../../../../common/container/IContainer';
import { inject } from '../../../../../../../common/container/inject';
import { UserProjectRoleAction } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { UserProjectRoleActionExtension } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleActionExtension';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../../service/RootAdminService';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { FormDomain } from '../../../../form/store/FormDomain';
import { IUserProjectRoleAdminModel } from './UserProjectRoleAdminModel';

export class UserProjectRoleAdminFormDomain extends FormDomain<IUserProjectRoleAdminModel, any> {
  public actionsValues: { name: string; value: string }[] = [];

  constructor(
    layoutDomain: IMainLayoutDomainStore,
    private container: IContainer,
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
  ) {
    super(layoutDomain, rootPrivateServices.projectRole.entity);
    this.setActionsValues();
  }

  async loadData(id: string | null) {
    await super.loadData(id);
  }

  save = async () => {
    this.ui.model.entity.isActive = true;
    await this.removeValidationErrors();
    await super.save();
    if (this.ui.validationErrors.list.length === 0) {
      this.router.goTo('/settings/user/project/role/entity');
    }
  };

  cancelEdit = async () => {
    this.router.goTo('/settings/user/project/role/entity');
  };

  private setActionsValues() {
    const additionalActions = this.container.get(UserProjectRoleActionExtension);
    this.actionsValues = [
      {
        value: UserProjectRoleAction.updateFieldsProject,
        name: 'Редактирование полей проекта',
      },
      {
        value: UserProjectRoleAction.updateTeamProject,
        name: 'Редактирование команды проекта',
      },
      {
        value: UserProjectRoleAction.creteApplication,
        name: 'Создание новых анкет',
      },
      {
        value: UserProjectRoleAction.editApplication,
        name: 'Редактирование анкет',
      },
      {
        value: UserProjectRoleAction.reportsAccess,
        name: 'Формирование отчётов',
      },
      {
        value: UserProjectRoleAction.customRequirementsAccess,
        name: 'Создание требований',
      },
      {
        value: UserProjectRoleAction.acceptCancelRequirementAccess,
        name: 'Принятие и исключение требований',
      },
      {
        value: UserProjectRoleAction.newMessagesNotification,
        name: 'Может получать уведомления о новых комментариях',
      },
      {
        value: UserProjectRoleAction.showUnverifiedRequirements,
        name: 'Просмотр неверифицированных требований',
      },
      {
        value: UserProjectRoleAction.showRegulators,
        name: 'Просмотр требований внешних регуляторов',
      },
      {
        value: UserProjectRoleAction.jiraIntegration,
        name: 'Возможность интегрировать АС с Jira',
      },
      ...additionalActions,
    ];
  }
}
