import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { ContainerContext } from '../../../../../../common/container/ContainerContext';
import { PageEmptyContent } from '../../../../../design/page/PageEmptyContent';
import { ProjectTeamSpaceExtensions } from '../inject/ProjectTeamSpaceExtensions';
import { IProjectTeamMemberView } from '../store/model/IProjectTeamView';
import { ProjectInfoDomain } from '../store/ProjectInfoDomain';

export interface IProjectTeamTableProperties {
  domain: ProjectInfoDomain;
  team: IProjectTeamMemberView[];
  projectId: string;
  isEmptyApplications: boolean;
}

export const ProjectTeamTable = observer(
  ({ domain, team, projectId, isEmptyApplications }: IProjectTeamTableProperties) => {
    const container = useContext(ContainerContext);
    const extensions = container.get(ProjectTeamSpaceExtensions);
    const classes = useStyles();
    const teamContent = domain.ui.teamTableRowsContent.list.find((item) => item.projectId === projectId);

    return (
      <Grid item>
        <Grid container direction={'column'} spacing={2}>
          {(teamContent?.data?.length || 0) > 0 && (
            <Grid item xs={5}>
              <Typography variant="h5">Команда</Typography>
              <Divider className={classes.textDivider} />
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {domain.ui.teamTableRowsHeaders.list.map((header) => (
                        <TableCell>{header.title}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teamContent?.data.map((content) => {
                      return (
                        <TableRow key={`${content.memberId}-${teamContent.projectId}`}>
                          {content.data.map((dataItem) => (
                            <TableCell style={{ width: dataItem.width }}>{dataItem.content}</TableCell>
                          ))}
                          {/* <TableCell>{member.fullName}</TableCell>
                  <TableCell style={{ width: 40 }}></TableCell>
                  <TableCell>{member.roleName}</TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {!isEmptyApplications && (teamContent?.data?.length || 0) === 0 && (
            <Grid item={true} xs={2}>
              <PageEmptyContent text="У проекта нет команды" isFullPage={false} />
            </Grid>
          )}

          {extensions.map((ExtensionComponent) => {
            return (
              <Grid item xs={5}>
                <ExtensionComponent domain={domain} projectId={projectId} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  textDivider: {
    width: 100,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
