import { IContainer } from '../common/container/IContainer';
import { IExtension } from './interface/IExtension';

export const loadExtension = async (extensionsNames: string[], container: IContainer) => {
  extensionsNames = extensionsNames ? [...extensionsNames, 'logger'] : ['logger'];
  return extensionsNames
    .map((extensionName) => {
      try {
        const extensionType = require(`./${extensionName}/index`).default;
        if (extensionType) {
          const extension: IExtension = new extensionType();
          extension.loadExtension(container);
        }
        return null;
      } catch (error) {}
    })
    .filter((extension) => extension !== null);
};
