import { ICoreServiceModel } from '../coreCommon/common/model/ICoreServiceModel';

export interface INotificationModel extends ICoreServiceModel {
  event: NotificationEvent;
  action: string;
  isImportant?: boolean;
  isRead?: boolean;
  data: any;
  notificationType: NotificationType;
  targetType: TargetType;
  targetId: string;
}

export enum NotificationEvent {
  PROJECT = 'PROJECT',
  APPLICATION = 'APPLICATION',
  JIRA = 'JIRA',
  REQUIREMENT = 'REQUIREMENT',
  REQUIREMENT_TEMPLATE = 'REQUIREMENT_TEMPLATE',
}

export enum NotificationType {
  CHANGE_STATUS = 'CHANGE_STATUS',
  UPSERT_PROJECT = 'UPSERT_PROJECT',
  CREATE_APPLICATION = 'CREATE_APPLICATION',
  EDIT_APPLICATION = 'EDIT_APPLICATION',
  CREATE_COMMENT_JIRA = 'CREATE_COMMENT_JIRA',
  CREATE_REQUIREMENT = 'CREATE_REQUIREMENT',
  INCLUDE_EXCLUDE_REQUIREMENT = 'INCLUDE_EXCLUDE_REQUIREMENT',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  EDIT_REQUIREMENT_TEMPLATE = 'EDIT_REQUIREMENT_TEMPLATE',
  DELETE_REQUIREMENT_TEMPLATE = 'DELETE_REQUIREMENT_TEMPLATE',
  EDIT_REQUIREMENT = 'EDIT_REQUIREMENT',
  CREATE_ADMIN = 'CREATE_ADMIN',
  EDIT_ROLES_GROUP = 'EDIT_ROLES_GROUP',
  EDIT_PASSWORD = 'EDIT_PASSWORD',
  UPSERT_ADDITIONAL_COLUMN = 'UPSERT_ADDITIONAL_COLUMN',
  CREATE_SYSTEM_ROLE = 'CREATE_SYSTEM_ROLE',
  EDIT_ADDITIONAL_COLUMN_CONTENT = 'EDIT_ADDITIONAL_COLUMN_CONTENT',
  TRANSFER_APPLICATION = 'TRANSFER_APPLICATION',
  COPY_APPLICATION = 'COPY_APPLICATION',
}

export enum TargetType {
  USER = 'USER',
  PROJECT = 'PROJECT',
  APPLICATION = 'APPLICATION',
}

export interface INotificationExtensionMap {
  value: string;
  name: string;
}
