import { ArrowDownward, ArrowUpward, DeleteForever, VisibilityOff } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { ISingleCode } from '../../../../../../service/unit/interface/theoryBlocks/ICode';
import { FormAutocompleteStandard } from '../../../../form/FormAutocompleteStandard';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';

export interface ICodeExample {
  data: ITheoryBlock<ISingleCode>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}
export const CodeExample = observer(({ data: { data, pseudoId }, onDelete, onUp, onDown }: ICodeExample) => {
  return (
    <Box
      key={pseudoId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'flex-end' }}>
        <Typography sx={{ marginRight: 'auto' }}>Пример кода</Typography>
        <Box sx={{ display: 'flex', gap: '8px' }} className="action">
          <DeleteForever
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDelete();
            }}
          />
          <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {}} />
          <ArrowUpward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onUp();
            }}
          />
          <ArrowDownward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDown();
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <FormAutocompleteStandard
          variant="outlined"
          shouldSort={true}
          values={[
            { name: 'JavaScript', value: 'javascript' },
            { name: 'HTML', value: 'html' },
            { name: 'PHP', value: 'php' },
            { name: 'JAVA', value: 'java' },
            { name: 'C#', value: 'csharp' },
            { name: 'XML', value: 'xml' },
            { name: 'Python', value: 'python' },
            { name: 'YAML', value: 'yaml' },
            { name: 'Plain Text', value: 'plaintext' },
            { name: 'Docker', value: 'dockerfile' },
            { name: 'Bash', value: 'bat' },
            { name: 'CSS', value: 'css' },
            { name: 'SQL', value: 'mysql' },
            { name: 'GO', value: 'go' },
            { name: 'C++', value: 'cpp' },
            { name: 'Perl', value: 'perl' },
            { name: 'JSON', value: 'json' },
            { name: 'Shell', value: 'shell' },
          ]}
          label={'Язык'}
          selected={data.language}
          onChangeSelect={(newValue) => {
            // @ts-ignore
            data.language = newValue.value;
            // @ts-ignore
            data.name = newValue.name;
          }}
        />
        <FormTextFieldStandard
          variant="outlined"
          key={pseudoId}
          label={'Пример кода'}
          value={data.content}
          multiline={true}
          rows={4}
          onChange={(newValue) => {
            data.content = newValue;
          }}
        />
      </Box>
    </Box>
  );
});
