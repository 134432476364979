import EditIcon from '@mui/icons-material/Edit';
import React from 'react';

import { inject } from '../../../../../../../common/container/inject';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { AdditionalColumnRequirementRelationType } from '../../../../../../../service/additionalColumn/content/AdditionalColumnRequirementRelationType';
import { IAdditionalColumnContentSearch } from '../../../../../../../service/additionalColumn/content/IAdditionalColumnContentSearch';
import { IAdditionalColumnModel } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { makeMultiAutoCompleteForTable } from '../../../../../../design/table/edit/TableMultiAutocomplete';
import { makeMultiAutoCompleteViewForTable } from '../../../../../../design/table/edit/TableMultiAutocompleteView';
import { makeSingleAutoCompleteForTable } from '../../../../../../design/table/edit/TableSingleAutocomplete';
import { makeSingleAutoCompleteViewForTable } from '../../../../../../design/table/edit/TableSingleAutocompleteView';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdminTableDomain } from '../../../../table/store/AdminTableDomain';
import { IAdminTableColumn } from '../../../../table/store/IAdminTableUI';
import { AdditionalColumnContentEntityAdminModel } from './AdditionalColumnContentEntityAdminModel';

export class AdditionalColumnContentEntityAdminDomain extends AdminTableDomain<
  AdditionalColumnContentEntityAdminModel,
  IAdditionalColumnContentSearch
> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    protected additionalColumns: IEntityListStore<IAdditionalColumnModel> = injectEntityList<IAdditionalColumnModel>(
      [],
    ),
    protected requirements: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([]),
  ) {
    super(layoutDomain, rootService.additionalColumn.content);
    this.ui.isInlineEdit.setValue(false);
  }

  setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-update']]),
    );
  }

  async loadData() {
    await this.loadDependencies();
    const columns = await this.getColumns();
    this.setActions();
    this.ui.columns.setList(columns);
    await super.loadData();
  }

  private setActions() {
    const actions: any[] = [];
    if (this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-update']])) {
      actions.push({
        icon: () => <EditIcon />,
        tooltip: 'Редактировать',
        onClick: (event, rowData: any) => {
          this.router.goTo(`/settings/additional/column/content/${rowData?.id}/edit`);
        },
      });
    }
    this.ui.actions.setList(actions);
  }

  protected async loadDependencies() {
    try {
      const defaultSearchQuery = { limit: 1000000 };
      this.ui.isLoading.setValue(true);

      const additionalColumnsListSearchResult = await this.rootService.additionalColumn.entity.search(
        defaultSearchQuery,
      );
      const requirementsListSearchResult = await this.rootService.requirement.entity.search({
        limit: 1000000,
        sort: {
          rules: [
            {
              isAsc: false,
              isDesc: true,
              name: 'isActive',
            },
            {
              isAsc: true,
              isDesc: false,
              name: 'shortName',
            },
          ],
        },
      });

      this.ui.isLoading.setValue(false);

      this.additionalColumns.setList(additionalColumnsListSearchResult.data);
      this.requirements.setList(requirementsListSearchResult.data);
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  private async getColumns(): Promise<IAdminTableColumn<any>[]> {
    const additionalColumns = this.additionalColumns.list.map((column) => ({
      value: column.id,
      name: column.isActive ? column.name : `${column.name} (неактивен)`,
    }));
    const requirements = this.requirements.list.map((column) => ({
      value: column.id,
      name: column.shortName,
    }));

    return [
      {
        title: 'Контент',
        field: 'content',
        custom: {
          multiline: true,
        },
        customFilterAndSearch: (term: string, rowData: AdditionalColumnContentEntityAdminModel) => {
          return !!rowData.content?.toLocaleLowerCase().includes(term.toLocaleLowerCase());
        },
      },
      {
        title: 'Дополнительная колонка',
        field: 'additionalColumnId',
        editComponent: makeSingleAutoCompleteForTable('', additionalColumns),
        render: makeSingleAutoCompleteViewForTable((item) => item.additionalColumnId, additionalColumns),
        customFilterAndSearch: (term: string, rowData: AdditionalColumnContentEntityAdminModel) => {
          const additionalColumnName =
            this.additionalColumns.list.find((category) => category.id === rowData.additionalColumnId)?.name || '';
          return additionalColumnName.toLocaleLowerCase().includes(term.toLocaleLowerCase());
        },
      },
      {
        title: 'Требования',
        field: 'requirementsIds',
        editComponent: makeMultiAutoCompleteForTable('', requirements),
        render: makeMultiAutoCompleteViewForTable((item) => item.requirementsIds, requirements, undefined, {
          exception: (rowData: AdditionalColumnContentEntityAdminModel) =>
            rowData.requirementsRelationType === AdditionalColumnRequirementRelationType.all,
          text: 'Все требования',
        }),
        customFilterAndSearch: (term: string, rowData: AdditionalColumnContentEntityAdminModel) => {
          //@todo refactoring
          let names = this.requirements.list
            .filter((requirement) => rowData.requirementsIds.includes(requirement.id || ''))
            .map((requirement) => requirement.shortName);

          if (rowData.requirementsRelationType === AdditionalColumnRequirementRelationType.all) {
            names = ['Все требования'];
          }

          return names.reduce((isHaveTerm: boolean, shortName = '') => {
            if (isHaveTerm === false) {
              return shortName.toLocaleLowerCase().includes(term.toLocaleLowerCase());
            }
            return isHaveTerm;
          }, false);
        },
      },
    ];
  }
}
