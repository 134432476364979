import { Container, CssBaseline, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';

import { ContainerContext } from '../../container/ContainerContext';
import { MainLayoutBottomLogoMenu } from '../../../view/layout/main/parts/LeftNavigation/parts/MainLayoutBottomLogoMenu';


const NotFoundErrorPage = () => {
  const context = useContext(ContainerContext);
  const ReqEduLogoComponent = context.get(MainLayoutBottomLogoMenu);
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container disableGutters maxWidth={false}>
        <Typography className={classes.background}>
          <Typography className={classes.topIcons}>
            <ReqEduLogoComponent />
          </Typography>

          <Typography>
            <img src="/pictures/404.png" />
          </Typography>

          <Typography variant='h3' className={classes.headlineText}>
            Данной страницы не существует
          </Typography>

          <Typography variant='h6' className={classes.mainText}>
            Запрошенный объект не найден
          </Typography>

          <Typography variant='body2' className={classes.footerText}>
            © ООО «Антифишинг», {new Date().getFullYear()}
          </Typography>
        </Typography>
      </Container>

    </>
  );
};

export default NotFoundErrorPage;

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    margin: '0',
    padding: '0',
    background: '#344046 !important',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topIcons: {
    paddingTop: '20px',
    paddingBottom: '30px',
    width: '220px'
  },
  mainText: {
    width: '480px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingBottom: '60px'
  },
  headlineText: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '48',
    paddingTop: '80px',
    paddingBottom: '30px',
  },
  footerText: {
    color: '#9C9C9C',
    fontSize: '12px',
    fontWeight: '200',
    position: 'absolute',
    bottom: '30px'
  }
}));
