import { Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IServiceModel } from '../../../../../../service/common/model/IServiceModel';
import { DataTableFormViewMode } from '../../../store/DataTableFormViewMode';
import { CommonRowContextMenuComponentProperties } from '../domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../domain/RowContextMenuDomain';

export interface IDefaultRowContextMenuProperties<
  RowType extends IServiceModel,
  DomainType = RowContextMenuDomain<RowType>,
> extends CommonRowContextMenuComponentProperties<RowType> {
  domain: DomainType;
  dataCy?: string
}

export const DefaultRowContextMenu = observer(
  <RowType extends IServiceModel = IServiceModel>({
    domain,
    isOpened,
    event,
    row,
    dataCy
  }: IDefaultRowContextMenuProperties<RowType>) => {
    return (
      <Menu
        open={isOpened}
        onClose={() => {
          row && domain.ui.closeContextMenu(row);
        }}
        anchorReference="anchorPosition"
        //@ts-ignore

        anchorPosition={isOpened === true ? { top: event?.clientY, left: event?.clientX } : undefined}
        componentsProps={{
          root: {
            //@ts-ignore
            onContextMenu: (e) => {
              e.preventDefault();
              row && domain.ui.closeContextMenu(row);
            },
          },
        }}
      >
        {domain.dataTableDomain.ui.features.value.isCanEdit && domain.dataTableDomain.ui.isCanEdit.value && (
          <MenuItem
            data-cy={`action-edit`}
            onClick={() => {
              row && domain.ui.closeContextMenu(row);
              row &&
                domain.dataTableDomain.ui.formMode.value === DataTableFormViewMode.modalWindow &&
                domain.onEditRow(row);
              row &&
                domain.dataTableDomain.ui.formMode.value === DataTableFormViewMode.drawer &&
                domain.onEditRowInDrawer(row);
            }}
          >
            Редактировать
          </MenuItem>
        )}
        {domain.dataTableDomain.ui.features.value.isCanDelete && domain.dataTableDomain.ui.isCanDelete.value && (
          <MenuItem
            data-cy={`action-delete`}
            onClick={() => {
              row && domain.ui.closeContextMenu(row);
              row && domain.onDeleteRow(row);
            }}
          >
            Удалить
          </MenuItem>
        )}

        {domain.dataTableDomain.ui.features.value.isCanRedirectDetail && (
          <MenuItem onClick={() => row && domain.onRedirectDetail(row)}>Детали</MenuItem>
        )}
      </Menu>
    );
  },
);
