import { inject } from '../../../../../../../../../common/container/inject';
import { IMainLayoutDomainStore } from '../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../../../service/LearningRootService';
import { ITaskLanguage } from '../../../../../../../service/unit/interface/IUnitPractice';
import { generateUUID } from '../../../../../../../utils/generateUUID';
import { IUnitDomain } from '../../../store/IUnitDomain';
import { IUnitPracticeDomain } from './IUnitPracticeDomain';
import { IUnitPracticeUI } from './IUnitPracticeUI';
import { UnitPracticeUI } from './UnitPracticeUI';

export class UnitPracticeDomain implements IUnitPracticeDomain {
  public ui: IUnitPracticeUI;
  public unitDomain: IUnitDomain;

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    unitDomain: IUnitDomain,
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {
    this.ui = new UnitPracticeUI();

    this.unitDomain = unitDomain;
  }
  addTask = () => {
    this.unitDomain.ui.unit.entity.practice.tasks.push({
      name: `Задача ${this.unitDomain.ui.unit.entity.practice.tasks.length + 1}`,
      explanation: '',
      type: 'review',
      languages: [],
      sortIndex: this.unitDomain.ui.unit.entity.practice.tasks.length + 1,
      pseudoId: generateUUID(),
    });
  };

  boot = () => {
    this.ui.pickedTask.setValue(this.unitDomain.ui.unit.entity.practice.tasks[0]);
  };
  onDelete = (index: number) => {
    if (this.unitDomain.ui.unit.entity.practice.tasks.length !== 1) {
      this.unitDomain.ui.unit.entity.practice.tasks.splice(index, 1);
      this.reindexTasks();
      this.ui.pickedTask.setValue(this.unitDomain.ui.unit.entity.practice.tasks[0]);
    }
  };

  reindexTasks = () => {
    let index = 1;
    const newList = this.unitDomain.ui.unit.entity.practice.tasks.map((task) => {
      task.name = `Задача ${index}`;
      task.sortIndex = index;
      index++;
      return task;
    });
    this.unitDomain.ui.unit.entity.practice.tasks = newList;
  };
  pickTask = (taksId: string) => {
    const pickedTask = this.unitDomain.ui.unit.entity.practice.tasks.filter((task) => task.pseudoId === taksId);
    this.ui.pickedTask.setValue(pickedTask[0]);
    this.changeContent('task');
    if (this.ui.pickedTask.value.languages[0]) {
      this.ui.pickedLang.setValue(this.ui.pickedTask.value.languages[0]);
    } else {
      this.ui.pickedLang.setValue(null as any);
    }
  };
  addLanguages = async (taskId: string, newLanguages: ITaskLanguage[]) => {
    this.unitDomain.ui.unit.entity.practice.tasks.forEach((task) => {
      if (task.pseudoId === taskId) {
        task.languages = newLanguages;
      }
    });
  };
  pickLang = (lang: ITaskLanguage) => {
    if (lang.files.length === 0) {
      const root = { id: generateUUID(), name: 'root', children: [] };
      lang.files.push(root);
    }
    this.ui.pickedLang.setValue(lang);
  };
  changeContent = (page: string) => {
    this.ui.contentPage.setValue(page);
  };
}
