import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { IUnitListAdminPageDomain } from '../store/IUnitListAdminPageDomain';
import { UnitListItem } from './UnitListItem';

interface Props {
  title: string;
  units: IUnitModel[];
  domain: IUnitListAdminPageDomain;
}

export const UnitListCard = observer(({ title, units, domain }: Props) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'repeat(auto-fill,64px)',
        rowGap: '8px',
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {units.map((item) => (
          <UnitListItem
            handleEdit={domain.onEditUnit}
            handleDelete={domain.onDeleteUnit}
            handlePublish={domain.publishUnit}
            handleUnpublish={domain.unpublishUnit}
            card={{
              name: item.settings.titleHeading,
              isPublished: item.isPublished,
              id: item.id,
            }}
          />
        ))}
      </Box>
    </Box>
  );
});
