import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import React from 'react';

import { SingleResultForm } from './components/SingleResultForm';
import { IUnitFinalResultsDomain } from './store/IUnitFinalResultsDomain';

export interface IFinalResultsProperties {
  domain: IUnitFinalResultsDomain;
}

const MAP = [
  { title: 'Успешно (от 70 до 100%)', subtitle: 'Все ответы верные' },
  { title: 'Нейтрально (от 50 до 69%)', subtitle: 'Более 50% ответов верные, но есть хотя бы одна ошибка' },
  { title: 'Провал (от 0 до 49%)', subtitle: 'Более 50% ответов неверные' },
];

export const FinalResults = observer(({ domain }: IFinalResultsProperties) => {
  const { success, neutral, failure } = domain.unitDomain.ui.unit.entity.finalResult.results;

  useEffect(() => {
    domain.boot();
  }, [domain]);

  const classes = useStyles();

  const formValues = [success, neutral, failure].map((item, index) => ({
    item: item,
    titleValue: item.title,
    subtitleValue: item.subtitle,
  }));

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h6">Результаты</Typography>
      <div className={classes.formContainer}>
        {formValues.map((item, index) => (
          <SingleResultForm item={item.item} title={MAP[index].title} subtitle={MAP[index].subtitle} />
        ))}
      </div>
    </div>
  );
});

const useStyles = makeStyles(() => ({
  mainContainer: {
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));
