import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { use } from 'i18next';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { ConfigManager } from '../../../../../application/config/ConfigManager';
import { ILocalization, ILocalizationToken } from '../../../../../application/language/ILocalization';
import { ContainerContext } from '../../../../../common/container/ContainerContext';
import { inject } from '../../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { AntiphishIcon } from '../../../../design/icon/AntiphishIcon';
import { REQIcon } from '../../../../design/icon/REQIcon';
import { StartIcon } from '../../../../design/icon/StartIcon';
import { ProjectLink } from '../../../../design/link/ProjectLink';
import { ListItemLink } from '../../../../design/list/ListItemLink';
import { IMainLayoutDomainStore, MainLayoutDomainStoreToken } from '../../store/domain/IMainLayoutDomainStore';
import { ILayoutUser } from '../../store/ui/ILayoutUser';
import { BottomLogoMenu } from './BottomLogoMenu';
import { LeftNavigationInjects, isShowAnalytic, isShowApplications, isShowFirst } from './LeftNavigationInjects';
import { MainLayoutBottomLogoMenu } from './parts/MainLayoutBottomLogoMenu';
import { MainLayoutLeftNavigationTopLogo } from './parts/MainLayoutLeftNavigationTopLogo';
import { useStyles } from './useStyles';

export interface ILeftNavigationProperties {
  layoutUser: ILayoutUser;
  layoutDomain: IMainLayoutDomainStore;
}

export const LeftNavigation = observer(({ layoutUser, layoutDomain }: ILeftNavigationProperties) => {
  const classes = useStyles();
  const context = useContext(ContainerContext);
  const MainLayoutLeftNavigationTopLogoComponent = context.get(
    MainLayoutLeftNavigationTopLogo,
  ) as typeof MainLayoutLeftNavigationTopLogo;
  const MainLayoutBottomLogoMenuComponent = context.get(MainLayoutBottomLogoMenu);
  const licenseText = ConfigManager.getConfig().license.isSupport
    ? ConfigManager.getConfig().license.licenseString
    : '';
  const newMessagesCount = layoutUser.newMessagesCount;
  const title = ConfigManager.getConfig().text.projectTitle;
  const router = inject<IRouterService>(RouterServiceToken);
  const isShowProjects = layoutUser.allowedPermissions.some((permission) => {
    return [
      UserSystemRoleModelPermissionMap['global-allow-any'],
      UserSystemRoleModelPermissionMap['is-have-access-to-projects'],
    ].includes(permission);
  });

  const isShowSettings = layoutUser.allowedPermissions.some((permission) => {
    return [
      UserSystemRoleModelPermissionMap['global-allow-any'],
      UserSystemRoleModelPermissionMap['settings-is-allow'],
    ].includes(permission);
  });

  useEffect(() => {
    layoutDomain.notificationDomain.lookForUpdates();
  }, [layoutDomain.notificationDomain]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        <div className={classes.titleContainer}>
          <MainLayoutLeftNavigationTopLogoComponent
            isShow={layoutDomain.config.extensions.getExtensionsConfigByName('EXTENSION_MVIDEO_IS_SHOW_LOGO', true)}
          />
          <br />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography color="secondary" variant="subtitle2" align={'left'} className={classes.titleText}>
              {layoutUser.displayName}
            </Typography>
            <IconButton
              style={{ marginRight: '10px' }}
              color="primary"
              onClick={() => {
                router.goTo('/notification');
                layoutDomain.isSelected.setValue('notification');
              }}
            >
              <Badge
                sx={{ '& .MuiBadge-badge': { backgroundColor: '#AE3030', padding: 0, height: '16px' } }}
                badgeContent={layoutDomain.notificationDomain.ui.notificationUnreadCount.value}
              >
                <NotificationsIcon color="secondary" />
              </Badge>
            </IconButton>
          </Box>
        </div>
        <div className={classes.navContainer}>
          <NavigationMenu
            layoutDomain={layoutDomain}
            isShowProjects={isShowProjects}
            isShowSettings={isShowSettings}
            newMessages={newMessagesCount}
          />
        </div>

        <Box sx={{ marginBottom: 'auto' }}></Box>

        <Box sx={{ padding: '0px 0px 0px 0px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Typography
            style={{ fontSize: '9px', paddingLeft: '37px', paddingTop: '40px', whiteSpace: 'pre-line' }}
            variant="caption"
            gutterBottom
            component="div"
          >
            {licenseText}
          </Typography>
        </Box>

        <Divider color="#434E54" />
        <MainLayoutBottomLogoMenuComponent />
      </Box>
    </Drawer>
  );
});

export const NavigationMenu = observer(({ newMessages, isShowProjects, isShowSettings, layoutDomain }: any) => {
  const [i18n] = useState(inject<ILocalization>(ILocalizationToken));
  const LeftNavigationExtensions = useContext(ContainerContext).get(LeftNavigationInjects);
  const isShowApplicationsMenu = useContext(ContainerContext).get(isShowApplications);
  const isShowAnalyticMenu = useContext(ContainerContext).get(isShowAnalytic);
  const isShowFirstExtensions = useContext(ContainerContext).get(isShowFirst);
  const extensionDomain = useContext(ContainerContext).getByToken(MainLayoutDomainStoreToken);

  useEffect(() => {
    layoutDomain.isSelected.setValue(layoutDomain.getActiveUrl().split('/')[1] || 'project');
  }, []);

  const systemName =
    newMessages > 0 ? `${i18n.translate('navigation.systems')} (${newMessages})` : i18n.translate('navigation.systems');
  return (
    <Fragment>
      {isShowFirstExtensions() &&
        LeftNavigationExtensions.map((NavigationExtension) => {
          return <NavigationExtension layoutDomain={extensionDomain} />;
        })}
      {isShowProjects && (
        <ListItemLink
          itemKey="projects"
          url="/project/all"
          primaryText={i18n.translate('navigation.projects')}
          isSelected={layoutDomain.isSelected.value === 'project'}
          onClick={() => {
            layoutDomain.isSelected.setValue('project');
          }}
        />
      )}
      {isShowApplicationsMenu() && isShowProjects && (
        <ListItemLink
          itemKey="applications"
          url="/application/all"
          primaryText={systemName}
          isSelected={layoutDomain.isSelected.value === 'application'}
          onClick={() => {
            layoutDomain.isSelected.setValue('application');
          }}
        />
      )}

      {!isShowFirstExtensions() &&
        LeftNavigationExtensions.map((NavigationExtension) => {
          return <NavigationExtension layoutDomain={extensionDomain} />;
        })}
      {isShowAnalyticMenu() && isShowProjects && (
        <ListItemLink
          itemKey="analytics"
          url="/analytics"
          primaryText="Аналитика"
          onClick={() => {
            layoutDomain.isSelected.setValue('analytics');
          }}
          isSelected={layoutDomain.isSelected.value === 'analytics'}
        />
      )}
      {isShowSettings && (
        <ListItemLink
          itemKey="settings"
          url="/settings"
          primaryText={i18n.translate('navigation.settings')}
          isSelected={layoutDomain.isSelected.value === 'settings'}
          onClick={() => {
            layoutDomain.isSelected.setValue('settings');
          }}
        />
      )}
      <ListItemLink
        onClick={() => layoutDomain.clearActiveUser()}
        itemKey="logout"
        url="/logout"
        primaryText={i18n.translate('navigation.logout')}
      />
    </Fragment>
  );
});
