import { observer } from 'mobx-react';
import React, { MouseEvent, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router';

import { LearningRoutes } from '../../../../constants/LearningRoutes';
import { UnitHeader } from '../../../shared/components/UnitHeader';
import { IAdminPageProperties } from '../IAdminPageProperties';
import { PassingBody } from './components/PassingBody';
import { IPassingFinalResultsDomain } from './parts/finalResults/store/IPassingFinalResultsDomain';
import { PassingFinalResultsDomain } from './parts/finalResults/store/PassingFinalResultsDomain';
import { IPassingPracticeDomain } from './parts/practice/store/IPassingPracticeDomain';
import { PassingPracticeDomain } from './parts/practice/store/PassingPracticeDomain';
import { IPassingQuizDomain } from './parts/quiz/store/IPassingQuizDomain';
import { PassingQuizDomain } from './parts/quiz/store/PassingQuizDomain';
import { IPassingTheoryDomain } from './parts/theory/store/IPassingTheoryDomain';
import { PassingTheoryDomain } from './parts/theory/store/PassingTheoryDomain';
import { PassingContext } from './PassingContext';
import { IPassingDomain } from './store/IPassingDomain';
import { PassingDomain } from './store/PassingDomain';

export interface PassingPageProperties extends IAdminPageProperties {}
export const PassingPage = observer(({ layoutDomain }: PassingPageProperties) => {
  const [theoryDomain] = useState<IPassingTheoryDomain>(new PassingTheoryDomain());
  const [quizDomain] = useState<IPassingQuizDomain>(new PassingQuizDomain(layoutDomain));
  const [practiceDomain] = useState<IPassingPracticeDomain>(new PassingPracticeDomain());
  const [finalResultsDomain] = useState<IPassingFinalResultsDomain>(new PassingFinalResultsDomain());
  const [passingDomain] = useState<IPassingDomain>(
    new PassingDomain(theoryDomain, quizDomain, practiceDomain, finalResultsDomain, layoutDomain),
  );
  const params = useParams() as any;
  useEffect(() => {
    passingDomain.loadData(params.passingId);
  }, [passingDomain, params.passingId]);

  const resetSteps = () => {
    //WORKAROUND: added activeStep.setValue(0) to set stepper buttons to workable condition. Stepper refactoring needed
    passingDomain.practiceDomain.ui.activeStep.setValue(0);
    passingDomain.theoryDomain.ui.activeStep.setValue(0);
    passingDomain.quizDomain.ui.activeStep.setValue(0);
    passingDomain.practiceDomain.ui.pickedLang.setValue('');
    passingDomain.practiceDomain.ui.activeStep.setValue(0);
    passingDomain.practiceDomain.ui.navigationList.setList(
      passingDomain.practiceDomain.ui.navigationList.list.map((item, index) => ({
        ...item,
        isActive: index === 0 ? true : false,
        passed: false,
      })),
    );
  };

  const redirectToStep = (event: MouseEvent, step: string) => {
    event.stopPropagation();
    passingDomain.ui.passingPage.setValue(step);
    resetSteps();
  };

  return (
    <PassingContext.Provider
      value={{
        redirectToStep,
        resetSteps,
        passingDomain,
        theoryDomain,
        quizDomain,
        practiceDomain,
        finalResultsDomain,
      }}
    >
      <UnitHeader
        title={passingDomain.ui.title.value}
        backLink={generatePath(LearningRoutes.LearningUser)}
        backButtonTitle="К знаниям"
      />
      <PassingBody isFullScreen={passingDomain.practiceDomain.ui.isFullScreen.value} />
    </PassingContext.Provider>
  );
});
