import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { Dispatch } from 'react';

interface Props {
  currentTab?: string;
  setCurrentTab: Dispatch<React.SetStateAction<string>>;
}

export const PreviewFinalResultsTabs = ({ currentTab, setCurrentTab }: Props) => {
  return (
    <Tabs value={currentTab} onChange={(_, n) => setCurrentTab(n)}>
      <Tab label="Успешно" value={'success'} />
      <Tab label="Нейтрально" value={'neutral'} />
      <Tab label="Провалено" value={'failure'} />
    </Tabs>
  );
};
