import Editor from '@monaco-editor/react';
import { Add, Delete } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { IReviewEditor } from './IReviewEditor';

export const ReviewEditor = observer(
  ({
    file,
    pickingOption,
    currentOption,
    lang,
    addOption,
    deleteOption,
    toggleOption,
    height,
    isExpand,
    headerHeight,
  }: IReviewEditor) => {
    const [titleRef, setTitleRef] = useState<HTMLSpanElement>({} as HTMLSpanElement);

    return (
      <Box sx={{ display: 'flex', width: '100%' }}>
        {file !== null && !file.hasOwnProperty('children') && (
          <Box sx={{ flex: 1 }}>
            {/* @ts-ignore */}
            <Typography
              ref={setTitleRef}
              sx={{
                minHeight: '64px',
                maxHeight: '64px',
                padding: '4px 16px 10px',
                borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                borderRight: '1px solid rgba(255, 255, 255, 0.12)',
              }}
            >
              {file.name}
            </Typography>
            <Editor
              language={lang.name}
              theme="vs-dark"
              height={isExpand ? height - headerHeight - titleRef?.clientHeight : '280px'}
              value={file.value}
              options={{ minimap: { enabled: false }, automaticLayout: true }}
              onChange={(value) => {
                file.value = value;
              }}
            />
          </Box>
        )}
        {file !== null && !file.hasOwnProperty('children') && (
          <Box sx={{ flex: 1 }}>
            {file.isQuestion && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ paddingLeft: '16px', borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}>
                  {file.name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                  }}
                >
                  {file.options?.map((option) => (
                    <Button
                      size="small"
                      sx={{
                        background: option.name === currentOption.value?.name ? 'rgba(0,0,0,0.25)' : '',
                      }}
                      onClick={() => {
                        pickingOption(option);
                      }}
                    >
                      {option.name}
                    </Button>
                  ))}

                  <IconButton
                    size="small"
                    sx={{ '&:hover': { background: 'none' } }}
                    onClick={() => {
                      addOption(lang);
                    }}
                  >
                    <Add />
                  </IconButton>

                  {currentOption.value !== null && (
                    <IconButton
                      size="small"
                      sx={{ '&:hover': { background: 'none' } }}
                      onClick={() => {
                        deleteOption(currentOption.value, lang);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                  <FormGroup sx={{ marginLeft: 'auto' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={currentOption.value !== null ? currentOption.value.isCorrect : false}
                          size="small"
                          onChange={() => {
                            toggleOption(currentOption.value.name);
                          }}
                        />
                      }
                      label="Верный"
                    />
                  </FormGroup>
                </Box>
              </Box>
            )}

            {currentOption.value !== null && (
              <Editor
                language={lang.name}
                theme="vs-dark"
                height={isExpand ? height : '280px'}
                value={currentOption.value.value}
                onChange={(newValue) => {
                  if (newValue !== undefined) {
                    currentOption.value.value = newValue;
                  }
                }}
                options={{ minimap: { enabled: false }, automaticLayout: true }}
              />
            )}
          </Box>
        )}
      </Box>
    );
  },
);
