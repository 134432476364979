import { EditComponentProps } from '@material-table/core';
import { observer } from 'mobx-react';
import React from 'react';

import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IStatusEntityModel } from '../../../../../../service/status/entity/IStatusEntityModel';
import { IStatusValueModel } from '../../../../../../service/status/value/IStatusValueModel';
import { IStatusValueSearch } from '../../../../../../service/status/value/IStatusValueSearch';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { Colors } from '../../../../../design/color/Colors';
import { FormAutocomplete, FormAutocompleteOptionIconPosition } from '../../../../../design/form/FormAutocomplete';
import { makeMultiAutoCompleteViewForTable } from '../../../../../design/table/edit/TableMultiAutocompleteView';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdminTableDomain } from '../../../table/store/AdminTableDomain';
import { IAdminTableColumn } from '../../../table/store/IAdminTableUI';
import { StatusValueColor } from '../color/StatusValueColor';
import { StatusValueColorIcon } from '../color/StatusValueColorIcon';

export class StatusValueAdminDomain extends AdminTableDomain<IStatusValueModel, IStatusValueSearch> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    protected statusEntities: IEntityListStore<IStatusEntityModel> = injectEntityList<IStatusEntityModel>([]),
  ) {
    super(layoutDomain, rootPrivateServices.status.value);
  }

  setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-update']]),
    );
  }

  protected async loadDependencies() {
    try {
      this.ui.isLoading.setValue(true);

      const statusEntities = await this.rootPrivateServices.status.entity.search({ limit: 100000 });

      this.ui.isLoading.setValue(false);
      this.statusEntities.setList(statusEntities.data);
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  async loadData() {
    await this.loadDependencies();
    const columns = await this.getColumns();
    this.ui.columns.setList(columns);
    await super.loadData();
  }

  private customSorting(a, b) {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();

    if (aLower < bLower) {
      return -1;
    } else if (aLower > bLower) {
      return 1;
    } else {
      return 0;
    }
  }

  private customSortingForStatus(a, b, statusesLookup) {
    const aStatus = statusesLookup?.[a]?.toLowerCase();
    const bStatus = statusesLookup?.[b]?.toLowerCase();

    if (aStatus !== undefined && bStatus !== undefined) {
      if (aStatus < bStatus) {
        return -1;
      } else if (aStatus > bStatus) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return aStatus === undefined ? -1 : 1;
    }
  }

  private async getColumns(): Promise<IAdminTableColumn[]> {
    const statusesLookup: any = {};
    this.statusEntities.list.forEach((status) => {
      statusesLookup[status.id || 'none'] = status.isActive ? status.name : `${status.name} (неактивен)`;
    });

    return [
      {
        title: 'Название',
        field: 'name',
        isRequired: true,
        customSort: (a, b) => this.customSorting(a.name, b.name),
      },
      {
        title: 'Описание',
        field: 'description',
        custom: {
          multiline: true,
        },
        isRequired: true,
        customSort: (a, b) => this.customSorting(a.description, b.description),
      },
      {
        title: 'Статус',
        field: 'statusId',
        lookup: statusesLookup,
        customSort: (a, b) => this.customSortingForStatus(a.statusId, b.statusId, statusesLookup),
      },
      {
        title: 'Порядок отображения',
        type: 'numeric',
        field: 'orderIndex',
        initialEditValue: 1,
        validate: (rowData) =>
          Number.isInteger(rowData.orderIndex)
            && !Number.isNaN(rowData.orderIndex)
            ? true
            : { isValid: false, helperText: 'Введите целое число' }
      },

      // {
      //   title: 'По умолчанию',
      //   field: 'isDefault',
      //   type: 'boolean',
      //   initialEditValue: false,
      // },
      {
        title: 'Показывать в системах',
        field: 'isShowInStats',
        type: 'boolean',
        initialEditValue: true,
      },
      {
        title: 'Цвет',
        field: 'statusValueColorId',
        type: 'string',
        initialEditValue: null,
        editComponent: (props: EditComponentProps<any>) => {
          return <StatusValueColorAutoComplete {...props} />;
        },
        render: (rowData: any) => {
          const color = StatusValueColor.find((item) => item.id === rowData.colorId);
          return (
            <StatusValueColorIcon
              color={Colors.rgbaOpacityTemplate(color?.rgbaTemplate, '0.6', 'rgba(255, 255, 255, 0.16)')}
            />
          );
        },
      },
      {
        title: 'Активен',
        field: 'isActive',
        type: 'boolean',
        initialEditValue: true,
      },
    ];
  }
}

const StatusValueColorAutoComplete = observer((props: any) => {
  return (
    <div key={props.rowData.id} style={{ width: 200 }}>
      <FormAutocomplete
        label={'Цвет'}
        onChangeSelect={function (newValue: any) {
          props.rowData.colorId = newValue?.value;
          props.onChange(newValue?.value || null);
        }}
        selected={props.rowData.colorId || ''}
        values={StatusValueColor.map((item) => ({
          value: item.id,
          name: item.name,
          icons: [
            {
              position: FormAutocompleteOptionIconPosition.left,
              fragment: (
                <StatusValueColorIcon
                  color={Colors.rgbaOpacityTemplate(item.rgbaTemplate, '0.6', 'rgba(255, 255, 255, 0.16)')}
                />
              ),
            },
          ],
        }))}
      />
    </div>
  );
});
