import React from 'react';

import { inject } from '../../../../../common/container/inject';
import { IError } from '../../../../../common/error/IError';
import { IValidationErrorData } from '../../../../../common/error/IValidationErrorData';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { IServiceModel } from '../../../../../service/common/model/IServiceModel';
import { ICRUDService } from '../../../../../service/common/service/ICRUDService';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { LayoutNotificationType } from '../../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { IDataTableDomain } from '../../store/IDataTableDomain';
import { DataTableFormUI } from './DataTableFormUI';
import { IDataTableFormDomain } from './IDataTableFormDomain';
import { IDataTableFormUI } from './IDataTableFormUI';

export class DataTableFormDomain<
  ModelType extends IServiceModel,
  EntityService extends ICRUDService<ModelType, any> = ICRUDService<ModelType, any>,
> implements IDataTableFormDomain<ModelType>
{
  public ui: IDataTableFormUI<ModelType>;
  public modalOptions: IPrimitiveStore<{ isFullWidth?: boolean; fullWidthMaxSize?: string }> = injectPrimitive({});
  private router: IRouterService;
  constructor(
    private entityService: EntityService,
    public layoutDomain: IMainLayoutDomainStore,
    public dataTableDomain: IDataTableDomain<ModelType, any>,
    ui?: IDataTableFormUI<ModelType>,
    router?: IRouterService,
  ) {
    this.ui = ui || new DataTableFormUI();
    this.router = router || inject<IRouterService>(RouterServiceToken);
  }

  async loadData(id: string | null) {
    if (id) {
      await this.loadItem(id);
    } else {
      this.ui.model.setEntity(this.ui.getDefaultModel());
    }
  }

  async loadItem(id: string) {
    try {
      const entity = await this.entityService.getById(id);
      this.ui.model.setEntity(entity);
      return true;
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  async onSaveUpdates() {
    this.ui.validationErrors.setList([]);
    let entity = this.ui.model.entity;
    const isUpdate = !!entity?.id;
    await this.save();
    await this.updateTableAfterMutate(entity.id || '', isUpdate);
    if (this.ui.validationErrors.list.length === 0) {
      this.onCloseModal();
    }
  }

  async save(model?: ModelType) {
    let entity = model || this.ui.model.entity;
    entity = this.transformViewToServer(entity);
    let entityId: any = entity.id;
    if (entity.id && entity.id) {
      await this.callService(this.entityService.updateByModel(entity));
    } else {
      entityId = await this.callService<string>(this.entityService.createByModel(entity));
      this.ui.model.entity.id = entityId || '';
    }
  }

  async updateTableAfterMutate(entityId: string, isUpdate: boolean) {
    if (this.ui.validationErrors.list.length === 0) {
      const entityFromService = (await this.callService<ModelType>(
        this.entityService.getById(`${entityId}` || ''),
      )) as ModelType;
      isUpdate
        ? this.dataTableDomain.ui.rows.updateEntity(entityFromService)
        : this.dataTableDomain.ui.rows.addNewEntity(entityFromService);
      this.ui.model.setEntity(this.ui.getDefaultModel());
    }
    const sortEggsInNest = (a: number, b: number) => {
      return a > b ? 1 : b > a ? -1 : 0;
    };
    this.dataTableDomain.ui.tableOptions.rowsPerPageOptions.setList(
      [this.dataTableDomain.ui.rows.entities.list.length, 20, 100].sort(sortEggsInNest),
    );
    this.dataTableDomain.ui.rows.pageSize.setValue(this.dataTableDomain.ui.rows.entities.list.length);
  }

  getUpdateFieldHandler(fieldName: string) {
    return (newValue: any) => {
      this.ui.model.entity[fieldName] = newValue;
    };
  }

  getUpdateIntegerFieldHandler(fieldName: string) {
    return (newValue: any) => {
      const INTEGER_REGEX = /^-?\d*$/;

      if (INTEGER_REGEX.test(newValue) || newValue === '') {
        this.ui.model.entity[fieldName] = newValue;
        this.removeValidationErrors();
      } else {
        this.addValidationErrors([
          {
            target: fieldName,
            message: 'Введите целое число',
          },
        ]);
      }
    };
  }

  getValidationErrorFor(fieldName: string, id?: string) {
    const error = this.ui.validationErrors.list.find((validationError) => validationError.target === fieldName);
    return error || undefined;
  }
  removeValidationErrors() {
    this.ui.validationErrors.setList([]);
  }

  onCancelUpdates = () => {
    this.onCloseModal();
    this.ui.model.setEntity(this.ui.getDefaultModel());
    this.ui.validationErrors.setList([]);
  };

  onOpenModal(form: any) {
    const FormComponent = this.ui.formComponent.value;
    const FormComponentWithDomain = () => <FormComponent domain={this} />;
    this.layoutDomain.modalWindow.showModalWindow(FormComponentWithDomain, this.modalOptions.value);
  }

  onCloseModal() {
    this.layoutDomain.modalWindow.closeModalWindow();
  }

  addValidationErrors(errors: IValidationErrorData[]) {
    this.ui.validationErrors.setList(errors);
  }

  errorsHandler = async (error: IError): Promise<boolean> => {
    if (error.webCode === '400') {
      this.addValidationErrors(error.data);
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Ошибка валидации',
      });
    } else {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Неизвестная ошибка',
      });
    }

    return false;
  };

  transformServerToView(item: ModelType): any {
    return item;
  }

  transformViewToServer(item: any): ModelType {
    return item;
  }

  public async callService<ReturnType = any>(serviceHandler: Promise<any>): Promise<boolean | ReturnType> {
    try {
      const result = await serviceHandler;

      return result || true;
    } catch (error) {
      return this.errorsHandler(error);
    }
  }
}
