import { FormControl, FormControlLabel, Switch, Theme } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../../design/form/FormAutocomplete';
import { FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { VendorRequirementModalDomain } from './VendorRequirementModalDomain';
import { RichTextEditor } from '../../../../../../design/RichTextEditor/RichTextEditor';
import { makeStyles } from '@mui/styles';

export interface IVendorRequirementAdminTableProperties {
  domain: VendorRequirementModalDomain;
}

export const VendorRequirementModal = observer(({ domain }: IVendorRequirementAdminTableProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <VendorRequirementShortNameCustomFieldText domain={domain} />
      <VendorRequirementDescriptionCustomFieldArea domain={domain} />
      <VendorRequirementCatgeoryDropDown domain={domain} />
      <VendorRequirementRequirementDropDown domain={domain} />
      <VendorRequirementOrderCustomFieldText domain={domain} />
      <VendorRequirementActiveStatusCustomSwitch domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const VendorRequirementShortNameCustomFieldText = observer(({ domain }: { domain: VendorRequirementModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.shortName}
      onChange={domain.getUpdateFieldHandler('shortName')}
      errorMessage={domain.getValidationErrorFor('shortName')?.message}
    />
  );
});

const VendorRequirementDescriptionCustomFieldArea = observer(({ domain }: { domain: VendorRequirementModalDomain }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
      padding: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const lableColor = 'rgba(255, 255, 255, 0.5)'

  const handleChange = (styledText, cleatText) => {
    const changeFuncStyled = domain.getUpdateFieldHandler('descriptionWithStyles')
    changeFuncStyled(styledText);
    const changeFunc = domain.getUpdateFieldHandler('description')
    changeFunc(cleatText);
  }
  const defaultDataForInput = domain.ui.model.entity?.descriptionWithStyles ?? domain.ui.model.entity?.description

  return (
    <FormControl fullWidth className={classes.formControl} variant="outlined">
      <div style={{ color: lableColor, paddingBottom: 2 }}>
        Описание*
      </div>
      <RichTextEditor
        defaultInputValue={defaultDataForInput || ''}
        changeHandler={handleChange}
        minHeight='150px'
        disabled={false}
        error={domain.getValidationErrorFor('description')?.message}
      />
    </FormControl>
  )
});

const VendorRequirementCatgeoryDropDown = observer(({ domain }: { domain: VendorRequirementModalDomain }) => {
  const categoryValues = [
    ...domain.ui.vendorEntity.list.map((category) => ({
      value: category.id,
      name: category.name,
    })),
  ];

  return (
    <FormAutocomplete
      values={categoryValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.vendorId = newValue.value;
      }}
      selected={domain.ui.model.entity.vendorId}
      label="Категория каталога требований"
      errorMessage={domain.getValidationErrorFor('vendorId')?.message}
    />
  );
});

const VendorRequirementRequirementDropDown = observer(({ domain }: { domain: VendorRequirementModalDomain }) => {
  const [categoryValues, setCategoryValues] = useState<any>();

  const currentCategoryValues = [
    ...domain.ui.requirementEntity.list.map((category) => ({
      value: category.id,
      name: category.shortName,
    })),
  ];
  useEffect(() => {
    setCategoryValues(domain.ui.model.entity.requirementsIds);
  }, [domain.ui.model.entity.requirementsIds]);
  return (
    <FormMultiAutocomplete
      values={currentCategoryValues}
      onChangeSelect={(newValue) => {
        domain.ui.model.entity.requirementsIds = newValue.map((value) => {
          return value.value;
        });
        setCategoryValues(domain.ui.model.entity.requirementsIds);
      }}
      selected={categoryValues}
      label="Требования"
      errorMessage={domain.getValidationErrorFor('requirementsIds')?.message}
    />
  );
});
const VendorRequirementOrderCustomFieldText = observer(({ domain }: { domain: VendorRequirementModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
    />
  );
});
const VendorRequirementActiveStatusCustomSwitch = observer(({ domain }: { domain: VendorRequirementModalDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isActive);
  }, [domain.ui.model.entity.isActive]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
      label="Статус"
    />
  );
});
