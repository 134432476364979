import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../../design/form/FormAutocomplete';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { SpecificationEntityModalDomain } from './SpecificationEntityModalDomain';

export interface ISpecificationEntityAdminTableProperties {
  domain: SpecificationEntityModalDomain;
  dataCy?: string;
}

export const SpecificationEntityModal = observer(({ domain }: ISpecificationEntityAdminTableProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <SpecificationEntityNameCustomFieldText domain={domain} />
      <SpecificationEntityDescriptionCustomFieldArea domain={domain} />
      <SpecificationEntityEntityCategoryDropDown domain={domain} />
      <SpecificationEntityOrderCustomFieldText domain={domain} />
      <SpecificationEntityActiveStatusCustomSwitch domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const SpecificationEntityNameCustomFieldText = observer(({ domain }: { domain: SpecificationEntityModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
      inputProps={{
        'data-cy': 'spec-entity-title'
      }}
    />
  );
});

const SpecificationEntityDescriptionCustomFieldArea = observer(
  ({ domain }: { domain: SpecificationEntityModalDomain }) => {
    return (
      <FormTextArea
        required={true}
        label={'Описание'}
        value={domain.ui.model.entity.description}
        onChange={domain.getUpdateFieldHandler('description')}
        errorMessage={domain.getValidationErrorFor('description')?.message}
        dataCy='spec-entity-description'
      />
    );
  },
);

const SpecificationEntityEntityCategoryDropDown = observer(({ domain }: { domain: SpecificationEntityModalDomain }) => {
  const categoryValues: any = [
    ...domain.ui.categories.list.map((category) => ({
      value: category.id,
      name: category.name,
    })),
  ];
  return (
    <FormAutocomplete
      values={categoryValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.categoryId = newValue.value;
      }}
      selected={domain.ui.model.entity.categoryId}
      label="Категория"
      errorMessage={undefined}
      dataCy='category-select'
    />
  );
});

const SpecificationEntityOrderCustomFieldText = observer(({ domain }: { domain: SpecificationEntityModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
      inputProps={{
        'data-cy': 'spec-entity-order-number'
      }}
    />
  );
});
const SpecificationEntityActiveStatusCustomSwitch = observer(
  ({ domain }: { domain: SpecificationEntityModalDomain }) => {
    const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

    const handleChange = (event: any) => {
      setChecked(event.target.checked);
      domain.ui.model.entity.isActive = event.target.checked;
    };
    useEffect(() => {
      setChecked(domain.ui.model.entity.isActive);
    }, [domain.ui.model.entity.isActive]);
    return (
      <FormControlLabel
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
        label="Статус"
      />
    );
  },
);
