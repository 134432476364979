import wretch from 'wretch';

import { IUnitResultModel } from './IUnitResultModel';
import { LearningCoreRestCRUDService } from '../common/LearningCoreRestCRUDService';
import { IUnitResultService } from './IUnitResultService';

wretch().errorType('json');

export class RestUnitResultService extends LearningCoreRestCRUDService<IUnitResultModel> implements IUnitResultService{
  getEndpoint(): string {
    const url = this.learningConfig.url.api.learning;
    return `${url}/unitResult`;
  }

  async updateManyModels(entitiesForUpdate: { unitResults: IUnitResultModel[]; userId: string }): Promise<any> {
    await this.request()
      .url(`${this.getEndpoint()}/updateMany`)
      .put(entitiesForUpdate)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);


  }
  async passTheory(entitiesForUpdate: IUnitResultModel): Promise<void> {
    await this.request()
      .url(`${this.getEndpoint()}/theory/pass`)
      .post(entitiesForUpdate)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
  }
  async passQuiz(entitiesForUpdate: IUnitResultModel): Promise<void> {
    await this.request()
      .url(`${this.getEndpoint()}/quiz/pass`)
      .post(entitiesForUpdate)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
  }
  async passPractice( body: { unitResult: IUnitResultModel; pseudoId: string }): Promise<void> {
    await this.request()
      .url(`${this.getEndpoint()}/practice/pass`)
      .post(body)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
  }

}
