import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect } from 'react';

import { ApplicationDetailDomain } from '../store/ApplicationDetailDomain';

export const ApplicationHeaderTabs = observer(({ domain }: { domain: ApplicationDetailDomain }) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    domain.ui.tabsData.value.state = newValue;
  };

  return (
    <Tabs
      value={domain.ui.tabsData.value.state}
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="secondary"
    >
      {domain.ui.tabsData.value.tabs.map((tab) => {
        return tab.isShow && tab.isShow(domain) === false ? (
          <Fragment></Fragment>
        ) : (
          <Tab key={tab.key} label={tab.getTitle(domain)} />
        );
      })}
    </Tabs>
  );
});
