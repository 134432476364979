import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { FormModalContainer } from '../../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../../../design/form/FormAutocomplete';
import { FormTextField } from '../../../../../../../design/form/FormTextField';
import { UserModalDomain } from './UserModalDomain';

export interface IUserEntityAdminTableProperties {
  domain: UserModalDomain;
}

const adminUserLogin = 'admin';

export const UserModal = observer(({ domain }: IUserEntityAdminTableProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <UserNameCustomField domain={domain} />
      <UserEmailCustomField domain={domain} />
      <UserLoginCustomField domain={domain} />
      <UserPasswordCustomField domain={domain} />
      <UserConfirmPasswordCustomField domain={domain} />
      <UserRoleTypeDropDown domain={domain} />
      <UserActiveStatusCustomSwitch domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const UserNameCustomField = observer(({ domain }: { domain: UserModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'ФИО'}
      value={domain.ui.model.entity.displayName}
      onChange={domain.getUpdateFieldHandler('displayName')}
      errorMessage={domain.getValidationErrorFor('displayName')?.message}
      disabled={domain.ui.model.entity.login === 'admin' && !!domain.ui.model.entity.id}
    />
  );
});

const UserEmailCustomField = observer(({ domain }: { domain: UserModalDomain }) => {
  return (
    <FormTextField
      errorMessage={domain.getValidationErrorFor('email')?.message}
      value={domain.ui.model.entity.email}
      onChange={domain.getUpdateFieldHandler('email')}
      label="Почта"
      required={true}
      disabled={domain.ui.model.entity.login === adminUserLogin && !!domain.ui.model.entity.id}
    />
  );
});
const UserLoginCustomField = observer(({ domain }: { domain: UserModalDomain }) => {
  return (
    <FormTextField
      errorMessage={domain.getValidationErrorFor('login')?.message}
      value={domain.ui.model.entity.login}
      onChange={domain.getUpdateFieldHandler('login')}
      label="Логин"
      required={true}
      disabled={domain.ui.model.entity.login === adminUserLogin && !!domain.ui.model.entity.id}
    />
  );
});
const UserPasswordCustomField = observer(({ domain }: { domain: UserModalDomain }) => {
  return (
    <FormTextField
      type="password"
      errorMessage={domain.getValidationErrorFor('password')?.message}
      value={domain.ui.model.entity.password}
      onChange={domain.getUpdateFieldHandler('password')}
      label="Пароль"
      required={true}
    />
  );
});
const UserConfirmPasswordCustomField = observer(({ domain }: { domain: UserModalDomain }) => {
  return (
    <FormTextField
      type="password"
      errorMessage={domain.getValidationErrorFor('confirmPassword')?.message}
      value={domain.ui.model.entity.confirmPassword}
      onChange={domain.getUpdateFieldHandler('confirmPassword')}
      label="Подтверждение пароля"
      required={true}
    />
  );
});

const UserRoleTypeDropDown = observer(({ domain }: { domain: UserModalDomain }) => {
  const roleTypeValues = [
    ...domain.ui.systemRoleId.list.map((role) => ({
      value: role.id,
      name: role.name,
    })),
  ];
  return (
    <FormAutocomplete
      values={roleTypeValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.systemRoleId = newValue.value;
      }}
      selected={domain.ui.model.entity.systemRoleId}
      label="Системная роль"
      errorMessage={domain.getValidationErrorFor('systemRoleId')?.message}
      disabled={domain.ui.model.entity.login === adminUserLogin && !!domain.ui.model.entity.id}
    />
  );
});

const UserActiveStatusCustomSwitch = observer(({ domain }: { domain: UserModalDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isActive);
  }, [domain.ui.model.entity.isActive]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
      label="Статус"
      disabled={domain.ui.model.entity.login === adminUserLogin && !!domain.ui.model.entity.id}
    />
  );
});
