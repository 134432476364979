import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { FormMultiAutocompleteStandard } from '../../../../../design/form/FormMultiAutocompleteStandard';
import { FormTextFieldStandard } from '../../../../../design/form/FormTextFieldStandard';
import { UnitDomain } from '../../store/UnitDomain';
import { useFormValidation as useSettingsForm } from './hooks/useSettingsForm';
import { mapToAutocompleteItems } from './utils/mapToAutocompleteItems';

interface ISettingsProperties {
  unitDomain: UnitDomain;
}

export const Settings = observer(({ unitDomain }: ISettingsProperties) => {
  const { validationErrors, onTitleChange } = useSettingsForm(unitDomain);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '760px', paddingTop: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">Система</Typography>
        <FormTextFieldStandard
          variant="outlined"
          label={'Название юнита*'}
          errorMessage={validationErrors?.title}
          value={unitDomain.ui.unit.entity.settings.titleHeading}
          onChange={onTitleChange}
        />
        <FormMultiAutocompleteStandard
          variant="outlined"
          values={mapToAutocompleteItems({ items: unitDomain.ui.characteristic.list, valueKey: 'id', nameKey: 'name' })}
          label={'Характеристики'}
          selected={unitDomain.ui.unit.entity.settings.characteristicsIds}
          onChangeSelect={(characteristics) => {
            unitDomain.ui.unit.entity.settings.characteristicsIds = characteristics?.map((characteristic) => {
              return characteristic.value;
            });
          }}
        />
        <FormMultiAutocompleteStandard
          variant="outlined"
          values={mapToAutocompleteItems({ items: unitDomain.ui.teamRoles.list, valueKey: 'id', nameKey: 'name' })}
          label={'Роли в команде'}
          selected={unitDomain.ui.unit.entity.settings.teamRolesIds}
          onChangeSelect={(roles) => {
            unitDomain.ui.unit.entity.settings.teamRolesIds = roles.map((item) => item.value);
          }}
        />
      </Box>
    </Box>
  );
});
