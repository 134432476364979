import { injectEntity } from '../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { UIStore } from '../../../../../../../common/store/base/UIStore';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../../common/store/interface/IEntityStore';
import { IUserProjectRoleModel } from '../../../../../../../service/projectRole/entity/IUserProjectRoleModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { IContextModel } from '../../../../../service/context/IContextModel';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { generateUUID } from '../../../../../utils/generateUUID';
import { IStepContent } from '../../../../design/stepper/CustomStepper';

export class UnitUi extends UIStore {
  constructor(
    public isLoading = injectPrimitive<boolean>(false),
    public isEmpty = injectPrimitive<boolean>(false),
    public stepsTitles = injectEntityList<string>([]),
    public stepsCodes = injectEntityList<string>([]),
    public stepContent = injectEntityList<IStepContent>([]),
    public contexts: IEntityListStore<IContextModel> = injectEntityList([]),
    public characteristic: IEntityListStore<ISpecificationEntityModel> = injectEntityList([]),
    public teamRoles: IEntityListStore<IUserProjectRoleModel> = injectEntityList([]),
    public validSteps: IEntityStore<Record<string, boolean>> = injectEntity({
      settings: false,
      theory: true,
      quiz: true,
      practice: true,
    }),
    public unit: IEntityStore<IUnitModel> = injectEntity({
      id: '',
      createDate: undefined,
      isDeleted: false,
      isActive: true,
      isIncluded: false,
      isPublished: false,
      quiz: {
        isEnabled: true,
        passageTime: '0',
        results: {
          success: {
            title: '',
            subtitle: '',
          },
          neutral: {
            title: '',
            subtitle: '',
          },
          failure: {
            title: '',
            subtitle: '',
          },
        },
        questions: [
          {
            name: '',
            explanation: '',
            type: 'single',
            randomize: true,
            answers: [],
            sortIndex: 1,
            pseudoId: generateUUID(),
            isCodeExample: false,
            codeExample: [],
          },
        ],
      },
      practice: {
        isEnabled: true,
        passageTime: '0',
        results: {
          success: {
            title: '',
            subtitle: '',
          },
          neutral: {
            title: '',
            subtitle: '',
          },
          failure: {
            title: '',
            subtitle: '',
          },
        },
        tasks: [
          {
            name: 'Задача 1',
            explanation: '',
            type: 'review',
            languages: [],
            sortIndex: 1,
            pseudoId: generateUUID(),
          },
        ],
      },
      theory: {
        chapters: [
          {
            title: 'Глава 1',
            theoryBlocks: [],
            sortIndex: 0,
            pseudoId: generateUUID(),
          },
        ],
        isEnabled: true,
      },
      finalResult: {
        isEnabled: true,
        results: {
          success: {
            title: 'Молодец, вы достигли высоких результатов!',
            subtitle: 'Отличная работа, продолжайте в том же духе!',
          },
          neutral: {
            title: 'Хороший результат! Вы ответили на большинство вопросов правильно.',
            subtitle: 'Перед следующим прохождением обратите внимание на вопросы, в которых допустили ошибки, и изучите юнит еще раз.',
          },
          failure: {
            title: 'Попробуйте еще раз, у вас недостаточно верных ответов, чтобы сдать юнит.',
            subtitle: 'Обратите внимание на вопросы, проанализируйте их и изучите проблемные области. Пройдите квиз/практику еще раз, чтобы проверить, что понимаете все правильно.',
          },
        },
      },
      settings: {
        contextId: '',
        characteristicsId: '',
        characteristicsIds: [],
        teamRolesIds: [],
        titleHeading: 'Новый юнит',
        titleLead: '',
      },
      lastUpdateDate: undefined,
      score: 0,
    }),
  ) {
    super();
  }

  startLoading(): void {
    this.isEmpty.setValue(false);
    this.isLoading.setValue(true);
  }
  endLoading(): void {
    this.isLoading.setValue(false);
  }
}
