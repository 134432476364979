export const TableTranslation = {
  body: {
    emptyDataSourceMessage: 'Нет данных',
    addTooltip: 'Добавить',
    deleteTooltip: 'Удалить',
    editTooltip: 'Редактировать',
    filterRow: {
      filterTooltip: 'Фильтр',
    },
    editRow: {
      deleteText: 'Вы уверены, что хотите удалить?',
      cancelTooltip: 'Отменить',
      saveTooltip: 'Сохранить',
    },
  },
  grouping: {
    placeholder: 'Перетащите заголовки ...',
  },
  header: {
    actions: 'Действия',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} из {count}',
    labelRowsSelect: 'записей',
    labelRowsPerPage: 'Записей на страницу:',
    firstAriaLabel: 'Первая старница',
    firstTooltip: 'Первая страница',
    previousAriaLabel: 'Предыдущая страница',
    previousTooltip: 'Предыдущая страница',
    nextAriaLabel: 'Следующая страница',
    nextTooltip: 'Следующая страница',
    lastAriaLabel: 'Последняя страница',
    lastTooltip: 'Последняя страница',
  },
  toolbar: {
    addRemoveColumns: 'Добавить или удалить колонки',
    nRowsSelected: '{0} запись(-ей) выбрано',
    showColumnsTitle: 'Показать колонки',
    showColumnsAriaLabel: 'Показать колонки',
    exportTitle: 'Экспортировать',
    exportAriaLabel: 'Экспортировать',
    exportName: 'Экспортировать в CSV',
    searchTooltip: 'Поиск',
    searchPlaceholder: 'Поиск',
  },
};
