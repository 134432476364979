import React, { useEffect, useRef, useState } from 'react';
import { ArrowDropDown, Save, Search } from '@mui/icons-material';
import { Button, Chip, InputAdornment, List, Paper, TextField, Toolbar } from '@mui/material';
import { observer } from 'mobx-react';
import { RequirementEntityAdminDomain } from '../RequirementEntityAdminDomain';

interface SearchRequirementProps {
  tableDomain: RequirementEntityAdminDomain
}
const SearchRequirement = observer(({ tableDomain }: SearchRequirementProps) => {
  return (
    <div style={{ width: '100%', marginRight: 'auto', margin: 0, fontSize: '13px', padding: '10px 10px 0px 15px' }}>
      <TextField
        style={{
          width: tableDomain.filterDomain.isOpenFilter.value ? 'calc(100% - 300px)' : '100%',
          transition: 'all 0.225s cubic-bezier(0, 0, 0.2, 1)',
        }}
        size="small"
        defaultValue="Small"
        variant="outlined"
        maxRows={1}
        autoComplete={'off'}
        value={tableDomain.ui?.rows.searchTerm.value}
        onChange={(event) => {
          tableDomain.ui.rows.searchTerm.setValue(event?.target?.value || '')
          tableDomain.filterDomain.triggerSearch();
        }}
        placeholder='Поиск по таблице'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          ),
          style: { fontSize: 13, paddingLeft: 10 }
        }}
        InputLabelProps={{ style: { fontSize: 13 } }}
      />
    </div>
  )
})

export default SearchRequirement