import { IPerformerTypeSearch } from '../../../../../../../service/performer/type/IPerformerTypeSearch';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import TableHeader from '../../../../../../design/dataTable/parts/appBar/TableHeader';
import { DataTableDomain } from '../../../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../../../design/dataTable/store/DataTableUI';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { UserModalDomain } from './modal/UserModalDomain';
import { UserAdminModel } from './UserAdminModel';
import { UserAdminUI } from './UserAdminUI';

export class CustomAppBar {
  Toolbar = TableHeader;
}
export class UserAdminDomain extends DataTableDomain<UserAdminModel, IPerformerTypeSearch> {
  public ui: UserAdminUI;
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.user.entity);
    this.ui = new UserAdminUI(this, null);
    this.modalDomain = new UserModalDomain(rootPrivateServices.user.entity, layoutDomain, this);

    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle?.setValue('Управление пользователями');
    this.ui.tableSubtitle?.setValue('Пользователи');
  }
  async loadData() {
    await this.loadDepenicies();

    await super.loadList();
    this.setPermissions();
  }

  async loadDepenicies() {
    const [systemRoleIdSearchResult, projectsSearchResult] = await Promise.all([
      this.rootPrivateServices.systemRole.entity.search({ limit: 1000000 }),
      this.rootPrivateServices.project.entity.search({ limit: 1000000 }),
    ]);

    this.ui.systemRoleId.setList(systemRoleIdSearchResult.data);
    this.ui.projects.setList(projectsSearchResult.data);
  }
  async setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-user-entity-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-user-entity-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-user-entity-update']]),
    );
  }

  async setNoDeletePermission() {
    this.ui.isCanDelete.setValue(false);
  }
}
