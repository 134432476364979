import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { IAdminPageProperties } from '../../IAdminPageProperties';
import { AdminTable } from '../../table/AdminTable';
import { StatusEntityAdminDomain } from './store/StatusEntityAdminDomain';

export interface IStatusEntityAdminTableProperties extends IAdminPageProperties {}

export const StatusEntityAdminTable = observer(({ layoutDomain }: IStatusEntityAdminTableProperties) => {
  const [domain] = useState(new StatusEntityAdminDomain(layoutDomain));

  return <AdminTable tableDomain={domain} />;
});
