import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IRequirementService } from '../../../../../../../service/requirement/entity/IRequirementService';
import { RequirementType } from '../../../../../../../service/requirement/entity/RequirementType';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { RequirementEntityDrawerUI } from './RequirementEntityDrawerUI';
import { IDataTableDrawerDomain } from '../../../../../../design/dataTable/parts/drawer/IDataTableDrawerDomain';
import { DataTableDrawerDomain } from '../../../../../../design/dataTable/parts/drawer/DataTableDrawerDomain';
import {
  AdditionalColumnType,
} from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';

export class RequirementEntityDrawerDomain
  extends DataTableDrawerDomain<IRequirementModel, IRequirementService>
  implements IDataTableDrawerDomain<IRequirementModel>
{
  public ui: RequirementEntityDrawerUI;
  constructor(
    entityService: IRequirementService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<IRequirementModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new RequirementEntityDrawerUI());
    this.ui = new RequirementEntityDrawerUI();
    this.layoutDomain.drawer.closeDrawer = () => {
      if (!this.isSaving.entity) {
        const drawerUi = this.layoutDomain.drawer.ui
        drawerUi.closeActiveDrawer()
      }
      this.ui.isEditMode.setValue(false)
    }
  }
  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependencies();
  }

  async loadDependencies() {
    const [
      specificationsSearchResult,
      entitiesSearchResult,
      performersSearchResult,
      tagCategoriesSearchResult,
      specificationCategorySearchResult,
      categoriesSearchResult,
      usersSearchResult,
      vendorsSearchResult,
      vendorRequirementsSearchResult,
    ] = await Promise.all([
      this.rootPrivateServices.specification.entity.search({ limit: 100000 }),
      this.rootPrivateServices.tag.entity.search({ limit: 1000000 }),
      this.rootPrivateServices.performer.type.search({ limit: 1000000 }),
      this.rootPrivateServices.tag.category.search({ limit: 1000000 }),
      this.rootPrivateServices.specification.category.search({ limit: 1000000 }),
      this.rootPrivateServices.requirement.category.search({ limit: 100000 }),
      this.rootPrivateServices.user.entity.search({ limit: 100000 }),
      this.rootPrivateServices.vendor.entity.search({ limit: 100000 }),
      this.rootPrivateServices.requirement.vendor.search({ limit: 100000 })
    ]);

    const [
      additionalColumnsSearchResult,
      additionalColumnsContentSearchResult,
    ] = await Promise.all(
      [
        this.rootPrivateServices.additionalColumn.entity.search({ limit: 100000 }),
        this.rootPrivateServices.additionalColumn.content.search({ limit: 100000 }),
      ],
    );

    this.ui.specifications.setList(specificationsSearchResult.data);
    this.ui.tags.setList(entitiesSearchResult.data);
    this.ui.performers.setList(performersSearchResult.data);
    this.ui.tagCategory.setList(tagCategoriesSearchResult.data);
    this.ui.specificationsCategories.setList(specificationCategorySearchResult.data);
    this.ui.categories.setList(categoriesSearchResult.data);
    this.ui.users.setList(usersSearchResult.data);
    this.ui.vendors.setList(vendorsSearchResult.data);
    this.ui.vendorsRequirements.setList(vendorRequirementsSearchResult.data);
    this.ui.additionalColumns.setList(additionalColumnsSearchResult.data);
    this.ui.additionalColumnContent.setList(additionalColumnsContentSearchResult.data);
    this.ui.additionalColumnContentToSet.setList(this.getRequirementAdditionalColumns(this.ui.model.entity.id, this));
    this.ui.requirementVendorsToSet.setList(this.getRequirementVendor(this.ui.model.entity.id, this).map((item) => item.id));
  }

  async onSaveUpdates({ additionalColumns = null, vendors = null }) {
    await super.onSaveUpdates({ additionalColumns, vendors });
    this.dataTableDomain.loadDetail()
  }

  generateShortName = async (): Promise<string> => {
    const categoryId = this.ui.model.entity.categoryId || null;
    if (categoryId && this.ui.isCanGenerateShortName.value === true) {
      const category = await this.rootPrivateServices.requirement.category.getById(categoryId);
      const categoryRequirements = await this.rootPrivateServices.requirement.entity.search({
        filter: { categoryId: { equal: categoryId }, type: { equal: RequirementType.system } },
      });
      let namesNumbers = categoryRequirements.data.map((requirement) => {
        return requirement?.shortName?.includes(category?.shortName)
          ? Number(requirement?.shortName?.replace(category?.shortName, ''))
          : 0;
      });

      namesNumbers = namesNumbers.filter(function (value) {
        return !Number.isNaN(value);
      });

      namesNumbers.push(0);
      const lastNameIndex = Math.max(...namesNumbers);
      const newNameIndex = Math.round(lastNameIndex + 1);
      const categoryName = category?.shortName || '';
      let generatedShortName = `${categoryName}${newNameIndex}`;
      if (newNameIndex === NaN) {
        generatedShortName = '';
      }
      this.ui.model.entity.shortName = generatedShortName;
    }

    return '';
  };

  getRequirementVendor = (requirementId, domain) => {
    return domain.dataTableDomain.detailDomain.ui.detailEntities.list.filter((detail) => {
      if (!domain.dataTableDomain.detailDomain.ui.isArray.value) {
        return detail[domain.dataTableDomain.detailDomain.ui.detailKey.value] === requirementId;
      } else {
        return detail[domain.dataTableDomain.detailDomain.ui.detailKey.value]?.some((item) => {
          return item === requirementId;
        });
      }
    });
  };

  getRequirementAdditionalColumns = (requirementId, domain) => {
    return domain.dataTableDomain.detailDomain.ui.secondDetailEntities.list.filter((detail) => {
      if (domain.dataTableDomain.detailDomain.ui.isSecondArray.value === false) {
        return detail[domain.dataTableDomain.detailDomain.ui.secondDetailKey.value] === requirementId;
      } else {
        return detail[domain.dataTableDomain.detailDomain.ui.secondDetailKey.value].some((item) => {
          return item === requirementId;
        });
      }
    }).map(item => item.id);
  };

  getRequirementsAdditionalColumnsByIds = async (ids: string[]) => {
    return this.rootPrivateServices.additionalColumn.entity.search({ limit: 10000, filter: { ids: { in: ids } } });
  };

  disabledColumns = [AdditionalColumnType.UPLOAD_FILE, AdditionalColumnType.DOWNLOAD_FILE];

  getAdditionalColumnContentsToUpdateByAction = async (): Promise<{ deleted: string[], newest: string[] }> => {
    const prevContent = await this.getRequirementAdditionalColumns(this.ui.model.entity.id, this);
    const notSavedContent = this.ui.additionalColumnContentToSet.list;

    const newestContent = notSavedContent.filter(item => !prevContent.includes(item));
    const deletedContent = prevContent.filter(item => !notSavedContent.includes(item));

    return { deleted: deletedContent, newest: newestContent }
  }

  getVendorsToUpdateByAction = async (): Promise<{ deleted: string[], newest: string[] }> => {
    const prevContent = await this.getRequirementVendor(this.ui.model.entity.id, this).map(item => item.id);
    const notSavedContent = this.ui.requirementVendorsToSet.list;

    const newestContent = notSavedContent.filter(item => !prevContent.includes(item));
    const deletedContent = prevContent.filter(item => !notSavedContent.includes(item));

    return { deleted: deletedContent, newest: newestContent }
  }

}
