import { Close } from '@mui/icons-material';
import { Button, Divider, FormControlLabel, Grid, IconButton, Switch, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { IValidationErrorData } from '../../../../../../common/error/IValidationErrorData';
import { Colors } from '../../../../../../view/design/color/Colors';
import { FormModalContainer } from '../../../../../../view/design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../view/design/form/FormActions';
import { FormAutocomplete } from '../../../../../../view/design/form/FormAutocomplete';
import { FormBox } from '../../../../../../view/design/form/FormBox';
import { FormTextField } from '../../../../../../view/design/form/FormTextField';
import { FormTextLabel } from '../../../../../../view/design/form/FormTextLabel';
import { IRiskManagerQuestionAnswerModel } from '../../../../service/question/answer/IRiskManagerQuestionAnswerModel';
import { RiskManagerStaticQuestionTypeArray } from '../../../../service/question/static/RiskManagerStaticQuestionType';
import { RiskManagerStaticQuestionFormDomain } from './RiskManagerStaticQuestionFormDomain';

export interface IRiskManagerStaticQuestionFormProperties {
  domain: RiskManagerStaticQuestionFormDomain;
}

export const RiskManagerQuestionForm = observer(({ domain }: IRiskManagerStaticQuestionFormProperties) => {
  const answersEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    answersEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const classes = useStyles();
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <NameField domain={domain} />
      <DescriptionField domain={domain} />
      <InputTypeField domain={domain} />
      <SortIndexField domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const NameField = observer(({ domain }: { domain: RiskManagerStaticQuestionFormDomain }) => {
  return (
    <FormTextField
      required={true}
      multiline={false}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const DescriptionField = observer(({ domain }: { domain: RiskManagerStaticQuestionFormDomain }) => {
  return (
    <FormTextField
      required={true}
      multiline={true}
      rows={4}
      label={'Описание'}
      value={domain.ui.model.entity.description || ''}
      onChange={domain.getUpdateFieldHandler('description')}
      errorMessage={domain.getValidationErrorFor('description')?.message}
    />
  );
});

const InputTypeField = observer(({ domain }: { domain: RiskManagerStaticQuestionFormDomain }) => {
  const answerPresetsValues = [
    ...RiskManagerStaticQuestionTypeArray.map((inputType) => ({
      value: inputType,
      name: domain.dataTableDomain.ui.translateType(inputType),
    })),
  ];
  return (
    <FormAutocomplete
      values={answerPresetsValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.type = newValue?.value;
      }}
      key="type"
      errorMessage={domain.getValidationErrorFor('type')?.message}
      selected={domain.ui.model.entity.type}
      label="Тип поля"
    />
  );
});

const SortIndexField = observer(({ domain }: { domain: RiskManagerStaticQuestionFormDomain }) => {
  return (
    <FormTextField
      type="text"
      key="sortIndex"
      required={true}
      label={'Порядок сортировки'}
      value={domain.ui.model.entity.sortIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('sortIndex')}
      errorMessage={domain.getValidationErrorFor('sortIndex')?.message}
    />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  answerTitleContainer: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  answerContainer: {
    // padding: theme.spacing(2),
    height: '500px',
    width: '100%',
    // overflow: 'scroll',
    overflow: 'scroll',
    overflowY: 'scroll',
  },
}));
