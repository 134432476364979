import { RestCRUDService } from '../common/rest/RestCRUDService';
import { CRUDServiceType } from '../common/service/CRUDServiceType';
import { IProjectModel } from './IProjectModel';
import { IProjectSearch } from './IProjectSearch';
import { IProjectService } from './IProjectService';

export class RestProjectService extends RestCRUDService<IProjectModel, IProjectSearch> implements IProjectService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  async getProjectApplicationsCounter(projectId: string): Promise<any> {
    const url = `${this.getEndpoint()}/${projectId}/chat/counter`;
    // return [];
    return this.request().url(url).get().json<any>().catch(this.handleError);
  }

  async getProjectsApplicationsCounters(searchRequest: IProjectSearch): Promise<any> {
    const url = `${this.getEndpoint()}/application/chat/counters`;
    return [];
    return this.request().url(url).post(searchRequest).json<any>().catch(this.handleError);
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/project`;
  }

  async post(url: string, data: any): Promise<any> {
    return this.request().url(url).post(data);
  }

  async getReport(applicationId, reportFormat, projectName, audit, isNeedColor): Promise<any> {
    const url = `${this.getEndpoint()}/questionnaire/${reportFormat}`;

    const doc = await this.post(url, {
      name: projectName || 'Отчёт',
      applicationId,
      audit,
      isNeedColor,
    });

    const blobDoc = await doc.blob();
    return blobDoc;
  }

  async getCount(){
      const url = `${this.getEndpoint()}/getCount`;
  
      return this.request().url(url).get().json<any>().catch(this.handleError);
  }

}
