import { Box, Typography } from '@mui/material';
import React from 'react';

import { IUnitModel } from '../../../../../../service/unit/interface/IUnitModel';
import { IPassingListPageDomain } from '../../store/IPassingListPageDomain';
import { PassingListUserCard } from '../PassingListUserCard';

interface Props {
  title: string;
  unitList: IUnitModel[];
  domain: IPassingListPageDomain;
}

export const GroupCardComponentList = ({ title, domain, unitList }: Props) => {
  if (!unitList.length) {
    return null;
  }

  return (
    <Box className="group_important" sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Typography variant="overline" sx={{ color: 'rgba(255, 255, 255, 0.7)', letterSpacing: '1.5px' }}>
        {title}
      </Typography>
      {unitList.map((unit) => {
        const unitResult = domain.ui.passingReslutList.list.find((unitRes) => unitRes.unitId === unit.id);
        return (
          unitResult && (
            <PassingListUserCard
              title={unit.settings.titleHeading}
              id={unit.id}
              action={domain.redirect}
              score={unit.score}
              totalUnitResult={unitResult.totalUnitResult}
              isPassed={unitResult.isPassed}
            />
          )
        );
      })}
    </Box>
  );
};
