import { ICoreCRUDService } from '../coreCommon/common/service/ICoreCRUDService';
import { IFileStoreModel } from './IFileStoreModel';

export interface IFileStoreService extends ICoreCRUDService<IFileStoreModel> {
  uploadImage(data: any, fileType: FileType): Promise<any>;
  deleteByFile(fileName: string, fileType: FileType): Promise<any>;
}

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  DOC = 'doc',
}
