import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITask, ITaskLanguage } from '../../../../../../service/unit/interface/IUnitPractice';
import { PassingCodeEditor } from '../../../../../design/codeEditor/PassingCodeEditor';
import { IPassingCodeEditorDomain } from '../../../../../design/codeEditor/passingStore/IPassingCodeEditorDomain';
import { IPassingPracticeDomain } from './store/IPassingPracticeDomain';

export interface IPassingTask {
  task: ITask;
  activeLang: ITaskLanguage;
  passingCodeEditorDomain?: IPassingCodeEditorDomain;
  rootDomain: IPassingPracticeDomain;
  previewAnswer?: { result: number };
  setShowSidebar?: (value: boolean) => void;
}

export const PassingTask = observer(
  ({ passingCodeEditorDomain, task, activeLang, rootDomain, previewAnswer, setShowSidebar }: IPassingTask) => {
    const currentAnswer =
      rootDomain.ui.answersList.list.filter((answer) => answer.task === task.pseudoId)[0] || previewAnswer;
    const canCheck = currentAnswer && rootDomain?.canCheckTask(currentAnswer);
    return (
      <>
        {currentAnswer && (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'hidden' }}>
            {!rootDomain?.ui.isFullScreen.value && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  flex: 1,
                  height: '100%',
                  overflowY: 'auto',
                  paddingRight: '24px',
                }}
              >
                {!canCheck && (
                  <Box sx={{ background: 'rgba(68, 79, 85, 1)', padding: '24px', borderRadius: '8px' }}>
                    {currentAnswer?.result === 1 ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography variant="h3">Поздравляем!</Typography>
                        <Typography variant="h3">Вы успешно выполнили задание {task.sortIndex}!</Typography>
                        <Typography sx={{ color: 'rgba(255, 255, 255, 1)' }} variant="body1">
                          Чемпион по безопасности - это про вас!
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography variant="h3">К сожалению вы не справились с заданием.</Typography>
                        <Typography sx={{ color: 'rgba(255, 255, 255, 1)' }} variant="body1">
                          Мы рекомендуем вам внимательно изучить теорию и повторить попытку.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
                <Typography variant="h6">{task.name}</Typography>
                <Typography variant="body1">{task.explanation}</Typography>
              </Box>
            )}

            <Box sx={{ flex: 1 }}>
              <PassingCodeEditor
                task={task}
                lang={activeLang}
                domain={passingCodeEditorDomain as IPassingCodeEditorDomain}
                rootDomain={rootDomain as IPassingPracticeDomain}
                setShowSidebar={setShowSidebar}
              />
            </Box>
          </Box>
        )}
      </>
    );
  },
);
