import { Close } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import { observer } from 'mobx-react';
// @ts-ignore
import React, { useEffect } from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';
import { FileType } from '../../../service/fileStore/IFileStoreService';
import { IFileUploadDomain } from './store/IFileUploadDomain';

export interface IFileUpload extends ICommonProperties {
  defaultValue: string;
  originalFileName: string;
  cancelFile?: any;
  isCanCancel: boolean;
  uploadDomain: IFileUploadDomain;
  sx?: any;
}
export const DownloadFileColumn = observer(
  ({ defaultValue = '', originalFileName = '', cancelFile, isCanCancel, uploadDomain, sx = {} }: IFileUpload) => {
    return (
      <React.Fragment>
        <section className="container" style={{ margin: '10px 0 0 0' }}>
          <Box
            sx={{
              padding: '16px 12px',
              borderRadius: '8px',
              border: `2px dashed rgba(0, 0, 0, 0.12) `,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              background: 'rgba(0, 0, 0, 0.04)',
              width: '30%',
              ...sx,
            }}
          >
            <Link
              style={{
                color: '#1976D2',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                fetch(defaultValue, { method: 'GET' })
                  .then((res) => {
                    return res.blob();
                  })
                  .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = originalFileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout((_) => {
                      window.URL.revokeObjectURL(url);
                    }, 60000);
                    a.remove();
                  })
                  .catch((err) => {
                    console.error('err: ', err);
                  });
              }}
            >
              {originalFileName}
            </Link>
            {isCanCancel && (
              <Close
                onClick={() => {
                  uploadDomain.deleteFileFromStore(
                    defaultValue.split('/')[defaultValue.split('/').length - 1],
                    FileType.DOC,
                  );
                  cancelFile();
                }}
                sx={{ cursor: 'pointer' }}
              />
            )}
          </Box>
        </section>
      </React.Fragment>
    );
  },
);
